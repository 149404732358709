
import React, { Component } from "react";
import axios from "axios";
import _ from 'lodash';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactDatetime from "react-datetime";
import 'react-toastify/dist/ReactToastify.css';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

import constant, { SUBSCRIBE_TIME_IN_SECONDS, SUBSCRIBE_SKIP_COUNT } from "../../shared/constant";
import { GApageView, GAevent } from "../../shared/gaUtils";
import html2pdf from "html2pdf.js";
import calendar_icon from '../../assets/images/icons/calendar-icon.png';
import AddIcon from '@mui/icons-material/Add';

import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '600px',
  width: '100%',
  bgcolor: '#fff',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 3,
  textAlign: 'center',
  padding: 2
};

const moment = require('moment');

class PayReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      commissions: [],
      expenses: [],
      employeePayouts: [],
      totalsPaidByEmployees: [],
      errors: {},
      dateFrom: moment().subtract(7, 'd').format('MM/DD/YYYY'),
      dateTo: moment().format('MM/DD/YYYY'),
      editPayouts: false,
      toastOpen: false,
      notificationText: "",
      notifyType: "error",
      subscribeQuestion: false,
      subscriptionText: '',
      employeeCount: 0,
      endDemo: false
    }
  }

  intervalID = 0;

  componentDidMount() {
    document.body.classList.add("pay-report-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role === constant['ROLE']['OWNER'] || this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) {
      // Check if pricing param exists
      const queryString = require('query-string');
      const parsed = queryString.parse(this.props.location.search);
      if (parsed.currentDay && parsed.currentDay === 'true') {
        this.setState({ dateFrom: moment().format('MM/DD/YYYY'), dateTo: moment().format('MM/DD/YYYY') }, () => {
          this.getPayrollData();
        })
      } else {
        this.getPayrollData();
      }
      this.getEmployeeCount();
      GApageView('Pay Report Page');
      GAevent('User', 'Pay Report Page', `User: ${this.props.auth.user.name}  visited Pay Report page`);
      if(this.props.history.location.search && localStorage.getItem('demoStatus') === 'yes') {
        this.setState ({
          endDemo: true
        })
        localStorage.setItem('demoStatus', 'no');
      }
    } else {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("pay-report-page");
    document.body.classList.remove("sidebar-collapse");
    clearInterval(this.intervalID);
  }

  getEmployeeCount() {
    axios
      .post('/api/users/employeeCount',
        {
          userId: this.props.auth.user.id
        })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ employeeCount: res.data });
        }
      });
  }

  getPdf = () => {
    const element = document.getElementById('pay-report');
    let clonedElement = element.cloneNode(true);
    let hideList = clonedElement.getElementsByClassName('hide-printing');
    for (let i = hideList.length - 1; i >= 0; i--) {
      let childNode = hideList[i];
      childNode.parentNode.removeChild(childNode);
    }
    const opt = {
      margin: [0.1, 0.1, 0.1, 0.1],
      filename: 'pay-report.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 2,
        letterRendering: true,
        scrollY: 0
      },
      jsPDF: { orientation: 'landscape', unit: 'in', format: 'a4' },
      pagesplit: true,
      putOnlyUsedFonts: true,
      // pagebreak: { mode: 'avoid-all' }
    };
    html2pdf().set(opt).from(clonedElement).save().then((res) => {
      clonedElement.remove();
    }).catch((err) => {
      console.log("RESULT ->", err);
      clonedElement.remove();
    });
  }

  getPayrollData = () => {
    const requests = [];
    requests.push(axios.post('/api/commissions/commissions',
      {
        userId: this.props.auth.user.id,
        dateFrom: moment(this.state.dateFrom).utc(),
        dateTo: moment(this.state.dateTo).endOf('day').utc()
      }));
    requests.push(axios.post('/api/expenses/expenses',
      {
        userId: this.props.auth.user.id,
        dateFrom: moment(this.state.dateFrom).utc(),
        dateTo: moment(this.state.dateTo).endOf('day').utc()
      }));
    axios.all(requests).then(axios.spread((...responses) => {
      // Parse Commission Data
      this.setState({ commissions: responses[0].data });
      this.setState({ expenses: responses[1].data });
      let employeePayouts = [];
      responses[0].data.forEach(commission => {
        commission.employeePayouts.forEach((employeePayoutDetail) => {
          employeePayoutDetail.payouts.forEach((payoutDetailObj) => {
            let totalPaid = 0;
            payoutDetailObj.transactions.forEach((transaction) => {
              totalPaid += transaction.amountPaid;
            });
            const payoutObj = {
              employeeId: employeePayoutDetail.employee.id,
              employeeName: employeePayoutDetail.employee.name,
              total: payoutDetailObj.total,
              owed: (payoutDetailObj.total - totalPaid).toFixed(2),
              transactions: payoutDetailObj.transactions,
              customerName: payoutDetailObj.customerName,
              type: 'Commission',
              commissionId: commission._id,
              employeePayoutId: employeePayoutDetail._id,
              payoutId: payoutDetailObj._id
            }
            employeePayouts.push(payoutObj);
          });
        });
      });

      // Parse Expense Data
      const expenses = _.filter(responses[1].data, { parentId: this.props.auth.user.id });
      expenses.forEach((expense) => {
        let totalPaid = 0;
        expense.transactions.forEach((transaction) => {
          totalPaid += transaction.amountPaid;
        });
        const payoutObj = {
          employeeId: expense.employeeId,
          employeeName: expense.employeeName,
          total: expense.total,
          owed: (expense.total - totalPaid).toFixed(2),
          transactions: expense.transactions,
          customerName: 'Expense',
          type: 'Expense',
          expenseId: expense._id
        }
        employeePayouts.push(payoutObj);
      });

      const payoutsByEmployees = _.groupBy(employeePayouts, 'employeeId');
      let totalsPaidByEmployees = [];
      _.mapKeys(payoutsByEmployees, (payoutDetails, employeeId) => {
        let totalPaid = 0;
        let totalExpenses = 0;
        payoutDetails.forEach((payoutDetail) => {
          if (payoutDetail.type === 'Commission')
            totalPaid += (payoutDetail.total - payoutDetail.owed);
          else {
            totalExpenses += (payoutDetail.total - payoutDetail.owed);
          }
        });
        if (totalPaid > 0) {
          totalsPaidByEmployees.push({
            employeeName: payoutDetails[0].employeeName,
            totalPaid: totalPaid.toFixed(2),
            totalExpenses: totalExpenses.toFixed(2),
            grossPaid: (totalPaid - totalExpenses).toFixed(2)
          });
        }
      });

      // Sort by alphabetically
      const sortedEmployeePayouts = employeePayouts.sort((payoutA, payoutB) => {
        var nameA = payoutA.employeeName.toUpperCase();
        var nameB = payoutB.employeeName.toUpperCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
      const sortedTotalsPaidByEmployees = totalsPaidByEmployees.sort((totalA, totalB) => {
        var nameA = totalA.employeeName.toUpperCase();
        var nameB = totalB.employeeName.toUpperCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
      this.setState({ employeePayouts: sortedEmployeePayouts, totalsPaidByEmployees: sortedTotalsPaidByEmployees });
    })).catch((err) => {
      console.log(err);
      this.setState({
        toastOpen: true,
        notificationText: err.message || 'Error occured while retrieving payment data',
        notifyType: "error"
      });
    });
  }

  handleDateFromChange = (date) => {
    this.setState({ dateFrom: moment(date).format('MM/DD/YYYY') });
  }

  handleDateToChange = (date) => {
    this.setState({ dateTo: moment(date).format('MM/DD/YYYY') });
  }

  editPayouts = () => {
    if (!this.state.editPayouts)
      this.setState({ editPayouts: true });
    else
      this.setState({ editPayouts: false });
  }

  removetransaction = (payoutDetail, transaction) => {
    if (payoutDetail.type === 'Commission') {
      let commission = _.find(this.state.commissions, { _id: payoutDetail.commissionId });
      let employeePayout = _.find(commission.employeePayouts, { _id: payoutDetail.employeePayoutId });
      let payoutObj = _.find(employeePayout.payouts, { _id: payoutDetail.payoutId });
      const tIndex = _.findIndex(payoutObj.transactions, { _id: transaction._id });
      payoutObj.transactions.splice(tIndex, 1);
      payoutObj.status = constant['PAYOUT_STATUS']['OPEN'];
      axios.put(`/api/commissions/commissions/${commission._id}`, { commission: commission })
        .then((res) => {
          this.setState({
            toastOpen: true,
            notificationText: "Successfully removed the transaction.",
            notifyType: "success"
          });
          this.getPayrollData();
        }).catch((err) => {
          console.log(err);
          this.setState({
            toastOpen: true,
            notificationText: "Failed removing the transaction.",
            notifyType: "error"
          });
        });
    } else {
      let expense = _.find(this.state.expenses, { _id: payoutDetail.expenseId });
      const tIndex = _.findIndex(expense.transactions, { _id: transaction._id });
      expense.transactions.splice(tIndex, 1);
      expense.status = constant['PAYOUT_STATUS']['OPEN'];
      axios.put(`/api/expenses/${expense._id}`, { expense: expense })
        .then((res) => {
          this.setState({
            toastOpen: true,
            notificationText: "Successfully removed the transaction.",
            notifyType: "success"
          });
          this.getPayrollData();
        }).catch((err) => {
          console.log(err);
          this.setState({
            toastOpen: true,
            notificationText: "Failed removing the transaction.",
            notifyType: "error"
          });
        });
    }
  }

  renderPayoutsData() {
    const moment = require('moment');
    let payrollHistory = [];
    this.state.employeePayouts.forEach((payoutDetail) => {
      payoutDetail.transactions.forEach((transaction) => {
        const elementPayoutDetail =
          (
            <>
              <div className="record">
                <div>
                  {payoutDetail.employeeName}
                </div>
                <div>
                  {moment(transaction.datePaid).format('MM/DD/YYYY')}
                </div>
                <div>
                  {payoutDetail.customerName}
                </div>
                <div>
                  {transaction.amountPaid.toFixed(2)}
                </div>
                <div>
                  {transaction.reason}
                </div>
                <div className="hide-printing">
                  <div className="remove-icon" onClick={() => this.removetransaction(payoutDetail, transaction)}>
                    <AddIcon fontSize="medium" />
                  </div>
                </div>
              </div>
              <div className="record-mobile">
                <div>
                  <span>Employee:</span>
                  <span>{payoutDetail.employeeName}</span>
                </div>
                <div>
                  <span>Date:</span>
                  <span>{moment(transaction.datePaid).format('MM/DD/YYYY')}</span>
                </div>
                <div>
                  <span>Customer:</span>
                  <span>{payoutDetail.customerName}</span>
                </div>
                <div>
                  <span>Amount:</span>
                  <span>{transaction.amountPaid.toFixed(2)}</span>
                </div>
                <div>
                  <span>Reason:</span>
                  <span>{transaction.reason}</span>
                </div>
                <div className="hide-printing">
                  <span>Action:</span>
                  <span>
                    <div className="remove-icon" onClick={() => this.removetransaction(payoutDetail, transaction)}>
                      <AddIcon fontSize="medium" />
                    </div>
                  </span>
                </div>
              </div>
            </>
          )
        payrollHistory.push(elementPayoutDetail);
      });
    });
    // return payrollHistory;
    return <>
      <div className="headers">
        {/* <div>No</div> */}
        <div>Employee</div>
        <div>Date</div>
        <div>Customer</div>
        <div>Amount</div>
        <div>Reason</div>
        <div className="hide-printing">Action</div>
      </div>
      <div className="records">
        {payrollHistory}
      </div>
    </>
  }

  renderTotalsByEmployeeData() {
    return <>
      <div className="headers">
        <div>Employee</div>
        <div>Total Paid</div>
        <div>Total Expenses</div>
        <div>Gross Paid</div>
      </div>
      <div className="records">
        {
          this.state.totalsPaidByEmployees.map((data) => {
            return (
              <>
                <div className="record">
                  <div>{data.employeeName}</div>
                  <div>{data.totalPaid}</div>
                  <div>{data.totalExpenses}</div>
                  <div>{data.grossPaid}</div>
                </div>
                <div className="record-mobile">
                  <div>
                    <span>Employee:</span>
                    <span>{data.employeeName}</span>
                  </div>
                  <div>
                    <span>Total Paid:</span>
                    <span>{data.totalPaid}</span>
                  </div>
                  <div>
                    <span>Total Expenses:</span>
                    <span>{data.totalExpenses}</span>
                  </div>
                  <div>
                    <span>Gross Paid:</span>
                    <span>{data.grossPaid}</span>
                  </div>
                </div>
              </>
            )
          })
        }
      </div>
    </>
  }

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  handleClose = () => {
    this.setState({
      toastOpen: false
    });
  };

  goToSubscribe = () => {
    this.props.history.push("/account");
  }

  noSubscribe = () => {
    let subSkippedCount = localStorage.getItem("SubSkippedCount");
    if (subSkippedCount === null) subSkippedCount = 1;
    else subSkippedCount = parseInt(subSkippedCount) + 1;
    localStorage.setItem("SubSkippedCount", subSkippedCount);
    this.setState({
      subscribeQuestion: false
    });
  }

  goToDashboard = () => {
    this.setState({
      endDemo: false
    })
    this.props.history.push("/");
  }
  
  render() {
    const { toastOpen, notificationText, notifyType, subscriptionText } = this.state;

    return (
      <>
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={notifyType} sx={{ width: '100%', fontSize: 24 }}>
            {notificationText}
          </Alert>
        </Snackbar>
        <div className="report-page" id="pay-report">
          <div className="top-content">
            <div className="check-date">
              <div className="title">Pay Period</div>
              <div className="date-range">
                <div className="label">Date from:</div>
                <div className="calendar">
                  <div>
                    <img src={calendar_icon} alt="calendar icon" />
                  </div>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Datetime Picker Here",
                      value: this.state.dateFrom
                    }}
                    closeOnSelect={true}
                    timeFormat={false}
                    onChange={this.handleDateFromChange}
                  ></ReactDatetime>
                </div>
                <div className="label">Date to</div>
                <div className="calendar">
                  <div>
                    <img src={calendar_icon} alt="calendar icon" />
                  </div>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Datetime Picker Here",
                      value: this.state.dateTo,
                    }}
                    closeOnSelect={true}
                    timeFormat={false}
                    onChange={this.handleDateToChange}
                  ></ReactDatetime>
                </div>
              </div>
              <div className="actions hide-printing">
                <div className="btn-blue" onClick={this.getPayrollData}>
                  Get Report
                </div>
                <div className="btn-blue" onClick={this.getPdf}>
                  Print
                </div>
              </div>
            </div>
            <div className="totals-by-employee">
              <div className="title">Totals By Employee</div>
              <div className="employee-table">
                {this.state.totalsPaidByEmployees.length > 0 ?
                  this.renderTotalsByEmployeeData()
                  :
                  <h4>No Payroll Data Found</h4>
                }
              </div>
            </div>
          </div>
          <div className="bottom-content">
            <div className="payroll">
              <div className="title">
                <div>Payroll</div>
              </div>
              <div className="payroll-table">
                {
                  this.renderPayoutsData()
                }
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.subscribeQuestion}
        >
          <Box sx={style}>
            <h2 className="mt-4 mb-2 mx-2 font-bold" style={{ color: '#22395B' }}>Welcome to CommissionPayPro!</h2>
            <h4 className="mt-2 mb-4 mx-2" style={{ color: '#7B8FB2' }}>
              {
                subscriptionText
              }
            </h4>
            <div className="display-flex justify-center align-center mb-4">
              <div className="btn-blue mr-2" style={{ padding: '8px 18px' }} onClick={this.goToSubscribe}>
                Subscribe
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={this.state.endDemo}
        >
          <Box sx={style}>
            <h2 className="mt-4 mb-2 mx-2 font-bold" style={{ color: '#22395B' }}>This is the Pay Report page.</h2>
            <h4 className="mt-2 mb-4 mx-2" style={{ color: '#7B8FB2' }}>Now try out all the navigation buttons to the left.</h4>
            <div className="display-flex justify-center align-center mb-4">
              <div className="btn-blue mr-2" style={{ padding: '8px 18px' }} onClick={this.goToDashboard}>
                Go to Dashboard
              </div>
            </div>
          </Box>
        </Modal>
      </>
    )
  }
}

PayReport.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps
)(PayReport)