import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import { updateUser } from "../../actions/authActions";

// material
import { Link } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Grid } from "@mui/material";
import Slide from '@mui/material/Slide';

// core components
import lineFloat from "../../assets/images/login/line.png";
import curveFloat from "../../assets/images/login/curve.png";
import moonFloat from "../../assets/images/login/moon.png";
import banner from "../../assets/images/login/banner.png";
import logo from "../../assets/images/logo.png";

class Success extends Component {
	constructor(props) {
		super(props);
		this.state = {
			toastOpen: false,
      notificationText: '',
      success: false,
			sessionId: ''
		}
	}

	componentDidMount() {
		document.body.classList.add("login-page");
		document.body.classList.add("sidebar-collapse");
		document.documentElement.classList.remove("nav-open");
		window.scrollTo(0, 0);
		document.body.scrollTop = 0;
	}

	componentWillUnmount() {
		document.body.classList.remove("login-page");
		document.body.classList.remove("sidebar-collapse");
	}

	onSubmit = e => {
		e.preventDefault()
		axios.post('/api/stripe/payment-success', {
			userId: this.props.auth.user.id, 
			sessionId: this.props.auth.modeType === false ? this.props.auth.user.stripeSessionId: this.props.auth.user.stripeSessionTestId,
      modeType: this.props.auth.modeType
		}).then(async (res) => {
			await this.props.updateUser(res.data.user)
      this.props.history.push('/account');
      const startData = {
        name: this.props.auth.user.name, 
        email: this.props.auth.user.email,
        membership: res.data.subscription.plan.name
      }
      axios.post('/api/users/startMembership', startData)
		}).catch((error) => {
			console.log("error:::", error)
		})
	}

	render() {
		const { toastOpen, notificationText, success } = this.state;
		return (
			<div className="auth-page">
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={success ? 'success' : 'error'} sx={{ width: '100%', fontSize: 24 }}>
            {notificationText}
          </Alert>
        </Snackbar>
        <div className="line-float">
          <img className="img-responsive" src={lineFloat} alt={'line-float'} />
        </div>
        <div className="curve-float">
          <img className="img-responsive" src={curveFloat} alt={'curve-float'} />
        </div>
        <div className="moon-float">
          <img className="img-responsive" src={moonFloat} alt={'moon-float'} />
        </div>
        <div className="content">
          <Grid item container spacing={0} className="content-container">
            <Grid item xl={8} lg={7} md={6} sm={12} xs={12} className="left">
              <div className="title-container display-flex justify-start align-center">
                <div className="page-title-line"></div>
                <div className="page-title">Easy Commission Calculations</div>
              </div>
              <div className="sub-title">
                All your commission calculations in one place
              </div>
              <div className="w-100 display-flex justify-end mobile-login">
                <div className="auth-form mobile-form">
                  <div className="top display-flex justify-start align-center">
                    <div>
                      <img className="img-responsive" alt="logo" src={logo} />
                    </div>
                    <div>
											Payment Successful
                    </div>
                  </div>
                  <div style={{ height: '40px' }}></div>
                  <div className="bottom display-flex justify-between align-center">
                    <div className={"bubbly-button"} style={{ width: '100%' }} onClick={this.onSubmit}>
                      <span>Proceed</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-image">
                <img className="img-responsive" alt={'page-img'} src={banner} />
              </div>
              <div className="display-flex justify-start align-center terms-policy">
                <a href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit" target="_blank" rel="noopener noreferrer">TERMS OF SERVICE</a>
                <Link to="/contact-us">CONTACT US</Link>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={6} sm={12} xs={12} className="right">
							<div className="w-100 display-flex justify-end">
                <div className="auth-form">
                  <div className="top display-flex justify-start align-center">
                    <div>
                      <img className="img-responsive" alt="logo" src={logo} />
                    </div>
                    <div>
                      Payment Successful
                    </div>
                  </div>
                  <div style={{ height: '40px' }}></div>
                  <div className="bottom display-flex justify-between align-center">
                    <div className={"bubbly-button"} style={{ width: '100%' }} onClick={this.onSubmit}>
                      <span>Proceed</span>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
		)
	}
}
Success.propTypes = {
  auth: PropTypes.object.isRequired,
	updateUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
	mapStateToProps, 
	{ updateUser }
	)(Success);