import React, { Component } from 'react';
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Grid } from "@mui/material";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import { loginUser } from "../../actions/authActions";
import constant from "../../shared/constant";
import { GApageView } from "../../shared/gaUtils";
import lineFloat from "../../assets/images/login/line.png";
import curveFloat from "../../assets/images/login/curve.png";
import moonFloat from "../../assets/images/login/moon.png";
import banner from "../../assets/images/login/banner.png";
import logo from "../../assets/images/logo.png";
import InputForm from '../component/InputForm';
import Slide from '@mui/material/Slide';

class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errors: {},
            firstFocus: false,
            lastFocus: false,
            toastOpen: false
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
                toastOpen: true
            });
        }
        if (nextProps.auth.isAuthenticated) {
            if (nextProps.auth.user.role === constant['ROLE']['SUPER_ADMIN']) {
                this.props.history.push("/admin/users");
            } else {
                this.props.history.push("/"); // push user to home page when they login
            }
        }
    }

    componentDidMount() {
        // If logged in and user navigates to Login page, should redirect them to home
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/");
            return;
        }
        document.body.classList.add("login-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        GApageView("Login Page");
    }

    componentWillUnmount() {
        document.body.classList.remove("login-page");
        document.body.classList.remove("sidebar-collapse");
    }

    onChange = e => {
        this.setState({ [e.id]: e.value });
    };

    onSubmit = () => {
        const userData = {
            email: this.state.email,
            password: this.state.password
        };

        this.props.loginUser(userData);
    };

    handleClose = () => {
        this.setState({
            toastOpen: false
        })
    };

    transitionLeft(props) {
        return <Slide {...props} direction="left" />;
    }

    render() {
        const { errors, toastOpen } = this.state;

        return (
            <div className="auth-page">
                <Snackbar
                    open={toastOpen}
                    autoHideDuration={5000}
                    onClose={this.handleClose}
                    TransitionComponent={this.transitionLeft}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                >
                    <Alert severity="error" sx={{ width: '100%', fontSize: 24 }}>
                        {errors.email || errors.password || errors.emailnotfound || errors.passwordincorrect || errors.verifyneeded}
                    </Alert>
                </Snackbar>
                <div className="content">
                    <Grid item container spacing={0} className="content-container items-end">
                        <Grid item xl={8} lg={7} md={6} sm={12} xs={12} className="left">
                            <div className="title-container display-flex justify-start align-center">
                                <div className="page-title-line"></div>
                                <div className="page-title white-text">Easy Commission Calculations</div>
                            </div>
                            <div className="sub-title white-text">
                                All your Commission Calculations in One Place
                            </div>
                            <div className="w-100 display-flex justify-end mobile-login">
                                <div className="auth-form mobile-form">
                                    <div className="top display-flex justify-start align-center">
                                        <div>
                                            <img className="img-responsive" alt="logo" src={logo} />
                                        </div>
                                        <div>
                                            CommissionPayPro
                                        </div>
                                    </div>
                                    <div className="middle">
                                        <div style={{ height: '20px' }}></div>
                                        <InputForm
                                            label={'Email'}
                                            id={'email'}
                                            isAddon={true}
                                            errors={errors.emailnotfound ? true : false}
                                            placeholderText={'william@gmail.com'}
                                            onChange={ev => this.onChange(ev)}
                                            value={this.state.email}
                                        />
                                        <div style={{ height: '60px' }}></div>
                                        <InputForm
                                            label={'Password'}
                                            id={'password'}
                                            isAddon={true}
                                            errors={errors.passwordincorrect ? true : false}
                                            onChange={ev => this.onChange(ev)}
                                            value={this.state.password}
                                            error={errors.password}
                                        />
                                        <div style={{ height: '40px' }}></div>
                                        <div className="display-flex justify-between">
                                            <div className="tos" style={{ width: '50%' }}>
                                                <a
                                                    rel="noopener noreferrer"
                                                    href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit?usp=sharing"
                                                    target="_blank"
                                                >
                                                    You agree to our TOS and Privacy Policy
                                                </a>
                                            </div>
                                            <Link className="forgot-password" to="/reset-password">Forgot Password?</Link>
                                        </div>
                                    </div>
                                    <div style={{ height: "40px" }}></div>
                                    <div className="bottom display-flex justify-between align-center">
                                        <Link to="/register">Create Account</Link>
                                        <div className="bubbly-button" onClick={this.onSubmit}>
                                            <span>Sign In</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="hero-image-box">
                                <img className="img-responsive" alt={'page-mockup-img'} src={banner} />
                            </div>
                        </Grid>
                        <Grid item xl={4} lg={5} md={6} sm={12} xs={12} className="right">
                            <div className="w-100 display-flex justify-end">
                                <div className="auth-form">
                                    <div className="top display-flex justify-start align-center">
                                        <div>
                                            <img className="img-responsive" alt="logo" src={logo} />
                                        </div>
                                        <div>
                                            CommissionPayPro
                                        </div>
                                    </div>
                                    <div className="middle">
                                        <div style={{ height: '20px' }}></div>
                                        <InputForm
                                            label={'Email'}
                                            id={'email'}
                                            isAddon={true}
                                            errors={errors.emailnotfound ? true : false}
                                            placeholderText={'email@address.com'}
                                            onChange={ev => this.onChange(ev)}
                                            value={this.state.email}
                                        />
                                        <div style={{ height: '60px' }}></div>
                                        <InputForm
                                            label={'Password'}
                                            id={'password'}
                                            isAddon={true}
                                            errors={errors.passwordincorrect ? true : false}
                                            onChange={ev => this.onChange(ev)}
                                            value={this.state.password}
                                            error={errors.password}
                                        />
                                        <div style={{ height: '40px' }}></div>
                                        <div className="display-flex justify-between">
                                            <div className="tos" style={{ width: '50%' }}>
                                                <a
                                                    rel="noopener noreferrer"
                                                    href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit?usp=sharing"
                                                    target="_blank"
                                                >
                                                    You agree to our TOS and Privacy Policy
                                                </a>
                                            </div>
                                            <Link className="forgot-password" to="/reset-password">Forgot Password?</Link>
                                        </div>
                                    </div>
                                    <div style={{ height: "40px" }}></div>
                                    <div className="bottom display-flex justify-between align-center">
                                        <Link to="/register">Create Account</Link>
                                        <div className={"bubbly-button"} onClick={this.onSubmit}>
                                            <span>Sign In</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Grid>

                    </Grid>
                    <div className='footer-row'>
                        <div className=''>
                            <a href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit" target="_blank" rel="noopener noreferrer" className='white-text'>TERMS OF SERVICE</a>
                            <Link to="/contact-us" className='white-text'>CONTACT US</Link>
                        </div>

                        <div className="licence">
                            <span className='white-text'>© 2023, Published by <span className="company-name"> My Market Partners</span></span>
                        </div>
                    </div>
                    <div className='animation-bg'>
                        <div className="particle-container">
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                        </div>
                        <div className='grey'></div>
                        <div className="area" >
                            <ul className="circles">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                        <div className='ripple-background'>
                            <div className='circle xxlarge shade1'></div>
                            <div className='circle xlarge shade2'></div>
                            <div className='circle large shade3'></div>
                            <div className='circle mediun shade4'></div>
                            <div className='circle small shade5'></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(Login);