import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import { GAexception } from "../../../shared/gaUtils";

// reactstrap components
import {
  Card,
  CardBody,
  Table,
  Button,
  UncontrolledTooltip
} from "reactstrap";

// core components
// import DropdownScrollNavbar from "../../shared/DropdownScrollNavbar";
import constant from "../../../shared/constant";
import _ from 'lodash';

const token = localStorage.getItem('jwtToken');

class Users extends Component {

  constructor(props) {
    super(props);
    this.state = {
      users: [],
      commissions: [],
      tempUsers: [],
    }
  }

  componentDidMount() {
    document.body.classList.add("admin-users-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) {
      this.getUserList();
      this.getUserCommissions();
    } else {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("admin-users-page");
    document.body.classList.remove("sidebar-collapse");
  }

  getUserList() {
    axios
      .post('/api/users', { userId: this.props.auth.user.id })
      .then((res) => {
        let tempUsers = [];
        res.data.forEach(item => {
          if(!item.dateLastVisit) {
            let tempItem = {...item, dateLastVisit: item.date};
            tempUsers.push(tempItem);
          } else {
            tempUsers.push(item);
          }
        })
        let users = []
        tempUsers.sort(function(a,b){
          return new Date(b.dateLastVisit) - new Date(a.dateLastVisit);
        });
        this.setState({ tempUsers: tempUsers });
        tempUsers.forEach(user => {
          users.push(res.data.filter(item => item._id === user._id)[0]);
        })
        this.setState({ users: users });
      }).catch((err) => {
        console.log(err);
      });
  }

  getUserCommissions() {
    axios
      .post('/api/commissions/commissionCounts', { userId: this.props.auth.user.id })
      .then((res) => {
        const result = _.values(_.groupBy(res.data)).map(d => ({ userId: d[0], count: d.length }));
        this.setState({ commissions: result });
      }).catch((err) => {
        console.log(err);
      });
  }

  cancelSubscription = (user) => {
    fetch('/api/stripe/cancel-subscription', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        subscriptionId: user.stripeSubscriptionId,
        modeType: this.props.auth.modeType
      }),
    }).then((response) => {
      return response.json();
    }).then((response) => {
      axios
        .put(`/api/users/${user._id}`, { level: constant['MEMBERSHIP']['ULTIMATE'], stripeSubscriptionId: null, subscribedDate: null})
        .then((res) => {
          this.getUserList();
        }).catch((err) => {
          console.log(err);
        });
    }).catch((err) => {
      console.log(err);
      GAexception(`Cancelling a subscription failed: ${err}`);
    });
  }

  removeUser(user) {
    axios
      .post(`/api/users/remove/${user._id}`, { userId: this.props.auth.user.id })
      .then((res) => {
        toast.info(res.data.message);
        this.getUserList();
      }).catch((err) => {
        console.log(err);
      });
  }

  updateMembership(user, level) {
    if (level === constant['MEMBERSHIP']['ULTIMATE'] && user.stripeSubscriptionId) {
      this.cancelSubscription(user);
    } else {
      axios
        .put(`/api/users/${user._id}`, { level: level })
        .then((res) => {
          this.getUserList();
        }).catch((err) => {
          console.log(err);
        });
    }
  }

  removeGuestUsers() {
    axios
      .post('/api/users/removeGuests', { userId: this.props.auth.user.id })
      .then((res) => {
        toast.info(res.data.message);
      }).catch((err) => {
        console.log(err);
      });
  }

  getUserMembership(level) {
    if (level === constant['MEMBERSHIP']['STARTER'] || level === constant['MEMBERSHIP']['PLUS'])
      return 'PLUS';
    else if (level === constant['MEMBERSHIP']['PROFESSIONAL']) {
      return 'PROFESSIONAL';
    } else if (level === constant['MEMBERSHIP']['CORPORATE']) {
      return 'CORPORATE';
    } else if (level === constant['MEMBERSHIP']['ULTIMATE']) {
      return 'ULTIMATE';
    } else {
      return 'FREE_TRIAL';
    }
  }

  deleteUser(user) {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='confirm-dialog'>
            <h1>Warning!</h1>
            <p>Are you sure to delete this user and all his employee data?</p>
            <button
              className="btn default-back-color font-crm"
              onClick={() => {
                onClose();
              }}
            >
              Go Back
            </button>
            <button className="btn ml-2  red-alert-color font-crm"
              onClick={() => {
                onClose();
                this.removeUser(user);
              }}>Remove Forever
            </button>
          </div>
        );
      }
    });
  }

  renderUserListTableData() {
    const moment = require('moment');
    // this.state.users.sort(function(a,b){
    //   return new Date(b.date) - new Date(a.date);
    // });
      return this.state.users.map((user) => {
        const commission = _.find(this.state.commissions, { userId: user._id });
        return (
          <tr key="#">
            <td className="text-left">
              <Button
                className="btn-icon btn-google"
                size="sm"
                type="button"
                onClick={() => this.deleteUser(user)}
              >
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </Button>
            </td>
            <td className="text-left">{user.name}</td>
            <td className="text-left">{user.email}</td>
            <td className="text-left">{user.phone}</td>
            <td className="text-left">{moment(user.date).format('MM/DD/YYYY HH:MM A')}</td>
            <td className="text-left">{!user.dateLastVisit ? "" : moment(user.dateLastVisit).format('MM/DD/YYYY HH:MM A')}</td>
            <th className="text-center">{user.employeeCount}</th>
            <th className="text-center">{commission ? commission.count : 0}</th>
            <th className="text-center">${user.totalSales}/{user.salesCount}</th>
            <td className="text-left">{this.getUserMembership(user.level)}</td>
            <td className="text-left">
              {user.level !== constant['MEMBERSHIP']['ULTIMATE'] ?
                <>
                  <Button
                    id="edit-commission"
                    className="btn-cpp mr-1"
                    size="sm"
                    type="button"
                    onClick={() => this.updateMembership(user, constant['MEMBERSHIP']['ULTIMATE'])}
                  >
                    Unlimited
                  </Button>
                  <UncontrolledTooltip
                    delay={100}
                    placement="top"
                    target="edit-commission"
                  >
                    Give User Unlimited Access
                  </UncontrolledTooltip>
                </>
                :
                <>
                  <Button
                    id="edit-commission"
                    className="btn-cpp mr-1"
                    size="sm"
                    type="button"
                    onClick={() => this.updateMembership(user, constant['MEMBERSHIP']['TRIAL'])}
                  >
                    Remove Unlimited
                  </Button>
                  <UncontrolledTooltip
                    delay={100}
                    placement="top"
                    target="edit-commission"
                  >
                    Withhold Unlimited Access
                  </UncontrolledTooltip>
                </>
              }
            </td>
          </tr>
        );
      });
  }

  sortBySign() {
    let tempUsers = [...this.state.users]
    tempUsers.sort(function(a,b){
      return new Date(b.date) - new Date(a.date);
    });
    this.setState({
      users: tempUsers
    })
  }

  sortByVisit() {
    let res = [];
    this.state.tempUsers.forEach(user => {
      res.push(this.state.users.filter(item => item._id === user._id)[0]);
    })
    this.setState({
      users: res
    })
  }

  render() {
    return (
      <>
        {/* <DropdownScrollNavbar /> */}

        <div className="wrapper">
          <div className="home">
            <div className="panel">
              <div className="mr-auto ml-auto col-md-8" style={{ textAlign: 'center' }}>
                <p style={{ marginTop: '50px' }} />
                <h3 className="title">User Management</h3>
              </div>
              <div className="mr-auto ml-auto col-lg-10" style={{ textAlign: 'left' }}>
                {/* <Button
                  style={{ backgroundColor: '#A62E1B', fontSize: 14 }}
                  type="button"
                  onClick={() => this.removeGuestUsers()}
                >
                  Delete Guests Data
                </Button> */}
                <Button
                  style={{ backgroundColor: '#508BF4', fontSize: 14, marginLeft: "10px" }}
                  type="button"
                  onClick={() => this.sortBySign()}
                >
                  Joined Date
                </Button>
                <Button
                  style={{ backgroundColor: '#508BF4', fontSize: 14, marginLeft: "10px" }}
                  type="button"
                  onClick={() => this.sortByVisit()}
                >
                  Last Visit Date
                </Button>
                <Card className="card-plain mt-2 table-card">
                  <CardBody>
                    {this.state.users.length > 0 ?
                      <Table striped responsive>
                        <thead>
                          <tr>
                            <th className="text-left">Actions</th>
                            <th className="text-left">Name</th>
                            <th className="text-left">Email</th>
                            <th className="text-left">Phone#</th>
                            <th className="text-left">Date Joined</th>
                            <th className="text-left">Date Last Visited</th>
                            <th className="text-left">Employee #</th>
                            <th className="text-left">Commission #</th>
                            <th className="text-left">Gross Sales</th>
                            <th className="text-left">Membership</th>
                            <th className="text-left">Update</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.renderUserListTableData()
                          }
                        </tbody>
                      </Table>
                      :
                      <h4>No User Found</h4>
                    }
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
          <ToastContainer />

        </div>
      </>
    );
  }
}

Users.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(Users);