import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import constant from "../../shared/constant";
import _ from 'lodash';
import ReactDatetime from "react-datetime";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// core components
import { GApageView, GAevent } from "../../shared/gaUtils";
import html2pdf from "html2pdf.js";
import calendar_icon from '../../assets/images/icons/calendar-icon.png';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ClearIcon from '@mui/icons-material/Clear';

import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '600px',
  width: '100%',
  bgcolor: '#fff',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 3,
  textAlign: 'center',
  padding: 2, 
  toastOpen: false,
  notificationText: "",
  notifyType: ""
};

const moment = require('moment');

class ExpenseReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expenses: [],
      collapses: [0],
      dateFrom: moment().subtract(7, 'd').format('MM/DD/YYYY'),
      dateTo: moment().format('MM/DD/YYYY'),
      employeeName: "",
      toastOpen: false,
      notificationText: "",
      notifyType: "error",
      subscribeQuestion: false,
      subscriptionText: '',
      employeeCount: 0, 
      employees: []
    }
  }

  intervalID = 0;

  componentDidMount() {
    document.body.classList.add("expense-report-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role === constant['ROLE']['OWNER'] || this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) {
      this.getExpenses();
      this.getEmployeeCount();
      this.getEmployees()
      GApageView('Expense Report Page');
      GAevent('User', 'Expense Report Page', `User: ${this.props.auth.user.name}  visited Expense Report page`);
    } else {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("expense-report-page");
    document.body.classList.remove("sidebar-collapse");
    clearInterval(this.intervalID);
  }

  getEmployees() {
    axios
      .post('/api/users/employees', { userId: this.props.auth.user.id })
      .then((res) => {
        const employees = res.data.map((employee) => {
          return { id: employee._id, name: employee.name }
        })
        this.setState({ employees: employees });
      }).catch((err) => {
        console.log(err);
      });
  }

  getEmployeeCount() {
    axios
      .post('/api/users/employeeCount',
        {
          userId: this.props.auth.user.id
        })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ employeeCount: res.data });
        }
      });
  }

  getExpenses = () => {
    axios
      .post('/api/expenses/expenses/dateCreated', {
        userId: this.props.auth.user.id,
        dateFrom: moment(this.state.dateFrom).utc(),
        dateTo: moment(this.state.dateTo).endOf('day').utc(), 
        employee: this.state.employeeName
      })
      .then((res) => {
        const sortedExpenses = res.data.sort((totalA, totalB) => {
          return new Date(totalB.date) - new Date(totalA.date);
        });
        this.setState({ expenses: sortedExpenses });
      }).catch((err) => {
        console.log(err);
        this.setState({
          toastOpen: true,
          notificationText: err.message || 'Error occured while retrieving expenses',
          notifyType: "error"
        });
      });
  }

  getPdf = () => {
    const element = document.getElementById('expense-report');
    let clonedElement = element.cloneNode(true);
    let hideList = clonedElement.getElementsByClassName('hide-printing');
    for (let i = hideList.length - 1; i >= 0; i--) {
      let childNode = hideList[i];
      childNode.parentNode.removeChild(childNode);
    }
    const opt = {
      margin: [0.1, 0.1, 0.1, 0.1],
      filename: 'expense-report.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 2,
        letterRendering: true,
        scrollY: 0
      },
      jsPDF: { orientation: 'landscape', unit: 'in', format: 'a4' },
      pagesplit: true,
      putOnlyUsedFonts: true,
      // pagebreak: { mode: 'avoid-all' }
    };
    html2pdf().set(opt).from(clonedElement).save().then((res) => {
      clonedElement.remove();
    }).catch((err) => {
      clonedElement.remove();
    });
  }

  removeExpense(expenseId) {
    axios
      .delete(`/api/expenses/${expenseId}`)
      .then((res) => {
        this.setState({
          toastOpen: true,
          notificationText: res.data.message,
          notifyType: "info"
        });
        this.getExpenses();
      }).catch((err) => {
        console.log(err);
      });
  }

  removeDocument(docId, expense) {
    _.remove(expense.docs, { _id: docId });
    axios
      .put(`/api/expenses/${expense._id}`, { expense: expense })
      .then((res) => {
        this.setState({
          toastOpen: true,
          notificationText: res.data.message || 'Sucessfully removed the document.',
          notifyType: "info"
        });
        this.getExpenses();
      }).catch((err) => {
        console.log(err);
      });
  }

  changeCollapse = (collapse) => {
    if (this.state.collapses.includes(collapse)) {
      this.setState({ collapses: this.state.collapses.filter((prop) => prop !== collapse) });
    } else {
      this.setState({ collapses: [...this.state.collapses, collapse] })
    }
  };

  handleDateFromChange = (date) => {
    this.setState({ dateFrom: moment(date).format('MM/DD/YYYY') });
  }

  handleDateToChange = (date) => {
    this.setState({ dateTo: moment(date).format('MM/DD/YYYY') });
  }

  renderExpenseTableData() {
    const moment = require('moment');
    return this.state.expenses.map((expense, index) => {
      const { _id, employeeName, total, docs, transactions, status, date } = expense;
      return (
        <>
          <div className="record">
            <div className="">{moment(date).format('MM/DD/YYYY')}</div>
            <div className="">{employeeName}</div>
            <div className="">${total.toFixed(2)}</div>
            <div className="">{status === constant['PAYOUT_STATUS']['OPEN'] ? 'Open' : 'Closed'}</div>
            <div className="">
              {docs.length > 0 ?
                <i className="now-ui-icons files_paper default-color"></i>
                :
                <>
                  {' '}
                </>
              }
            </div>
            <div className="">
              <div style={{ display: transactions.length > 0 || docs.length > 0 ? "" : "none", }} onClick={(e) => {
                  e.preventDefault();
                  this.changeCollapse(index);
                }}>
                {this.state.collapses.includes(index) ?
                  // <i className="now-ui-icons arrows-1_minimal-down"></i>
                  <span>
                    <ExpandMoreIcon fontSize="medium" />
                  </span>
                  :
                  // <i className="now-ui-icons arrows-1_minimal-up"></i>
                  <span>
                    <ExpandLessIcon fontSize="medium" />
                  </span>
                }
              </div>
              <div onClick={() => this.removeExpense(_id)}>
                {/* <i className="now-ui-icons ui-1_simple-remove"></i> */}
                <span>
                  <ClearIcon fontSize="medium" />
                </span>
              </div>
            </div>
          </div>
          <div className="record-collapse" style={{ display: this.state.collapses.includes(index) ? "" : "none" }}>
            {
              docs.map((doc) =>
                <div className="file-status-bar" key={doc._id}>
                  <div>
                    <div className="file-type-logo"></div>
                    <div className="file-type">{doc.type}</div>
                    <a className="file-name" href={doc.path} rel="noopener noreferrer">{doc.name}</a>
                    <span className="file-size mobile">({doc.size})</span>
                  </div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div className="file-remove hide-printing" onClick={() => this.removeDocument(doc._id, expense)}>
                    <ClearIcon fontSize="medium" />
                  </div>
                </div>
              )
            }
          </div>
          
          <div className="record-mobile">
            <div>
              <span>Date:</span>
              <span>{moment(date).format('MM/DD/YYYY')}</span>
            </div>
            <div>
              <span>Employee:</span>
              <span>{employeeName}</span>
            </div>
            <div>
              <span>Total:</span>
              <span>${total.toFixed(2)}</span>
            </div>
            <div>
              <span>Status:</span>
              <span>{status === constant['PAYOUT_STATUS']['OPEN'] ? 'Open' : 'Closed'}</span>
            </div>
            <div className="hide-printing">
              <span>Files:</span>
              <span>{docs.length > 0 ?
                <i className="now-ui-icons files_paper default-color"></i>
                :
                <>
                  {' '}
                </>
              }</span>
            </div>
            <div className="hide-printing">
              <span>Action:</span>
              <span>
                <div style={{ display: transactions.length > 0 || docs.length > 0 ? "" : "none", }} onClick={(e) => {
                    e.preventDefault();
                    this.changeCollapse(index);
                  }}>
                  {this.state.collapses.includes(index) ?
                    // <i className="now-ui-icons arrows-1_minimal-down"></i>
                    <span>
                      <ExpandMoreIcon fontSize="medium" />
                    </span>
                    :
                    // <i className="now-ui-icons arrows-1_minimal-up"></i>
                    <span>
                      <ExpandLessIcon fontSize="medium" />
                    </span>
                  }
                </div>
                <div onClick={() => this.removeExpense(_id)}>
                  {/* <i className="now-ui-icons ui-1_simple-remove"></i> */}
                  <span>
                    <ClearIcon fontSize="medium" />
                  </span>
                </div>
              </span>
            </div>
          </div>
          <div className="record-collapse-mobile" style={{ display: this.state.collapses.includes(index) ? "" : "none" }}>
            {
              docs.map((doc) =>
                <div className="file-status-bar" key={doc._id}>
                  <div>
                    <div className="file-type-logo"></div>
                    <div className="file-type">{doc.type}</div>
                    {/* {console.log("doc.path------", doc.path, doc.name)} */}
                    <a className="file-name" href={doc.path} target="_blank" rel="noopener noreferrer">{doc.name}</a>
                    <span className="file-size mobile">({doc.size})</span>
                  </div>
                  
                  <div className="file-remove" onClick={() => this.removeDocument(doc._id, expense)}>
                    <ClearIcon fontSize="medium" />
                  </div>
                </div>
              )
            }
          </div>
        </>
      );
    });
  }

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  handleClose = () => {
    this.setState({
      toastOpen: false
    });
  };

  goToSubscribe = () => {
    this.props.history.push("/account");
  }

  noSubscribe = () => {
    let subSkippedCount = localStorage.getItem("SubSkippedCount");
    if (subSkippedCount === null) subSkippedCount = 1;
    else subSkippedCount = parseInt(subSkippedCount) + 1;
    localStorage.setItem("SubSkippedCount", subSkippedCount);
    this.setState({
      subscribeQuestion: false
    });
  }

  handleEmployeeName = (name) => {
    this.setState({
      employeeName: name
    })
  }

  downloadAll = async () => {
    let downloadUrls = []
    for (let expense of this.state.expenses) {
      for (let doc of expense.docs) {
        downloadUrls.push(doc.path)
      }
    }

    try {
      const downloadPromises = downloadUrls.map(url => {
        return axios({
          url,
          method: 'GET',
          responseType: 'blob',
        })
          .then(response => {
            const filename = this.getFileNameFromUrl(url);
            const urlObject = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = urlObject;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
  
            link.parentNode.removeChild(link);
          })
          .catch(error => {
            console.error(`Error downloading file from URL: ${url}`, error);
          });
      });
  
      await Promise.all(downloadPromises);
  
      this.setState({
        toastOpen: true,
        notificationText: "All files downloaded successfully",
        notifyType: "success"
      });
    } catch (error) {
      console.error('Error downloading files:', error);
    }
  }

  // this.setState({
  //   toastOpen: true,
  //   notificationText: "All files downloaded successfully",
  //   notifyType: "success"
  // });

  getFileNameFromUrl = url => {
    const lastSlashIndex = url.lastIndexOf('/');
    return url.substring(lastSlashIndex + 1);
  };

  render() {
    const { toastOpen, notificationText, notifyType, subscriptionText } = this.state;

    return (
      <>
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={notifyType} sx={{ width: '100%', fontSize: 24 }}>
            {notificationText}
          </Alert>
        </Snackbar>
        <div className="expense-page" id="expense-report">
          <div className="top-content">
            <div className="check-date">
              <div className="checkBox">
                <div className="title">Pay Period</div>
                <div className="date-range">
                  <div className="label">Date from:</div>
                  <div className="calendar">
                    <div>
                      <img src={calendar_icon} alt="calendar icon" />
                    </div>
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Datetime Picker Here",
                        value: this.state.dateFrom
                      }}
                      closeOnSelect={true}
                      timeFormat={false}
                      onChange={this.handleDateFromChange}
                    ></ReactDatetime>
                  </div>
                  <div className="label">Date to</div>
                  <div className="calendar">
                    <div>
                      <img src={calendar_icon} alt="calendar icon" />
                    </div>
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Datetime Picker Here",
                        value: this.state.dateTo,
                      }}
                      closeOnSelect={true}
                      timeFormat={false}
                      onChange={this.handleDateToChange}
                    ></ReactDatetime>
                  </div>
                </div>
              </div>
              <div className="checkBox">
                <div className="title">Employee</div>
                <div className="date-range">
                  <div className="form-input">
                    <div className="form-label">Employee</div>
                    <select 
                      onChange={(ev) => this.handleEmployeeName(ev.target.value)} 
                      // value={payoutDetail.employee.id}
                    >
                      <option value={""}>All Employees</option>
                      {
                        this.state.employees.map(employee => {
                          return <option key={employee.id} value={employee.id}>{employee.name}</option>
                        })
                      }
                    </select>
                    <ArrowDropDownIcon fontSize="medium" />
                  </div>
                </div>
                <div className="actions hide-printing">
                  <div className="btn-blue" onClick={this.getExpenses}>
                    Get Report
                  </div>
                  <div className="btn-blue" onClick={this.getPdf}>
                    Print
                  </div>
                </div>
              </div>
            </div>
            <div className="totals-by-employee">
              <div className="title">
                <span>Expense Report</span>
              </div>
              <div className="employee-table">
                {this.state.expenses.length > 0 ?
                  <>
                    <div className="headers">
                      <div className="">Date</div>
                      <div className="">Employee</div>
                      <div className="">Total</div>
                      <div className="">Status</div>
                      <div className="">Files</div>
                      <div className="hide-printing">Action</div>
                    </div>
                    <div className="records">
                      {
                        this.renderExpenseTableData()
                      }
                    </div>
                  </>
                  :
                  <h4>No Expenses Found</h4>
                }
              </div>
              <div className="btn-blue" style={{marginTop: "50px", width: '300px'}} onClick={this.downloadAll}>
                Download All Receipts
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.subscribeQuestion}
        >
          <Box sx={style}>
            <h2 className="mt-4 mb-2 mx-2 font-bold" style={{ color: '#22395B' }}>Welcome to CommissionPayPro!</h2>
            <h4 className="mt-2 mb-4 mx-2" style={{ color: '#7B8FB2' }}>
              {
                subscriptionText
              }
            </h4>
            <div className="display-flex justify-center align-center mb-4">
              <div className="btn-blue mr-2" style={{ padding: '8px 18px' }} onClick={this.goToSubscribe}>
                Subscribe
              </div>
            </div>
          </Box>
        </Modal>
      </>
    );
  }
}

ExpenseReport.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps
)(ExpenseReport);