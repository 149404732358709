import { SET_CURRENT_USER, USER_LOADING, TOGGLE_SIDEBAR, SWITCH_MODE } from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  isSidebar: true, 
  modeType: (localStorage.getItem('modeType') === 'true' ? true : false) || false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        user: action.payload
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true
      };
    case TOGGLE_SIDEBAR:
        return {
          ...state,
          isSidebar: action.payload
        };
    case SWITCH_MODE:
      return {
        ...state,
        modeType: action.payload
      };

    default:
      return state;
  }
}