import React, { Component } from 'react';
import { connect } from "react-redux";
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import DeleteIcon from '@mui/icons-material/Delete';
import constant from "../../shared/constant";
import {isMobile} from 'react-device-detect';
import Floater from 'react-floater';
import Button from '@mui/material/Button';

class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAction: 0,
            walkthrough: false,
        }

        this.actionRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.checkIfClickedOutside);
        if (this.props.auth.user.role === constant['ROLE']['GUEST']) {
            const walkThrough = localStorage.getItem("WalkThrough");
            if (walkThrough === null || walkThrough !== "Completed") {
              this.setState({ walkthrough: true});
            } 
        }
    }

    checkIfClickedOutside = e => {
        if (this.state.isAction !== 0 && this.actionRef.current && !this.actionRef.current.contains(e.target)) {
            this.setState({
                isAction: 0,
            });
        }
    }

    onAction = (id) => {
        this.setState({
            isAction: id
        });
    }

    onEdit = (data) => {
        this.props.onEdit(data);
    }

    onEmail = (data) => {
        this.props.onEmail(data);
    }

    onDelete = (data) => {
        this.props.onDelete(data);
    }

    _renderTableRecord = (employee, ind) => {
        const { isAction } = this.state;
        const { name,  role, email, phone, status, verified} = employee;
        
        const shortNameFirst = name.split(" ")[0][0].toUpperCase();
        const shortNameSecond = name.split(" ").length > 1 ? name.split(" ")[1][0].toUpperCase() : "";

        return  <div className="table-record" key={employee._id}>
                    <div className="body-item item-1">{ind + 1}</div>
                    <div className="body-item item-2">
                        {
                            status === 0 && verified === false && <span className="name-badge light-bg">{shortNameFirst + shortNameSecond}</span>
                        }
                        {
                            status === 1 && verified === false && <span className="name-badge light-bg" style={{backgroundColor: '#8898ab', color: 'white'}}>{shortNameFirst + shortNameSecond}</span>
                        }
                        {
                            verified === true && <span className="name-badge heavy-bg">{shortNameFirst + shortNameSecond}</span>
                        }
                        {name}
                    </div>
                    <div className="body-item item-3">{role}</div>
                    <div className="body-item item-4">{email}</div>
                    <div className="body-item item-5">{phone}</div>
                    <div className="body-item item-6">                        
                        {
                            status === 0 && verified === false && <span className="status-badge light-bg">Working</span> 
                        }
                        {
                            status === 1 && verified === false && <span className="status-badge light-bg" style={{backgroundColor: '#8898ab', color: 'white'}}>Emailed</span> 
                        }
                        {
                            verified === true && <span className="status-badge heavy-bg">Active</span>
                        }
                    </div>
                    <div className="body-item item-7">
                        {status === 0 &&
                            <>
                                {(this.props.auth.user.role === constant['ROLE']['GUEST'] && this.state.walkthrough ) &&
                                <Floater
                                    autoOpen={true}
                                    showCloseButton={true}
                                    styles={{
                                        floater: {
                                            maxWidth: isMobile ? 300 : 500 ,
                                            width: '100%'
                                        },
                                        arrow: {
                                            color: '#4A4C5D'
                                        },
                                        close: {
                                            color: 'white'
                                        },
                                        container: {
                                            backgroundColor: '#4A4C5D',
                                        }
                                    }}
                                    content={
                                        <div>
                                            <h3 className="mt-2" style={{ color: 'white', marginBottom: '20px' }}>Invite Your Employee!</h3>
                                            <h5 style={{ color: 'white' }}>
                                                Click the email button inside actions to send an invite email to your employee or you can skip this step to use the program without employee input.
                                            </h5>
                                            <h5 style={{ color: 'white' }}>
                                                You can always send an email invite later.
                                            </h5>
                                            <div style={{ textAlign: 'end' }}>
                                            <Button sx={{ fontWeight: '500', backgroundColor: '#0094CF', fontSize: '1em', color: '#fff' }} onClick={() => { this.props.history.push('/commissions') }}>Skip for now</Button>
                                            </div>
                                        </div>
                                    }
                                    placement="left"
                                >
                                    <span> </span>
                                </Floater>
                                }
                            </>
                        }
                        <span className="action" onClick={() => this.onAction(ind + 1)}>
                            <SettingsEthernetIcon />
                            {
                                isAction === (ind + 1) && <div className="actions" ref={this.actionRef}>
                                                    <div className="action-btn" onClick={() => this.onEdit(employee)}>
                                                        <EditIcon fontSize="small" />
                                                        Edit
                                                    </div>
                                                    {
                                                       <div className="action-btn" onClick={() => this.onEmail(employee)}>
                                                            <EmailIcon fontSize="small" />
                                                            Email
                                                        </div>
                                                    }
                                                    <div className="action-btn" onClick={() => this.onDelete(employee)}>
                                                        <DeleteIcon fontSize="small" />
                                                        Delete
                                                    </div>
                                                </div>
                            }
                        </span>
                    </div>
                </div> 
    }

    _renderEmptyTableRecord = () => {
        return <div className="table-record">
            <div className="body-item">No Employees Found</div>
        </div>
    }

    render() {
        const { employees } = this.props;

        return <div className="table-container">
            <div className="table-header">
                <div className="header-item item-1">No</div>
                <div className="header-item item-2">Name</div>
                <div className="header-item item-3">Role</div>
                <div className="header-item item-4">Email</div>
                <div className="header-item item-5">Phone</div>
                <div className="header-item item-6">Status</div>
                <div className="header-item item-7">Actions</div>
            </div>
            <div className="table-body">
                {
                    employees.length !== 0 && employees.map((employee, index) => {
                        return this._renderTableRecord(employee, index)
                    })
                }
                {
                    employees.length === 0 && this._renderEmptyTableRecord()
                }
            </div>
        </div>;
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
  
export default connect(
    mapStateToProps,
    {  }
)(Table);

// export default Table;