import React, { Component } from "react";
import { Link } from 'react-router-dom';

import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

import Card from '../component/Card';
import CustomBtn from '../component/CustomBtn';
import graph1 from '../../assets/images/graph1.png';
import graph2 from '../../assets/images/graph2.png';
import graph3 from '../../assets/images/graph3.png';
import graph4 from '../../assets/images/graph4.png';
import calendar from '../../assets/images/calendar.png';
import gross_sales from '../../assets/images/gross-sales.png';
import top_performer from '../../assets/images/top-performer.png';
import payroll_history from '../../assets/images/payroll-history.png';
import gross_expenses from '../../assets/images/gross-expenses.png';

import PropTypes from "prop-types";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { registerUser } from "../../actions/authActions";
import Floater from 'react-floater';

import { logoutUser, updateUser } from "../../actions/authActions";

// core components
import constant, { SUBSCRIBE_TIME_IN_SECONDS, SUBSCRIBE_SKIP_COUNT } from "../../shared/constant";
import { GAevent, GApageView } from "../../shared/gaUtils";
import ChartistGraph from "react-chartist";
// import ChartistTooltip from "chartist-plugin-tooltips-updated"; 
import _ from 'lodash';

import {
  monthlySalesChart,
  payrollHistoryChart,
  grossExpenseChart
} from "variables/charts.js";
import $ from 'jquery';

import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '600px',
  width: '100%',
  bgcolor: '#fff',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 3,
  textAlign: 'center',
  padding: 2
};

var Chartist = require("chartist");
const moment = require('moment');

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subscribeQuestion: false,
      period_dropdown: false,
      period_dropdown1: false,
      period_dropdown2: false,
      period_dropdown3: false,
      unit1: 'In Thousands',
      unit2: 'In Thousands',
      unit3: 'In Thousands',
      walkthrough: true,
      commissionCount: 0,
      totalSales: 0,
      salesCount: 0,
      periodFilterValue: 'ytd',
      salesCountTimeFilter: {
        start: moment().startOf('year').utc(),
        end: moment().endOf('day').utc()
      },
      topPerformer: {},
      topPerformers: [],
      topFilterValue: 'ytd',
      topPerformerFilter: {
        start: moment().startOf('year').utc(),
        end: moment().endOf('day').utc()
      },
      openPayAmount: 0,
      employeeCount: 0,
      grossSalesFilterValue: 'ytd',
      grossSalesFilter: {
        start: moment().startOf('year').utc(),
        end: moment().endOf('day').utc()
      },
      grossSalesFilter2: {
        start: moment().subtract(1, 'year').startOf('year').utc(),
        end: moment().subtract(1, 'year').endOf('day').utc()
      },
      grossSalesData: {
        labels: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        series: [[0]]
      },
      grossSalesOptions: {
        low: 0,
        high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
        chartPadding: {
          top: 0,
          right: 10,
          bottom: 0,
          left: 10
        },
        lineSmooth: false,
        showPoint: true,
        axisX: {
          showGrid: false,
          showLabel: true,
        },
        axisY: {
          labelInterpolationFnc: function (value) { }
        }
      },
      payrollHistoryFilterValue: 'ytd',
      payrollHistoryFilter: {
        start: moment().startOf('year').utc(),
        end: moment().endOf('day').utc()
      },
      payrollHistoryData: {
        labels: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        series: [[0]]
      },
      payrollHistoryOptions: {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0
        }),
        low: 0,
        high: 50,
        top: 0,
        right: 10,
        bottom: 0,
        left: 0,
        axisX: {
          showGrid: false,
          showLabel: true,
        },
        axisY: {
          labelInterpolationFnc: function (value) {
            if ((value / 1000) >= 1) {
              return (value / 1000).toFixed(1) + 'K';
            }
            if ((value / 1000000) >= 1) {
              return (value / 1000000).toFixed(1) + 'M';
            }
            if ((value / 1000) < 1) {
              return value;
            }
          }
        }
      },
      grossExpenseFilterValue: 'ytd',
      grossExpenseFilter: {
        start: moment().startOf('year').utc(),
        end: moment().endOf('day').utc()
      },
      grossExpenseData: {
        labels: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        series: [[0]]
      },
      grossExpenseOptions: {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0
        }),
        low: 0,
        high: 50,
        top: 0,
        right: 10,
        bottom: 0,
        left: 0,
        axisX: {
          showGrid: false,
          showLabel: true,
        },
        axisY: {
          labelInterpolationFnc: function (value) {
            if ((value / 1000) >= 1) {
              return (value / 1000).toFixed(1) + 'K';
            }
            if ((value / 1000000) >= 1) {
              return (value / 1000000).toFixed(1) + 'M';
            }
            if ((value / 1000) < 1) {
              return value;
            }
          }
        }
      },
      redraw: false,
      toastOpen: false,
      notificationText: "",
      notifyType: "error"
    }

    this.dropdownRef = React.createRef();
    this.dropdownRef1 = React.createRef();
    this.dropdownRef2 = React.createRef();
    this.dropdownRef3 = React.createRef();

    this.redrawRef = React.createRef();
  }

  intervalID = 0;

  // componentWillReceiveProps(nextProps, prevProps) {
  //   console.log(nextProps.auth.isAuthenticated);
  //   if (nextProps.auth.isAuthenticated && nextProps.auth.user.role === constant['ROLE']['GUEST']) {
  //     this.props.props.history.push("/employees");
  //   }
  // }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.isSidebar === this.props.auth.isSidebar) {

    } else {
      setTimeout(() => {
        this.redrawRef.click();
      }, 250)
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentDidMount() {
    if (this.props.auth.user.role === constant['ROLE']['EMPLOYEE'] || this.props.auth.user.role === constant['ROLE']['CONTRACTOR'] || this.props.auth.user.role === constant['ROLE']['MANAGER'])
      this.props.props.history.push('/expense');

    document.body.classList.add("home-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    GApageView("Dashboard Page");
    if (this.props.auth.user.role === constant['ROLE']['OWNER'] || this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) {
      this.getSalesCountData();
      this.getGrossSalesData();
      this.getPayrollHistoryData();
      this.getGrossExpenseData();
      this.getCommissionCount();
      this.getEmployeeCount();
      this.getOpenPayAmount();
      this.getTopPerformers();
      GAevent('User', 'Dashboard Page', `User: ${this.props.auth.user.name}  logged in`);
      const demoStatus = localStorage.getItem("demoStatus");
      if(this.props.auth.user.newUser === 'yes' && demoStatus === null) {
        localStorage.setItem('demoStatus', 'yes');
        this.props.props.history.push('/learn');
      }
      if(this.props.auth.user.newUser === 'no') {
        localStorage.setItem('demoStatus', 'no');
      }
    } else if (this.props.auth.user.role === undefined) {
      GAevent('Guest', 'Dashboard Page', `Guest user visited Dashboard page`);
      const visitedSite = localStorage.getItem("VisitedSite");
      if (visitedSite === null || visitedSite !== "yes") {
        localStorage.setItem("VisitedSite", "yes");
        // this.props.props.history.push("/employees");
        const queryString = require('query-string');
        const parsed = queryString.parse(this.props.props.location.search);
        if (parsed.name) localStorage.setItem("Name", parsed.name); 
        if (parsed.email) localStorage.setItem("Email", parsed.email);
      } 
      // else {
      // }
      this.props.props.history.push("/login");
    }

    document.addEventListener("mousedown", this.checkIfClickedOutside)

    // tooltip for gross graph
    var $tooltip = $('<div class="custom-tooltip-hidden custom-tooltip"></div>').appendTo($('.ct-chart-gross'));

    $(document).on('mouseenter', '.gross-graph .ct-point', function () {
      var value = $(this).attr('ct:value');

      // $tooltip.html("<span class='blue-dot'></span>" + "<span class='tooltip-label'>Sales:</span> <span class='tooltip-value'>$" + Number(value).toFixed(2) + "</span>");
      $tooltip.html(`<span class='blue-dot'></span><span class='tooltip-label'>Sales:</span> <span class='tooltip-value'>$ ${Number(value).toFixed(2)}</span>`);
      $tooltip.removeClass('custom-tooltip-hidden');
    });

    $(document).on('mouseleave', '.gross-graph .ct-point', function () {
      $tooltip.addClass('custom-tooltip-hidden');
    });

    $(document).on('mousemove', '.gross-graph .ct-point', function (event) {
      $tooltip.css({
        left: event.offsetX - $tooltip.width() / 2,
        top: event.offsetY - $tooltip.height() - 20
      });
    });

    // tooltip for expenses graph
    var $tooltip2 = $('<div class="custom-tooltip-hidden custom-tooltip"></div>').appendTo($('.expenses-graph .ct-chart-expenses'));

    $(document).on('mouseenter', '.expenses-graph .ct-point', function () {
      var value2 = $(this).attr('ct:value');
      console.log(typeof (value2))
      // $tooltip2.html("<span class='blue-dot'></span>" + "<span class='tooltip-label'>Expenses:</span> <span class='tooltip-value'> $" + Number(value2).toFixed(2) + "</span>");
      $tooltip2.html(`<span class='blue-dot'></span><span class='tooltip-label'>Expenses:</span> <span class='tooltip-value'> $ ${Number(value2).toFixed(2)} </span>`);
      $tooltip2.removeClass('custom-tooltip-hidden');
    });

    $(document).on('mouseleave', '.expenses-graph .ct-point', function () {
      $tooltip2.addClass('custom-tooltip-hidden');
    });

    $(document).on('mousemove', '.expenses-graph .ct-point', function (event) {
      $tooltip2.css({
        left: event.offsetX - $tooltip2.width() / 2,
        top: event.offsetY - $tooltip2.height() - 20
      });
    });
  }

  componentWillUnmount() {
    document.body.classList.remove("home-page");
    document.body.classList.remove("sidebar-collapse");
  }

  getOpenPayAmount() {
    axios.post('/api/commissions/commissions', { userId: this.props.auth.user.id })
      .then((res) => {
        let totalOwed = 0;
        // Parse Commission Data
        res.data.forEach(commission => {
          commission.employeePayouts.forEach((employeePayoutDetail) => {
            employeePayoutDetail.payouts.forEach((payoutDetailObj) => {
              if (payoutDetailObj.status === constant['PAYOUT_STATUS']['OPEN']) {
                // Calculate Owed amount
                let totalPaid = 0;
                payoutDetailObj.transactions.forEach((transaction) => {
                  totalPaid += transaction.amountPaid;
                });
                totalOwed += (payoutDetailObj.total - totalPaid);
              }
            });
          });
        });
        this.setState({ openPayAmount: totalOwed });
      });
  }

  getEmployeeCount() {
    axios
      .post('/api/users/employeeCount',
        {
          userId: this.props.auth.user.id
        })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ employeeCount: res.data });
          let user = this.props.auth.user;
          user.employeeCount = res.data.length;
          this.props.updateUser(user);
        }
      });
  }

  getCommissionCount = () => {
    axios
      .post('/api/commissions/count',
        {
          userId: this.props.auth.user.id,
        })
      .then((res) => {
        if (res.status === 200)
          this.setState({ commissionCount: res.data });
      }).catch((err) => {
        console.log(err);
      });
  }

  togglePeriodDropdown = () => {
    this.setState({
      period_dropdown: !this.state.period_dropdown
    });
  }

  togglePeriodDropdown1 = () => {
    this.setState({
      period_dropdown1: !this.state.period_dropdown1
    });
  }

  togglePeriodDropdown2 = () => {
    this.setState({
      period_dropdown2: !this.state.period_dropdown2
    });
  }

  togglePeriodDropdown3 = () => {
    this.setState({
      period_dropdown3: !this.state.period_dropdown3
    });
  }

  checkIfClickedOutside = e => {
    if (this.state.period_dropdown && this.dropdownRef.current && !this.dropdownRef.current.contains(e.target)) {
      this.setState({
        period_dropdown: false,
      })
    }
    if (this.state.period_dropdown1 && this.dropdownRef1.current && !this.dropdownRef1.current.contains(e.target)) {
      this.setState({
        period_dropdown1: false,
      })
    }
    if (this.state.period_dropdown2 && this.dropdownRef2.current && !this.dropdownRef2.current.contains(e.target)) {
      this.setState({
        period_dropdown2: false,
      })
    }
    if (this.state.period_dropdown3 && this.dropdownRef3.current && !this.dropdownRef3.current.contains(e.target)) {
      this.setState({
        period_dropdown3: false,
      })
    }
  }

  // Sales/Count Card
  _renderStaticsCard1 = () => {
    const { period_dropdown, totalSales, salesCount, periodFilterValue } = this.state;
    let sales = 0;
    if ((totalSales / 1000000) > 1) {
      sales = (totalSales / 1000000).toFixed(1) + 'M';
    } else if ((totalSales / 1000) > 1) {
      sales = (totalSales / 1000).toFixed(1) + 'K';
    } else {
      sales = totalSales;
    }

    return <div className="statics">
      <Card>
        <div className="display-flex justify-start">
          <div className="img-icon">
            <img src={graph1} alt="graph-icon-1" />
          </div>
          <div className="card-values">
            <div className="value-big font-bold">${sales}/{salesCount}</div>
            <div className="value-small font-medium">Sales/Count</div>
          </div>
        </div>
        <div style={{ height: '18px' }}></div>
        <div className="display-flex justify-between">
          <div className="period display-flex justify-between align-center" onClick={this.togglePeriodDropdown} ref={this.dropdownRef}>
            <img src={calendar} alt="calendar-icon" />
            <span className="font-medium">
              {
                periodFilterValue === 'ytd' ? 'YTD' : periodFilterValue === 'mtd' ? 'MTD' : 'WTD'
              }
            </span>
            <ArrowDropDownIcon />
            {
              period_dropdown ? <div className="period-dropdown">
                <div className="display-flex justify-start align-center" onClick={() => this.setPeriodFilter('ytd')}>
                  <div className="img-container">
                    <img src={calendar} alt="calendar-icon" />
                  </div>
                  <div>YTD</div>
                </div>
                <div className="display-flex justify-start align-center" onClick={() => this.setPeriodFilter('mtd')}>
                  <div className="img-container">
                    <img src={calendar} alt="calendar-icon" />
                  </div>
                  <div>MTD</div>
                </div>
                <div className="display-flex justify-start align-center" onClick={() => this.setPeriodFilter('wtd')}>
                  <div className="img-container">
                    <img src={calendar} alt="calendar-icon" />
                  </div>
                  <div>WTD</div>
                </div>
              </div> : null
            }
          </div>
          {/* <Link className="action-btn text-btn-hover font-medium display-flex justify-between" to="/receive-funds"> */}
          <Link className="action-btn text-btn-hover font-medium display-flex justify-between" to="/receive-funds">
            <span onClick={(e) => {
              e.preventDefault();
              if (this.props.auth.user.role === undefined) {
                GAevent('Guest', 'Dashboard Page', `Guest user: ${this.props.auth.user.name} clicked Receive Funds button and sent him to Login page`);
              }
              this.props.props.history.push("/receive-funds");
            }}>
              Receive Funds
            </span>
            <span className="display-flex justify-center align-center">
              <ChevronRightIcon />
            </span>
          </Link>
        </div>
      </Card>
    </div>;
  }

  setPeriodFilter(param) {
    this.setState({
      periodFilterValue: param,
      salesCountTimeFilter: {
        start: param === 'ytd' ? moment().startOf('year').utc() : param === 'mtd' ? moment().startOf('month').utc() : moment().startOf('week').utc(),
        end: moment().endOf('day').utc()
      }
    }, () => {
      this.getSalesCountData();
    });
  }

  getSalesCountData() {
    let that = this;
    let totalSales = 0;
    let salesCount = 0;
    const requests = [];
    const { salesCountTimeFilter } = this.state;
    requests.push(axios.post('/api/commissions/commissions',
      {
        userId: this.props.auth.user.id,
        dateFrom: salesCountTimeFilter.start,
        dateTo: salesCountTimeFilter.end,
        mode: 'GrossSales'
      })
    );
    axios.all(requests).then(axios.spread((...responses) => {
      // get sales and count from commissions
      responses[0].data.forEach(commission => {
        commission.employeePayouts.forEach((employeePayoutDetail) => {
          employeePayoutDetail.payouts.forEach((payoutDetailObj) => {
            salesCount += 1;
            totalSales += payoutDetailObj.grossIncome;
          });
        });
      });
      that.setState({
        totalSales: totalSales,
        salesCount: salesCount
      });
    })).catch((err) => {
      this.setState({
        toastOpen: true,
        notificationText: err.message || 'Error occured while retrieving payment data',
        notifyType: "error"
      });
    });
  }

  _renderStaticsCard2 = () => {
    let openPayAmount = this.state.openPayAmount;
    if ((openPayAmount / 1000000) > 1) {
      openPayAmount = (openPayAmount / 1000000).toFixed(1) + 'M';
    } else if ((openPayAmount / 1000) > 1) {
      openPayAmount = (openPayAmount / 1000).toFixed(1) + 'K';
    } else {
      openPayAmount = openPayAmount.toFixed(2);
    }
    return <div className="statics">
      <Card>
        <div className="display-flex justify-start">
          <div className="img-icon">
            <img src={graph2} alt="graph-icon-1" />
          </div>
          <div className="card-values">
            <div className="value-big font-bold">{'$' + openPayAmount}</div>
            <div className="value-small font-medium">Open Pay</div>
          </div>
        </div>
        <div style={{ height: '18px' }}></div>
        <div className="display-flex justify-end">
          <Link className="action-btn text-btn-hover font-medium display-flex justify-between" to="/pay">
            <span onClick={(e) => {
              e.preventDefault();
              if (this.props.auth.user.role === undefined) {
                GAevent('Guest', 'Dashboard Page', `Guest user clicked Pay Now button and sent him to Login page`);
              }
              this.props.props.history.push("/pay");
            }}>
              Pay Now
            </span>
            <span className="display-flex justify-center align-center">
              <ChevronRightIcon />
            </span>
          </Link>
        </div>
      </Card>
    </div>;
  }

  _renderStaticsCard3 = () => {
    return <div className="statics">
      <Card>
        <div className="display-flex justify-start">
          <div className="img-icon">
            <img src={graph3} alt="graph-icon-1" />
          </div>
          <div className="card-values">
            <div className="value-big font-bold">{this.state.employeeCount > 0 ? this.state.employeeCount : 'Just You'}</div>
            <div className="value-small font-medium">Employees</div>
          </div>
        </div>
        <div style={{ height: '18px' }}></div>
        <div className="display-flex justify-end">
            <Link className="action-btn text-btn-hover font-medium display-flex justify-between" to="/employees">
              <span onClick={(e) => {
                e.preventDefault();
                if (this.props.auth.user.role === undefined) {
                  GAevent('Guest', 'Dashboard Page', `Guest user: ${this.props.auth.user.name} clicked Add Employees button and sent him to Employee page`);
                  // this.createGuestUser();
                } else {
                  console.log(!this.props.auth.user.stripeSubscriptionId, this.state.employeeCount >= 2, !this.state.subscribeQuestion);
                  if (this.props.auth.user.level !== constant['MEMBERSHIP']['ULTIMATE']) {
                    if (!this.props.auth.user.stripeSubscriptionId && this.state.employeeCount >= 2 && !this.state.subscribeQuestion && this.props.auth.user.role !== constant['ROLE']['SUPER_ADMIN']) {
                      this.setState({
                        subscribeQuestion: true,
                        subscriptionText: 'Just 99¢ for the first 7 days, then $39.95/mo for up to 5 users. Cancel Anytime',
                      })
                    } else {
                      this.props.props.history.push("/employees");
                    }
                  } else {
                    this.props.props.history.push("/employees");
                  }
                }
              }}>
                Add Employee
              </span>
              <span className="display-flex justify-center align-center">
                <ChevronRightIcon />
              </span>
            </Link>
        </div>
      </Card>
    </div>;
  }

  _renderStaticsCard4 = () => {
    return <div className="statics">
      <Card>
        <div className="display-flex justify-start">
          <div className="img-icon">
            <img src={graph4} alt="graph-icon-1" />
          </div>
          <div className="card-values">
            <div className="value-big font-bold">{this.state.commissionCount > 0 ? this.state.commissionCount : 'None'}</div>
            <div className="value-small font-medium">Commissions</div>
          </div>
        </div>
        <div style={{ height: '18px' }}></div>
        <div className="display-flex justify-end">
          <Link className="action-btn text-btn-hover font-medium display-flex justify-between" to="/commissions">
            <span onClick={(e) => {
              e.preventDefault();
              if (this.props.auth.user.role === undefined) {
                GAevent('Guest', 'Dashboard Page', `Guest user: ${this.props.auth.user.name} clicked Add Commissions button and sent him to Login page`);
              }
              this.props.props.history.push("/commissions");
            }}>
              Add Commission
            </span>
            <span className="display-flex justify-center align-center">
              <ChevronRightIcon />
            </span>
          </Link>
        </div>
      </Card>
    </div>;
  }

  titleClick = () => {
    this.setState({
      redraw: !this.state.redraw
    })
  }

  _renderGrossSales = () => {
    const { period_dropdown1, grossSalesFilterValue, unit1, redraw } = this.state;

    return <div className="gross-sales">
      <div className="top-period">
        <div className="display-flex justify-around align-center">
          <div className={grossSalesFilterValue === "ytd" ? "tab-btn__active" : "tab-btn"} onClick={() => this.setGrossSalesFilter("ytd")}>
            YTD
          </div>
          <div className={grossSalesFilterValue === "mtd" ? "tab-btn__active" : "tab-btn"} onClick={() => this.setGrossSalesFilter("mtd")}>
            MTD
          </div>
          <div className={grossSalesFilterValue === "wtd" ? "tab-btn__active" : "tab-btn"} onClick={() => this.setGrossSalesFilter("wtd")}>
            WTD
          </div>
        </div>
      </div>
      <Card>
        <div className="header display-flex justify-between align-center">
          <div className="display-flex justify-between align-center">
            <div className="card-logo-outer display-flex justify-center align-center">
              <div className="card-logo-inner display-flex justify-center align-center">
                <img src={gross_sales} alt="overview-logo" />
              </div>
            </div>
            <div className="card-title font-bold" onClick={() => this.titleClick()} ref={input => this.redrawRef = input}>
              Gross Sales
              {
                redraw ? '' : ''
              }
            </div>
            <CustomBtn>
              <div className="display-flex justify-between align-center position-relative" onClick={this.togglePeriodDropdown1} ref={this.dropdownRef1}>
                <span className="font-medium">{unit1}</span>
                <ArrowDropDownIcon />
                {
                  period_dropdown1 ? <div className="period-dropdown thousands">
                    <div className="display-flex justify-start align-center" onClick={() => this.getYUnit1('thousand')}>
                      <span>In Thousands</span>
                    </div>
                    <div className="display-flex justify-start align-center" onClick={() => this.getYUnit1('ten')}>
                      <span>In Tens of Thousands</span>
                    </div>
                    <div className="display-flex justify-start align-center" onClick={() => this.getYUnit1('hundred')}>
                      <span>In Hundreds of Thousands </span>
                    </div>
                    <div className="display-flex justify-start align-center" onClick={() => this.getYUnit1('million')}>
                      <span>In Millions </span>
                    </div>
                  </div> : null
                }
              </div>
            </CustomBtn>
          </div>
          <div className="actions display-flex justify-between align-center text-btn-hover">
            <Link className="font-medium" to="/report/gross-sales">Gross Sales Report</Link>
            <span className="display-flex justify-center align-center">
              <ChevronRightIcon />
            </span>
          </div>
        </div>
        <div className="sub-header display-flex justify-between">
          <div className="graph-label display-flex justify-between align-center">
            <span className="font-medium">This Year</span>
            <span className="blue-line"></span>
            <span className="font-medium">Last Year</span>
            <span className="pink-line"></span>
          </div>
          <div className="display-flex justify-between align-center">
            <div className={grossSalesFilterValue === "ytd" ? "period-filter-btn period-filter-btn__active font-medium custom-btn-hover" : "period-filter-btn font-medium custom-btn-hover"} onClick={() => this.setGrossSalesFilter("ytd")}>YTD</div>
            <div className={grossSalesFilterValue === "mtd" ? "period-filter-btn period-filter-btn__active font-medium custom-btn-hover" : "period-filter-btn font-medium custom-btn-hover"} onClick={() => this.setGrossSalesFilter("mtd")}>MTD</div>
            <div className={grossSalesFilterValue === "wtd" ? "period-filter-btn period-filter-btn__active font-medium custom-btn-hover" : "period-filter-btn font-medium custom-btn-hover"} onClick={() => this.setGrossSalesFilter("wtd")}>WTD</div>
          </div>
        </div>
        <div className="gross-graph">
          <ChartistGraph
            className="ct-chart-gross"
            data={this.state.grossSalesData}
            type="Line"
            options={this.state.grossSalesOptions}
            listener={monthlySalesChart.animation}
          />
        </div>
        <div className="bottom-actions display-flex justify-end align-center text-btn-hover">
          <Link className="font-medium" to="/receive-funds">Receive Funds Now</Link>
          <span className="display-flex justify-center align-center">
            <ChevronRightIcon />
          </span>
        </div>
      </Card>
    </div>;
  }

  getYUnit1 = (param) => {
    const that = this;
    if (param === 'thousand') {
      this.setState({
        unit1: 'In Thousands'
      }, () => {
        that.getGrossSalesData();
      });
    }
    if (param === 'ten') {
      this.setState({
        unit1: 'In Tens of Thousands'
      }, () => {
        that.getGrossSalesData();
      });
    }
    if (param === 'hundred') {
      this.setState({
        unit1: 'In Hundreds of Thousands'
      }, () => {
        that.getGrossSalesData();
      });
    }
    if (param === 'million') {
      this.setState({
        unit1: 'In Thousands of Thousands'
      }, () => {
        that.getGrossSalesData();
      });
    }
  }

  setGrossSalesFilter(param) {
    this.setState({
      grossSalesFilterValue: param,
      grossSalesFilter: {
        start: param === 'ytd' ? moment().startOf('year').utc() : param === 'mtd' ? moment().startOf('month').utc() : moment().startOf('week').utc(),
        end: moment().endOf('day').utc()
      },
      grossSalesFilter2: {
        start: param === 'ytd' ? moment().subtract(1, 'year').startOf('year').utc() : param === 'mtd' ? moment().subtract(1, 'year').startOf('month').utc() : moment().subtract(1, 'year').startOf('week').utc(),
        end: moment().subtract(1, 'year').endOf('day').utc()
      },
    }, () => {
      this.getGrossSalesData();
    });
  }

  getGrossSalesData() {
    const { grossSalesFilter, grossSalesFilterValue, grossSalesFilter2, unit1 } = this.state;
    // get labels 
    let dateStart = moment(grossSalesFilter.start);
    let dateEnd = moment(grossSalesFilter.end);
    let ytdLabels = [];
    let mtdLabels = [];
    let wtdLabels = [];
    // for YTD
    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      ytdLabels.push(dateStart.format('MM'));
      dateStart.add(1, 'month');
    }
    // for MTD
    dateStart = moment(grossSalesFilter.start);
    dateEnd = moment(grossSalesFilter.end);

    while (dateEnd > dateStart || dateStart.format('D') === dateEnd.format('D')) {
      mtdLabels.push(dateStart.format('DD'));
      wtdLabels.push(dateStart.format('DD'));
      dateStart.add(1, 'days');
    }

    axios
      .post('/api/commissions/commissions',
        {
          userId: this.props.auth.user.id,
          dateFrom: grossSalesFilter.start,
          dateTo: grossSalesFilter.end,
          mode: 'GrossSales'
        })
      .then((res) => {
        let employeePayouts = [];
        res.data.forEach(commission => {
          commission.employeePayouts.forEach((employeePayoutDetail) => {
            employeePayoutDetail.payouts.forEach((payoutDetailObj) => {
              // Define Payout Object
              const payoutObj = {
                employeeId: employeePayoutDetail.employee.id,
                employeeName: employeePayoutDetail.employee.name,
                grossIncome: payoutDetailObj.grossIncome,
                month: moment(payoutDetailObj.date, 'YYYY/MM/DD').format('MM').substring(0, 5),
                date: moment(payoutDetailObj.date, 'YYYY/MM/DD').format('DD').substring(0, 2)
              }
              employeePayouts.push(payoutObj);
            });
          });
        });
        // Get Monthly Gross Sales Data
        if (grossSalesFilterValue === 'ytd') {
          const payoutsByMonth = _.groupBy(employeePayouts, 'month');
          let totalsGrossByMonths = [];
          _.mapKeys(payoutsByMonth, (payoutDetails, month) => {
            let totalGross = 0;
            payoutDetails.forEach((payoutDetail) => {
              totalGross += payoutDetail.grossIncome;
            });
            totalsGrossByMonths.push({
              month: month,
              totalGross: totalGross
            });
          });
          let totalGrossDataFull = ytdLabels.map(month => {
            const grossMonth = _.find(totalsGrossByMonths, { 'month': month });
            return grossMonth ? grossMonth.totalGross : 0;
          });
          const grossSalesData = {
            labels: ytdLabels,
            series: [totalGrossDataFull]
          }
          const grossSalesOptions = {
            height: "200px",
            onlyInteger: true,
            lineSmooth: false,
            showPoint: true,
            axisX: {
              showGrid: false,
              showLabel: true,
            },
            axisY: {
              offset: 50,
              // type: Chartist.FixedScaleAxis,
              // divisor: 5,
              // ticks : [ 0, 250, 500, 750, 1000 ],
              labelInterpolationFnc: function (value) {
                if (value > 1) {
                  if (unit1 === 'In Thousands') {
                    return value !== 0 ? (value / 1000) + 'K' : 0
                  }
                  if (unit1 === 'In Tens of Thousands') {
                    return value !== 0 ? (value / 10000) + 'K' : 0
                  }
                  if (unit1 === 'In Hundreds of Thousands') {
                    return value !== 0 ? (value / 100000) + 'K' : 0
                  }
                  if (unit1 === 'In Thousands of Thousands') {
                    return value !== 0 ? (value / 1000000) + 'M' : 0
                  }
                } else {
                  return value.toFixed(1)
                }
              },
            },
            classNames: {
              point: "ct-point ct-green",
              line: "ct-line ct-green",
            },
            low: 0,
            high: _.max(totalGrossDataFull) + 1,
            chartPadding: {
              top: 0,
              right: 10,
              bottom: 0,
              left: 10
            }
          };
          this.setState({
            grossSalesData: grossSalesData,
            grossSalesOptions: grossSalesOptions
          })
        }
        if (grossSalesFilterValue === 'mtd') {
          const payoutsByDate = _.groupBy(employeePayouts, 'date');
          let totalsGrossByDates = [];
          _.mapKeys(payoutsByDate, (payoutDetails, date) => {
            let totalGross = 0;
            payoutDetails.forEach((payoutDetail) => {
              totalGross += payoutDetail.grossIncome;
            });
            totalsGrossByDates.push({
              date: date,
              totalGross: totalGross
            });
          });
          let totalGrossDataFull = mtdLabels.map(date => {
            const grossDate = _.find(totalsGrossByDates, { 'date': date });
            return grossDate ? grossDate.totalGross : 0;
          });
          const grossSalesData = {
            labels: mtdLabels,
            series: [totalGrossDataFull]
          }
          const grossSalesOptions = {
            lineSmooth: false,
            height: "200px",
            scaleMinSpace: 300,
            onlyInteger: true,
            showPoint: true,
            axisX: {
              showGrid: false,
              showLabel: true,
            },
            axisY: {
              offset: 50,
              labelInterpolationFnc: function (value) {
                if (value > 1) {
                  if (unit1 === 'In Thousands') {
                    return value !== 0 ? (value / 1000) + 'K' : 0
                  }
                  if (unit1 === 'In Tens of Thousands') {
                    return value !== 0 ? (value / 10000) + 'K' : 0
                  }
                  if (unit1 === 'In Hundreds of Thousands') {
                    return value !== 0 ? (value / 100000) + 'K' : 0
                  }
                  if (unit1 === 'In Thousands of Thousands') {
                    return value !== 0 ? (value / 1000000) + 'M' : 0
                  }
                } else {
                  return value.toFixed(1)
                }
              }
            },
            classNames: {
              point: "ct-point ct-green",
              line: "ct-line ct-green",
            },
            low: 0,
            high: _.max(totalGrossDataFull) + 1,
            chartPadding: {
              top: 0,
              right: 10,
              bottom: 0,
              left: 10
            }
          };
          this.setState({
            grossSalesData: grossSalesData,
            grossSalesOptions: grossSalesOptions
          })
        }
        if (grossSalesFilterValue === 'wtd') {
          const payoutsByDate = _.groupBy(employeePayouts, 'date');
          let totalsGrossByDates = [];
          _.mapKeys(payoutsByDate, (payoutDetails, date) => {
            let totalGross = 0;
            payoutDetails.forEach((payoutDetail) => {
              totalGross += payoutDetail.grossIncome;
            });
            totalsGrossByDates.push({
              date: date,
              totalGross: totalGross
            });
          });
          let totalGrossDataFull = wtdLabels.map(date => {
            const grossDate = _.find(totalsGrossByDates, { 'date': date });
            return grossDate ? grossDate.totalGross : 0;
          });
          const grossSalesData = {
            labels: wtdLabels,
            series: [totalGrossDataFull]
          }
          const grossSalesOptions = {
            height: "200px",
            scaleMinSpace: 300,
            onlyInteger: true,
            lineSmooth: false,
            showPoint: true,
            axisX: {
              showGrid: false,
              showLabel: true,
            },
            axisY: {
              offset: 50,
              labelInterpolationFnc: function (value) {
                if (value > 1) {
                  if (unit1 === 'In Thousands') {
                    return value !== 0 ? (value / 1000) + 'K' : 0
                  }
                  if (unit1 === 'In Tens of Thousands') {
                    return value !== 0 ? (value / 10000) + 'K' : 0
                  }
                  if (unit1 === 'In Hundreds of Thousands') {
                    return value !== 0 ? (value / 100000) + 'K' : 0
                  }
                  if (unit1 === 'In Thousands of Thousands') {
                    return value !== 0 ? (value / 1000000) + 'M' : 0
                  }
                } else {
                  return value.toFixed(1)
                }
              }
            },
            low: 0,
            high: _.max(totalGrossDataFull) + 1,
            chartPadding: {
              top: 0,
              right: 10,
              bottom: 0,
              left: 10
            }
          };
          this.setState({
            grossSalesData: grossSalesData,
            grossSalesOptions: grossSalesOptions
          })
        }
      }).catch((err) => {
        console.log(err);
      });

    // get last year gross sales data
    // get labels 
    let dateStart2 = moment(grossSalesFilter2.start);
    let dateEnd2 = moment(grossSalesFilter2.end);
    let ytdLabels2 = [];
    let mtdLabels2 = [];
    let wtdLabels2 = [];
    // for YTD
    while (dateEnd2 > dateStart2 || dateStart2.format('M') === dateEnd2.format('M')) {
      ytdLabels2.push(dateStart2.format('MM'));
      dateStart2.add(1, 'month');
    }
    // for MTD
    dateStart2 = moment(grossSalesFilter2.start);
    dateEnd2 = moment(grossSalesFilter2.end);

    while (dateEnd2 > dateStart2 || dateStart2.format('D') === dateEnd2.format('D')) {
      mtdLabels2.push(dateStart2.format('DD'));
      wtdLabels2.push(dateStart2.format('DD'));
      dateStart2.add(1, 'days');
    }
    axios
      .post('/api/commissions/commissions',
        {
          userId: this.props.auth.user.id,
          dateFrom: grossSalesFilter2.start,
          dateTo: grossSalesFilter2.end,
          mode: 'GrossSales'
        })
      .then((res) => {
        let employeePayouts = [];
        res.data.forEach(commission => {
          commission.employeePayouts.forEach((employeePayoutDetail) => {
            employeePayoutDetail.payouts.forEach((payoutDetailObj) => {
              // Define Payout Object
              const payoutObj = {
                employeeId: employeePayoutDetail.employee.id,
                employeeName: employeePayoutDetail.employee.name,
                grossIncome: payoutDetailObj.grossIncome,
                month: moment(payoutDetailObj.date, 'YYYY/MM/DD').format('MM').substring(0, 5),
                date: moment(payoutDetailObj.date, 'YYYY/MM/DD').format('DD').substring(0, 2)
              }
              employeePayouts.push(payoutObj);
            });
          });
        });
        // Get Monthly Gross Sales Data
        if (grossSalesFilterValue === 'ytd') {
          const payoutsByMonth = _.groupBy(employeePayouts, 'month');
          let totalsGrossByMonths = [];
          _.mapKeys(payoutsByMonth, (payoutDetails, month) => {
            let totalGross = 0;
            payoutDetails.forEach((payoutDetail) => {
              totalGross += payoutDetail.grossIncome;
            });
            totalsGrossByMonths.push({
              month: month,
              totalGross: totalGross
            });
          });
          let totalGrossDataFull = ytdLabels2.map(month => {
            const grossMonth = _.find(totalsGrossByMonths, { 'month': month });
            return grossMonth ? grossMonth.totalGross : 0;
          });
          this.state.grossSalesData.series.push(totalGrossDataFull);
          const grossSalesData = {
            labels: this.state.grossSalesData.labels,
            series: this.state.grossSalesData.series
          }
          const grossSalesOptions = {
            height: "200px",
            scaleMinSpace: 300,
            onlyInteger: true,
            lineSmooth: false,
            showPoint: true,
            axisX: {
              showGrid: false,
              showLabel: true,
            },
            axisY: {
              offset: 50,
              labelInterpolationFnc: function (value) {
                if (value > 1) {
                  if (unit1 === 'In Thousands') {
                    return value !== 0 ? (value / 1000) + 'K' : 0
                  }
                  if (unit1 === 'In Tens of Thousands') {
                    return value !== 0 ? (value / 10000) + 'K' : 0
                  }
                  if (unit1 === 'In Hundreds of Thousands') {
                    return value !== 0 ? (value / 100000) + 'K' : 0
                  }
                  if (unit1 === 'In Thousands of Thousands') {
                    return value !== 0 ? (value / 1000000) + 'M' : 0
                  }
                } else {
                  return value.toFixed(1)
                }
              }
            },
            classNames: {
              point: "ct-point ct-green",
              line: "ct-line ct-green",
            },
            low: 0,
            high: _.max(totalGrossDataFull) + 1,
            chartPadding: {
              top: 0,
              right: 10,
              bottom: 0,
              left: 10
            }
          };
          if (this.state.grossSalesOptions.high >= grossSalesOptions.high) {
            this.setState({
              grossSalesData: grossSalesData,
              grossSalesOptions: this.state.grossSalesOptions
            })
          } else {
            this.setState({
              grossSalesData: grossSalesData,
              grossSalesOptions: grossSalesOptions
            })
          }
        }
        if (grossSalesFilterValue === 'mtd') {
          const payoutsByDate = _.groupBy(employeePayouts, 'date');
          let totalsGrossByDates = [];
          _.mapKeys(payoutsByDate, (payoutDetails, date) => {
            let totalGross = 0;
            payoutDetails.forEach((payoutDetail) => {
              totalGross += payoutDetail.grossIncome;
            });
            totalsGrossByDates.push({
              date: date,
              totalGross: totalGross
            });
          });
          let totalGrossDataFull = mtdLabels2.map(date => {
            const grossDate = _.find(totalsGrossByDates, { 'date': date });
            return grossDate ? grossDate.totalGross : 0;
          });

          this.state.grossSalesData.series.push(totalGrossDataFull);
          const grossSalesData = {
            labels: this.state.grossSalesData.labels,
            series: this.state.grossSalesData.series
          }
          const grossSalesOptions = {
            height: "200px",
            scaleMinSpace: 300,
            onlyInteger: true,
            lineSmooth: false,
            showPoint: true,
            axisX: {
              showGrid: false,
              showLabel: true,
            },
            axisY: {
              offset: 50,
              labelInterpolationFnc: function (value) {
                if (value > 1) {
                  if (unit1 === 'In Thousands') {
                    return value !== 0 ? (value / 1000) + 'K' : 0
                  }
                  if (unit1 === 'In Tens of Thousands') {
                    return value !== 0 ? (value / 10000) + 'K' : 0
                  }
                  if (unit1 === 'In Hundreds of Thousands') {
                    return value !== 0 ? (value / 100000) + 'K' : 0
                  }
                  if (unit1 === 'In Thousands of Thousands') {
                    return value !== 0 ? (value / 1000000) + 'M' : 0
                  }
                } else {
                  return value.toFixed(1)
                }
              }
            },
            classNames: {
              point: "ct-point ct-green",
              line: "ct-line ct-green",
            },
            low: 0,
            high: _.max(totalGrossDataFull) + 1,
            chartPadding: {
              top: 0,
              right: 10,
              bottom: 0,
              left: 10
            }
          };
          if (this.state.grossSalesOptions.high >= grossSalesOptions.high) {
            this.setState({
              grossSalesData: grossSalesData,
              grossSalesOptions: this.state.grossSalesOptions
            })
          } else {
            this.setState({
              grossSalesData: grossSalesData,
              grossSalesOptions: grossSalesOptions
            })
          }
        }
        if (grossSalesFilterValue === 'wtd') {
          const payoutsByDate = _.groupBy(employeePayouts, 'date');
          let totalsGrossByDates = [];
          _.mapKeys(payoutsByDate, (payoutDetails, date) => {
            let totalGross = 0;
            payoutDetails.forEach((payoutDetail) => {
              totalGross += payoutDetail.grossIncome;
            });
            totalsGrossByDates.push({
              date: date,
              totalGross: totalGross
            });
          });
          let totalGrossDataFull = wtdLabels2.map(date => {
            const grossDate = _.find(totalsGrossByDates, { 'date': date });
            return grossDate ? grossDate.totalGross : 0;
          });
          this.state.grossSalesData.series.push(totalGrossDataFull);
          const grossSalesData = {
            labels: this.state.grossSalesData.labels,
            series: this.state.grossSalesData.series
          }
          const grossSalesOptions = {
            height: "200px",
            scaleMinSpace: 300,
            onlyInteger: true,
            lineSmooth: false,
            showPoint: true,
            axisX: {
              showGrid: false,
              showLabel: true,
            },
            axisY: {
              offset: 50,
              labelInterpolationFnc: function (value) {
                if (value > 1) {
                  if (unit1 === 'In Thousands') {
                    return value !== 0 ? (value / 1000) + 'K' : 0
                  }
                  if (unit1 === 'In Tens of Thousands') {
                    return value !== 0 ? (value / 10000) + 'K' : 0
                  }
                  if (unit1 === 'In Hundreds of Thousands') {
                    return value !== 0 ? (value / 100000) + 'K' : 0
                  }
                  if (unit1 === 'In Thousands of Thousands') {
                    return value !== 0 ? (value / 1000000) + 'M' : 0
                  }
                } else {
                  return value.toFixed(1)
                }
              }
            },
            low: 0,
            high: _.max(totalGrossDataFull) + 1,
            chartPadding: {
              top: 0,
              right: 10,
              bottom: 0,
              left: 10
            }
          };
          if (this.state.grossSalesOptions.high >= grossSalesOptions.high) {
            this.setState({
              grossSalesData: grossSalesData,
              grossSalesOptions: this.state.grossSalesOptions
            })
          } else {
            this.setState({
              grossSalesData: grossSalesData,
              grossSalesOptions: grossSalesOptions
            })
          }
        }
      }).catch((err) => {
        console.log(err);
      });
  }

  _renderTopPerformers = () => {
    const { topPerformers, topFilterValue } = this.state;

    return <div className="top-performers">
      <div className="top-period">
        <div className="display-flex justify-around align-center">
          <div className={topFilterValue === "ytd" ? "tab-btn__active" : "tab-btn"} onClick={() => this.setTopFilter("ytd")}>
            YTD
          </div>
          <div className={topFilterValue === "mtd" ? "tab-btn__active" : "tab-btn"} onClick={() => this.setTopFilter("mtd")}>
            MTD
          </div>
          <div className={topFilterValue === "wtd" ? "tab-btn__active" : "tab-btn"} onClick={() => this.setTopFilter("wtd")}>
            WTD
          </div>
        </div>
      </div>
      <Card>
        <div className="header display-flex justify-between align-center">
          <div className="display-flex justify-between align-center">
            <div className="card-logo-outer display-flex justify-center align-center">
              <div className="card-logo-inner display-flex justify-center align-center">
                <img src={top_performer} alt="overview-logo" />
              </div>
            </div>
            <div className="card-title font-bold">
              Top Performers
            </div>
          </div>
          <div className="actions display-flex justify-between align-center text-btn-hover">
            <Link to="/pay" className="font-medium">Pay Now</Link>
            <span className="display-flex justify-center align-center">
              <ChevronRightIcon />
            </span>
          </div>
        </div>
        <div className="sub-header display-flex justify-end">
          <div className="display-flex justify-between align-center">
            <div className={topFilterValue === "ytd" ? "period-filter-btn period-filter-btn__active font-medium custom-btn-hover" : "period-filter-btn font-medium custom-btn-hover"} onClick={() => this.setTopFilter("ytd")}>YTD</div>
            <div className={topFilterValue === "mtd" ? "period-filter-btn period-filter-btn__active font-medium custom-btn-hover" : "period-filter-btn font-medium custom-btn-hover"} onClick={() => this.setTopFilter("mtd")}>MTD</div>
            <div className={topFilterValue === "wtd" ? "period-filter-btn period-filter-btn__active font-medium custom-btn-hover" : "period-filter-btn font-medium custom-btn-hover"} onClick={() => this.setTopFilter("wtd")}>WTD</div>
          </div>
        </div>
        <div className="top-performers-content">
          <div className="table-header display-flex justify-between align-center">
            <div className="title-1">Rank</div>
            <div className="title-2">Name</div>
            <div className="title-3">Amount</div>
          </div>
          <div className="table-content">
            {
              topPerformers.length > 0 ? topPerformers.map((top, index) => {
                return <div key={index} className={(index % 2) === 1 ? "table-record table-record-odd display-flex justify-between align-center" : "table-record display-flex justify-between align-center"}>
                  <div className="record-1">{index + 1}</div>
                  <div className="record-2">{top.employeeName}</div>
                  <div className="record-3">${top.totalGross}</div>
                </div>
              }) : <div className={"table-record display-flex justify-between align-center"}>
                <div className="record-1"></div>
                <div className="record-2" style={{ textAlign: 'center' }}>No Results</div>
                <div className="record-3"></div>
              </div>
            }
          </div>
        </div>
        <div className="bottom-actions display-flex justify-end align-center text-btn-hover">
          <Link to="/pay" className="font-medium">Pay Now</Link>
          <span className="display-flex justify-center align-center">
            <ChevronRightIcon />
          </span>
        </div>
      </Card>
    </div>;
  }

  setTopFilter(param) {
    this.setState({
      topFilterValue: param,
      topPerformerFilter: {
        start: param === 'ytd' ? moment().startOf('year').utc() : param === 'mtd' ? moment().startOf('month').utc() : moment().startOf('week').utc(),
        end: moment().endOf('day').utc()
      }
    }, () => {
      this.getTopPerformers();
    });
  }

  getTopPerformers() {
    const { topPerformerFilter } = this.state;
    axios
      .post('/api/commissions/commissions',
        {
          userId: this.props.auth.user.id,
          dateFrom: topPerformerFilter.start,
          dateTo: topPerformerFilter.end,
          mode: 'GrossSales'
        })
      .then((res) => {
        let employeePayouts = [];
        res.data.forEach(commission => {
          commission.employeePayouts.forEach((employeePayoutDetail) => {
            employeePayoutDetail.payouts.forEach((payoutDetailObj) => {
              // Define Payout Object
              const payoutObj = {
                employeeId: employeePayoutDetail.employee.id,
                employeeName: employeePayoutDetail.employee.name,
                grossIncome: payoutDetailObj.grossIncome,
                month: moment(payoutDetailObj.date, 'YYYY/MM/DD').format('MMMM').substring(0, 3)
              }
              employeePayouts.push(payoutObj);
            });
          });
        });
        // Top, Lower Performer Data
        const payoutsByEmployees = _.groupBy(employeePayouts, 'employeeId');
        let totalsPaidByEmployees = [];
        _.mapKeys(payoutsByEmployees, (payoutDetails, employeeId) => {
          let totalGross = 0;
          payoutDetails.forEach((payoutDetail) => {
            totalGross += payoutDetail.grossIncome;
          });
          totalsPaidByEmployees.push({
            employeeId: employeeId,
            employeeName: payoutDetails[0].employeeName,
            totalGross: parseInt(totalGross.toFixed(0))
          });
        });
        let topPerformers = [];
        
        while (totalsPaidByEmployees.length > 0) {
          const top = _.maxBy(totalsPaidByEmployees, 'totalGross');
          topPerformers.push(top);
          _.remove(totalsPaidByEmployees, {
            employeeId: top.employeeId
          });
        }
        topPerformers.splice(5, topPerformers.length - 1);
        this.setState({
          topPerformers: topPerformers
        })
      }).catch((err) => {
        console.log(err);
      });
  }

  _renderPayrollHistory = () => {
    const { payrollHistoryFilterValue, period_dropdown2, unit2 } = this.state;
    return <div className="payroll-history">
      <div className="top-period">
        <div className="display-flex justify-around align-center">
          <div className={payrollHistoryFilterValue === "ytd" ? "tab-btn__active" : "tab-btn"} onClick={() => this.setPayrollHistoryFilter("ytd")}>
            YTD
          </div>
          <div className={payrollHistoryFilterValue === "mtd" ? "tab-btn__active" : "tab-btn"} onClick={() => this.setPayrollHistoryFilter("mtd")}>
            MTD
          </div>
          <div className={payrollHistoryFilterValue === "wtd" ? "tab-btn__active" : "tab-btn"} onClick={() => this.setPayrollHistoryFilter("wtd")}>
            WTD
          </div>
        </div>
      </div>
      <Card>
        <div className="header display-flex justify-between align-center">
          <div className="display-flex justify-between align-center">
            <div className="card-logo-outer display-flex justify-center align-center">
              <div className="card-logo-inner display-flex justify-center align-center">
                <img src={payroll_history} alt="overview-logo" />
              </div>
            </div>
            <div className="card-title font-bold">
              Payroll History
            </div>
            <CustomBtn>
              <div className="display-flex justify-between align-center position-relative" onClick={this.togglePeriodDropdown2} ref={this.dropdownRef2}>
                <span className="font-medium">{unit2}</span>
                <ArrowDropDownIcon />
                {
                  period_dropdown2 ? <div className="period-dropdown thousands">
                    <div className="display-flex justify-start align-center" onClick={() => this.getYUnit2('thousand')}>
                      <span>In Thousands</span>
                    </div>
                    <div className="display-flex justify-start align-center" onClick={() => this.getYUnit2('ten')}>
                      <span>In Tens of Thousands</span>
                    </div>
                    <div className="display-flex justify-start align-center" onClick={() => this.getYUnit2('hundred')}>
                      <span>In Hundreds of Thousands </span>
                    </div>
                    <div className="display-flex justify-start align-center" onClick={() => this.getYUnit2('million')}>
                      <span>In Thousands of Thousands </span>
                    </div>
                  </div> : null
                }
              </div>
            </CustomBtn>
          </div>
          <div className="actions display-flex justify-between align-center text-btn-hover">
            <Link to="/report/pay-report" className="font-medium">Payroll Report</Link>
            <span className="display-flex justify-center align-center">
              <ChevronRightIcon />
            </span>
          </div>
        </div>
        <div className="sub-header display-flex justify-end">
          <div className="display-flex justify-between align-center">
            <div className={payrollHistoryFilterValue === "ytd" ? "period-filter-btn period-filter-btn__active font-medium custom-btn-hover" : "period-filter-btn font-medium custom-btn-hover"} onClick={() => this.setPayrollHistoryFilter("ytd")}>YTD</div>
            <div className={payrollHistoryFilterValue === "mtd" ? "period-filter-btn period-filter-btn__active font-medium custom-btn-hover" : "period-filter-btn font-medium custom-btn-hover"} onClick={() => this.setPayrollHistoryFilter("mtd")}>MTD</div>
            <div className={payrollHistoryFilterValue === "wtd" ? "period-filter-btn period-filter-btn__active font-medium custom-btn-hover" : "period-filter-btn font-medium custom-btn-hover"} onClick={() => this.setPayrollHistoryFilter("wtd")}>WTD</div>
          </div>
        </div>
        <div className="payroll-graph">
          <ChartistGraph
            className="ct-chart-payroll"
            data={this.state.payrollHistoryData}
            type="Bar"
            options={this.state.payrollHistoryOptions}
            responsiveOptions={payrollHistoryChart.responsiveOptions}
            listener={payrollHistoryChart.animation}
          />
        </div>
        <div className="bottom-actions display-flex justify-end align-center text-btn-hover">
          <Link to="/report/pay-report" className="font-medium">Payroll Report</Link>
          <span className="display-flex justify-center align-center">
            <ChevronRightIcon />
          </span>
        </div>
      </Card>
    </div>;
  }

  getYUnit2 = (param) => {
    const that = this;
    if (param === 'thousand') {
      this.setState({
        unit2: 'In Thousands'
      }, () => {
        that.getPayrollHistoryData();
      });
    }
    if (param === 'ten') {
      this.setState({
        unit2: 'In Tens of Thousands'
      }, () => {
        that.getPayrollHistoryData();
      });
    }
    if (param === 'hundred') {
      this.setState({
        unit2: 'In Hundreds of Thousands'
      }, () => {
        that.getPayrollHistoryData();
      });
    }
    if (param === 'million') {
      this.setState({
        unit2: 'In Thousands of Thousands'
      }, () => {
        that.getPayrollHistoryData();
      });
    }
  }

  setPayrollHistoryFilter(param) {
    this.setState({
      payrollHistoryFilterValue: param,
      payrollHistoryFilter: {
        start: param === 'ytd' ? moment().startOf('year').utc() : param === 'mtd' ? moment().startOf('month').utc() : moment().startOf('week').utc(),
        end: moment().endOf('day').utc()
      }
    }, () => {
      this.getPayrollHistoryData();
    });
  }

  getPayrollHistoryData() {
    const { payrollHistoryFilter, payrollHistoryFilterValue, unit2 } = this.state;
    // get labels 
    let dateStart = moment(payrollHistoryFilter.start);
    let dateEnd = moment(payrollHistoryFilter.end);
    let ytdLabels = [];
    let mtdLabels = [];
    let wtdLabels = [];
    // for YTD
    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      ytdLabels.push(dateStart.format('MM'));
      dateStart.add(1, 'month');
    }
    // for MTD
    dateStart = moment(payrollHistoryFilter.start);
    dateEnd = moment(payrollHistoryFilter.end);

    while (dateEnd > dateStart || dateStart.format('D') === dateEnd.format('D')) {
      mtdLabels.push(dateStart.format('DD'));
      wtdLabels.push(dateStart.format('DD'));
      dateStart.add(1, 'days');
    }
    const requests = [];
    requests.push(axios.post('/api/commissions/commissions',
      {
        userId: this.props.auth.user.id,
        dateFrom: payrollHistoryFilter.start,
        dateTo: payrollHistoryFilter.end,
      }));
    axios.all(requests).then(axios.spread((...responses) => {
      let allTransactions = [];
      // Parse Commission Data
      responses[0].data.forEach(commission => {
        commission.employeePayouts.forEach((employeePayoutDetail) => {
          employeePayoutDetail.payouts.forEach((payoutDetailObj) => {
            payoutDetailObj.transactions.forEach((transaction) => {
              allTransactions.push(
                {
                  amountPaid: transaction.amountPaid,
                  month: moment(transaction.datePaid, 'YYYY/MM/DD').format('MM').substring(0, 5),
                  date: moment(transaction.datePaid, 'YYYY/MM/DD').format('DD').substring(0, 2)
                }
              );
            });
          });
        });
      });
      // Payroll Hisotry
      if (payrollHistoryFilterValue === 'ytd') {
        const payoutsByMonths = _.groupBy(allTransactions, 'month');
        let totalsPayrollByMonths = [];
        _.mapKeys(payoutsByMonths, (payouts, month) => {
          let totalPaid = 0;
          payouts.forEach((payout) => {
            totalPaid += payout.amountPaid;
          });
          totalsPayrollByMonths.push({
            month: month,
            totalPaid: totalPaid
          });
        });
        let totalPayrollDataFull = ytdLabels.map(month => {
          const payrollMonth = _.find(totalsPayrollByMonths, { 'month': month });
          return payrollMonth ? payrollMonth.totalPaid : 0
        });
        const payrollHistoryData = {
          labels: ytdLabels,
          series: [totalPayrollDataFull]
        };
        const payrollHistoryOptions = {
          height: "200px",
          onlyInteger: true,
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: _.max(totalPayrollDataFull) + 1,
          chartPadding: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 10
          },
          axisX: {
            showGrid: false,
            showLabel: true,
          },
          axisY: {
            offset: 50,
            labelInterpolationFnc: function (value) {
              if (value > 1) {
                if (unit2 === 'In Thousands') {
                  return value !== 0 ? (value / 1000) + 'K' : 0
                }
                if (unit2 === 'In Tens of Thousands') {
                  return value !== 0 ? (value / 10000) + 'K' : 0
                }
                if (unit2 === 'In Hundreds of Thousands') {
                  return value !== 0 ? (value / 100000) + 'K' : 0
                }
                if (unit2 === 'In Thousands of Thousands') {
                  return value !== 0 ? (value / 1000000) + 'M' : 0
                }
              } else {
                return value.toFixed(1)
              }
            }
          },
        };
        this.setState({
          payrollHistoryData: payrollHistoryData,
          payrollHistoryOptions: payrollHistoryOptions
        });
      }
      if (payrollHistoryFilterValue === 'mtd') {
        const payoutsByDates = _.groupBy(allTransactions, 'date');
        let totalsPayrollByDates = [];
        _.mapKeys(payoutsByDates, (payouts, date) => {
          let totalPaid = 0;
          payouts.forEach((payout) => {
            totalPaid += payout.amountPaid;
          });
          totalsPayrollByDates.push({
            date: date,
            totalPaid: totalPaid
          });
        });
        let totalPayrollDataFull = mtdLabels.map(date => {
          const payrollDate = _.find(totalsPayrollByDates, { 'date': date });
          return payrollDate ? payrollDate.totalPaid : 0;
        });
        const payrollHistoryData = {
          labels: mtdLabels,
          series: [totalPayrollDataFull]
        };
        const payrollHistoryOptions = {
          height: "200px",
          onlyInteger: true,
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: _.max(totalPayrollDataFull) + 1,
          chartPadding: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 10
          },
          axisX: {
            showGrid: false,
            showLabel: true,
          },
          axisY: {
            offset: 50,
            labelInterpolationFnc: function (value) {
              if (value > 1) {
                if (unit2 === 'In Thousands') {
                  return value !== 0 ? (value / 1000) + 'K' : 0
                }
                if (unit2 === 'In Tens of Thousands') {
                  return value !== 0 ? (value / 10000) + 'K' : 0
                }
                if (unit2 === 'In Hundreds of Thousands') {
                  return value !== 0 ? (value / 100000) + 'K' : 0
                }
                if (unit2 === 'In Thousands of Thousands') {
                  return value !== 0 ? (value / 1000000) + 'M' : 0
                }
              } else {
                return value.toFixed(1)
              }
            }
          },
        };
        this.setState({
          payrollHistoryData: payrollHistoryData,
          payrollHistoryOptions: payrollHistoryOptions
        });
      }
      if (payrollHistoryFilterValue === 'wtd') {
        const payoutsByDates = _.groupBy(allTransactions, 'date');
        let totalsPayrollByDates = [];
        _.mapKeys(payoutsByDates, (payouts, date) => {
          let totalPaid = 0;
          payouts.forEach((payout) => {
            totalPaid += payout.amountPaid;
          });
          totalsPayrollByDates.push({
            date: date,
            totalPaid: totalPaid
          });
        });
        let totalPayrollDataFull = wtdLabels.map(date => {
          const payrollDate = _.find(totalsPayrollByDates, { 'date': date });
          return payrollDate ? payrollDate.totalPaid : 0;
        });
        const payrollHistoryData = {
          labels: wtdLabels,
          series: [totalPayrollDataFull]
        };
        const payrollHistoryOptions = {
          height: "200px",
          onlyInteger: true,
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: _.max(totalPayrollDataFull) + 1,
          chartPadding: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 10
          },
          axisX: {
            showGrid: false,
            showLabel: true,
          },
          axisY: {
            offset: 50,
            labelInterpolationFnc: function (value) {
              if (value > 1) {
                if (unit2 === 'In Thousands') {
                  return value !== 0 ? (value / 1000) + 'K' : 0
                }
                if (unit2 === 'In Tens of Thousands') {
                  return value !== 0 ? (value / 10000) + 'K' : 0
                }
                if (unit2 === 'In Hundreds of Thousands') {
                  return value !== 0 ? (value / 100000) + 'K' : 0
                }
                if (unit2 === 'In Thousands of Thousands') {
                  return value !== 0 ? (value / 1000000) + 'M' : 0
                }
              } else {
                return value.toFixed(1)
              }
            }
          },
        };
        this.setState({
          payrollHistoryData: payrollHistoryData,
          payrollHistoryOptions: payrollHistoryOptions
        });
      }
    })).catch((err) => {
      console.log(err);
      this.setState({
        toastOpen: true,
        notificationText: err.message || 'Error occured while retrieving payment data',
        notifyType: "error"
      });
    });
  }

  _renderGrossExpenses = () => {
    const { grossExpenseFilterValue, period_dropdown3, unit3 } = this.state;
    return <div className="gross-expenses">
      <div className="top-period">
        <div className="display-flex justify-around align-center">
          <div className={grossExpenseFilterValue === "ytd" ? "tab-btn__active" : "tab-btn"} onClick={() => this.setGrossExpenseFilter("ytd")}>
            YTD
          </div>
          <div className={grossExpenseFilterValue === "mtd" ? "tab-btn__active" : "tab-btn"} onClick={() => this.setGrossExpenseFilter("mtd")}>
            MTD
          </div>
          <div className={grossExpenseFilterValue === "wtd" ? "tab-btn__active" : "tab-btn"} onClick={() => this.setGrossExpenseFilter("wtd")}>
            WTD
          </div>
        </div>
      </div>
      <Card>
        <div className="header display-flex justify-between align-center">
          <div className="display-flex justify-between align-center">
            <div className="card-logo-outer display-flex justify-center align-center">
              <div className="card-logo-inner display-flex justify-center align-center">
                <img src={gross_expenses} alt="overview-logo" />
              </div>
            </div>
            <div className="card-title font-bold">
              Gross Expenses
            </div>
            <CustomBtn>
              <div className="display-flex justify-between align-center position-relative" onClick={this.togglePeriodDropdown3} ref={this.dropdownRef3}>
                <span className="font-medium">{unit3}</span>
                <ArrowDropDownIcon />
                {
                  period_dropdown3 ? <div className="period-dropdown thousands">
                    <div className="display-flex justify-start align-center" onClick={() => this.getYUnit3('thousand')}>
                      <span>In Thousands</span>
                    </div>
                    <div className="display-flex justify-start align-center" onClick={() => this.getYUnit3('ten')}>
                      <span>In Tens of Thousands</span>
                    </div>
                    <div className="display-flex justify-start align-center" onClick={() => this.getYUnit3('hundred')}>
                      <span>In Hundreds of Thousands </span>
                    </div>
                    <div className="display-flex justify-start align-center" onClick={() => this.getYUnit3('million')}>
                      <span>In Thousands of Thousands </span>
                    </div>
                  </div> : null
                }
              </div>
            </CustomBtn>
          </div>
          <div className="actions display-flex justify-between align-center text-btn-hover">
            <Link to="/report/expense-report" className="font-medium">Review Expenses</Link>
            <span className="display-flex justify-center align-center">
              <ChevronRightIcon />
            </span>
          </div>
        </div>
        <div className="sub-header display-flex justify-end">
          <div className="display-flex justify-between align-center">
            <div className={grossExpenseFilterValue === "ytd" ? "period-filter-btn period-filter-btn__active font-medium custom-btn-hover" : "period-filter-btn font-medium custom-btn-hover"} onClick={() => this.setGrossExpenseFilter("ytd")}>YTD</div>
            <div className={grossExpenseFilterValue === "mtd" ? "period-filter-btn period-filter-btn__active font-medium custom-btn-hover" : "period-filter-btn font-medium custom-btn-hover"} onClick={() => this.setGrossExpenseFilter("mtd")}>MTD</div>
            <div className={grossExpenseFilterValue === "wtd" ? "period-filter-btn period-filter-btn__active font-medium custom-btn-hover" : "period-filter-btn font-medium custom-btn-hover"} onClick={() => this.setGrossExpenseFilter("wtd")}>WTD</div>
          </div>
        </div>
        <div className="expenses-graph">
          <ChartistGraph
            className="ct-chart-expenses"
            data={this.state.grossExpenseData}
            type="Line"
            options={this.state.grossExpenseOptions}
            responsiveOptions={grossExpenseChart.responsiveOptions}
            listener={grossExpenseChart.animation}
          />
        </div>
        <div className="bottom-actions display-flex justify-end align-center text-btn-hover">
          <Link to="/report/expense-report" className="font-medium">Review Expenses</Link>
          <span className="display-flex justify-center align-center">
            <ChevronRightIcon />
          </span>
        </div>
      </Card>
    </div>;
  }

  getYUnit3 = (param) => {
    const that = this;
    if (param === 'thousand') {
      this.setState({
        unit3: 'In Thousands'
      }, () => {
        that.getGrossExpenseData();
      });
    }
    if (param === 'ten') {
      this.setState({
        unit3: 'In Tens of Thousands'
      }, () => {
        that.getGrossExpenseData();
      });
    }
    if (param === 'hundred') {
      this.setState({
        unit3: 'In Hundreds of Thousands'
      }, () => {
        that.getGrossExpenseData();
      });
    }
    if (param === 'million') {
      this.setState({
        unit3: 'In Thousands of Thousands'
      }, () => {
        that.getGrossExpenseData();
      });
    }
  }

  setGrossExpenseFilter(param) {
    this.setState({
      grossExpenseFilterValue: param,
      grossExpenseFilter: {
        start: param === 'ytd' ? moment().startOf('year').utc() : param === 'mtd' ? moment().startOf('month').utc() : moment().startOf('week').utc(),
        end: moment().endOf('day').utc()
      }
    }, () => {
      this.getGrossExpenseData();
    });
  }

  getGrossExpenseData() {
    const { grossExpenseFilter, grossExpenseFilterValue, unit3 } = this.state;
    // get labels 
    let dateStart = moment(grossExpenseFilter.start);
    let dateEnd = moment(grossExpenseFilter.end);
    let ytdLabels = [];
    let mtdLabels = [];
    let wtdLabels = [];
    // for YTD
    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
      ytdLabels.push(dateStart.format('MM'));
      dateStart.add(1, 'month');
    }
    // for MTD
    dateStart = moment(grossExpenseFilter.start);
    dateEnd = moment(grossExpenseFilter.end);

    while (dateEnd > dateStart || dateStart.format('D') === dateEnd.format('D')) {
      mtdLabels.push(dateStart.format('DD'));
      wtdLabels.push(dateStart.format('DD'));
      dateStart.add(1, 'days');
    }
    const requests = [];
    requests.push(axios.post('/api/expenses/expenses',
      {
        userId: this.props.auth.user.id,
        dateFrom: grossExpenseFilter.start,
        dateTo: grossExpenseFilter.end,
      }));
    axios.all(requests).then(axios.spread((...responses) => {
      // let allTransactions = [];
      // Parse Expense Data
      let grossExpenses = [];
      const expenses = _.filter(responses[0].data, (expense) => {
        return (moment(expense.date).isAfter(moment().startOf('year').utc())) &&
          (moment(expense.date).isBefore(moment().endOf('day').utc()));
      });
      expenses.forEach((expense) => {
        // expense.transactions.forEach((transaction) => {
        //   allTransactions.push(
        //     {
        //       amountPaid: transaction.amountPaid,
        //       month: moment(transaction.datePaid, 'YYYY/MM/DD').format('MM').substring(0, 5),
        //       date: moment(transaction.datePaid, 'YYYY/MM/DD').format('DD').substring(0, 2)
        //     }
        //   );
        // });
        grossExpenses.push({
          total: expense.total,
          month: moment(expense.date, 'YYYY/MM/DD').format('MM').substring(0, 3),
          date: moment(expense.date, 'YYYY/MM/DD').format('DD').substring(0, 2),
        })
      });
      // Gross Expenses

      if (grossExpenseFilterValue === 'ytd') {
        const grossExpensesByMonths = _.groupBy(grossExpenses, 'month');
        let totalsExpensesByMonths = [];
        _.mapKeys(grossExpensesByMonths, (expenses, month) => {
          let total = 0;
          expenses.forEach((expense) => {
            total += expense.total;
          });
          totalsExpensesByMonths.push({
            month: month,
            total: total
          });
        });
        let totalGrossDataFull = ytdLabels.map(month => {
          const expenseMonth = _.find(totalsExpensesByMonths, { 'month': month });
          return expenseMonth ? expenseMonth.total : 0
        });

        const grossExpenseData = {
          labels: ytdLabels,
          series: [totalGrossDataFull]
        };
        const grossExpenseOptions = {
          height: "200px",
          onlyInteger: true,
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: _.max(totalGrossDataFull) + 1,
          chartPadding: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 10
          },
          axisX: {
            showGrid: false,
            showLabel: true,
          },
          axisY: {
            offset: 50,
            labelInterpolationFnc: function (value) {
              if (value > 1) {
                if (unit3 === 'In Thousands') {
                  return value !== 0 ? (value / 1000) + 'K' : 0
                }
                if (unit3 === 'In Tens of Thousands') {
                  return value !== 0 ? (value / 10000) + 'K' : 0
                }
                if (unit3 === 'In Hundreds of Thousands') {
                  return value !== 0 ? (value / 100000) + 'K' : 0
                }
                if (unit3 === 'In Thousands of Thousands') {
                  return value !== 0 ? (value / 1000000) + 'M' : 0
                }
              } else {
                return value.toFixed(1)
              }
            }
          },
        };
        this.setState({
          grossExpenseData: grossExpenseData,
          grossExpenseOptions: grossExpenseOptions
        });
      }
      if (grossExpenseFilterValue === 'mtd') {
        const grossExpensesByDates = _.groupBy(grossExpenses, 'date');
        let totalsExpensesByDates = [];
        _.mapKeys(grossExpensesByDates, (expenses, date) => {
          let total = 0;
          expenses.forEach((expense) => {
            total += expense.total;
          });
          totalsExpensesByDates.push({
            date: date,
            total: total
          });
        });
        let totalGrossDataFull = mtdLabels.map(date => {
          const expenseDate = _.find(totalsExpensesByDates, { 'date': date });
          return expenseDate ? expenseDate.total : 0;
        });
        const grossExpenseData = {
          labels: mtdLabels,
          series: [totalGrossDataFull]
        };
        const grossExpenseOptions = {
          height: "200px",
          onlyInteger: true,
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: _.max(totalGrossDataFull) + 1,
          chartPadding: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 10
          },
          axisX: {
            showGrid: false,
            showLabel: true,
          },
          axisY: {
            offset: 50,
            labelInterpolationFnc: function (value) {
              if (value > 1) {
                if (unit3 === 'In Thousands') {
                  return value !== 0 ? (value / 1000) + 'K' : 0
                }
                if (unit3 === 'In Tens of Thousands') {
                  return value !== 0 ? (value / 10000) + 'K' : 0
                }
                if (unit3 === 'In Hundreds of Thousands') {
                  return value !== 0 ? (value / 100000) + 'K' : 0
                }
                if (unit3 === 'In Thousands of Thousands') {
                  return value !== 0 ? (value / 1000000) + 'M' : 0
                }
              } else {
                return value.toFixed(1)
              }
            }
          },
        };
        this.setState({
          grossExpenseData: grossExpenseData,
          grossExpenseOptions: grossExpenseOptions
        });
      }
      if (grossExpenseFilterValue === 'wtd') {
        const grossExpensesByDates = _.groupBy(grossExpenses, 'date');
        let totalsExpensesByDates = [];
        _.mapKeys(grossExpensesByDates, (expenses, date) => {
          let total = 0;
          expenses.forEach((expense) => {
            total += expense.total;
          });
          totalsExpensesByDates.push({
            date: date,
            total: total
          });
        });
        let totalGrossDataFull = wtdLabels.map(date => {
          const expenseDate = _.find(totalsExpensesByDates, { 'date': date });
          return expenseDate ? expenseDate.total : 0;
        });
        const grossExpenseData = {
          labels: wtdLabels,
          series: [totalGrossDataFull]
        };
        const grossExpenseOptions = {
          height: "200px",
          onlyInteger: true,
          lineSmooth: Chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: _.max(totalGrossDataFull) + 1,
          chartPadding: {
            top: 0,
            right: 10,
            bottom: 0,
            left: 10
          },
          axisX: {
            showGrid: false,
            showLabel: true,
          },
          axisY: {
            offset: 50,
            labelInterpolationFnc: function (value) {
              if (value > 1) {
                if (unit3 === 'In Thousands') {
                  return value !== 0 ? (value / 1000) + 'K' : 0
                }
                if (unit3 === 'In Tens of Thousands') {
                  return value !== 0 ? (value / 10000) + 'K' : 0
                }
                if (unit3 === 'In Hundreds of Thousands') {
                  return value !== 0 ? (value / 100000) + 'K' : 0
                }
                if (unit3 === 'In Thousands of Thousands') {
                  return value !== 0 ? (value / 1000000) + 'M' : 0
                }
              } else {
                return value.toFixed(1)
              }
            }
          },
        };
        this.setState({
          grossExpenseData: grossExpenseData,
          grossExpenseOptions: grossExpenseOptions
        });
      }

    })).catch((err) => {
      console.log(err);
      this.setState({
        toastOpen: true,
        notificationText: err.message || 'Error occured while retrieving payment data',
        notifyType: "error"
      });
    });
  }

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  handleClose = () => {
    this.setState({
      toastOpen: false
    })
  };

  goToSubscribe = () => {
    this.props.props.history.push("/account");
  }

  noSubscribe = () => {
    let subSkippedCount = localStorage.getItem("SubSkippedCount");
    if (subSkippedCount === null) subSkippedCount = 1;
    else subSkippedCount = parseInt(subSkippedCount) + 1;
    localStorage.setItem("SubSkippedCount", subSkippedCount);
    this.setState({
      subscribeQuestion: false
    });
  }

  render() {
    const { toastOpen, notificationText, notifyType, subscriptionText } = this.state;
    return (
      <>
        <div className="home-wrapper">
          <Snackbar
            open={toastOpen}
            autoHideDuration={5000}
            onClose={this.handleClose}
            TransitionComponent={this.transitionLeft}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert severity={notifyType} sx={{ width: '100%', fontSize: 24 }}>
              {notificationText}
            </Alert>
          </Snackbar>
          <div className="display-flex justify-between flex-wrap">
            {
              this._renderStaticsCard1()
            }
            {
              this._renderStaticsCard2()
            }
            {
              this._renderStaticsCard3()
            }
            {
              this._renderStaticsCard4()
            }
          </div>
          <div className="overview">
            <div className="display-flex justify-between">
              {
                this._renderGrossSales()
              }
              {
                this._renderTopPerformers()
              }
            </div>
            <div className="display-flex justify-between">
              {
                this._renderPayrollHistory()
              }
              {
                this._renderGrossExpenses()
              }
            </div>
          </div>
          <Modal
            open={this.state.subscribeQuestion}
          >
            <Box sx={style}>
              <h2 className="mt-4 mb-2 mx-2 font-bold" style={{ color: '#22395B' }}>Welcome to CommissionPayPro!</h2>
              <h4 className="mt-2 mb-4 mx-2" style={{ color: '#7B8FB2' }}>
                {
                  subscriptionText
                }
              </h4>
              <div className="display-flex justify-center align-center mb-4">
                <div className="btn-blue mr-2" style={{ padding: '8px 18px' }} onClick={this.goToSubscribe}>
                  Subscribe
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </>
    );
  }
}

Home.propTypes = {
  auth: PropTypes.object.isRequired,
  registerUser: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { logoutUser, registerUser, updateUser }
)(Home);