import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import Dropzone from 'react-dropzone'
import constant from '../../shared/constant';
import { updateUser } from "../../actions/authActions";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

// reactstrap components
import {
  Row,
  Card,
  Col,
  FormGroup,
  Input, 
  Container,
  Button,
  Spinner
} from "reactstrap";  

// core components
import { GApageView } from "../../shared/gaUtils";
import Footer from "components/shared/Footer.js";

class Expense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptedFiles: [],
      errors: [],
      total: 0,
      isUploading: false,
      toastOpen: false,
      notificationText: "",
      notifyType: "error"
    }
  }

  componentDidMount() {
    document.body.classList.add("expense-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    GApageView('Expense');
  }

  componentWillUnmount() {
    document.body.classList.remove("expense-page");
    document.body.classList.remove("sidebar-collapse");
  }

  onDropRejected = (rejectedFiles) => {
    const errors = [];
    rejectedFiles.forEach((data) => {
      data.errors.forEach((error) => {
        errors.push(error);
      });
    });
    this.setState({errors: errors});
  }

  onDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      return ;
    } else if (acceptedFiles.length > constant['MAX_UPLOAD_FILE_COUNT'] ) {
      this.setState({
        toastOpen: true,
        notificationText: "You have exceeded the limit of file uploads",
        notifyType: "error"
      });
      return;
    }
    const curFiles = this.state.acceptedFiles;
    this.setState({acceptedFiles: curFiles.concat(acceptedFiles)});
  }

  fileSize = (size) => {
    if (size === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(size) / Math.log(k));
    return parseFloat((size / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  fileType = (fileName) => {
    return fileName.substring(fileName.lastIndexOf('.') + 1, fileName.length) || fileName;
  }

  removeFile = (name) => {
    // find the index of the item
    // remove the item from array
    const index = this.state.acceptedFiles.findIndex(e => e.name === name);
    const tempFiles = this.state.acceptedFiles;
    tempFiles.splice(index, 1);
    // update acceptedFiles array
    this.setState({acceptedFiles: tempFiles});
  }

  uploadExpenses = () => {
    if (this.state.acceptedFiles.length === 0) {
      this.setState({
        toastOpen: true,
        notificationText: "You haven't attached any document files.",
        notifyType: "error"
      });
      return;
    }

    if (this.state.total === 0) {
      this.setState({
        toastOpen: true,
        notificationText: "You haven't put expenses total.",
        notifyType: "error"
      });
      return;
    }
    const requests = [];
    this.state.acceptedFiles.forEach((file) => {
      const formData = new FormData();        
      formData.append('file', file); 
      requests.push(axios.post('/api/users/upload_expense', formData));
    });
    const _this = this;
    this.setState({isUploading: true});
    axios.all(requests).then(axios.spread((...responses) => {
      const docs = [];
      responses.forEach((response) => {
        docs.push({
          name: response.data.name,
          path: response.data.path,
          size: _this.fileSize(response.data.size),
          type: _this.fileType(response.data.name)
        });
      });
      const expenseData = {
        employeeId: this.props.auth.user.id,
        employeeName: this.props.auth.user.name,
        parentId: this.props.auth.user.parentId,
        total: this.state.total,
        docs: docs,
        transactions: []
      };
      axios
      .post('/api/expenses', {expense: expenseData})
      .then((res) => {
        this.setState({
          toastOpen: true,
          notificationText: "You have successfully uploaded total & expense documents",
          notifyType: "success"
        });
        this.setState({total: 0});
        this.setState({acceptedFiles: []});
        const totalEl = document.getElementById('total');
        totalEl.value = '';
        this.setState({isUploading: false});
      }).catch((err) => {
        // console.log(err);
        this.setState({
          toastOpen: true,
          notificationText: err.message || "You have successfully uploaded total & expense documents",
          notifyType: "error"
        });
        this.setState({isUploading: false});
      });
    })).catch((errors) => {
      // console.log(errors);
      this.setState({
        toastOpen: true,
        notificationText: "Error occured while uploading files!",
        notifyType: "error"
      });
      this.setState({isUploading: false});
    });
  }

  onChange = e => {
    let val = e.target.value;
    this.setState({total: val});
  };

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  handleClose = () => {
    this.setState({
      toastOpen: false
    })
  };

  render() {
    const { 
      toastOpen, 
      notificationText, 
      notifyType
    } = this.state;

    return (
      <>
        {/* <ToastContainer /> */}
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={notifyType} sx={{ width: '100%', fontSize: 24 }}>
              {notificationText}
          </Alert>
        </Snackbar>
        <div className="employee-expense-page">
          <div className="page-content">
            <div className="input">
              <div className="label">Enter Your Expenses Total</div>
              <div>
                <input
                  id="total"
                  onChange={this.onChange}
                  placeholder="Total here..."
                  autoComplete="off"
                  type="number"
                  pattern="[0-9]*"
                />
              </div>
            </div>
            <div className="input" style={{marginTop: '30px'}}>
              <div className="label">Enter Customer (Optional)</div>
              <div>
                <input
                  id="total"
                  type={'text'}
                  placeholder="Enter customer"
                  // onChange={this.onChange}
                  // value={commission.title}
                  // error={errors.title}
                />
              </div>
            </div>
            <div className="upload">
              <div className="sub-title">Upload Expenses</div>
              <Container>
                <Col className="mt-3">
                  <Dropzone 
                    onDrop={this.onDrop}
                    onDropRejected={this.onDropRejected}
                    accept=".doc,.docx,.ppt,.pptx,.xls,.xlsx,.zip,.pdf, .jpeg,.jpg,.ai,.png"
                    minSize={0}
                    maxSize={constant['MAX_UPLOAD_FILE_SIZE']}
                    multiple
                  >
                    {({getRootProps, getInputProps, isDragActive, isDragReject }) => {
                      return (
                        <div {...getRootProps()} className="dropzone">
                          <div className="upload-icon"></div>
                          <input {...getInputProps()} />
                          <p style={{ fontWeight: 500 }}>Click here or drop files to upload!</p>
                          <p style={{ color: '#ff9966', fontSize: 14, fontWeight: 400}}>Allowed file types: doc,.docx,.ppt,.pptx,.xls,.xlsx,.zip,.pdf, .jpeg,.jpg,.ai,.png</p>
                          <p style={{ color: '#ff9966', fontSize: 14, fontWeight: 400}}>Max file upload size: 10MB </p>
                        </div>
                      )}
                    }
                  </Dropzone>
                </Col>
                <Col>
                  <div className="mt-2">
                    {
                      this.state.errors.map((data, i) =>
                        <span className="red-text mt-2">{data.message}</span>
                      )
                    }
                  </div>
                </Col>
                <Col>
                  <div className="file-display-container">
                    {
                      this.state.acceptedFiles.map((data, i) => 
                          <div className="file-status-bar" key={i}>
                              <div>
                                  <div className="file-type-logo"></div>
                                  <div className="file-type">{this.fileType(data.name)}</div>
                                  <span className={`file-name ${data.invalid ? 'file-error' : ''}`}>{data.name}</span>
                                  <span className="file-size">({this.fileSize(data.size)})</span>
                                  <span className="file-remove" onClick={() => this.removeFile(data.name)}>
                                    X
                                  </span>
                              </div>
                          </div>
                      )
                    }
                  </div>
                </Col>
              </Container>
              <Row className="justify-content-center mt-3">
                <Button className="btn-round btn-cpp btn-blue" type="button" onClick={this.uploadExpenses} disabled={this.state.isUploading}>
                  {!this.state.isUploading ?
                    'Upload Expenses'
                    :
                    <>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {' Uploading...'}
                    </>
                  }
                </Button>
              </Row>
            </div>
          </div>
        </div>
      </>
    )
  }
}

Expense.propTypes = {
  auth: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  {updateUser}
)(Expense);