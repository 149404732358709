import React, { Component } from "react";
import "./App.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import { Provider } from "react-redux";
import store from "./store";

import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Home from "./components/home/Home";
import Layout from "./components/Layout";
import Commissions from "./components/commission/Commissions";
import Employees from "./components/employees/Employees";
import ReceiveFunds from "./components/receive-funds/ReceiveFunds";
import Pay from "./components/pay/Pay";
import PayReport from "./components/reports/PayReport";
import PayrollHistory from "./components/reports/PayrollHistory";
import Account from "./components/account/Account";
import Expense from "./components/employee-stuffs/Expense";
import EmployeePay from "./components/employee-stuffs/EmployeePay";
import ResetPassword from "./components/auth/ResetPassword";
import ForgotPassword from "./components/auth/ForgotPassword";
import ExpenseReport from "./components/reports/ExpenseReport";
import GrossSales from "./components/reports/GrossSales";
import { initGA } from "./shared/gaUtils";
import ContactUs from "./components/contact-us/ContactUs";
import Users from "./components/admin/users/Users";
import AccessDemo from "./components/access-demo/AccessDemo";
import constant from "./shared/constant";
import EmployeeHome from './components/home/EmployeeHome';
import CustomerReport from "./components/reports/CustomerReport";
import EmailVerify from "./components/auth/EmailVerify";
import Demo from "./components/demo/Demo";
import Explainer from "./components/explainer/Explainer";
import Questions from "./components/questions/Quesions";
import Success from "./components/success/Success"
// import axios from 'axios';
// axios.defaults.baseURL = 'https://app.commissionpaypro.com/';

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());
    // Redirect to login
    window.location.href = "/login";
  }
}

initGA();

class App extends Component {

  componentDidMount() {
    setInterval(() => {
      // Check for token to keep user logged in
      if (localStorage.jwtToken) {
        // Set auth token header auth
        const token = localStorage.jwtToken;
        setAuthToken(token);
        // Decode token and get user info and exp
        const decoded = jwt_decode(token);
        // Set user and isAuthenticated
        store.dispatch(setCurrentUser(decoded));

        // Check for expired token
        const currentTime = Date.now() / 1000; // to get in milliseconds
        if (decoded.exp < currentTime) {
          // Logout user
          store.dispatch(logoutUser());
          // Redirect to login
          window.location.href = "./login";
        }
      }
    }, constant['SESSION_EXPIRE_TIME_IN_SECONDS']);
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Route path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/questions" component={Questions} />
            <Route exact path="/reset-password" component={ForgotPassword} />
            <Route path="/reset" component={ResetPassword} />
            <Route path="/contact-us" component={ContactUs} />
            <Route path="/access-demo" component={Register} />
            <Route path="/users/:id/verify/:token" component={EmailVerify} />
            <Route path="/success" component={Success} />
            <Route exact path="/" render={(props) => <Layout props={props}><Home props={props} /></Layout>} />
            <Switch>
              <PrivateRoute exact path="/learn" component={Demo} />
              <PrivateRoute exact path="/explainer" component={Explainer} />
              <PrivateRoute exact path="/employeeHome" component={EmployeeHome} />
              <PrivateRoute exact path="/account" component={Account} />
              <PrivateRoute exact path="/commissions" component={Commissions} />
              <PrivateRoute exact path="/employees" component={Employees} />
              <PrivateRoute path="/receive-funds" component={ReceiveFunds} />
              <PrivateRoute path="/pay" component={Pay} />
              <PrivateRoute path="/report/pay-report" component={PayReport} />
              <PrivateRoute path="/report/payroll-history" component={PayrollHistory} />
              <PrivateRoute path="/expense" component={Expense} />
              <PrivateRoute path="/employee-pay" component={EmployeePay} />
              <PrivateRoute path="/report/expense-report" component={ExpenseReport} />
              <PrivateRoute path="/report/gross-sales" component={GrossSales} />
              <PrivateRoute path="/report/customer-report" component={CustomerReport} />
              <PrivateRoute exact path="/admin/users" component={Users} />
              
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;