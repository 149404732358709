import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
// reactstrap components
import {
  Button
} from "reactstrap";

import constant from "../../shared/constant";

// core components  
import { GAexception, GApageView, GAevent } from "../../shared/gaUtils";
import Floater from 'react-floater';

import fund_big_icon from '../../assets/images/fund-big-icon.png';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '600px',
  width: '100%',
  bgcolor: '#fff',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 3,
  textAlign: 'center',
  padding: 2
};

const moment = require('moment');

class ReceiveFunds extends Component {

  constructor(props) {
    super(props);
    this.state = {
      commission: {},
      errors: {},
      commissions: [],
      grossIncome: 0,
      expense: 0,
      employeeName: "",
      customerName: '',
      customerNameForExpense: '',
      adjustment: '',
      adjustmentTotal: 0,
      walkthrough: false,
      toastOpen: false,
      notificationText: "",
      notifyType: "error",
      subscribeQuestion: false,
      subscriptionText: '', 
      employees: []
    }
  }

  intervalID = 0;

  componentDidMount() {
    document.body.classList.add("receive-funds-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role === constant['ROLE']['EMPLOYEE'] || this.props.auth.user.role === constant['ROLE']['CONTRACTOR'] || this.props.auth.user.role === constant['ROLE']['MANAGER']) {
      this.props.history.push('/');
    } else {
      this.getCommissions();
      this.getEmployeeCount();
      this.getEmployees();
      GApageView('Receive Funds Page');
      if (this.props.auth.user.role === constant['ROLE']['GUEST']) {
        GAevent('Guest', 'Receive Funds Page', `Guest user: ${this.props.auth.user.name}  visited Receive Funds page`);
      } else {
        GAevent('User', 'Receive Funds Page', `User: ${this.props.auth.user.name}  visited Receive Funds page`);
      }
    }
    if (this.props.auth.user.role === constant['ROLE']['GUEST']) {
      const walkThrough = localStorage.getItem("WalkThrough");
      if (walkThrough === null || walkThrough !== "Completed") {
        this.setState({ walkthrough: true });
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("commission-pay-page");
    document.body.classList.remove("sidebar-collapse");
    clearInterval(this.intervalID);
  }

  getEmployeeCount() {
    console.log("object")
    axios
      .post('/api/users/employeeCount',
        {
          userId: this.props.auth.user.id
        })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ employeeCount: res.data })
          //   , () => {
          //   if (this.props.auth.user.role === constant['ROLE']['OWNER'] && this.props.auth.user.level !== constant['MEMBERSHIP']['ULTIMATE']) {
          //     if (!this.props.auth.user.stripeSubscriptionId && this.state.employeeCount >= 2) {
          //       if (!this.state.subscribeQuestion) {
          //         this.intervalID = setInterval(() => {
          //           this.setState({
          //             subscribeQuestion: true,
          //             subscriptionText: 'Just 99¢ for the first 7 days, then $39.95/mo for up to 5 users. Cancel Anytime',
          //           })
          //         }, SUBSCRIBE_TIME_IN_SECONDS);
          //       }
          //     } else if (this.props.auth.user.level === 1 && this.state.employeeCount >= 10) {
          //       if (!this.state.subscribeQuestion) {
          //         this.intervalID = setInterval(() => {
          //           this.setState({
          //             subscribeQuestion: true,
          //             subscriptionText: 'Subscribe $89.95/mo plan to add more employees. Cancel Anytime',
          //           })
          //         }, SUBSCRIBE_TIME_IN_SECONDS);
          //       }
          //     } else if (this.props.auth.user.level === 2 && this.state.employeeCount >= 25) {
          //       if (!this.state.subscribeQuestion) {
          //         this.intervalID = setInterval(() => {
          //           this.setState({
          //             subscribeQuestion: true,
          //             subscriptionText: 'Subscribe $149.95/mo plan to add more employees. Cancel Anytime',
          //           })
          //         }, SUBSCRIBE_TIME_IN_SECONDS);
          //       }
          //     } else if (this.props.auth.user.level === 0) {
          //       // var currentDate = moment(this.props.auth.user.subscribedDate).add(3, 'months');
          //       // if (!moment().isBefore(currentDate)) {
          //       //   if (!this.state.subscribeQuestion) {
          //       //     this.intervalID = setInterval(() => {
          //       //       this.setState({
          //       //         subscribeQuestion: true,
          //       //         subscriptionText: 'Current plan - $0.99 has been ended. Subscribe $39.95/mo plan to add more employees. Cancel Anytime',
          //       //       })
          //       //     }, SUBSCRIBE_TIME_IN_SECONDS);
          //       //   }
          //       // }
          //     }
          //   }
          // });
        }
      });
  }

  getEmployees() {
    axios
      .post('/api/users/employees', { userId: this.props.auth.user.id })
      .then((res) => {
        const employees = res.data.map((employee) => {
          return { id: employee._id, name: employee.name }
        })
        this.setState({ employees: employees });
      }).catch((err) => {
        console.log(err);
      });
  }

  getCommissions() {
    axios
      .post('/api/commissions/commissions', { userId: this.props.auth.user.id })
      .then((res) => {
        this.setState({ commissions: res.data });
      }).catch((err) => {
        console.log(err);
      });
  }

  onChange = e => {
    let val = e.target.value;
    if (e.target.id === 'grossIncome' || e.target.id === 'adjustmentTotal' || e.target.id === 'expense') {
      if (e.target.value !== '') {
        val = Number(e.target.value);
      }
    }
    if (e.target.id === 'customerNameForExpense') {
      this.setState({customerNameForExpense: e.target.value})
    }
    if (e.target.id === 'customerName' || e.target.id === 'adjustment') {
      if (val.length === 1) {
        let str = val.toUpperCase(); // First Letter Uppercase
        this.setState({ [e.target.id]: str });
        return;
      }
      let str = val;
      let char = str[str.length - 2];
      if (char === ' ') { // After space, add captialize
        let upperChar = str[str.length - 1].toUpperCase();
        str = str.substring(0, str.length - 1);
        str += upperChar;
        this.setState({ [e.target.id]: str });
        return;
      }
    }
    this.setState({ [e.target.id]: val });
  };

  validateForm = () => {
    if (_.isEmpty(this.state.commission)) {
      this.setState({
        toastOpen: true,
        notificationText: "Please pick a commission and fill in information required.",
        notifyType: "error"
      });
      return false;
    }
    if (_.isEmpty(this.state.customerName)) {
      this.setState({
        toastOpen: true,
        notificationText: "Please put the customer name.",
        notifyType: "error"
      });
      return false;
    }
    if (this.state.grossIncome === 0) {
      this.setState({
        toastOpen: true,
        notificationText: "Please put the gross income.",
        notifyType: "error"
      });
      return false;
    }
    return true;
  }

  validateExpenseForm = () => {
    if (_.isEmpty(this.state.employeeName)) {
      this.setState({
        toastOpen: true,
        notificationText: "Please choose Employee's name",
        notifyType: "error"
      });
      return false;
    }
    if (this.state.expense === 0) {
      this.setState({
        toastOpen: true,
        notificationText: "Please put the Expense.",
        notifyType: "error"
      });
      return false;
    }
    return true;
  }

  handleSelectCommission = (ev) => {
    const value = ev.target.value;
    if (value !== "") {
      const { commissions } = this.state;
      const data = commissions.filter(com => com._id === value)[0];
      this.setState({ commission: data });
    }
  }

  updateCommission = () => {
    if (!this.validateForm())
      return;
    // Calculate Total per each payout
    const employeePayouts = this.state.commission.employeePayouts.map((employeePayout) => {
      let total = 0;
      if (employeePayout.formula && employeePayout.formula !== '') {
        if (employeePayout.percentForEmployee > 0) {
          if (employeePayout.fixedForEmployee > 0) {
            // formula = `((G+A) *${employeePayout.percentForEmployee}%) + ${employeePayout.fixedForEmployee}$`;
            total = ((this.state.grossIncome + this.state.adjustmentTotal) * employeePayout.percentForEmployee) / 100 +
              employeePayout.fixedForEmployee;
          } else if (employeePayout.fixedTakenByCompany > 0) {
            // formula = `((G+A) *${employeePayout.percentForEmployee}%) - ${employeePayout.fixedTakenByCompany}$`;
            total = ((this.state.grossIncome + this.state.adjustmentTotal) * employeePayout.percentForEmployee) / 100 -
              employeePayout.fixedTakenByCompany;
          } else {
            // formula =  `(G+A) *${employeePayout.percentForEmployee}%`;
            total = ((this.state.grossIncome + this.state.adjustmentTotal) * employeePayout.percentForEmployee) / 100;
          }
        } else if (employeePayout.percentTakenByCompany > 0) {
          if (employeePayout.fixedTakenByCompany > 0) {
            // formula = `(G+A) - (G+A)*${employeePayout.percentTakenByCompany}% - ${employeePayout.fixedTakenByCompany}$`;
            total = (this.state.grossIncome + this.state.adjustmentTotal) -
              ((this.state.grossIncome + this.state.adjustmentTotal) * employeePayout.percentTakenByCompany) / 100 -
              employeePayout.fixedTakenByCompany;
          } else if (employeePayout.fixedForEmployee > 0) {
            // formula = `(G+A) - (G+A)*${employeePayout.percentTakenByCompany}% + ${employeePayout.fixedForEmployee}$`;
            total = (this.state.grossIncome + this.state.adjustmentTotal) -
              ((this.state.grossIncome + this.state.adjustmentTotal) * employeePayout.percentTakenByCompany) / 100 +
              employeePayout.fixedForEmployee;
          } else {
            // formula =  `(G+A) - (G+A)*${employeePayout.percentTakenByCompany}%`;
            total = (this.state.grossIncome + this.state.adjustmentTotal) -
              ((this.state.grossIncome + this.state.adjustmentTotal) * employeePayout.percentTakenByCompany) / 100;
          }
        } else if (employeePayout.fixedForEmployee > 0) {
          // formula = `${employeePayout.fixedForEmployee}$`;
          total = employeePayout.fixedForEmployee;
        } else if (employeePayout.fixedTakenByCompany > 0) {
          // formula = `-${employeePayout.fixedTakenByCompany}$`;
          total = this.state.grossIncome - employeePayout.fixedTakenByCompany;
        }
      } else {
        total = this.state.grossIncome;
      }
      const payoutObj = {
        customerName: this.state.customerName,
        grossIncome: this.state.grossIncome,
        adjustment: this.state.adjustment,
        adjustmentTotal: this.state.adjustmentTotal,
        total: total.toFixed(2),
        transactions: []
      }
      employeePayout.payouts.push(payoutObj);
      return employeePayout;
    });
    const commissionObj = this.state.commission;
    commissionObj.employeePayouts = employeePayouts;
    const commissionData =
    {
      ...commissionObj,
      userId: this.props.auth.user.id
    };
    axios
      .put(`/api/commissions/commissions/${commissionData._id}`, { commission: commissionData })
      .then((res) => {
        this.setState({
          toastOpen: true,
          notificationText: "Successfully posted!",
          notifyType: "success"
        });
        const demoStatus = localStorage.getItem("demoStatus");
        if (demoStatus === 'yes') {
          this.props.history.push('/learn');
        } else {
          this.props.history.push("/pay");
        }
      }).catch((err) => {
        // console.log(err);
        this.setState({
          toastOpen: true,
          notificationText: "Oops! Error happened while posting commission.",
          notifyType: "info"
        });
        GAexception(`Posting to Ledger failed: ${err}`);
      });
  }

  customFilter = (option, searchText) => {
    const title = option.data.title.toLowerCase();
    if (
      title.slice(0, searchText.length) === searchText.toLowerCase()
    ) {
      return true;
    } else {
      return false;
    }
  }

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  handleClose = () => {
    this.setState({
      toastOpen: false
    });
  };

  goToSubscribe = () => {
    this.props.history.push("/account");
  }

  noSubscribe = () => {
    let subSkippedCount = localStorage.getItem("SubSkippedCount");
    if (subSkippedCount === null) subSkippedCount = 1;
    else subSkippedCount = parseInt(subSkippedCount) + 1;
    localStorage.setItem("SubSkippedCount", subSkippedCount);
    this.setState({
      subscribeQuestion: false
    });
  }

  handleEmployeeName = (name) => {
    this.setState({
      employeeName: name
    })
  }

  handleExpense = () => {
    if (!this.validateExpenseForm())
      return;
    const expenseData = {
      employeeId: this.state.employeeName, 
      employeeName: this.state.employees.filter((employee) => employee.id === this.state.employeeName)[0].name,
      parentId: this.props.auth.user.id,
      total: this.state.expense, 
      docs: [],
      customerName: this.state.customerNameForExpense ? this.state.customerNameForExpense : "Expense",
      transactions: []
    }
    axios
      .post('/api/expenses', {expense: expenseData})
      .then((res) => {
        this.setState({
          toastOpen: true,
          notificationText: "You have successfully uploaded total & expense documents",
          notifyType: "success"
        });
      }).catch((err) => {
        this.setState({
          toastOpen: true,
          notificationText: err.message || "You have successfully uploaded total & expense documents",
          notifyType: "error"
        });
      })
  }

  render() {
    const { errors, commission, commissions, toastOpen, notificationText, notifyType, subscriptionText } = this.state;

    return (
      <>
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={notifyType} sx={{ width: '100%', fontSize: 24 }}>
            {notificationText}
          </Alert>
        </Snackbar>
        <div className="receive-funds-page">
          <div className="page-content">
            <div className="card-left">
              <div className="card-icon">
                <img src={fund_big_icon} alt="fund content icon" />
              </div>
              <div className="card-title">
                Enter Gross Sale or Enter Employee Expense and Match it with an Employee Commission, then Post the Transaction and Forget It Until Pay Day
              </div>
            </div>
            <div className="card-content">
              <div className="card-title">
                Enter Gross Sale over the Left and Enter Employee Expense over the right.
              </div>
              <div className="card-form">
                <div className="card-individual">
                <div className="form-label"><strong>Enter Gross Sale</strong></div>
                  <div className="form-input">
                    {(this.props.auth.user.role === constant['ROLE']['GUEST']) && this.state.walkthrough ?
                      <Floater
                        autoOpen={true}
                        showCloseButton={true}
                        styles={{
                          arrow: {
                            color: '#4A4C5D'
                          },
                          close: {
                            color: 'white'
                          },
                          container: {
                            backgroundColor: '#4A4C5D',
                          }
                        }}
                        content={
                          <div>
                            <h3 className="mt-2" style={{ color: 'white', marginBottom: '20px' }}>Receive Funds!</h3>
                            <h5 style={{ color: 'white' }}>This is where you receive a sale that your new employee created.</h5>
                            <h5 style={{ color: 'white' }}>Pick your Employee's Commission, Enter the Customer, then add the Amount, then Post to Ledger.</h5>
                            <div style={{ textAlign: 'end' }}>
                              <Button style={{ fontWeight: '500', backgroundColor: '#0094CF', fontSize: '1em' }} onClick={() => { this.setState({ walkthrough: false }) }}>Got it</Button>
                            </div>
                          </div>
                        }
                        placement="left"
                      >
                        <div className="form-label">Pick A Commission</div>
                      </Floater>
                      :
                      <>
                        <div className="form-label">Pick A Commission</div>
                      </>
                    }

                    <select onChange={this.handleSelectCommission} value={commission ? commission._id : ""}>
                      <option value={""}></option>
                      {
                        commissions.map(com => {
                          return <option key={com._id} value={com._id}>{com.title}</option>;
                        })
                      }
                    </select>
                    <ArrowDropDownIcon fontSize="medium" />
                  </div>
                  <div className="form-input">
                    <div className="form-label">Customer Name</div>
                    <input
                      id="customerName"
                      onChange={this.onChange}
                      value={this.state.customerName}
                      // placeholder="Customer Name here..."
                      type="text"
                    />
                    <span className="red-text">
                      {errors.customerName}
                    </span>
                  </div>
                  <div className="form-input">
                    <div className="form-label">Gross Amount</div>
                    <input
                      id="grossIncome"
                      onChange={this.onChange}
                      // placeholder="Gross Income Here..."
                      pattern="[0-9]*"
                      type="number"
                    />
                    <span className="red-text">
                      {errors.grossIncome}
                    </span>
                  </div>
                  <div className="form-input">
                    <div className="form-label">Enter + or - Adjustment to Gross</div>
                    <input
                      id="adjustmentTotal"
                      onChange={this.onChange}
                      placeholder="Optional Amount"
                      type="number"
                    />
                    <div style={{ height: '48px' }}></div>
                    <input
                      id="adjustment"
                      value={this.state.adjustment}
                      onChange={this.onChange}
                      placeholder="Optional Reason Here..."
                      type="text"
                    />
                  </div>
                  <div style={{ height: '48px' }}></div>
                  <div className="btn-blue" onClick={this.updateCommission}>
                    Post to Ledger
                  </div>
                </div>
                <div className="card-individual">
                  <div>
                    <div className="form-label"><strong>Enter Employee Expense</strong></div>
                    <div className="form-input">
                      <div className="form-label">Employee</div>
                      <select onChange={(ev) => this.handleEmployeeName(ev.target.value)}>
                        <option value={""}>Choose Employee</option>
                        {
                          this.state.employees.map(employee => {
                            return <option key={employee.id} value={employee.id}>{employee.name}</option>
                          })
                        }
                      </select>
                      <ArrowDropDownIcon fontSize="medium" />
                    </div>
                    <span className="red-text">
                      {errors.customerName}
                    </span>
                    <div className="form-input">
                      <div className="form-label">Customer Name (optional)</div>
                      <input
                        id="customerNameForExpense"
                        onChange={this.onChange}
                        value={this.state.customerNameForExpense}
                        // placeholder="Customer Name here..."
                        type="text"
                      />
                    </div>
                    <div className="form-input">
                      <div className="form-label">Expense Amount</div>
                      <input
                        id="expense"
                        onChange={this.onChange}
                        // placeholder="Gross Income Here..."
                        pattern="[0-9]*"
                        type="number"
                      />
                      <span className="red-text">
                        {errors.grossIncome}
                      </span>
                    </div>
                  </div>
                  <div style={{ height: '48px' }}></div>
                  <div className="btn-blue" onClick={this.handleExpense}>
                    Post to Ledger
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.subscribeQuestion}
        >
          <Box sx={style}>
            <h2 className="mt-4 mb-2 mx-2 font-bold" style={{ color: '#22395B' }}>Welcome to CommissionPayPro!</h2>
            <h4 className="mt-2 mb-4 mx-2" style={{ color: '#7B8FB2' }}>
              {
                subscriptionText
              }
            </h4>
            <div className="display-flex justify-center align-center mb-4">
              <div className="btn-blue mr-2" style={{ padding: '8px 18px' }} onClick={this.goToSubscribe}>
                Subscribe
              </div>
            </div>
          </Box>
        </Modal>
      </>
    );
  }
}

ReceiveFunds.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps
)(ReceiveFunds);