import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import name_icon from "../../../assets/images/name-icon.png";
import email_icon from "../../../assets/images/email-icon.png";
import phone_icon from "../../../assets/images/phone-icon.png";
import address_icon from "../../../assets/images/address-icon.png";
import location_icon from "../../../assets/images/location-icon.png"
import constant from "../../../shared/constant";
import axios from "axios";
import { logoutUser } from "../../../actions/authActions";
import {
  Row,
  Card,
  CardBody,
  Col,
  Input,
  FormGroup,
  Button,
  Modal,
  ModalFooter,
  NavItem,
  NavLink,
  Nav
} from "reactstrap";

class AccountInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.auth.user.name,
      email: this.props.auth.user.email,
      phone: this.props.auth.user.phone,
      companyName: this.props.auth.user.companyName,
      companyAddress: this.props.auth.user.companyAddress,
      nameFocus: false,
      emailFocus: false,
      phoneFocus: false,
      companyNameFocus: false,
      companyAddressFocus: false,
      deleteAccount: false
    };
  }

  componentDidMount() {

  }
  
  componentWillUnmount() {

  }

  onChange = e => {
    if (e.target.id === 'name' || e.target.id === 'companyAddress' || e.target.id === 'companyName') {
      if (e.target.value.length === 1) {
        let str = e.target.value.toUpperCase(); // First Letter Uppercase
        this.setState({ [e.target.id]: str});
        return;
      }
      let str = e.target.value;
      let char = str[str.length -2];
      if (char === ' '){ // After space, add captialize
        let upperChar = str[str.length -1].toUpperCase();
        str = str.substring(0, str.length - 1);
        str += upperChar;
        this.setState({ [e.target.id]: str});
        return;
      }
    }
    this.setState({ [e.target.id]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const userData = {
      ...this.props.auth.user,
      name: this.state.name,
      phone: this.state.phone,
      companyAddress: this.state.companyAddress,
      companyName: this.state.companyName
    };
    this.props.handleUpdateAccountInfo(userData);
  }

  onDelete = (user) => {
    if (user.stripeCustomerId) {
      axios.post('api/stripe/delete-customer', {customerId: user.stripeCustomerId})
      .then(res => {
        if(res.status === 200) {
          axios.post(`api/users/remove/${user.id}`, {userId: user.id})
          .then((res) => {
            this.props.logoutUser();
          })
          .catch((err) => {
            console.log(err)
          })
        } 
      })
    } else {
      axios.post(`api/users/remove/${user.id}`, {userId: user.id})
        .then((res) => {
          this.props.logoutUser();
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  render() {
    return (
      <>
        <div className="tab-content-edit">
          <div className="edit-title">
            Manage Account Information
          </div>
          <div className="form-container">
            <div className="form-input">
              <input
                id="name"
                placeholder="Name"
                type="text"
                onChange={this.onChange}
                value={this.state.name}
                autoComplete="off"
                onFocus={() => this.setState({nameFocus: true})}
                onBlur={() => this.setState({nameFocus: false})}
              />
              <span>
                <img src={name_icon} alt="name icon" />
              </span>
            </div>
            <span className="red-text">
            </span>
          </div>
          <div className="form-container">
            <div className="form-input">
              <input
                // onChange={this.onChange}
                value={this.state.email}
                placeholder="Email"
                id="email"
                type="email"
                autoComplete="off"
                onFocus={() => this.setState({emailFocus: true})}
                onBlur={() => this.setState({emailFocus: false})}
              />
              <span>
                <img src={email_icon} alt="email icon" />
              </span>
            </div>
            <span className="red-text">
              {/* {errors.customerName} */}
            </span>
          </div>
          <div className="form-container">
            <div className="form-input">
              <input
                id="phone"
                type="text"
                onChange={this.onChange}
                value={this.state.phone}
                placeholder="Phone"
                autoComplete="off"
                onFocus={() => this.setState({phoneFocus: true})}
                onBlur={() => this.setState({phoneFocus: false})}
              />
              <span>
                <img src={phone_icon} alt="phone icon" />
              </span>
            </div>
            <span className="red-text">
              {/* {errors.customerName} */}
            </span>
          </div>
          <div className="form-container">
            <div className="form-input">
              <input
                onChange={this.onChange}
                value={this.state.companyName}
                placeholder="Company Name"
                id="companyName"
                type="text"
                autoComplete="off"
                onFocus={() => this.setState({companyNameFocus: true})}
                onBlur={() => this.setState({companyNameFocus: false})}
              />
              <span>
                <img src={address_icon} alt="address icon" />
              </span>
            </div>
            <span className="red-text">
              {/* {errors.customerName} */}
            </span>
          </div>
          <div className="form-container">
            <div className="form-input">
              <input
                onChange={this.onChange}
                value={this.state.companyAddress}
                placeholder="Company Address"
                id="companyAddress"
                type="text"
                autoComplete="off"
                onFocus={() => this.setState({companyAddressFocus: true})}
                onBlur={() => this.setState({companyAddressFocus: false})}
              />
              <span>
                <img src={location_icon} alt="location icon" />
              </span>
            </div>
            <span className="red-text">
              {/* {errors.customerName} */}
            </span>
          </div>
          <div style={{height:"24px"}}></div>
          <div className="display-flex justify-between">
            {/* {this.props.auth.user.role !== constant['ROLE']['SUPER_ADMIN'] && <div className="btn-red" onClick={() => this.onDelete(this.props.auth.user)}>Delete</div>} */}
            {this.props.auth.user.role !== constant['ROLE']['SUPER_ADMIN'] && <div className="btn-red" onClick={() => this.setState({deleteAccount: true })}>Delete Account Forever</div>}
            <div className="btn-blue" onClick={this.onSubmit} >Update</div>
          </div>
        </div>
        <Modal
          isOpen={this.state.deleteAccount}
          toggle={() => this.setState({ deleteAccount: false })}
        >
          <div className="modal-body" style={{color: "black"}}>
            <p style={{fontWeight: "400", margin: "0px"}}>This will delete your account forever including all employee data and charges!</p>
          </div>
          <ModalFooter>
            <Button className="btn-cpp" type="button" onClick={() => this.setState({ deleteAccount: false })} >Cancel</Button>
            <Button className="btn-red" type="button" onClick={() => this.onDelete(this.props.auth.user)}>Confirm</Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

AccountInfo.propTypes = {
  auth: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(AccountInfo)