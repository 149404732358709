import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
  TOGGLE_SIDEBAR,
  SWITCH_MODE
} from "./types";
import { GAexception } from "../shared/gaUtils";

// Register User
export const registerUser = (userData) => dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/users/register", userData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        console.log(err);
        GAexception(`Register User Failed: ${err}`);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          });
        } else if (err.request) {
          dispatch({
            type: GET_ERRORS,
            payload: err.request
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: err.message
          });
        }
        reject();
      }
      );
  });
};


// Register Guest
export const registerGuest = (userData) => dispatch => {
  axios
    .post("/api/users/register", userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err => {
      GAexception(`Register User Failed: ${err}`);
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: { message: "Oops!!! Something went wrong" }
        })
      }
    }
    );
};

// Register User
export const registerEmployee = (userData, history) => dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .post("/api/users/registerEmployee", userData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        console.log(err);
        GAexception(`Register User Failed: ${err}`);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          });
        } else if (err.request) {
          dispatch({
            type: GET_ERRORS,
            payload: err.request
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: err.message
          });
        }
        reject();
      }
      );
  });
};

// Login - get user token
export const loginUser = userData => dispatch => {
  axios
    .post("/api/users/login", userData)
    .then(res => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err => {
      GAexception(`Login Failed: ${err}`);
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: { message: "Oops!!! Something went wrong" }
        })
      }
    }
    );
};

// Login - get user token
export const updateUser = userData => async dispatch => {
  await axios
    .post('/api/users/update', userData)
    .then((res) => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    }).catch((err) => {
      GAexception(`User Detail Update Failed: ${err}`);
      if (err.response) {
        dispatch({
          type: GET_ERRORS,
          payload: err.response.data
        })
      } else {
        dispatch({
          type: GET_ERRORS,
          payload: { message: "Oops!!! Something went wrong" }
        })
      }
    });
};

export const updateGuestUser = (userId, userData) => dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/users/updateGuestUser`, { userId, userData })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        console.log(err);
        GAexception(`Register User Failed: ${err}`);
        if (err.response) {
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data
          });
        } else if (err.request) {
          dispatch({
            type: GET_ERRORS,
            payload: err.request
          });
        } else {
          dispatch({
            type: GET_ERRORS,
            payload: err.message
          });
        }
        reject();
      }
      );
  });
};

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING
  };
};

// toggle sidebar
export const toggleSidebar = (flag) => {
  return {
    type: TOGGLE_SIDEBAR,
    payload: flag
  }
}

// switch Mode for Stripe
export const switchMode = (flag) => {
  return {
    type: SWITCH_MODE,
    payload: flag
  }
}

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  localStorage.removeItem('modeType');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};