import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from "moment";
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloneIcon from '@mui/icons-material/ContentCopy';

class Table extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAction: 0
		}

		this.actionRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.checkIfClickedOutside)
	}

	checkIfClickedOutside = e => {
		if (this.state.isAction !== 0 && this.actionRef.current && !this.actionRef.current.contains(e.target)) {
			this.setState({
				isAction: 0,
			});
		}
	}

	onAction = (id) => {
		this.setState({
			isAction: id
		});
	}

	onEdit = (data) => {
		this.props.onEdit(data);
	}

	onEmail = (data) => {
		this.props.onEmail(data);
	}

	onDelete = (data) => {
		this.props.onDelete(data);
	}

	onClone = (data) => {
		this.props.onClone(data);
	}

	_renderTableRecord = (commission, ind) => {
		const { isAction } = this.state;
		const { title, date } = commission;

		return <div className="table-record" key={commission._id}>
			<div className="body-item item-8">{ind + 1}</div>
			<div className="body-item item-9">{title}</div>
			<div className="body-item item-10">{moment(date).format('MM/DD/YYYY')}</div>
			<div className="body-item item-11">
				<span className="action" onClick={() => this.onAction(ind + 1)}>
					<SettingsEthernetIcon />
					{
						isAction === (ind + 1) && <div className="actions" ref={this.actionRef}>
							<div className="action-btn" onClick={() => this.onEdit(commission)}>
								<EditIcon fontSize="small" />
								Edit
							</div>
							<div className="action-btn" onClick={() => this.onDelete(commission)}>
								<DeleteIcon fontSize="small" />
								Delete
							</div>
							<div className="action-btn" onClick={() => this.onClone(commission)}>
								<CloneIcon fontSize="small" />
								Clone
							</div>
						</div>
					}
				</span>
			</div>
		</div>
	}

	_renderEmptyTableRecord = () => {
		return <div className="table-record">
			<div className="body-item">No Commissions Found</div>
		</div>
	}

	render() {
		const { commissions } = this.props;

		return <div className="table-container">
			<div className="table-header">
				<div className="header-item item-8">No</div>
				<div className="header-item item-9">Title</div>
				<div className="header-item item-10">Date</div>
				<div className="header-item item-11">Actions</div>
			</div>
			<div className="table-body">
				{
					commissions.length !== 0 && commissions.map((commission, index) => {
						return this._renderTableRecord(commission, index)
					})
				}
				{
					commissions.length === 0 && this._renderEmptyTableRecord()
				}
			</div>
		</div>;
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(
	mapStateToProps,
	{}
)(Table);

// export default Table;