import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

// core components
import { registerUser, updateGuestUser } from "../../actions/authActions";
import { registerEmployee } from "../../actions/authActions";
import { GApageView } from "../../shared/gaUtils";
import constant from "../../shared/constant";
import lineFloat from "../../assets/images/login/line.png";
import curveFloat from "../../assets/images/login/curve.png";
import moonFloat from "../../assets/images/login/moon.png";
import banner from "../../assets/images/login/banner.png";
import logo from "../../assets/images/logo.png";
import InputForm from "../component/InputForm";
import Slide from "@mui/material/Slide";

class Register extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      nameError: false,
      email: "",
      emailError: false,
      phone: "",
      phoneError: false,
      password: "",
      passwordError: false,
      companyName: "",
      companyNameError: false,
      companyAddress: "",
      errors: {},
      nameFocus: false,
      emailFocus: false,
      phoneFocus: false,
      passwordFocus: false,
      companyNameFocus: false,
      companyAddressFocus: false,
      parentId: "",
      userId: "",
      toastOpen: false,
      notificationText: "",
      notifyType: 'error'
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        toastOpen: true,
        notifyType: "error"
      });
    }
  }

  componentDidMount() {
    const queryString = require("query-string");
    const parsed = queryString.parse(this.props.location.search);
    if (parsed.guest) this.setState({ userId: parsed.guest });
    if (parsed.parentId) this.setState({ parentId: parsed.parentId });
    if (parsed.companyName) this.setState({ companyName: parsed.companyName });
    if (parsed.email) this.setState({ email: parsed.email });
    const name = localStorage.getItem("Name");
    const email = localStorage.getItem("Email");
    if (name) this.setState({ name: name });
    if (email) this.setState({ email: email });
    // If logged in and user navigates to Register page, should redirect them to home
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
      return;
    }
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    GApageView("Register Page");
  }
  componentWillUnmount() {
    document.body.classList.remove("login-page");
    document.body.classList.remove("sidebar-collapse");
  }

  onChange = (e) => {
    if (
      e.id === "name" ||
      e.id === "companyName" ||
      e.id === "companyAddress"
    ) {
      if (e.value.length === 1) {
        let str = e.value.toUpperCase(); // First Letter Uppercase
        this.setState({ [e.id]: str });
        return;
      }
      let str = e.value;
      let char = str[str.length - 2];
      if (char === " ") {
        // After space, add captialize
        let upperChar = str[str.length - 1].toUpperCase();
        str = str.substring(0, str.length - 1);
        str += upperChar;
        this.setState({ [e.id]: str });
        return;
      }
    }
    this.setState({ [e.id]: e.value });
  };

  onSubmit = (e) => {
    e.preventDefault();
    const newUser = {
      name: this.state.name,
      email: this.state.email,
      phone: this.state.phone,
      password: this.state.password,
      companyName: this.state.companyName,
      companyAddress: this.state.companyAddress,
      role: constant["ROLE"]["OWNER"],
      parentId: this.state.parentId,
    };
    console.log("newUser-------", newUser.parentId)
    if (newUser.parentId && newUser.parentId !== "") {
      this.props.registerEmployee(newUser).then((data) => {
        this.setState({
          notificationText: data.message,
          toastOpen: true,
          notifyType: "success"
        });
      }).catch(() => { });
    } else if (this.state.userId && this.state.userId !== "") {
      if (!this.validateForm(newUser)) return;
      this.props.updateGuestUser(this.state.userId, newUser)
        .then((data) => {
          this.setState({
            notificationText: data.message,
            toastOpen: true,
            notifyType: "error"
          });
        }).catch(() => { });
    } else {
      if (!this.validateForm(newUser)) return;
      this.props.registerUser(newUser)
        .then((data) => {
          this.setState({
            notificationText: data.message,
            toastOpen: true,
            notifyType: "error"
          });
        }).catch(() => { });
    }
  };

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  validateForm = (userData) => {
    if (userData.name === "") {
      this.setState({
        notificationText:
          "We need your name for better experience. Please enter your name",
        toastOpen: true,
        nameError: true,
        notifyType: "error"
      });
      return false;
    } else {
      this.setState({
        nameError: false,
      });
    }
    if (userData.email === "") {
      this.setState({
        notificationText:
          "Please enter your email. We don't send any spam emails.",
        toastOpen: true,
        nameError: true,
        notifyType: "error"
      });
      return false;
    } else {
      this.setState({
        emailError: false,
      });
    }

    if (userData.password === "" || userData.password.length < 6) {
      this.setState({
        notificationText:
          "Please enter your password. Password should be more than 6 characters.",
        toastOpen: true,
        passwordError: true,
        notifyType: "error"
      });
      return false;
    } else {
      this.setState({
        passwordError: false,
      });
    }

    this.setState({
      notificationText: '',
      toastOpen: false,
    });
    return true;
  };

  render() {
    const { errors, toastOpen, notificationText } = this.state;
    return (
      <div className="auth-page">
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert severity={this.state.notifyType} sx={{ width: "100%", fontSize: 24 }}>
            {notificationText !== '' ? notificationText : errors.email || errors.password || errors.emailnotfound || errors.passwordincorrect}
          </Alert>
        </Snackbar>
        <div className="content">
          <Grid item container spacing={0} className="content-container items-end">
            <Grid item xl={8} lg={7} md={6} sm={12} xs={12} className="left">
              <div className="title-container display-flex justify-start align-center">
                <div className="page-title-line"></div>
                <div className="page-title white-text">Easy Commission Calculations</div>
              </div>
              <div className="sub-title white-text">
                Frazzled Keeping Up with Sales Commission Tracking?
              </div>
              <div className="w-100 display-flex justify-end mobile-login">
                <div className="auth-form mobile-form">
                  <div className="top display-flex justify-start align-center">
                    <div>
                      <img className="img-responsive" alt="logo" src={logo} />
                    </div>
                    <div>Free to Sign Upp</div>
                  </div>
                  <div className="middle">
                    {/* <div style={{height: '40px'}}></div> */}
                    <InputForm
                      label={"Your Name"}
                      id={"name"}
                      errors={this.state.nameError}
                      isAddon={false}
                      placeholderText={""}
                      onChange={(ev) => this.onChange(ev)}
                      value={this.state.name}
                    />
                    <div style={{ height: "40px" }}></div>
                    <InputForm
                      label={"Phone"}
                      id={"phone"}
                      isAddon={false}
                      placeholderText={""}
                      onChange={(ev) => this.onChange(ev)}
                      value={this.state.phone}
                    />
                    <div style={{ height: "40px" }}>(Optional if you want Sales to Call)</div>
                    <InputForm
                      label={"Your Email"}
                      id={"email"}
                      errors={errors.emailnotfound ? true : false}
                      isAddon={false}
                      placeholderText={""}
                      onChange={(ev) => this.onChange(ev)}
                      value={this.state.email}
                    />
                    <div style={{ height: "40px" }}></div>
                    <InputForm
                      label={"Create Password"}
                      id={"password"}
                      errors={this.state.passwordError}
                      isAddon={true}
                      placeholderText={""}
                      onChange={(ev) => this.onChange(ev)}
                      value={this.state.password}
                    />
                  </div>
                  <div style={{ height: "30px" }}></div>
                  <div className="tos" style={{ width: '100%' }}>
                    <a
                      rel="noopener noreferrer"
                      href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit?usp=sharing"
                      target="_blank"
                    >
                      You agree to our TOS and Privacy Policy
                    </a>
                  </div>
                  <div style={{ height: "30px" }}></div>
                  <div className="bottom display-flex justify-between align-center">
                    <div>
                      <span style={{ fontSize: 15, marginRight: 15 }}>Already have an account?</span>
                      <Link to="/login">Sign In</Link>
                    </div>
                    <div
                      className={"bubbly-button"}
                      style={{ width: "181px" }}
                      onClick={this.onSubmit}
                    >
                      <span>Enter Now</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-image">
                <img
                  className="img-responsive"
                  alt={"page-img"}
                  src={banner}
                />
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={6} sm={12} xs={12} className="right">
              <div className="w-100 display-flex justify-end">
                <div className="auth-form register-form">
                  <div className="top display-flex justify-start align-center">
                    <div>
                      <img className="img-responsive" alt="logo" src={logo} />
                    </div>
                    <div>Free to Sign Up</div>
                  </div>
                  <div className="middle">
                    <InputForm
                      label={"Your Name"}
                      id={"name"}
                      errors={this.state.nameError}
                      isAddon={false}
                      placeholderText={""}
                      onChange={(ev) => this.onChange(ev)}
                      value={this.state.name}
                    />
                    <div style={{ height: "40px" }}></div>
                    <InputForm
                      label={"Phone"}
                      id={"phone"}
                      isAddon={false}
                      placeholderText={""}
                      onChange={(ev) => this.onChange(ev)}
                      value={this.state.phone}
                    />
                    <div style={{ height: "40px" }}>(Optional if you want Sales to Call)</div>
                    <InputForm
                      label={"Your Email"}
                      id={"email"}
                      errors={this.state.emailError}
                      isAddon={false}
                      placeholderText={""}
                      onChange={(ev) => this.onChange(ev)}
                      value={this.state.email}
                    />
                    <div style={{ height: "40px" }}></div>
                    <InputForm
                      label={"Create Password"}
                      id={"password"}
                      errors={this.state.passwordError}
                      isAddon={true}
                      placeholderText={""}
                      onChange={(ev) => this.onChange(ev)}
                      value={this.state.password}
                    />
                  </div>
                  <div style={{ height: "30px" }}></div>
                  <div className="tos">
                    <a
                      rel="noopener noreferrer"
                      href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit?usp=sharing"
                      target="_blank"
                    >
                      You agree to our TOS and Privacy Policy
                    </a>
                  </div>
                  <div style={{ height: "30px" }}></div>
                  <div className="bottom display-flex justify-between align-center">
                    <div>
                      <span style={{ fontSize: 15 }}>Already have an account?</span>
                      <Link to="/login">Sign In</Link>
                    </div>
                    <div className={"bubbly-button"} onClick={this.onSubmit}>
                      <span>Enter Now</span>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
          <div className='footer-row'>
            <div className=''>
              <a href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit" target="_blank" rel="noopener noreferrer" className='white-text'>TERMS OF SERVICE</a>
              <Link to="/contact-us" className='white-text'>CONTACT US</Link>
            </div>

            <div className="licence">
              <span className='white-text'>© 2023, Published by <span className="company-name"> My Market Partners</span></span>
            </div>
          </div>
          <div className="animation-bg">
            <div className="particle-container">
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
              <div className="particle"></div>
            </div>
            <div className='grey'></div>
            <div className="area" >
              <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
            </div>
            <div className='ripple-background'>
              <div className='circle xxlarge shade1'></div>
              <div className='circle xlarge shade2'></div>
              <div className='circle large shade3'></div>
              <div className='circle mediun shade4'></div>
              <div className='circle small shade5'></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateGuestUser: PropTypes.func.isRequired,
  registerEmployee: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  registerUser,
  registerEmployee,
  updateGuestUser,
})(withRouter(Register));
