import React, { Component } from "react";

class SelectForm extends Component {
    onChange = (ev) => {
        this.props.onChange({value: ev.target.value, label: ev.target.value});
    }

    render() {
        const { options, defaultValue } = this.props;

        return <div className="select-form">
            <select onChange={this.onChange} value={defaultValue}>
                {
                    options.length > 0 && options.map(data => {
                        return <option value={data.value} key={data.label}>{data.label}</option>
                    })
                }
            </select>
        </div>;
    }
}

export default SelectForm;