import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import Floater from 'react-floater';
import Drawer from '@mui/material/Drawer';
import { Box } from '@mui/system';
import Table from './Table';
import CardView from './CardView';
import AddIcon from '@mui/icons-material/Add';
import WindowIcon from '@mui/icons-material/Window';
import TocIcon from '@mui/icons-material/Toc';
import Button from "reactstrap/lib/Button";
import Modal from '@mui/material/Modal';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import constant, { SUBSCRIBE_TIME_IN_SECONDS, SUBSCRIBE_SKIP_COUNT } from "../../shared/constant";
import _ from 'lodash';
import { GAexception, GApageView, GAevent } from "../../shared/gaUtils";
import { BrowserView } from 'react-device-detect';

// const roles = [
//   { value: 'Employee', label: 'Employee' },
//   { value: 'Contractor', label: 'Contractor' },
//   { value: 'Manager', label: 'Manager' }
// ]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '600px',
  width: '100%',
  bgcolor: '#fff',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 3,
  textAlign: 'center'
};

class Commissions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subscribeQuestion: false,
      subscriptionText: '',
      commissions: [],
      employeeCount: 0,
      view: 'table',
      onNewCommission: false,
      employees: [],
      formulaModal: false,
      type: 'create',
      commission: {
        title: '',
        date: moment().format('MM/DD/YYYY'),
        employeePayouts: [{
          employee: { id: "", name: "Choose Employee" },
          percentForEmployee: 0,
          percentTakenByCompany: 0,
          fixedForEmployee: 0,
          fixedTakenByCompany: 0,
          formula: '',
          payouts: [],
          expense: 0
        }],
        userId: '',
      },
      errors: {},
      tabs: "1",
      selectedPayoutDetail: {},
      selectedPayoutDetailIndex: 0,
      isPercentForEmployeeActive: true,
      isPercentTakenByCompanyActive: false,
      isFixedForEmployeeActive: false,
      isFixedTakenByCompanyActive: false,
      confirmModal: false,
      walkthrough: false,
      toastOpen: false,
      notificationText: "",
      notifyType: "error"
    }
  }

  intervalID = 0;

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentDidMount() {
    document.body.classList.add("commission-pay-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role === constant['ROLE']['EMPLOYEE'] || this.props.auth.user.role === constant['ROLE']['CONTRACTOR'] || this.props.auth.user.role === constant['ROLE']['MANAGER']) {
      this.props.history.push('/');
    } else {
      this.getCommissions();
      this.getEmployeeCount();
      this.getEmployees();
      if (this.props.match.params.id) {
        this.setState({ type: 'edit' });
        this.getCommissionDetail(this.props.match.params.id);
      }
      GApageView("Commission List");
      if (this.props.auth.user.role === constant['ROLE']['GUEST']) {
        GAevent('Guest', 'Commissions Page', `Guest user: ${this.props.auth.user.name} visited Commissions page`);
      } else {
        GAevent('User', 'Commissions Page', `User: ${this.props.auth.user.name}  visited Commissions page`);
      }
    }
    if (this.props.auth.user.role === constant['ROLE']['GUEST']) {
      const walkThrough = localStorage.getItem("WalkThrough");
      if (walkThrough === null || walkThrough !== "Completed") {
        this.setState({ walkthrough: true });
      }
    }
  }

  getEmployees() {
    axios
      .post('/api/users/employees', { userId: this.props.auth.user.id })
      .then((res) => {
        const employees = res.data.map((employee) => {
          return { id: employee._id, name: employee.name }
        })
        this.setState({ employees: employees });
      }).catch((err) => {
        console.log(err);
      });
  }

  getCommissionDetail(commissionId) {
    axios
      .get(`/api/commissions/commissions/${commissionId}`)
      .then((res) => {
        this.setState({ commission: res.data });
      }).catch((err) => {
        console.log(err);
      });
  }

  onChange = e => {
    let commissionObj = this.state.commission;
    if (e.target.id === 'title') {
      if (e.target.value.length === 1) {
        let str = e.target.value.toUpperCase(); // First Letter Uppercase
        commissionObj[e.target.id] = str;
        this.setState({ commission: commissionObj });
        return;
      }
      let str = e.target.value;
      let char = str[str.length - 2];
      if (char === ' ') { // After space, add captialize
        let upperChar = str[str.length - 1].toUpperCase();
        str = str.substring(0, str.length - 1);
        str += upperChar;
        commissionObj[e.target.id] = str;
        this.setState({ commission: commissionObj });
        return;
      }
      commissionObj[e.target.id] = str;
      this.setState({ commission: commissionObj });
    }
  };

  getEmployeeCount() {
    axios
      .post('/api/users/employeeCount',
        {
          userId: this.props.auth.user.id
        })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ employeeCount: res.data });
        }
      });
  }

  init() {
    this.setState({
      formulaModal: false,
      type: 'create',
      commission: {
        title: '',
        date: moment().format('MM/DD/YYYY'),
        employeePayouts: [{
          employee: { id: "", name: "Choose Employee" },
          percentForEmployee: 0,
          percentTakenByCompany: 0,
          fixedForEmployee: 0,
          fixedTakenByCompany: 0,
          formula: '',
          payouts: [],
        }],
        userId: '',
      },
      errors: {},
      tabs: "1",
      selectedPayoutDetail: {},
      selectedPayoutDetailIndex: 0,
      isPercentForEmployeeActive: true,
      isPercentTakenByCompanyActive: false,
      isFixedForEmployeeActive: false,
      isFixedTakenByCompanyActive: false,
      confirmModal: false,
      onNewCommission: false
    })
  }

  getCommissions() {
    axios
      .post('/api/commissions/commissions', { userId: this.props.auth.user.id })
      .then((res) => {
        const sortedCommissions = res.data.sort((commissionA, commissionB) => {
          return new Date(commissionB.date) - new Date(commissionA.date);
        })
        this.setState({ commissions: sortedCommissions });
        this.init();
      }).catch((err) => {
        console.log(err);
      });
  }

  editCommission = (commission) => {
    this.props.history.push(`/commissions/${commission._id}`);
  }

  removeCommission = (commission) => {
    axios
      .delete(`/api/commissions/commissions/${commission._id}`)
      .then((res) => {
        this.setState({
          toastOpen: true,
          notificationText: res.data.message,
          notifyType: "info"
        });
        this.getCommissions();
      }).catch((err) => {
        console.log(err);
      });
  }

  componentWillUnmount() {
    document.body.classList.remove("commission-pay-page");
    document.body.classList.remove("sidebar-collapse");
  }

  onClickCreateComission = () => {
    if (this.state.employeeCount === 0) {
      this.setState({
        toastOpen: true,
        notificationText: 'You don not have any employees, create one now.',
        notifyType: "info"
      });
    } else {
      this.setState({
        onNewCommission: true,
        commission: {
          title: '',
          date: moment().format('MM/DD/YYYY'),
          employeePayouts: [
            {
              employee: { id: "", name: "Choose Employee" },
              percentForEmployee: 0,
              percentTakenByCompany: 0,
              fixedForEmployee: 0,
              fixedTakenByCompany: 0,
              formula: '',
              payouts: [],
            }
          ],
          userId: '',
        },
        type: 'create'
      })
      // this.props.history.push("/commissions/create");
    }
  }

  onClickReceiveFunds = () => {
    if (this.state.employeeCount === 0) {
      this.setState({
        toastOpen: true,
        notificationText: 'You don not have any employees, create one now.',
        notifyType: "info"
      });
    } else {
      this.props.history.push("/receive-funds");
    }
  }

  onView = (view) => {
    this.setState({
      view: view
    });
  }

  toggleDrawer = (event) => {
    this.setState({ onNewCommission: false });
  };

  openFormula = () => {
    this.setState({
      formulaModal: true
    });
  }

  validateForm = (commissionData, forceSave = false) => {
    if (commissionData.title === '') {
      this.setState({
        toastOpen: true,
        notificationText: "Title can't be empty. Please fix it",
        notifyType: "error"
      });
      return false;
    }
    let emptyFormula = false;
    let fixedFormula = false;
    let emptyEmployee = false;
    commissionData.employeePayouts.forEach((employeePayout) => {
      if (employeePayout.formula === '')
        emptyFormula = true;
      if (employeePayout.percentForEmployee === 0
        && employeePayout.percentTakenByCompany === 0) {
        fixedFormula = true;
      }
      if (employeePayout.employee.id === '')
        emptyEmployee = true;
    });
    if (emptyFormula) {
      this.setState({
        toastOpen: true,
        notificationText: "There is an empty formula. Please fix it",
        notifyType: "error"
      });
      return false;
    }
    if (fixedFormula) {
      if (!forceSave) {
        this.setState({ confirmModal: true });
        return false;
      }
      else
        return true;
    }
    if (emptyEmployee) {
      this.setState({
        toastOpen: true,
        notificationText: "Please add at least an employee before saving your commssion.",
        notifyType: "error"
      });
      return false;
    }
    return true;
  }

  saveFormula = () => {
    this.setState({
      formulaModal: false,
    });
    const payoutDetail = this.state.selectedPayoutDetail;
    let formula = '';
    if (payoutDetail.percentForEmployee > 0) {
      if (payoutDetail.fixedForEmployee > 0) {
        formula = `$((G+A) *${payoutDetail.percentForEmployee}%) + $${payoutDetail.fixedForEmployee}`;
      } else if (payoutDetail.fixedTakenByCompany > 0) {
        formula = `$((G+A) *${payoutDetail.percentForEmployee}%) - $${payoutDetail.fixedTakenByCompany}`;
      } else {
        formula = `$(G+A) *${payoutDetail.percentForEmployee}%`;
      }
    } else if (payoutDetail.percentTakenByCompany > 0) {
      if (payoutDetail.fixedTakenByCompany > 0) {
        formula = `$(G+A) - $(G+A)*${payoutDetail.percentTakenByCompany}% - $${payoutDetail.fixedTakenByCompany}`;
      } else if (payoutDetail.fixedForEmployee > 0) {
        formula = `$(G+A) - $(G+A)*${payoutDetail.percentTakenByCompany}% + $${payoutDetail.fixedForEmployee}`;
      } else {
        formula = `$(G+A) - $(G+A)*${payoutDetail.percentTakenByCompany}%`;
      }
    } else if (Number(payoutDetail.fixedForEmployee) > 0) {
      formula = `$${payoutDetail.fixedForEmployee}`;
    } else if (payoutDetail.fixedForEmployee < 0)  {
      formula = `-$${Math.abs(payoutDetail.fixedForEmployee)}`;
    } else if (payoutDetail.fixedTakenByCompany > 0) {
      formula = `-$${payoutDetail.fixedTakenByCompany}`;
    }
    payoutDetail.formula = formula;
    this.setState({ selectedPayoutDetail: payoutDetail });
    const commissionObj = this.state.commission;
    commissionObj.employeePayouts[this.state.selectedPayoutDetailIndex] = payoutDetail;
    this.setState({ commission: commissionObj });
  }

  handleSelectChange(id, index) {
    if (id !== "") {
      const value = this.state.employees.filter(em => em.id === id)[0];
      const commissionObj = this.state.commission;
      let employeePayout = commissionObj.employeePayouts[index];
      employeePayout.employee = value;
      commissionObj.employeePayouts[index] = employeePayout;
      this.setState({ commission: commissionObj });
    }
  }

  updateFormula(payoutDetail, index) {
    this.setState({ formulaModal: true, selectedPayoutDetail: payoutDetail, selectedPayoutDetailIndex: index });
  }

  setPayoutDetails = (e) => {
    const val = e.target.value;
    let payoutObj = this.state.selectedPayoutDetail;
    payoutObj[e.target.id] = val;
    this.setState({ selectedPayoutDetail: payoutObj });
  }

  addEmployee = () => {
    let commissionObj = this.state.commission;
    let employeePayouts = commissionObj.employeePayouts;
    employeePayouts.push({
      employee: { id: "", name: "Choose Employee" },
      percentForEmployee: 0,
      percentTakenByCompany: 0,
      fixedForEmployee: 0,
      fixedTakenByCompany: 0,
      formula: '',
      payouts: []
    });
    commissionObj.employeePayouts = employeePayouts;
    this.setState({ commission: commissionObj });
  }

  removeEmployee = (index) => {
    let commissionObj = this.state.commission;
    let employeePayouts = commissionObj.employeePayouts;
    employeePayouts.splice(index, 1);
    commissionObj.employeePayouts = employeePayouts;
    this.setState({ commission: commissionObj });
  }

  setActiveQuestions = (e) => {
    const actionId = e.target.id;
    switch (actionId) {
      case 'tab1':
        if (this.state.isPercentTakenByCompanyActive && !this.state.isPercentForEmployeeActive) {
          this.setState(prevState => ({
            isPercentForEmployeeActive: !prevState.isPercentForEmployeeActive,
            isPercentTakenByCompanyActive: !prevState.isPercentTakenByCompanyActive
          }));
          // Reset values when changing questions
          this.setState({ selectedPayoutDetail: { ...this.state.selectedPayoutDetail, percentTakenByCompany: 0 } });
        } else {
          this.setState(prevState => ({
            isPercentForEmployeeActive: !prevState.isPercentForEmployeeActive,
          }));
          // Reset values when changing questions
          this.setState({ selectedPayoutDetail: { ...this.state.selectedPayoutDetail, percentForEmployee: 0 } });
        }
        break;
      case 'tab2':
        if (!this.state.isPercentTakenByCompanyActive && this.state.isPercentForEmployeeActive) {
          this.setState(prevState => ({
            isPercentForEmployeeActive: !prevState.isPercentForEmployeeActive,
            isPercentTakenByCompanyActive: !prevState.isPercentTakenByCompanyActive
          }));
          // Reset values when changing questions
          this.setState({ selectedPayoutDetail: { ...this.state.selectedPayoutDetail, percentForEmployee: 0 } });
        } else {
          this.setState(prevState => ({
            isPercentTakenByCompanyActive: !prevState.isPercentTakenByCompanyActive
          }));
          // Reset values when changing questions
          this.setState({ selectedPayoutDetail: { ...this.state.selectedPayoutDetail, percentTakenByCompany: 0 } });
        }
        break;
      case 'tab3':
        if (this.state.isFixedTakenByCompanyActive && !this.state.isFixedForEmployeeActive) {
          this.setState(prevState => ({
            isFixedForEmployeeActive: !prevState.isFixedForEmployeeActive,
            isFixedTakenByCompanyActive: !prevState.isFixedTakenByCompanyActive
          }));
          // Reset values when changing questions
          this.setState({ selectedPayoutDetail: { ...this.state.selectedPayoutDetail, fixedTakenByCompany: 0 } });
        } else {
          this.setState(prevState => ({
            isFixedForEmployeeActive: !prevState.isFixedForEmployeeActive,
          }));
          // Reset values when changing questions
          this.setState({ selectedPayoutDetail: { ...this.state.selectedPayoutDetail, fixedForEmployee: 0 } });
        }
        break;
      case 'tab4':
        if (this.state.isFixedForEmployeeActive && !this.state.isFixedTakenByCompanyActive) {
          this.setState(prevState => ({
            isFixedForEmployeeActive: !prevState.isFixedForEmployeeActive,
            isFixedTakenByCompanyActive: !prevState.isFixedTakenByCompanyActive
          }));
          // Reset values when changing questions
          this.setState({ selectedPayoutDetail: { ...this.state.selectedPayoutDetail, fixedForEmployee: 0 } });
        } else {
          this.setState(prevState => ({
            isFixedTakenByCompanyActive: !prevState.isFixedTakenByCompanyActive,
          }));
          // Reset values when changing questions
          this.setState({ selectedPayoutDetail: { ...this.state.selectedPayoutDetail, fixedTakenByCompany: 0 } });
        }
        break;
      default:
        break;
    }
  }

  createCommission = (forceSave = false) => {
    const commissionData =
    {
      ...this.state.commission,
      userId: this.props.auth.user.id
    };
    if (!this.validateForm(commissionData, forceSave)) {
      return;
    }

    if (this.state.type === 'edit') { // Update
      axios
        .put(`/api/commissions/commissions/${commissionData._id}`, { commission: commissionData })
        .then((res) => {
          this.setState({
            toastOpen: true,
            notificationText: "Commission has been successfully updated.",
            notifyType: "success"
          });
          window.scrollTo(0, 0);
          this.getCommissions();
        }).catch((err) => {
          console.log(err);
          GAexception(`Updating a commission failed: ${err}`);
        });
    } else { // Create
      axios
        .post('/api/commissions/create', { commission: commissionData })
        .then((res) => {
          if (this.props.auth.user.role === constant['ROLE']['GUEST']) {
            GAevent('Guest', 'Commission Page', `Guest user: ${this.props.auth.user.name}  created a new commission`);
            this.props.history.push('/receive-funds');
          } else {
            GAevent('User', 'Commission Page', `User: ${this.props.auth.user.name}  created a Commission`);
            window.scrollTo(0, 0);
            this.setState({
              toastOpen: true,
              notificationText: "Commission has been successfully created.",
              notifyType: "success"
            });
            this.getCommissions();
            const demoStatus = localStorage.getItem("demoStatus");
            if (demoStatus === 'yes') {
              this.props.history.push('/learn');
            }
          }
        }).catch((err) => {
          console.log(err);
          GAexception(`Creating a new commission failed: ${err}`);
        });
    }
  }

  postToLedger = () => {
    // Calculate Total per each payout
    const employeePayouts = this.state.commission.employeePayouts.map((employeePayout) => {
      let total = 0;
      if (employeePayout.formula && employeePayout.formula !== '') {
        if (employeePayout.fixedForEmployee > 0) {
          // formula = `${employeePayout.fixedForEmployee}$`;
          total = employeePayout.fixedForEmployee;
        } else if (employeePayout.fixedTakenByCompany > 0) {
          // formula = `-${employeePayout.fixedTakenByCompany}$`;
          total = -employeePayout.fixedTakenByCompany;
        }
      }
      const payoutObj = {
        customerName: 'N/A',
        grossIncome: 0,
        adjustment: 'N/A',
        adjustmentTotal: 0,
        total: _.floor(total, 2),
        transactions: []
      }
      employeePayout.payouts.push(payoutObj);
      return employeePayout;
    });
    const commissionObj = this.state.commission;
    commissionObj.employeePayouts = employeePayouts;
    const commissionData =
    {
      ...commissionObj,
      userId: this.props.auth.user.id
    };
    if (commissionData._id) {
      axios
        .put(`/api/commissions/commissions/${commissionData._id}`, { commission: commissionData })
        .then((res) => {
          this.setState({
            toastOpen: true,
            notificationText: "Successfully posted!",
            notifyType: "info"
          });
          this.props.history.push("/pay");
        }).catch((err) => {
          this.setState({
            toastOpen: true,
            notificationText: "Oops! Error happened while posting commission.",
            notifyType: "error"
          });
          console.log(err);
        });
    } else {
      axios
        .post('/api/commissions/create', { commission: commissionData })
        .then((res) => {
          this.setState({
            toastOpen: true,
            notificationText: "Successfully posted!",
            notifyType: "info"
          });
          this.props.history.push("/pay");
        }).catch((err) => {
          this.setState({
            toastOpen: true,
            notificationText: "Oops! Error happened while posting commission.",
            notifyType: "error"
          });
          console.log(err);
        });
    }

  }

  renderCommissionForm = () => {
    const { type, commission, errors, employees } = this.state;

    return <div className="commission-form">
      <div className="form-content">
        {(this.props.auth.user.role === constant['ROLE']['GUEST']) && this.state.walkthrough ?
          <Floater
            autoOpen={true}
            showCloseButton={true}
            styles={{
              arrow: {
                color: '#4A4C5D'
              },
              close: {
                color: 'white'
              },
              container: {
                backgroundColor: '#4A4C5D',
              }
            }}
            content={
              <div>
                <h5 className="mt-2" style={{ color: 'white' }}>Select Employee, Create Commission</h5>
                <div style={{ textAlign: 'end' }}>
                  <Button style={{ fontWeight: '500', backgroundColor: '#0094CF', fontSize: '1em' }} onClick={() => { this.setState({ walkthrough: false }) }}>Got it</Button>
                </div>
              </div>
            }
            placement="left"
          >
            {
              type === 'create' ? <div className="form-title">Add Commissions</div> : <>
                <div className="form-title">Edit Commissions</div>
                {/* <div className="com-title">{}</div> */}
              </>
            }
          </Floater>
          :
          <>
            {
              type === 'create' ? <div className="form-title">Add Commissions</div> : <>
                <div className="form-title">Edit Commissions</div>
                {/* <div className="com-title">{}</div> */}
              </>
            }
          </>
        }
        <div className="form-container">
          <div className="form-input">
            <div className="form-label">Title</div>
            <input
              id="title"
              type={'text'}
              placeholder="Enter employee name and commission split"
              onChange={this.onChange}
              value={commission.title}
              error={errors.title}
            />
            <span className="red-text">
              {errors.title}
            </span>
          </div>
        </div>
        {commission.employeePayouts.map((payoutDetail, index) => {
          return <div className="employee-form">
            <div className="cancel-form" onClick={() => this.removeEmployee(index)}>
              <AddIcon />
            </div>
            <div className="form-input">
              <div className="form-label">Employee</div>
              <select onChange={(ev) => this.handleSelectChange(ev.target.value, index)} value={payoutDetail.employee.id}>
                <option value={""}>Choose Employee</option>
                {
                  employees.map(employee => {
                    return <option key={employee.id} value={employee.id}>{employee.name}</option>
                  })
                }
              </select>
              <ArrowDropDownIcon fontSize="medium" />
            </div>
            <span className="red-text">
              {errors.employee}
            </span>
            <div className="form-input">
              <div className="form-label">Formula</div>
              <div className="value-field" onClick={() => this.updateFormula(payoutDetail, index)}>{payoutDetail.formula}</div>
              <ArrowDropDownIcon fontSize="medium" />
            </div>
            <span className="red-text">
              {errors.formula}
            </span>
          </div>
        }
        )}
        <div className="employee-addon" onClick={this.addEmployee}>
          <AddIcon fontSize="small" />
          <span>Add Employee</span>
        </div>

        <div className="form-action">
          <div className="btn-blue" onClick={(e) => { e.preventDefault(); this.createCommission(false); }}>
            <AddIcon fontSize="small" />
            <span>Save Commission</span>
          </div>
        </div>
      </div>
    </div>
  }

  closeFormulaModal = () => {
    this.setState({
      formulaModal: false
    });
  }

  onEdit = (data) => {
    this.setState({
      type: 'edit',
      commission: data,
      onNewCommission: true
    })
  }

  onClone = (data) => {
    const employeePayouts = data.employeePayouts.map((ePayout) => {
      ePayout.payouts = [];
      return ePayout;
    });
    let commissionData = data;
    commissionData.employeePayouts = employeePayouts;
    commissionData.title = "Copy of " + commissionData.title;
    delete commissionData._id;
    delete commissionData.date;
    axios
        .post('/api/commissions/create', { commission: commissionData })
        .then((res) => {
          if (this.props.auth.user.role === constant['ROLE']['GUEST']) {
            GAevent('Guest', 'Commission Page', `Guest user: ${this.props.auth.user.name}  created a new commission`);
            this.props.history.push('/receive-funds');
          } else {
            GAevent('User', 'Commission Page', `User: ${this.props.auth.user.name}  created a Commission`);
            window.scrollTo(0, 0);
            this.setState({
              toastOpen: true,
              notificationText: "Commission has been successfully created.",
              notifyType: "success"
            });
            this.getCommissions();
          }
        }).catch((err) => {
          console.log(err);
          GAexception(`Creating a new commission failed: ${err}`);
        });
  }

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  handleClose = () => {
    this.setState({
      toastOpen: false
    })
  };

  goToSubscribe = () => {
    this.props.history.push("/account");
  }

  noSubscribe = () => {
    let subSkippedCount = localStorage.getItem("SubSkippedCount");
    if (subSkippedCount === null) subSkippedCount = 1;
    else subSkippedCount = parseInt(subSkippedCount) + 1;
    localStorage.setItem("SubSkippedCount", subSkippedCount);
    this.setState({
      subscribeQuestion: false
    });
  }

  render() {
    const {
      commissions,
      view,
      onNewCommission,
      toastOpen,
      notificationText,
      notifyType,
      subscriptionText
    } = this.state;

    return (
      <>
        <div className="commission-page">
          <Snackbar
            open={toastOpen}
            autoHideDuration={5000}
            onClose={this.handleClose}
            TransitionComponent={this.transitionLeft}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert severity={notifyType} sx={{ width: '100%', fontSize: 24 }}>
              {notificationText}
            </Alert>
          </Snackbar>
          {
            this.state.employeeCount === 0 ?
              <div className="no-employee">
                You don't have any employees, <span className="link-btn" onClick={(e) => {
                  e.preventDefault();
                  this.props.history.push("/employees");
                }}>create one now</span>
              </div> : <>
                <div className="header display-flex justify-between align-center">
                  <div className="layout-toggle">
                    <BrowserView>
                      <span className={view === "table" ? "active-icon" : ""} onClick={() => this.onView('table')}>
                        <TocIcon fontSize={'large'} />
                      </span>
                      <span className={view === "card" ? "active-icon" : ""} onClick={() => this.onView('card')}>
                        <WindowIcon />
                      </span>
                    </BrowserView>
                  </div>
                  {(this.props.auth.user.role === constant['ROLE']['GUEST']) && this.state.walkthrough ?
                    <Floater
                      autoOpen={true}
                      showCloseButton={true}
                      styles={{
                        arrow: {
                          color: '#4A4C5D'
                        },
                        close: {
                          color: 'white'
                        },
                        container: {
                          backgroundColor: '#4A4C5D',
                        }
                      }}
                      content={
                        <div>
                          <h3 className="mt-2" style={{ color: 'white', marginBottom: '20px' }}>Great, you've added your employee!</h3>
                          <p style={{ color: 'white' }}>
                            Now it's time to create your first commission
                          </p>
                        </div>
                      }
                      placement="left"
                    >
                      <div className="btn-blue" onClick={this.onClickCreateComission}>
                        <AddIcon />
                        <span>New Commission</span>
                      </div>
                    </Floater>
                    :
                    <div className="btn-blue" onClick={this.onClickCreateComission}>
                      <AddIcon />
                      <span>New Commission</span>
                    </div>
                  }
                </div>
                {
                  view === 'table' && <div className="table-view">
                    <Table commissions={commissions} history={this.props.history} onEdit={(data) => this.onEdit(data)} onClone={(data) => this.onClone(data)} onDelete={(data) => this.removeCommission(data)} />
                  </div>
                }
                <div className={view === "card" ? "card-view" : "card-view-mobile"}>
                  <CardView commissions={commissions} history={this.props.history} onEdit={(data) => this.onEdit(data)} onClone={(data) => this.onClone(data)} onDelete={(data) => this.removeCommission(data)} addNew={this.onClickCreateComission} />
                </div>
                {
                  commissions.length > 0 && <div className="header display-flex justify-end align-center" style={{ marginTop: '2rem' }}>
                    <div className="btn-blue" onClick={this.onClickReceiveFunds}>
                      <ArrowRightAltIcon />
                      <span>Receive Funds</span>
                    </div>
                  </div>
                }
              </>
          }
          <Drawer
            anchor={'right'}
            open={onNewCommission}
            onClose={this.toggleDrawer}
          >
            {
              this.renderCommissionForm()
            }
          </Drawer>
          <Modal
            open={this.state.formulaModal}
            onClose={() => this.closeFormulaModal()}
          >
            <Box sx={style}>
              <div className="formula-modal">
                <div className="top">
                  <div className="modal-title">Build your Employee's Commission</div>
                  <div className="modal-sub-title">When gross proceeds are received, will this employee's commission be:</div>
                  <div id="tab1" className={this.state.isPercentForEmployeeActive ? "com-option com-option-active" : "com-option"} onClick={this.setActiveQuestions}>
                    A percentage amount for the employee?
                  </div>
                  <div id="tab2" className={this.state.isPercentTakenByCompanyActive ? "com-option com-option-active" : "com-option"} onClick={this.setActiveQuestions}>
                    A percentage amount taken out by the company?
                  </div>
                  <div id="tab3" className={this.state.isFixedForEmployeeActive ? "com-option com-option-active" : "com-option"} onClick={this.setActiveQuestions}>
                    A fixed amount for the employee?
                  </div>
                  <div id="tab4" className={this.state.isFixedTakenByCompanyActive ? "com-option com-option-active" : "com-option"} onClick={this.setActiveQuestions}>
                    A fixed amount taken out by the company?
                  </div>
                </div>
                <div className="divide-line"></div>
                <div className="bottom">
                  <div className="form-container">
                    {
                      this.state.isPercentForEmployeeActive ? <div className="form-input">
                        <div className="form-label">What is the percentage?</div>
                        <input
                          id="percentForEmployee"
                          onChange={this.setPayoutDetails}
                          placeholder="Percent Here..."
                          type="number"
                          pattern="[0-9]*"
                        />
                      </div> : <></>
                    }
                    {
                      this.state.isPercentTakenByCompanyActive ?
                        <div className="form-input">
                          <div className="form-label">What is the percentage?</div>
                          <input
                            id="percentTakenByCompany"
                            onChange={this.setPayoutDetails}
                            placeholder="Percent Here..."
                            type="number"
                            pattern="[0-9]*"
                          />
                        </div> : <></>
                    }
                    {
                      this.state.isFixedForEmployeeActive ? <div className="form-input">
                        <div className="form-label">What is the fixed amount?</div>
                        <input
                          id="fixedForEmployee"
                          onChange={this.setPayoutDetails}
                          placeholder="Amount Here..."
                          type="number"
                          pattern="[0-9]*"
                        />
                      </div> : <></>
                    }
                    {this.state.isFixedTakenByCompanyActive ? <div className="form-input">
                      <div className="form-label">What is the fixed amount?</div>
                      <input
                        id="fixedTakenByCompany"
                        onChange={this.setPayoutDetails}
                        placeholder="Amount Here..."
                        type="number"
                        pattern="[0-9]*"
                      />
                    </div> : <></>}
                  </div>
                  <div className="form-action">
                    <div className="cancel-btn" onClick={() => this.setState({ formulaModal: false })}>Cancel</div>
                    <div className="save-btn" onClick={this.saveFormula}>Save</div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={this.state.confirmModal}
            onClose={() => this.setState({ confirmModal: false })}
          >
            <Box sx={style}>
              <div className="confirm-modal">
                <div className="top">
                  <div className="modal-title">Confirmation</div>
                  <div className="modal-sub-title">We notice this is a fixed commission, would you like to post it now?</div>
                </div>
                <div className="bottom">
                  <div className="form-action">
                    <div className="cancel-btn" onClick={() => { this.postToLedger(); this.setState({ confirmModal: false }); }}>Post To Ledger</div>
                    <div className="save-btn" onClick={() => { this.createCommission(true); this.setState({ confirmModal: false }) }}>Save</div>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
          <Modal
            open={this.state.subscribeQuestion}
          >
            <Box sx={style}>
              <h2 className="mt-4 mb-2 mx-2 font-bold" style={{ color: '#22395B' }}>Welcome to CommissionPayPro!</h2>
              <h4 className="mt-2 mb-4 mx-2" style={{ color: '#7B8FB2' }}>
                {
                  subscriptionText
                }
              </h4>
              <div className="display-flex justify-center align-center mb-4">
                <div className="btn-blue mr-2" style={{ padding: '8px 18px' }} onClick={this.goToSubscribe}>
                  Subscribe
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </>
    );
  }
}

Commissions.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps
)(Commissions);