import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from "moment";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PlusIcon from '@mui/icons-material/AddCircleOutline';
import CloneIcon from '@mui/icons-material/ContentCopy';

class CardView extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAction: 0
		}

		this.actionRef = React.createRef();
	}

	componentDidMount() {
		document.addEventListener("mousedown", this.checkIfClickedOutside)
	}

	checkIfClickedOutside = e => {
		if (this.state.isAction !== 0 && this.actionRef.current && !this.actionRef.current.contains(e.target)) {
			this.setState({
				isAction: 0,
			});
		}
	}

	onAction = (id) => {
		this.setState({
			isAction: id
		});
	}

	onEdit = (data) => {
		this.props.onEdit(data);
	}

	onEmail = (data) => {
		this.props.onEmail(data);
	}

	onDelete = (data) => {
		this.props.onDelete(data);
	}

	onClone = (data) => {
		this.props.onClone(data);
	}

	addNew = () => {
		this.props.addNew();
	}

	_renderCardRecord = (commission, ind) => {
		const { title, date } = commission;

		return <div className="card-record">
			<div className={"card-image"}></div>
			<div className="card-content">
				<div className="card-column display-flex justify-start align-center">
					<span>
						{title}
					</span>
				</div>
				<div className="card-column display-flex justify-start align-center">
					<span>
						{moment(date).format('MM/DD/YYYY')}
					</span>
				</div>
				<div className="card-action display-flex justify-around align-center">
					<span onClick={() => this.onEdit(commission)}>
						<EditIcon fontSize='small' />
					</span>
					<span onClick={() => this.onDelete(commission)}>
						<DeleteIcon fontSize='small' />
					</span>
					<span onClick={() => this.onClone(commission)}>
						<CloneIcon fontSize='small' />
					</span>
				</div>
			</div>
		</div>
	}

	_renderEmptyCardRecord = () => {
		return <div className="card-record empty-record" onClick={this.addNew}>
			<div className={"card-image"}>

			</div>
			<div className="card-content">
				<PlusIcon fontSize={'large'} />
			</div>
		</div>
	}

	render() {
		const { commissions } = this.props;

		return <div className="card-view-container">
			<div className="card-view-body">
				{
					commissions.length !== 0 && commissions.map((commission, index) => {

						const { title, date } = commission;
						return <div key={commission.id} className="card-record">
							<div className={"card-image"}></div>
							<div className="card-content">
								<div className="card-column text-center">
									{title}
								</div>
								<div className="card-column text-center">
									{moment(date).format('MM/DD/YYYY')}
								</div>
								<div className="card-action display-flex justify-center align-center">
									<span onClick={() => this.onEdit(commission)} style={{ marginRight: '12px' }}>
										<EditIcon fontSize='small' />
									</span>
									<span onClick={() => this.onDelete(commission)} style={{ marginLeft: '12px', marginRight: 12 }}>
										<DeleteIcon fontSize='small' />
									</span>
									<span onClick={() => this.onClone(commission)} style={{ marginLeft: '12px' }}>
										<CloneIcon fontSize='small' />
									</span>
								</div>
							</div>
						</div>
					})
				}
				{
					commissions.length === 0 && this._renderEmptyCardRecord()
				}
			</div>
		</div>;
	}
}

const mapStateToProps = state => ({
	auth: state.auth,
	errors: state.errors
});

export default connect(
	mapStateToProps,
	{}
)(CardView);

// export default Table;