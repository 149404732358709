import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Button
  } from "reactstrap";

class EmployeeHome extends Component {
    // constructor(props) {    
    //     super(props);
    // }

    componentDidMount() {
        document.body.classList.add("home-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
    }

    componentWillUnmount() {
        document.body.classList.remove("home-page");
        document.body.classList.remove("sidebar-collapse");
    }
    
    render() {
        return (
            <div className="wrapper">
                <div className="home">
                    <div className="panel">
                    <Container fluid={true}>
                        <div className="mr-auto ml-auto col-lg-8 mt-3">
                            <Row className="row-modules">
                            <Col lg="5" className="btn-container">
                                <Button className="btn-modules btn-round"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.history.push("/employee-pay");
                                }}>
                                My Pay
                                    </Button>
                                <i className="icon-module now-ui-icons business_money-coins"></i>
                            </Col>
                            <Col lg="2">
                            </Col>
                            <Col lg="5" className="btn-container">
                                <Button className="btn-modules btn-round"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.props.history.push("/expense");
                                }}>
                                Expense
                                    </Button>
                                <i className="icon-module now-ui-icons business_chart-bar-32"></i>
                            </Col>
                            </Row>
                        </div>
                    </Container>
                    </div>
                </div>
            </div>
        );
    }
}

export default EmployeeHome;