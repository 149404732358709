import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Checkbox from '@mui/material/Checkbox';

// core components
import { registerUser, updateGuestUser } from "../../actions/authActions";
import { registerEmployee } from "../../actions/authActions";
import { GApageView } from "../../shared/gaUtils";
import constant from "../../shared/constant";
import lineFloat from "../../assets/images/login/line.png";
import curveFloat from "../../assets/images/login/curve.png";
import moonFloat from "../../assets/images/login/moon.png";
import startHero from "../../assets/images/login/app-hero-3.png";
import blackHero from "../../assets/images/app-hero-5.png";
import asianHero from "../../assets/images/app-hero-4.png";
import lastHero from "../../assets/images/app-hero-2.png";
import successImg from "../../assets/images/question-done.gif";
import weeklyImg from "../../assets/images/weekly.png";
import monthlyImg from "../../assets/images/monthly.png";
import biWeeklyImg from "../../assets/images/bi-weekly.png";
import semiMonthlyImg from "../../assets/images/semi-monthly.png";
import logo from "../../assets/images/logo.png";
import InputForm from "../component/InputForm";
import Slide from "@mui/material/Slide";

class Questions extends Component {
    constructor() {
        super();
        this.state = {
            questionStep: 0,
            answerOne: '',
            name: "",
            nameError: false,
            email: "",
            emailError: false,
            phone: "",
            phoneError: false,
            password: "",
            passwordError: false,
            companyName: "",
            companyNameError: false,
            companyAddress: "",
            errors: {},
            nameFocus: false,
            emailFocus: false,
            phoneFocus: false,
            passwordFocus: false,
            companyNameFocus: false,
            companyAddressFocus: false,
            parentId: "",
            userId: "",
            toastOpen: false,
            notificationText: "",
            notifyType: 'error',
            checked1: false,
            checked2: false,
            checked3: false,
            checked4: false,
            checked5: false,
            checked6: false,
            checked7: false,
            checked8: false,
            checked9: false,
            checked10: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
                toastOpen: true,
                notifyType: "error"
            });
        }
    }

    componentDidMount() {
        const queryString = require("query-string");
        const parsed = queryString.parse(this.props.location.search);
        if (parsed.guest) this.setState({ userId: parsed.guest });
        if (parsed.parentId) this.setState({ parentId: parsed.parentId });
        if (parsed.companyName) this.setState({ companyName: parsed.companyName });
        if (parsed.email) this.setState({ email: parsed.email });
        const name = localStorage.getItem("Name");
        const email = localStorage.getItem("Email");
        if (name) this.setState({ name: name });
        if (email) this.setState({ email: email });
        // If logged in and user navigates to Questions page, should redirect them to home
        // if (this.props.auth.isAuthenticated) {
        //     this.props.history.push("/");
        //     return;
        // }
        document.body.classList.add("login-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        GApageView("Questions Page");
    }
    componentWillUnmount() {
        document.body.classList.remove("login-page");
        document.body.classList.remove("sidebar-collapse");
    }

    nextStep = () => {
        let newStep = this.state.questionStep + 1;
        this.setState({ questionStep: newStep });
    }

    setAnswerOne = (e) => {
        let answer = e;
        this.setState({ answerOne: answer });
    }

    pastStep = () => {
        let newStep = this.state.questionStep - 1;
        this.setState({ questionStep: newStep });
    }


    handleChange = (event) => {
        event.persist();
        const target = event.target;
        switch (target.id) {
            case 'checkbox1':
                this.setState({ checked1: target.checked });
                break;
            case 'checkbox2':
                this.setState({ checked2: target.checked });
                break;
            case 'checkbox3':
                this.setState({ checked3: target.checked });
                break;
            case 'checkbox4':
                this.setState({ checked4: target.checked });
                break;
            case 'checkbox5':
                this.setState({ checked5: target.checked });
                break;
            case 'checkbox6':
                this.setState({ checked6: target.checked });
                break;
            case 'checkbox7':
                this.setState({ checked7: target.checked });
                break;
            case 'checkbox8':
                this.setState({ checked8: target.checked });
                break;
            case 'checkbox9':
                this.setState({ checked9: target.checked });
                break;
            case 'checkbox10':
                this.setState({ checked10: target.checked });
                break;
        }
    }

    onChange = (e) => {
        if (
            e.id === "name" ||
            e.id === "companyName" ||
            e.id === "companyAddress"
        ) {
            if (e.value.length === 1) {
                let str = e.value.toUpperCase(); // First Letter Uppercase
                this.setState({ [e.id]: str });
                return;
            }
            let str = e.value;
            let char = str[str.length - 2];
            if (char === " ") {
                // After space, add captialize
                let upperChar = str[str.length - 1].toUpperCase();
                str = str.substring(0, str.length - 1);
                str += upperChar;
                this.setState({ [e.id]: str });
                return;
            }
        }
        this.setState({ [e.id]: e.value });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const newUser = {
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            password: this.state.password,
            companyName: this.state.companyName,
            companyAddress: this.state.companyAddress,
            role: constant["ROLE"]["OWNER"],
            parentId: this.state.parentId,
        };
        console.log("newUser-------", newUser.parentId)
        if (newUser.parentId && newUser.parentId !== "") {
            this.props.registerEmployee(newUser).then((data) => {
                this.setState({
                    notificationText: data.message,
                    toastOpen: true,
                    notifyType: "success"
                });
            }).catch(() => { });
        } else if (this.state.userId && this.state.userId !== "") {
            if (!this.validateForm(newUser)) return;
            this.props.updateGuestUser(this.state.userId, newUser)
                .then((data) => {
                    this.setState({
                        notificationText: data.message,
                        toastOpen: true,
                        notifyType: "error"
                    });
                }).catch(() => { });
        } else {
            if (!this.validateForm(newUser)) return;
            this.props.registerUser(newUser)
                .then((data) => {
                    this.setState({
                        notificationText: data.message,
                        toastOpen: true,
                        notifyType: "error"
                    });
                }).catch(() => { });
        }
    };

    transitionLeft(props) {
        return <Slide {...props} direction="left" />;
    }

    validateForm = (userData) => {
        if (userData.name === "") {
            this.setState({
                notificationText:
                    "We need your name for better experience. Please enter your name",
                toastOpen: true,
                nameError: true,
                notifyType: "error"
            });
            return false;
        } else {
            this.setState({
                nameError: false,
            });
        }
        if (userData.email === "") {
            this.setState({
                notificationText:
                    "Please enter your email. We don't send any spam emails.",
                toastOpen: true,
                nameError: true,
                notifyType: "error"
            });
            return false;
        } else {
            this.setState({
                emailError: false,
            });
        }

        if (userData.password === "" || userData.password.length < 6) {
            this.setState({
                notificationText:
                    "Please enter your password. Password should be more than 6 characters.",
                toastOpen: true,
                passwordError: true,
                notifyType: "error"
            });
            return false;
        } else {
            this.setState({
                passwordError: false,
            });
        }

        this.setState({
            notificationText: '',
            toastOpen: false,
        });
        return true;
    };

    render() {
        const { errors, toastOpen, notificationText, questionStep, answerOne, checked1, checked2, checked3, checked4, checked5, checked6, checked7, checked8, checked9, checked10 } = this.state;
        return (
            <div className="auth-page">
                <div className="content">
                    <div className="questions-page">
                        <div className="title-container display-flex justify-start align-center">
                            <div className="page-title-line"></div>
                            <div className="page-title white-text">Easy Commission Calculations</div>
                        </div>
                        {questionStep > 0 && questionStep < 3 && <div className="back-step-arrow-btn" onClick={() => this.pastStep()}>
                            <svg fill="#ffffff" height="32px" width="32px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 492 492" space="preserve">
                                <g>
                                    <g>
                                        <path d="M464.344,207.418l0.768,0.168H135.888l103.496-103.724c5.068-5.064,7.848-11.924,7.848-19.124
			c0-7.2-2.78-14.012-7.848-19.088L223.28,49.538c-5.064-5.064-11.812-7.864-19.008-7.864c-7.2,0-13.952,2.78-19.016,7.844
			L7.844,226.914C2.76,231.998-0.02,238.77,0,245.974c-0.02,7.244,2.76,14.02,7.844,19.096l177.412,177.412
			c5.064,5.06,11.812,7.844,19.016,7.844c7.196,0,13.944-2.788,19.008-7.844l16.104-16.112c5.068-5.056,7.848-11.808,7.848-19.008
			c0-7.196-2.78-13.592-7.848-18.652L134.72,284.406h329.992c14.828,0,27.288-12.78,27.288-27.6v-22.788
			C492,219.198,479.172,207.418,464.344,207.418z"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        }
                        <div className="sub-title white-text main-title">
                            {questionStep !== 0 && questionStep < 3 && <p className="page-number">{questionStep}/2</p>}
                            {questionStep == 3 && <p className="page-number">Answer completed!</p>}
                            {questionStep == 0 && 'Ready to make your commission management easy?'}
                            {questionStep == 1 && 'How often do you pay Payroll?'}
                            {questionStep == 2 && 'How would you like to pay commissions? (Pick one or two maximum)'}
                            {questionStep == 3 && 'Thank You'}
                        </div>
                        {questionStep == 0 &&
                            <div className="">
                                <div className="guide-text-box">
                                    <p className="guide-text">We support various types of commission management.</p>
                                    <p className="guide-text">Answer 2 questions to create your own commission structure.</p>
                                </div>
                                <div className="question-start-btn" onClick={() => this.setState({ questionStep: 1 })}>Get started</div>
                            </div>
                        }

                        {questionStep == 1 &&
                            <div className="select-box">
                                <div className="select-btn-group">
                                    <div className={answerOne == 'weekly' ? 'select-btn active-select-btn' : 'select-btn'} onClick={() => this.setAnswerOne('weekly')}>Weekly
                                        <img className={answerOne == 'weekly' ? 'img-responsive select-img active-select-img' : 'img-responsive select-img'} alt={"page-img"} src={weeklyImg} />
                                    </div>
                                    <div className={answerOne == 'bi-weekly' ? 'select-btn active-select-btn' : 'select-btn'} onClick={() => this.setAnswerOne('bi-weekly')}>Bi Weekly
                                        <img className={answerOne == 'bi-weekly' ? 'img-responsive select-img active-select-img' : 'img-responsive select-img'} alt={"page-img"} src={biWeeklyImg} />
                                    </div>
                                    <div className={answerOne == 'semi-monthly' ? 'select-btn active-select-btn' : 'select-btn'} onClick={() => this.setAnswerOne('semi-monthly')}>Semi-Monthly
                                        <img className={answerOne == 'semi-monthly' ? 'img-responsive select-img active-select-img' : 'img-responsive select-img'} alt={"page-img"} src={semiMonthlyImg} />
                                    </div>
                                    <div className={answerOne == 'monthly' ? 'select-btn active-select-btn' : 'select-btn'} onClick={() => this.setAnswerOne('monthly')}>Monthly
                                        <img className={answerOne == 'monthly' ? 'img-responsive select-img active-select-img' : 'img-responsive select-img'} alt={"page-img"} src={monthlyImg} />
                                    </div>
                                </div>
                            </div>
                        }
                        {questionStep == 2 &&
                            <div className="question-accordion-box">
                                <Accordion className="accordion-item">
                                    <AccordionSummary className="accordion-title-box"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Checkbox
                                            id="checkbox1"
                                            checked={this.state.checked1}
                                            onChange={this.handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                                            className="check-box"
                                        />
                                        <Typography>A Percentage of a Gross Sale Amount</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordion-desc-box">
                                        <Typography>
                                            Example: A car salesperson sells a $25,000 vehicle and earns 3% of the sales. They receive $750 in revenue commission for that sale. Calculation for revenue commission: <strong>Sale Price x Commission Percentage = Total Commission <span className="blue-text">(G x % = Comm)</span></strong>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="accordion-item">
                                    <AccordionSummary className="accordion-title-box"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Checkbox
                                            id="checkbox2"
                                            checked={this.state.checked2}
                                            onChange={this.handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                                            className="check-box"
                                        />
                                        <Typography>Fixed or Base Payroll Amount</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordion-desc-box">
                                        <Typography>
                                            Example: The company’s four salespeople each earn the same amount per employee per week no matter how many sales they make. Each employee can make different amounts.  <strong><span className="blue-text">There is no calculation as admin is required to specify an input amount by pay period for each employee. (Fixed Amount per payroll period specified in question 1) then each payroll period our system automatically adds the admin specified base to PayNow (ledger). </span></strong>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="accordion-item">
                                    <AccordionSummary className="accordion-title-box"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Checkbox
                                            id="checkbox3"
                                            checked={this.state.checked3}
                                            onChange={this.handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                                            className="check-box"
                                        />
                                        <Typography>A Tiered Percentage that grows based on Gross Sales</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordion-desc-box">
                                        <Typography>
                                            Example: A salesperson's base commission is 5% up to a total of $100,000 in sales. That commission increases to 7% for total sales between $100,001 and $200,000. Any sales over $200,001 earn them 10% in commission. <strong><span className="blue-text"> Admin must specify gross sale tiers and percentages for each in a payroll period.  (G - (1st Tier x 1st%)) + G - (1st Tier + 2nd Tier) x 2nd% = Comm
                                            </span></strong>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="accordion-item">
                                    <AccordionSummary className="accordion-title-box"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Checkbox
                                            id="checkbox4"
                                            checked={this.state.checked4}
                                            onChange={this.handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                                            className="check-box"
                                        />
                                        <Typography>Base salary plus commission</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordion-desc-box">
                                        <Typography>
                                            Example: A salesperson earns $500 a month in salary with 10% commission, or $500, for $5,000 worth in sales. If he sells $20,000 of product in one month, he earns $2,500: $500 in salary and $2,000 in commission.  Calculation for base rate only commission:
                                            <strong>Commission Percentage x Amount Sold = Commission<span className="blue-text"> (Base(by admin by time period of question#1 on its own line item in Pay Now))  (G x % = Comm) for another line item.
                                            </span></strong>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="accordion-item">
                                    <AccordionSummary className="accordion-title-box"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Checkbox
                                            id="checkbox5"
                                            checked={this.state.checked5}
                                            onChange={this.handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                                            className="check-box"
                                        />
                                        <Typography>Pay employees residual commissions with receipt of gross sales payment </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordion-desc-box">
                                        <Typography>
                                            Example: An insurance salesperson lands a large account. As long as that company pays its premiums of $3,000 a month, the salesperson receives a 5% commission or $150 each month.  Calculation for residual commission:
                                            <strong>Payment x Commission Percentage = Total Commission.<span className="blue-text">(G x % = Comm)
                                            </span></strong>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="accordion-item">
                                    <AccordionSummary className="accordion-title-box"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Checkbox
                                            id="checkbox6"
                                            checked={this.state.checked6}
                                            onChange={this.handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                                            className="check-box"
                                        />
                                        <Typography>A percentage of Gross Margin (% of profit) </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordion-desc-box">
                                        <Typography>
                                            Example: A salesperson is selling a $100,000 car that costs $60,000 to make. The gross margin is $40,000. The salesperson earns 5% on the margin or $2,000 in compensation.Calculation for gross margin commission:
                                            <strong>Total Sale Price - Cost = Gross Margin. Gross Margin x Commission Percentage = Total Commission<span className="blue-text">(We need to add a new line item under Receive Payment called Calculated Profit (CP). It is a datafield that would remain mostly grayed out, never to be filled out, unless our Prospect chooses this type of commission structure.
                                            </span></strong>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="accordion-item">
                                    <AccordionSummary className="accordion-title-box"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Checkbox
                                            id="checkbox7"
                                            checked={this.state.checked7}
                                            onChange={this.handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                                            className="check-box"
                                        />
                                        <Typography>Pay straight commission per sale</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordion-desc-box">
                                        <Typography>
                                            Example: A telemarketer that sells vacation condo rentals earns $150 for every booking. The more time put in on the phone, the greater the chance of making a sale.Calculation for a straight commission:
                                            <strong>Sales x Commission Rate = Income<span className="blue-text"> (this is Fixed Commission (ie. +$150) with ability for admin to specify quantity  Quant x $150 = Comm
                                            </span></strong>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="accordion-item">
                                    <AccordionSummary className="accordion-title-box"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Checkbox
                                            id="checkbox8"
                                            checked={this.state.checked8}
                                            onChange={this.handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                                            className="check-box"
                                        />
                                        <Typography>I want to offer Draws to employees</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordion-desc-box">
                                        <Typography>
                                            Example: A salesperson is expected to earn $4,000 a month in commission and receives $2,000 a month in draw. If they meet their $4,000 goal, they earn $2,000 more, the amount over the draw. If they earn only $1,000, they owe the company $1,000, the amount under the draw.  Calculation for draw commission:
                                            <strong> Commission Total - Draw = Commission Owed<span className="blue-text"> Draw specified by admin per time period of question#1  This is tricky because draws can be plus meaning due employee or minus meaning due FROM employee.
                                            </span></strong>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion className="accordion-item">
                                    <AccordionSummary className="accordion-title-box"
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                    >
                                        <Checkbox
                                            id="checkbox9"
                                            checked={this.state.checked9}
                                            onChange={this.handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 32 } }}
                                            className="check-box"
                                        />
                                        <Typography>I have a different idea in mind</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordion-desc-box">
                                        <Typography>
                                            <strong><span className="blue-text"> This means custom and we need to send lead prospect to demo calendar signup or talk to sales now.
                                            </span></strong>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        }
                        {questionStep > 0 && questionStep < 3 &&
                            <div className="step-control-btn-box">
                                <div className={questionStep == 1 ? 'hidden' : 'back-step-btn'} onClick={() => this.pastStep()}>Back</div>
                                <div className={(questionStep == 1 && answerOne) || ((questionStep == 2) && (checked1 || checked2 || checked3 || checked4 || checked5 || checked6 || checked7 || checked8 || checked9 || checked10)) ? 'next-step-btn' : 'hidden'} onClick={() => this.nextStep()}>Next</div>
                            </div>
                        }
                        {questionStep == 3 &&
                            <div className="question-end-modal">
                                <img className="success-img" alt={"page-img"} src={successImg} />
                                <p className="">Your own commission structure has been created.
                                    <br />From now on, manage your commissions easily!</p>
                                <Link to={'/'} className='go-app-btn' >Go to App</Link >
                            </div>

                        }
                    </div>
                    <div className="animation-bg">
                        <div className="particle-container">
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                            <div className="particle"></div>
                        </div>
                        <div className='grey'></div>
                        <div className="area" >
                            <ul className="circles">
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>
                        <div className='ripple-background'>
                            <div className='circle xxlarge shade1'></div>
                            <div className='circle xlarge shade2'></div>
                            <div className='circle large shade3'></div>
                            <div className='circle mediun shade4'></div>
                            <div className='circle small shade5'></div>
                        </div>
                    </div>
                    <div className="start-hero-container">
                        {questionStep == 0 && <img className="img-responsive start-hero" alt={"page-img"} src={startHero} />}
                        {questionStep == 1 && <img className="img-responsive asian-hero" alt={"page-img"} src={asianHero} />}
                        {questionStep == 2 && <img className="img-responsive black-hero" alt={"page-img"} src={blackHero} />}
                        {questionStep == 3 && <img className="img-responsive start-hero" alt={"page-img"} src={lastHero} />}

                    </div>
                </div>
            </div >
        );
    }
}

Questions.propTypes = {
    registerUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    updateGuestUser: PropTypes.func.isRequired,
    registerEmployee: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});

export default connect(mapStateToProps, {
    registerUser,
    registerEmployee,
    updateGuestUser,
})(withRouter(Questions));
