import React from "react";
// import { Button } from "reactstrap";
// import defaultImage from "assets/img/Upload-PNG-Image-File.png";
import upload_icon from "../../assets/images/upload-icon.png";

function ImageUpload(props) {
  const [file, setFile] = React.useState(null);
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(upload_icon);
  // const [banner, setBanner] = React.useState("");
  const fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    fileInput.current.click();
  };

  // const handleRemove = () => {
  //   setFile(null);
  //   setImagePreviewUrl(defaultImage);
  //   fileInput.current.value = null;
  // };

  return (
    <div className="tab-content-upload">
      <input type="file" onChange={handleImageChange} ref={fileInput} />
      <div className="upload-container">
        <div
          className={"fileinput-new thumbnail"}
        >
          <img src={imagePreviewUrl} alt="..." />
        </div>
        <div className="upload-text">
          Please upload file with 256*256 size to make the super look
        </div>
        {file === null ? (          
          <div className="btn-blue" onClick={handleClick}>
            Click Here To Upload
          </div>
        ) : (
          <span className="display-flex justify-end">
            <div className="btn-blue" onClick={handleClick}>
              Change
            </div>
            {null}
            <div className="btn-blue" onClick={() => props.handleUploadEvent(file)}>
              Submit
            </div>
          </span>
        )}
      </div>
    </div>
  );
}

export default ImageUpload;
