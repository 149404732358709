import React, { Component } from "react";
import PaymentForm from './PaymentForm';
import { updateUser } from "../../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import constant from "../../../shared/constant";
import { GAevent, GApageView, GAexception } from "../../../shared/gaUtils";
import moment from "moment";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '600px',
  width: '100%',
  bgcolor: '#fff',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 3,
  textAlign: 'center',
  padding: 1
};

const token = localStorage.getItem('jwtToken');

class Pricing extends Component {
  constructor() {
    super();
    this.state = {
      quantity: 1,
      priceId: 'STARTER',
      subscription: null,
      invoice: null,
      employees: [],
      updateCard: false,
      stripeCustomerId: null,
      stripeCustomerTestId: null,
      toastOpen: false,
      notificationText: "",
      notifyType: "error",
      subscribing: false,
      subscribingPlan: '',
      cancelSubscription: false,
      reasonCancel: '',
      modeType: false
    };
  }

  componentDidMount() {
    if (this.props.auth.modeType === false && this.props.auth.user.stripeSubscriptionId) {
      this.getSubscriptionInformation(this.props.auth.user.stripeSubscriptionId)
    }
    if (this.props.auth.modeType === true && this.props.auth.user.stripeSubscriptionTestId) {
      this.getSubscriptionInformation(this.props.auth.user.stripeSubscriptionTestId)
    }
    this.getEmployeeData();
    this.checkCancelSubscription();
    GApageView('Pricing Page');
    GAevent('User', 'Pricing Page', `User: ${this.props.auth.user.name}  visited Pricing page`);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.modeType !== this.props.auth.modeType) {
      console.log('modeType has changed:', this.props.auth.modeType);
      if (this.props.auth.modeType === false) {
        if (this.props.auth.user.stripeSubscriptionId) {
          this.getSubscriptionInformation(this.props.auth.user.stripeSubscriptionId)
        } else {
          this.setState({ subscription: null, quantity: 1 });
        }
      }
      if (this.props.auth.modeType === true) {
        if (this.props.auth.user.stripeSubscriptionTestId) {
          this.getSubscriptionInformation(this.props.auth.user.stripeSubscriptionTestId)
        } else {
          this.setState({ subscription: null, quantity: 1 });
        }
        
      }
      this.getEmployeeData();
      this.checkCancelSubscription();
    }
  }

  getEmployeeData() {
    axios
      .post('/api/users/employees', { userId: this.props.auth.user.id })
      .then((res) => {
        this.setState({ employees: res.data });
      }).catch((err) => {
        console.log(err);
      });
  }

  checkCancelSubscription = () => {
    axios
      .get('/api/users/' + this.props.auth.user.id)
      .then((res) => {
        if (res.data.subscribedDate) {
          var currentDate = moment(res.data.subscribedDate).add(3, 'months');
          if (!moment().isBefore(currentDate) && this.props.auth.user.level === 0) {
            this.cancelSubscription();
          }
        }
      }).catch((err) => {
        this.setState({
          toastOpen: true,
          notificationText: "Error occured whiling fetching user data",
          notifyType: "error"
        });
        GAexception(`Getting user data failed: ${err}`);
      });
  }

  componentWillUnmount() {

  }

  getSubscriptionInformation(stripeSubscriptionId) {
    axios.post('/api/stripe/retrieve-subscription', {
      subscriptionId: stripeSubscriptionId, 
      modeType: this.props.auth.modeType
    })
    .then((res) => {
      this.setState({ subscription: res.data, quantity: res.current_quantity });
    }).catch((error) => {
      console.log(error)
    })
  }

  updateSubscription = async (priceId) => {
    return fetch('/api/stripe/update-subscription', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        subscriptionId: this.props.auth.modeType === false ? this.props.auth.user.stripeSubscriptionId : this.props.auth.user.stripeSubscriptionTestId,
        newPriceId: priceId,
        quantity: this.state.quantity,
        modeType: this.props.auth.modeType
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => { 
        const user = this.props.auth.user;
        if (this.props.auth.modeType === false) {
          user.stripeSubscriptionId = response.subscription.id;
        } else {
          user.stripeSubscriptionTestId = response.subscription.id;
        }
        user.level = constant['MEMBERSHIP'][this.state.priceId];
        user.subscribedDate = moment.unix(response.subscription.created);
        this.props.updateUser(user);
        this.getSubscriptionInformation(response.subscription.id).then((res) => {
          this.setState({
            subscription: res,
            quantity: res.current_quantity,
            subscribing: false,
            subscribingPlan: ''
          });
        });
        GAevent('User', 'Pricing Page', ``);
        // this.props.goBackToEmployees();
      }).catch((err) => {
        console.log(err);
      });
  }

  cancelSubscription = () => {
    console.log("this.props.auth.user.stripeSubscriptionTestId", this.props.auth.user.stripeSubscriptionTestId)
    fetch('/api/stripe/cancel-subscription', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        subscriptionId: this.props.auth.modeType === false ? this.props.auth.user.stripeSubscriptionId : this.props.auth.user.stripeSubscriptionTestId, 
        modeType: this.props.auth.modeType
      }),
    }).then((response) => {
      return response.json();
    })
      .then((response) => {
        const user = this.props.auth.user;
        if (this.props.auth.modeType === false) {
          user.stripeSubscriptionId = null;
          user.stripeSessionId = null;
        } else {
          user.stripeSubscriptionTestId = null;
          user.stripeSessionTestId = null;  
        }
        user.subscribedDate = null;
        user.level = constant['MEMBERSHIP']['TRIAL'];
        this.props.updateUser(user);
        this.setState({
          subscription: null,
          toastOpen: true,
          notificationText: 'You have successfully unsubscribed the plan.',
          notifyType: "info"
        });
        GAevent('User', 'Pricing Page', `User: ${this.props.auth.user.name} `);
      }).catch((err) => {
        console.log(err);
        GAexception(`Cancelling a subscription failed: ${err}`);
        this.setState({
          toastOpen: true,
          notificationText: 'Error occured while canceling your subscription',
          notifyType: "error"
        });
      });
  }

  handleSubscribeClick = (param, fromDialog = false) => {
    if (fromDialog) this.setState({ cancelSubscription: false });
    if (param === 'plus') {
      if (this.props.auth.user.level === -1) {
        this.onSubscribe('STARTER');
      } else {
        this.onSubscribe('PLUS');
      }
    }
    if (param === 'professional') {
      this.onSubscribe('PROFESSIONAL');
    }
    if (param === 'corporate') {
      this.onSubscribe('CORPORATE');
    }
    if (param === 'ultimate') {
      this.onSubscribe('ULTIMATE');
    }
  }

  createSubscription = (priceId) => {
    axios.post('/api/stripe/create-subscription-checkout-session', {
      customerId: this.props.auth.user.stripeCustomerId, 
      plan: priceId, 
      userId: this.props.auth.user.id, 
      modeType: this.props.auth.modeType 
    })
    .then(async (res) => {
      await this.props.updateUser(res.data.user)
      console.log("--------", res.data.session)
      window.location = res.data.session.url;
    }).catch((error) => {
      console.log(error)
    })
  }

  onSubscribe = (priceId) => {
    if (!this.props.auth.user.stripeCustomerId || this.props.auth.user.stripeCustomerId === undefined) {
      axios
        .post('/api/stripe/create-customer', { 
          email: this.props.auth.user.email, 
          name: this.props.auth.user.name, 
          modeType: this.props.auth.modeType 
        })
        .then(res => {
          let user = this.props.auth.user;
          user.stripeCustomerId = res.data.id;
          this.props.updateUser(user);
          this.createSubscription(priceId)
          GAevent('User', 'Pricing Page', `User: ${this.props.auth.user.name}  upgraded to STARTER`);
        }).catch((err) => {
          this.setState({
            toastOpen: true,
            notificationText: "Oops!!! Something went wrong",
            notifyType: "info"
          });
        });
    } else if (!this.props.auth.user.stripeCustomerTestId || this.props.auth.user.stripeCustomerTestId === undefined) {
      axios
        .post('api/stripe/create-customer', { 
          email: this.props.auth.user.email, 
          name: this.props.auth.user.name, 
          modeType: this.props.auth.modeType 
        })
        .then(res => {
          let user = this.props.auth.user;
          user.stripeCustomerTestId = res.data.id;
          this.props.updateUser(user);
          this.createSubscription(priceId)
          GAevent('User', 'Pricing Page', `User: ${this.props.auth.user.name}  upgraded to STARTER`);
        }).catch((err) => {
          this.setState({
            toastOpen: true,
            notificationText: "Oops!!! Something went wrong",
            notifyType: "info"
          });
        });
    } else if (!this.props.auth.user.stripeSubscriptionId || !this.props.auth.user.stripeSubscriptionTestId || this.props.auth.user.stripeSubscriptionTestId === undefined || this.props.auth.user.stripeSubscriptionId === undefined) {
      this.createSubscription(priceId)
    } else {
      this.updateSubscription(priceId);
    }
  }

  // handleSubscriptionComplete = (stripeAccInfo) => {
  //   GAevent('User', 'Pricing Page', `${stripeAccInfo.quantity} Employees added to User: ${this.props.auth.user.name} `);
  //   const user = this.props.auth.user;
  //   user.level = constant['MEMBERSHIP'][stripeAccInfo.priceId];
  //   user.stripeSubscriptionId = stripeAccInfo.subscription.id;
  //   user.subscribedDate = moment.unix(stripeAccInfo.subscription.created);
  //   this.props.updateUser(user);
  //   this.getSubscriptionInformation(user.stripeSubscriptionId).then((res) => {
  //     this.setState({
  //       subscription: null,
  //       toastOpen: true,
  //       notificationText: `You have successfully subscribed ${res.product_description} plan.`,
  //       notifyType: "info"
  //     });
  //     this.setState({
  //       subscription: res,
  //       quantity: res.current_quantity,
  //       subscribing: false,
  //       subscribingPlan: ''
  //     });
  //   });
  // }

  handleUpdateCard = () => {
    this.setState({ updateCard: true });
    GAevent('User', 'Pricing Page', `User: ${this.props.auth.user.name} `);
  }

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  handleClose = () => {
    this.setState({
      toastOpen: false
    })
  };

  onChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  sendCancelReason = () => {
    if (this.state.reasonCancel === '') {
      this.setState({
        toastOpen: true,
        notificationText: "Oops! Please give us the main reason you are canceling."
      });
    } else {
      this.setState({ cancelSubscription: false }, () => {
        if (this.state.reasonCancel !== undefined) {
          axios.post('/api/users/sendCancelReason',
            {
              name: this.props.auth.user.name,
              email: this.props.auth.user.email,
              reason: this.state.reasonCancel,
              phone: this.props.auth.user.phone,
            }).then((res) => {
              this.cancelSubscription();
            }).catch((err) => {
              this.setState({
                toastOpen: true,
                notificationText: "Oops! Error happened while sending a message."
              });
              setTimeout(() => {
                this.setState({
                  toastOpen: false,
                  notificationText: ""
                });
              }, 3000);
            });
        } else {
          this.cancelSubscription();
        }
      });
    }
  }

  render() {
    const { toastOpen, notificationText, notifyType, subscription, subscribing, subscribingPlan, cancelSubscription } = this.state;
    
    return (
      <>
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={notifyType} sx={{ width: '100%', fontSize: 24 }}>
            {notificationText}
          </Alert>
        </Snackbar>
        <div className="tab-content-membership">
          <div className="tab-content-title">
            Please Subscribe to a Plan, Cancel Anytime
          </div>
          <ul class="two-column-list">
            <li>Quick Dashboard Access</li>
            <li>Unlimited Comp Plans</li>
            <li>Gross Sales Report</li>
            <li>Customer Report</li>
            <li>Payroll and Payroll History</li>
            <li>Expense Report</li>
            <li>Employee Expense Report</li>
            <li>Employee Receipts Uploader</li>
          </ul>
          <div className="plan-list">
            <div className={subscription && (subscription.product_description === constant['MEMBERSHIP_PLANS']['PLUS'] || subscription.product_description === constant['MEMBERSHIP_PLANS']['STARTER']) ? "plan-item subscribed-plan" : "plan-item"}>
              {
                subscription && (subscription.current_price === constant['STRIPE_MEMBERSHIP']['PLUS'] || subscription.current_price === constant['STRIPE_MEMBERSHIP']['STARTER'])
                ? <div className="price">
                    <div className="plan-title">
                      <div>STARTER</div>
                      <hr />
                    </div>
                    <span>99¢</span>
                    <span>/</span>
                    <span>7 days</span>
                    <div style={{marginTop: "30px"}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <MoreHorizIcon />
                        <MoreHorizIcon />
                        <MoreHorizIcon />
                        <div style={{marginLeft: "3px", fontWeight: "bold"}}>{subscription.card.last4}</div>
                      </div>
                      <div style={{fontSize: "15px", textAlign: 'center'}}>{subscription.card.expMonth + " / " + subscription.card.expYear}</div>
                    </div>
                  </div>
                : <div className="price">
                    <div className="plan-title">
                      <div>STARTER</div>
                      <hr />
                    </div>
                    <span>99¢</span>
                    <span>/</span>
                    <span>7 days</span>
                    <div className="membership-box">
                      <p>for the first 7 days, cancel anytime, then $39.95/mo, add up to <strong>5</strong> employees</p>
                      <ul>
                      </ul>
                    </div>
                  </div>
              }
              <div className="subscribe">
                {
                  subscription && (subscription.product_description === constant['MEMBERSHIP_PLANS']['PLUS'] ||
                    subscription.product_description === constant['MEMBERSHIP_PLANS']['STARTER']) 
                    ? 
                      <div className="cancel-btn" onClick={() => { this.setState({ cancelSubscription: true }) }}>
                        Cancel
                      </div> 
                    : subscription === null
                      ? <div className="btn-blue" onClick={() => this.handleSubscribeClick('plus')}>Start</div>
                      : <div className="btn-blue" onClick={() => this.handleSubscribeClick('plus', true)}>Downgrade</div>
                      // <div className="btn-blue" onClick={() => this.handleSubscribeClick('plus')}>
                      //   {
                      //     ((subscribingPlan === 'Plus' || subscribingPlan === 'Starter') && subscribing) ? 'Subscribing...' : 'Your Plan'
                      //   }
                      // </div>

                }
              </div>
            </div>
            <div className={subscription && subscription.product_description === constant['MEMBERSHIP_PLANS']['PRO'] ? "plan-item subscribed-plan" : "plan-item"}>
              {
                subscription && subscription.current_price === constant['STRIPE_MEMBERSHIP']['PROFESSIONAL']
                ? <div className="price">
                    <div className="plan-title">
                      <div>PROFESSIONAL</div>
                      <hr />
                    </div>
                    <span>$89.95</span>
                    <span>/</span>
                    <span>Mo</span>
                    <div style={{marginTop: "30px"}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <MoreHorizIcon />
                        <MoreHorizIcon />
                        <MoreHorizIcon />
                        <div style={{marginLeft: "3px", fontWeight: "bold"}}>{subscription.card.last4}</div>
                      </div>
                      <div style={{fontSize: "15px", textAlign: 'center'}}>{subscription.card.expMonth + " / " + subscription.card.expYear}</div>
                    </div>
                  </div>
                : <div className="price">
                    <div className="plan-title">
                      <div>PROFESSIONAL</div>
                      <hr />
                    </div>
                    <span>$89.95</span>
                    <span>/</span>
                    <span>Mo</span>
                    <div className="membership-box">
                      <p>add up to <strong>15</strong> employees</p>
                      <ul>
                      </ul>
                    </div>
                  </div>
              }
              <div className="subscribe">
                {
                  subscription && subscription.product_description === constant['MEMBERSHIP_PLANS']['PRO'] 
                  ? <div className="cancel-btn" onClick={() => { this.setState({ cancelSubscription: true }) }}>Cancel</div> 
                  : subscription 
                    ? (subscription.product_description === constant['MEMBERSHIP_PLANS']['ULTI'] || subscription.product_description === constant['MEMBERSHIP_PLANS']['CORP'])
                      ? <div className="btn-blue" onClick={() => this.handleSubscribeClick('professional', true)}>Downgrade</div>
                      : <div className="btn-blue" onClick={() => this.handleSubscribeClick('professional', true)}>Upgrade</div>
                    : <div className="btn-blue" onClick={() => this.handleSubscribeClick('professional')}>Start</div>
                }
              </div>
            </div>
            <div className={subscription && subscription.product_description === constant['MEMBERSHIP_PLANS']['CORP'] ? "plan-item subscribed-plan" : "plan-item"}>
              {
                subscription && subscription.current_price === constant['STRIPE_MEMBERSHIP']['CORPORATE']
                ? <div className="price">
                    <div className="plan-title">
                      <div>CORPORATE</div>
                      <hr />
                    </div>
                    <span>$149.95</span>
                    <span>/</span>
                    <span>Mo</span>
                    <div style={{marginTop: "30px"}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <MoreHorizIcon />
                        <MoreHorizIcon />
                        <MoreHorizIcon />
                        <div style={{marginLeft: "3px", fontWeight: "bold"}}>{subscription.card.last4}</div>
                      </div>
                      <div style={{fontSize: "15px", textAlign: 'center'}}>{subscription.card.expMonth + " / " + subscription.card.expYear}</div>
                    </div>
                  </div>
                : <div className="price">
                    <div className="plan-title">
                      <div>CORPORATE</div>
                      <hr />
                    </div>
                    <span>$149.95</span>
                    <span>/</span>
                    <span>Mo</span>
                    <div className="membership-box">
                      <p>add up to <strong>30</strong> employees</p>
                      <ul>
                        <li>Get Custom API Integration</li>
                      </ul>
                    </div>
                  </div>
              }
              <div className="subscribe">
                {
                  subscription && subscription.product_description === constant['MEMBERSHIP_PLANS']['CORP'] 
                  ? <div className="cancel-btn" onClick={() => { this.setState({ cancelSubscription: true }) }}>Cancel</div>
                  : subscription
                    ? subscription.product_description === constant['MEMBERSHIP_PLANS']['ULTI'] 
                      ?<div className="btn-blue" onClick={() => this.handleSubscribeClick('corporate', true)}>Downgrade</div>
                      : <div className="btn-blue" onClick={() => this.handleSubscribeClick('corporate', true)}>Upgrade</div>
                    : <div className="btn-blue" onClick={() => this.handleSubscribeClick('corporate')}>Start</div>
                }
              </div>
            </div>
            <div className={subscription && subscription.product_description === constant['MEMBERSHIP_PLANS']['ULTI'] ? "plan-item subscribed-plan" : "plan-item"}>
              {
                subscription && subscription.current_price === constant['STRIPE_MEMBERSHIP']['ULTIMATE']
                ? <div className="price">
                    <div className="plan-title">
                      <div>ULTIMATE</div>
                      <hr />
                    </div>
                    <span>$449.95</span>
                    <span>/</span>
                    <span>Mo</span>
                    <div style={{marginTop: "30px"}}>
                      <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <MoreHorizIcon />
                        <MoreHorizIcon />
                        <MoreHorizIcon />
                        <div style={{marginLeft: "3px", fontWeight: "bold"}}>{subscription.card.last4}</div>
                      </div>
                      <div style={{fontSize: "15px", textAlign: "center"}}>{subscription.card.expMonth + " / " + subscription.card.expYear}</div>
                    </div>
                  </div>
                : <div className="price">
                    <div className="plan-title">
                      <div>ULTIMATE</div>
                      <hr />
                    </div>
                    <span>$449.95</span>
                    <span>/</span>
                    <span>Mo</span>
                    <div className="membership-box">
                      <p>add up to <strong>100</strong> employees</p>
                      <ul>
                        <li>Get Custom API Integration</li>
                        <li>Get Unlimited API integrations</li>
                        <li>40 Hours programming</li>
                        <li>Customization included</li>
                      </ul>
                    </div>
                  </div>
              }
              <div className="subscribe">
                {
                  subscription && subscription.product_description === constant['MEMBERSHIP_PLANS']['ULTI'] 
                  ? <div className="cancel-btn" onClick={() => { this.setState({ cancelSubscription: true }) }}>Cancel</div> 
                  : subscription 
                    ? <div className="btn-blue"  onClick={() => this.handleSubscribeClick('ultimate', true)}>Upgrade</div>
                    : <div className="btn-blue" onClick={() => this.handleSubscribeClick('ultimate')}>Start</div>
                }
              </div>
            </div>
          </div>
        </div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={cancelSubscription}
        >
          <DialogContent>
            <div className="cancel-subscription-dialog">
              <div className="dialog-title">
                <h4 style={{ fontSize: 22, fontWeight: 'bold' }}>Are you sure to cancel this subscription?</h4>
                <h5>We are so sorry you want to cancel.  Please give us the main reason you are canceling</h5>
              </div>
              <textarea
                className="form-control"
                id={'reasonCancel'}
                onChange={ev => this.onChange(ev)}
                value={this.state.reasonCancel}
              />
              <div style={{ height: '20px' }}></div>
              <div style={{ height: '20px' }}></div>
              <div className="display-flex justify-center align-center" style={{ marginTop: '12px' }}>
                <div className="btn-blue" style={{ fontSize: '14px', marginRight: '12px' }} onClick={this.sendCancelReason}>Finalize</div>
                <div className="btn-blue" style={{ fontSize: '14px' }} onClick={() => { this.setState({ cancelSubscription: false }) }}>Cancel</div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

Pricing.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { updateUser }
)(Pricing);
