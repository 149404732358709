import React, { Component } from "react";
import axios from 'axios';

// material
import { Link } from "react-router-dom";
import { Grid } from "@mui/material";

// core components
import { GApageView } from "../../shared/gaUtils";
import lineFloat from "../../assets/images/login/line.png";
import curveFloat from "../../assets/images/login/curve.png";
import moonFloat from "../../assets/images/login/moon.png";
import banner from "../../assets/images/login/banner.png";
import logo from "../../assets/images/logo.png";

class EmailVerify extends Component {
    constructor() {
        super();
        this.state = {

        };
    }

    verifyEmailUrl = () => {
        const location = this.props.location.pathname || "";
        const tokens = location.split("/");
        const userId = tokens[2];
        const token = tokens[4];
        axios
            .get(`/api/users/${userId}/verify/${token}`)
            .then(res => {
                console.log(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    componentDidMount() {
        document.body.classList.add("login-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
        GApageView('Forgot Password');
        this.verifyEmailUrl();
    }

    componentWillUnmount() {
        document.body.classList.remove("login-page");
        document.body.classList.remove("sidebar-collapse");
    }

    render() {
        return (
            <div className="auth-page">
                <div className="line-float">
                    <img className="img-responsive" src={lineFloat} alt={'line-float'} />
                </div>
                <div className="curve-float">
                    <img className="img-responsive" src={curveFloat} alt={'curve-float'} />
                </div>
                <div className="moon-float">
                    <img className="img-responsive" src={moonFloat} alt={'moon-float'} />
                </div>
                <div className="content">
                    <Grid item container spacing={0} className="content-container">
                        <Grid item xl={8} lg={7} md={6} sm={12} xs={12} className="left">
                            <div className="title-container display-flex justify-start align-center">
                                <div className="page-title-line"></div>
                                <div className="page-title">Easy Commission Calculations</div>
                            </div>
                            <div className="sub-title">
                                All your commission calculations in one place
                            </div>
                            <div className="w-100 display-flex justify-end mobile-login">
                                <div className="auth-form mobile-form">
                                    <div className="top display-flex flex-column justify-start align-center">
                                        <div>
                                            <img className="img-responsive" alt="logo" src={logo} />
                                        </div>
                                        <div style={{ height: '40px' }}>
                                            Email verified successfully
                                        </div>
                                    </div>
                                    <div style={{ marginTop: '40px' }}></div>
                                    <div className="bottom display-flex justify-between align-center">
                                        <div className={"bubbly-button"} style={{ width: '100%' }} onClick={() => { this.props.history.push("/login"); }}>
                                            <span>Login</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="page-image">
                                <img className="img-responsive" alt={'page-img'} src={banner} />
                            </div>
                            <div className="display-flex justify-start align-center terms-policy">
                                <a href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit" target="_blank" rel="noopener noreferrer">TERMS OF SERVICE</a>
                                <Link to="/contact-us">CONTACT US</Link>
                            </div>
                        </Grid>
                        <Grid item xl={4} lg={5} md={6} sm={12} xs={12} className="right">
                            <div className="w-100 display-flex justify-end">
                                <div className="auth-form">
                                    <div className="top display-flex flex-column justify-start align-center">
                                        <div>
                                            <img className="img-responsive" alt="logo" src={logo} />
                                        </div>
                                        <div style={{ marginTop: '40px' }}>
                                            Email verified successfully
                                        </div>
                                    </div>
                                    <div style={{ height: '40px' }}></div>
                                    <div className="bottom display-flex justify-between align-center">
                                        <div className={"bubbly-button"} style={{ width: '100%' }} onClick={() => { this.props.history.push("/"); }}>
                                            <span>Login</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="licence">
                                <span>© 2020, Published by <span className="marked-text">My Market Partners</span></span>
                            </div>
                        </Grid>
                    </Grid>
                </div>
                {/* <div className="licence">
                <span>© 2020, Published by <span className="marked-text">My Market Partners</span></span>
            </div> */}
            </div>
        );
    }
}

export default EmailVerify;