import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import { updateUser } from "../../actions/authActions";
import axios from "axios";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

// core components
import ImageUpload from "components/shared/ImageUpload.js";
import Pricing from "components/account/pricing/Pricing.js";
import constant from "../../shared/constant";
import user_menu_icon from "../../assets/images/user-menu-icon.png";
import AccountInfo from "./account/AccountInfo";
import { GApageView } from "../../shared/gaUtils";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: "1",
      errors: {},
      currentTab: 1,
      toastOpen: false,
      notificationText: "",
      notifyType: "error"
    }
  }

  componentDidMount() {
    document.body.classList.add("account-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role === constant['ROLE']['OWNER'] || this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) {
      // Check if pricing param exists
      const queryString = require('query-string');
      const parsed = queryString.parse(this.props.location.search);
      if (parsed.pricing && parsed.pricing === 'true') {
        this.setState({ tabs: "2" });
      }
      GApageView('Account');
    } else {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("account-page");
    document.body.classList.remove("sidebar-collapse");
  }

  updateProfile = (user) => {
    const userData = user;
    this.props.updateUser(user);
  }

  handleUpdateAccountInfo = (userData) => {
    this.setState({
      toastOpen: true,
      notificationText: 'Updating your account details.',
      notifyType: "success"
    });
    this.props.updateUser(userData);
  }

  handleUploadEvent = (file) => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      axios
        .post('/api/users/upload_logo', formData)
        .then(res => {
          if (res.data.path.trim() !== '') {
            let user = this.props.auth.user;
            user.logo = res.data.path;
            this.updateProfile(user);
            this.setState({
              toastOpen: true,
              notificationText: 'You have successfully uploaded your Logo',
              notifyType: "success"
            });
          }
        }).catch((err) => {
          console.log(err);
        });
    }
  }

  goBackToEmployees = () => {
    this.props.history.push("/employees");
  }

  moveTab = (id) => {
    this.setState({
      currentTab: id
    })
  }

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  handleClose = () => {
    this.setState({
      toastOpen: false
    })
  };

  render() {
    const { currentTab, toastOpen, notificationText, notifyType } = this.state;
    
    return (
      <>
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={notifyType} sx={{ width: '100%', fontSize: 24 }}>
              {notificationText}
          </Alert>
        </Snackbar>
        <div className="account-page">
          <div className="page-content">
            <div className="left">
              <div className="menu">
                <div className="menu-logo">
                  <img src={user_menu_icon} alt="account sub menu" />
                </div>
                <div className="menu-title">Manage Account</div>
                <div className="menu-item">Pricing Levels</div>
                <div className="menu-item">Upload Logo</div>
                <div className="menu-item">Manage Account</div>
              </div>
            </div>
            <div className="right">
              <div className="tab-nav" ref={this.tabRef}>
                <div className={currentTab === 1 ? "tab-item tab-item__active" : "tab-item"} onClick={() => this.moveTab(1)}>Pricing Levels</div>
                <div className={currentTab === 2 ? "tab-item tab-item__active" : "tab-item"} onClick={() => this.moveTab(2)}>Upload Logo</div>
                <div className={currentTab === 3 ? "tab-item tab-item__active" : "tab-item"} onClick={() => this.moveTab(3)}>Manage Account</div>
              </div>
              <div className="tab-container">
                {
                  currentTab === 1 && <Pricing goBackToEmployees={this.goBackToEmployees} />
                }
                {
                  currentTab === 2 && <ImageUpload handleUploadEvent={this.handleUploadEvent} />
                }
                {
                  currentTab === 3 && <AccountInfo handleUpdateAccountInfo={this.handleUpdateAccountInfo} />
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

Account.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { updateUser }
)(Account);