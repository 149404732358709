import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import constant, { SUBSCRIBE_TIME_IN_SECONDS, SUBSCRIBE_SKIP_COUNT } from "../../shared/constant";
// core components
import { GAevent, GAexception, GApageView } from "../../shared/gaUtils";
// import Floater from 'react-floater';
import { logoutUser, updateUser, registerGuest } from "../../actions/authActions";
import { Box } from '@mui/system';
// import Table from './Table';
// import CardView from './CardView';
import AddIcon from '@mui/icons-material/Add';
import WindowIcon from '@mui/icons-material/Window';
import CloseIcon from '@mui/icons-material/Close';
import TocIcon from '@mui/icons-material/Toc';
import Button from "reactstrap/lib/Button";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';

import InputForm from '../component/InputForm';
import SelectForm from '../component/SelectForm';
import moment from "moment";

import { BrowserView } from 'react-device-detect';
import demoUser from '../../assets/images/icons/demo-user.png';
import demoCoin from '../../assets/images/icons/demo-coin.png';
import demoFund from '../../assets/images/icons/demo-contract.png';
import demoPay from '../../assets/images/icons/demo-like.png';
import { Icon } from "@mui/material";

const token = localStorage.getItem('jwtToken');

// var randomEmail = require('random-email');

class Employees extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subscribeQuestion: false,
      subscriptionText: '',
      employees: [],
      view: 'table',
      newEmployeeOpen: false,
      actionType: 'add',
      employee: {},
      name: "",
      nameInvalid: false,
      email: "",
      emailInvalid: false,
      phone: "",
      role: "Employee",
      walkthrough: false,
      confirmDialog: false,
      toastOpen: false,
      notificationText: "",
      notifyType: "error",
    }
  }

  intervalID = 0;

  // componentWillReceiveProps(nextProps, prevProps) {
  //   if (nextProps.auth.isAuthenticated && nextProps.auth.user.role === constant['ROLE']['GUEST']) {
  //     //Add employee
  //     this.onAddEmployee(nextProps);
  //     //Redirect to Commissions page
  //     this.props.props.history.push("/commissions");
  //   }
  // }

  componentWillUnmount() {
    // clearInterval(this.intervalID);
  }

  componentDidMount() {
    localStorage.setItem('demoStatus', 'yes');
  }

  componentWillUnmount() {
  }

  goDashBoard = () => {
    localStorage.setItem('demoStatus', 'no')
    this.props.history.push('/');
  }

  render() {
    const {
      employees,
      view,
      newEmployeeOpen,
      confirmDialog,
      toastOpen,
      notificationText,
      notifyType,
      subscriptionText
    } = this.state;

    return (
      <>
        <div className="justify-center demo-content">
            <h2 className="demo-title">How CommissionPayPro Works</h2>
            {/* <button onClick={this.goDashBoard}>My Dashboard</button> */}
            <div className="row justify-content-center pt-3">
              <a className="tutor-video" href="/explainer">Quick Start Video</a>
            </div>
            <div className="row justify-content-center pt-3">
              <div className="p-3 px-5 col-12 col-md-12 col-xl-6 col-lg-12 text-center">
                <p><img class="demo-icon" src={demoUser} alt="employee" /></p>
                <a className="demo-link" href="/employees">#1 Add an Employee</a>
                <p className="demo-description">You first start by adding an employee or a sub-contractor who you pay regularly via commission.</p>
              </div>
              <div className="p-3 px-5 col-12 col-md-12 col-xl-6 col-lg-12 text-center">
                <p><img class="demo-icon" src={demoCoin} alt="commission" /></p>
                <a className="demo-link" href="/commissions">#2 Set Employee's Commission</a>
                <p className="demo-description">You set your employee's commission with a fixed amount or a percentage of a gross sale or a combination of both</p>
              </div>
              <div className="p-3 px-5 col-12 col-md-12 col-xl-6 col-lg-12 text-center">
                <p><img class="demo-icon" src={demoFund} alt="commission" /></p>
                <a className="demo-link" href="/receive-funds">#3 Enter Your Employee's Sales </a>
                <p className="demo-description">You enter the gross sale and the employee commission that goes with the sale.  You can even add multiple employees paid from one sale.</p>
              </div>
              <div className="p-3 px-5 col-12 col-md-12 col-xl-6 col-lg-12 text-center">
                <p><img class="demo-icon" src={demoPay} alt="pay" /></p>
                <a className="demo-link" href="/pay">#4 Run Easy Pay Day Report</a>
                <p className="demo-description">On Payday, you just run the report of all employee's compensation due. The report keeps track of all unpaid employees and their amount due. Simple!</p>
              </div>
          </div>
        </div>
      </>
    );
  }
}

Employees.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  registerGuest: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { logoutUser, updateUser, registerGuest }
)(Employees);