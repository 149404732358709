import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { connect } from 'react-redux';

import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import {
  Col,
  Button
} from "reactstrap";  
import { GAevent, GAexception, GApageView } from '../../../shared/gaUtils';
import axios from "axios"

const CheckoutForm = ({ priceId, stripeCustomerId, stripeCustomerTestId, quantity, handleSubscriptionComplete, user, updateCard, modeType}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [subscribing, setSubscribing] = useState(false);
  const [stripeAccInfo, setStripeAccInfo] = useState(null);
  const [errorToDisplay, setErrorToDisplay] = useState('');
  const [billingName, setBillingName] = useState('');

  const token = localStorage.getItem('jwtToken');

  React.useEffect(() => {
    GApageView('Payment Form');
  });

  function handlePaymentThatRequiresCustomerAction({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry,
  }) {
    if (subscription && subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc)
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // There's a risk of the customer closing the window before callback
              // execution. To handle this case, set up a webhook endpoint and
              // listen to invoice.payment_succeeded. This webhook endpoint
              // returns an Invoice.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId,
              };
            }
          }
        });
    } else {
      // No customer action needed
      return { subscription, priceId, paymentMethodId };
    }
  }

  function handleRequiresPaymentMethod({
    subscription,
    paymentMethodId,
    priceId,
  }) {
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );
      throw new Error('Your card was declined.');
    } else {
      return { subscription, priceId, paymentMethodId };
    }
  }

  function retryInvoiceWithNewPaymentMethod({ paymentMethodId, invoiceId }) {
    return (
      fetch('/retry-invoice', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          customerId: stripeCustomerId,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          console.log(error);
          // An error has happened. Display the failure to the user here.
          setSubscribing(false);
          setErrorToDisplay(error && error.error && error.error.decline_code);
          GAexception(`Retrieving invoice data failed: ${error.message}`);
        })
    );
  }

  function onSubscriptionComplete(result) {
    // console.log(result);
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    if (result && !result.subscription) {
      const subscription = { id: result.invoice.subscription };
      result.subscription = subscription;
      localStorage.clear();
    }

    setStripeAccInfo(result);
    handleSubscriptionComplete(result);
    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  function createSubscription({ paymentMethodId }) {
    return (
      fetch('/api/stripe/create-subscription', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
          'Authorization' : token
        },
        body: JSON.stringify({
          customerId: modeType === false ? stripeCustomerId : stripeCustomerTestId,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          quantity: quantity, 
          modeType: modeType
        }),
      })
        .then((response) => {
          const startData = {
            name: user.name, 
            email: user.email,
            membership: priceId
          }
          axios.post('/api/users/startMembership', startData)
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned
        // by Stripe. Add the addional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            subscription: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
          };
        })
        // Some payment methods require a customer to do additional
        // authentication with their financial institution.
        // Eg: 2FA for cards.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail. You will
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setSubscribing(false);
          setErrorToDisplay(error.message || error.error.decline_code);
          GAexception(`Creating a new subscription failed: ${error.message}`);
        })
    );
  }

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();

    setSubscribing(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // If a previous payment was attempted, get the lastest invoice
    const latestInvoicePaymentIntentStatus = localStorage.getItem(
      'latestInvoicePaymentIntentStatus'
    );

    // Use your card Element with other Stripe.js APIs
    
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: billingName
      }
    });

    if (error) {
      console.log('[createPaymentMethod error]', error);
      setSubscribing(false);
      setErrorToDisplay(error && error.message);
      return;
    }
    // console.log('[PaymentMethod]', paymentMethod);
    GAevent('Membership', 'Makes Payment', `${paymentMethod} used for payment. User: ${user.name} with user ID ${user.id}`);

    const paymentMethodId = paymentMethod.id;
    if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
      // Update the payment method and retry invoice payment
      const invoiceId = localStorage.getItem('latestInvoiceId');
      retryInvoiceWithNewPaymentMethod({
        paymentMethodId: paymentMethodId,
        invoiceId: invoiceId,
      });
      return;
    }

    // Create the subscription
    createSubscription({
      paymentMethodId: paymentMethodId,
    });

  };

  const onChange = (e) =>{
    let val = e.target.value;
    setBillingName(val);
  }

  return (
    <>
      <div className='text-blue'>
        <h3 className="title">Enter your card details.</h3>
        {!updateCard && <h3>Your subscription will start now.</h3>}
      </div>
      {!updateCard &&
        <>
          <h5 className='text-blue'>
            → Subscribing to{' '}
            <span className="default-color">{priceId === 'STARTER' ? 'PLUS' : priceId}</span>
          </h5>
        </>
      }
      <div className="w-full mt-4 text-blue">
        <div className="flex flex-wrap -mx-3 mb-2">
          <Col className="w-full px-3 md:mb-0 text-left">
            <h5 className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
              Full name
            </h5>
            <input
              className="paymentForm-input"
              id="name"
              type="text"
              onChange={onChange}
              placeholder="First and last name"
              required
            />
          </Col>
        </div>
        <form id="payment-form" onSubmit={handleSubmit}>
          <div className="flex flex-wrap -mx-3 mb-3 mt-3">
            <div className="w-full px-3 mb-0 text-left">
              <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                Card
              </label>
              <div
                className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded-md py-3 px-2 leading-tight focus:outline-none focus:bg-white"
                id="card-element"
              >
                <CardElement
                  options={{
                    style: {
                      base: {
                        fontSize: '16px',
                        color: '#32325d',
                        fontFamily:
                          '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                        '::placeholder': {
                          color: '#a0aec0',
                        },
                      },
                      invalid: {
                        color: '#9e2146',
                      },
                    },
                  }}
                />
              </div>
              <div className="text-gray-700 text-base mt-2" role="alert">
                {errorToDisplay ? errorToDisplay : null}
              </div>
            </div>
          </div>
          <Button id="submit-premium" className="btn-blue" type="submit" style={{maxWidth: '200px'}}>
            {subscribing ? 'Subscribing...' : 'Subscribe'}
          </Button>
        </form>
      </div>
    </>  
  );

};

const PaymentForm = (props) => {
  let modeEnv = ""
    if (props.modeType === false) {
      modeEnv = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
    } else {
      modeEnv = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST
    }
    const stripePromise = loadStripe(modeEnv);

  if (modeEnv === "") {
    console.error('**Stripe publishable key environment variable not set**');
    console.error(
      '**Add an environemnt variable REACT_APP_STRIPE_PUBLISHABLE_KEY**'
    );
    console.error('**Replace .env.example with .env and **');
  }
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm {...props} />
    </Elements>
  )
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // yourAction: () => dispatch(yourActionCreator()) // Dispatch your action here
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);

// export default PaymentForm;