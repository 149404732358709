import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import constant, { SUBSCRIBE_TIME_IN_SECONDS, SUBSCRIBE_SKIP_COUNT } from "../../shared/constant";
// core components
import { GAevent, GAexception, GApageView } from "../../shared/gaUtils";
// import Floater from 'react-floater';
import { logoutUser, updateUser, registerGuest } from "../../actions/authActions";
import { Box } from '@mui/system';
import Table from './Table';
import CardView from './CardView';
import AddIcon from '@mui/icons-material/Add';
import WindowIcon from '@mui/icons-material/Window';
import CloseIcon from '@mui/icons-material/Close';
import TocIcon from '@mui/icons-material/Toc';
import Button from "reactstrap/lib/Button";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Modal from '@mui/material/Modal';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';

import InputForm from '../component/InputForm';
import SelectForm from '../component/SelectForm';
import moment from "moment";

import { BrowserView } from 'react-device-detect';

const roles = [
  { value: 'Employee', label: 'Employee' },
  { value: 'Contractor', label: 'Contractor' },
  { value: 'Manager', label: 'Manager' }
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '600px',
  width: '100%',
  bgcolor: '#fff',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 3,
  p: 2,
  textAlign: 'center'
};

const token = localStorage.getItem('jwtToken');

// var randomEmail = require('random-email');

class Employees extends Component {

  constructor(props) {
    super(props);
    this.state = {
      subscribeQuestion: false,
      subscriptionText: '',
      employees: [],
      view: 'table',
      newEmployeeOpen: false,
      actionType: 'add',
      employee: {},
      name: "",
      nameInvalid: false,
      email: "",
      emailInvalid: false,
      phone: "",
      role: "Employee",
      walkthrough: false,
      confirmDialog: false,
      toastOpen: false,
      notificationText: "",
      notifyType: "error",
      employeeCount: 0
    }
  }

  intervalID = 0;

  // componentWillReceiveProps(nextProps, prevProps) {
  //   if (nextProps.auth.isAuthenticated && nextProps.auth.user.role === constant['ROLE']['GUEST']) {
  //     //Add employee
  //     this.onAddEmployee(nextProps);
  //     //Redirect to Commissions page
  //     this.props.props.history.push("/commissions");
  //   }
  // }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentDidMount() {
    document.body.classList.add("employee-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role === constant['ROLE']['EMPLOYEE'] || this.props.auth.user.role === constant['ROLE']['CONTRACTOR'] || this.props.auth.user.role === constant['ROLE']['MANAGER']) {
      this.props.history.push('/');
    } else {
      GApageView('Employees Page');
      // if(this.props.auth.user.role !== undefined) {
        this.getEmployees();
      // }
      if (this.props.auth.user.role === constant['ROLE']['OWNER'] ||
        this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) {
        GAevent('User', 'Employees Page', `User: ${this.props.auth.user.name} visited in`);
      } else if (this.props.auth.user.role === constant['ROLE']['GUEST']) {
        GAevent('Guest', 'Employees Page', `Guest user: ${this.props.auth.user.name} visited Employees page`);
      } 
      // else if (this.props.auth.user.role === undefined || this.props.auth.user.role === constant['ROLE']['GUEST']) {
      //   const visitedSite = localStorage.getItem("VisitedSite");
      //   if (visitedSite === null || visitedSite !== "yes") {
      //     localStorage.setItem("VisitedSite", "yes");
      //     this.setState({
      //       newEmployeeOpen: true,
      //       actionType: 'add',
      //       employee: {},
      //       name: "",
      //       nameInvalid: false,
      //       email: "",
      //       emailInvalid: false,
      //       phone: "",
      //       role: "Employee",
      //       walkthrough: true
      //     });
      //   } else {
      //     this.props.props.history.push("/login");
      //   }
      // }
    }
    if (this.props.auth.user.role === constant['ROLE']['GUEST']) {
      const walkThrough = localStorage.getItem("WalkThrough");
      if (walkThrough === null || walkThrough !== "Completed") {
        this.setState({ walkthrough: true });
      }
    }

    // if (this.props.auth.user.role !== undefined) {
      this.checkCancelSubscription();
    // }
    
  }

  componentWillUnmount() {
    document.body.classList.remove("employee-page");
    document.body.classList.remove("sidebar-collapse");
  }

  getEmployees() {
    axios
      .post('/api/users/employees', { userId: this.props.auth.user.id })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ employees: res.data, employeeCount: res.data.length }, () => {
            let user = this.props.auth.user;
            user.employeeCount = res.data.length;
            this.props.updateUser(user);
          });
        }
      }).catch((err) => {
        console.log(err);
      });
  }

  checkCancelSubscription = () => {
    axios
      .get('/api/users/' + this.props.auth.user.id)
      .then((res) => {
        if (res.data.subscribedDate) {
          var currentDate = moment(res.data.subscribedDate).add(3, 'months');
          if (!moment().isBefore(currentDate) && this.props.auth.user.level === 0) {
            this.cancelSubscription();
          }
        }
      }).catch((err) => {
        this.setState({
          toastOpen: true,
          notificationText: "Error occured whiling fetching user data",
          notifyType: "error"
        });
        GAexception(`Getting user data failed: ${err}`);
      });
  }

  cancelSubscription = () => {
    fetch('/api/stripe/cancel-subscription', {
      method: 'post',
      headers: {
        'Content-type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        subscriptionId: this.props.auth.user.stripeSubscriptionId,
        modeType: this.props.auth.modeType
      }),
    }).then((response) => {
      return response.json();
    })
      .then((response) => {
        const user = this.props.auth.user;
        user.stripeSubscriptionId = null;
        user.subscribedDate = null;
        this.props.updateUser(user);
        this.setState({ subscription: null });
      }).catch((err) => {
        console.log(err);
        GAexception(`Cancelling a subscription failed: ${err}`);
        this.setState({
          toastOpen: true,
          notificationText: 'Error occured while canceling your subscription',
          notifyType: "error"
        });
      });
  }

  removeEmployee(employee) {
    axios
      .post(`/api/users/deleteEmployee`, { userId: this.props.auth.user.id, employeeId: employee._id })
      .then((res) => {
        this.setState({
          toastOpen: true,
          notificationText: res.data.message,
          notifyType: "success"
        });
        this.getEmployees();
      }).catch((err) => {
        console.log(err);
      });
  }

  sendEmail(employee) {
    axios
      .post('/api/users/sendEmail', { userName: this.props.auth.user.name, companyName: this.props.auth.user.companyName, employee })
      .then((res) => {
        this.setState({
          toastOpen: true,
          notificationText: res.data && res.data.message,
          notifyType: "info"
        });
        if (this.props.auth.user.level === constant['MEMBERSHIP']['FREE_TRIAL'] || this.props.auth.user.role === constant['ROLE']['GUEST']) {
          GAevent('Guest', 'Employees Page', `Guest user: ${this.props.auth.user.name} sent an email to his employee`);
          this.props.history.push("/commissions");
        } else {
          GAevent('User', 'Employees Page', `User: ${this.props.auth.user.name} sent an email to his employee`);
        }
      }).catch((err) => {
        // console.log(err);
        this.setState({
          toastOpen: true,
          notificationText: err.message,
          notifyType: "error"
        });
      });
  }

  onView = (view) => {
    this.setState({
      view: view
    }, () => {
      console.log(view);
    });
  }

  newEmploymentModal = () => {
    this.setState({
      newEmployeeOpen: true,
      actionType: 'add',
      employee: {},
      name: "",
      nameInvalid: false,
      email: "",
      emailInvalid: false,
      phone: "",
      role: "Employee",
    });
  }

  onNewEmployee = () => {
    if (this.props.auth.user.role !== constant['ROLE']['SUPER_ADMIN']) {
      if (this.props.auth.user.level === -1) {
        if (this.state.employees.length >= 2) {
          this.setState({
            subscribeQuestion: true,
            subscriptionText: 'Just 99¢ for the first 7 days, then $39.95/mo for up to 5 users. Cancel Anytime',
          });
        } else {
          this.newEmploymentModal()
        }
      } else if ((this.props.auth.user.level === 0 || this.props.auth.user.level === 1)) {
        if (this.state.employees.length >= 5) {
          this.setState({
            subscribeQuestion: true,
            subscriptionText: 'Subscribe $89.95/mo plan to add more employees. Cancel Anytime',
          });
        } else {
          this.newEmploymentModal()
        }
        
      } else if (this.props.auth.user.level === 2) {
        if (this.state.employees.length >= 15) {
          this.setState({
            subscribeQuestion: true,
            subscriptionText: 'Subscribe $149.95/mo plan to add more employees. Cancel Anytime',
          });
        } else {
          this.newEmploymentModal()
        }
      } else if (this.props.auth.user.level === 3) {
        if (this.state.employees.length >= 30) {
          this.setState({
            subscribeQuestion: true,
            subscriptionText: 'Subscribe $449.95/mo plan to add more employees. Cancel Anytime',
          });
        } else {
          this.newEmploymentModal()
        }
      } else if (this.props.auth.user.level === 4) {
        this.newEmploymentModal()
      }
    } else {
      this.newEmploymentModal()
    }
    
  }

  onNewEmployeeForGuest = () => {
    this.setState({
      newEmployeeOpen: true,
      actionType: 'add',
      employee: {},
      name: "",
      nameInvalid: false,
      email: "",
      emailInvalid: false,
      phone: "",
      role: "Employee",
    });
  }

  handleClose = () => {
    this.setState({
      newEmployeeOpen: false
    });
  }

  onChange = e => {
    this.setState({ [e.id]: e.value });
  };

  handleSelection = (selectedCategory) => {
    this.setState({ role: selectedCategory.value });
  };

  getEmployeeDetail(userId) {
    axios
      .get(`/api/users/${userId}`)
      .then((res) => {
        this.setState({ employee: res.data });
      }).catch((err) => {
        console.log(err);
      });
  }

  validateForm = () => {
    return true;
  }

  onAddEmployee = () => {
    // if(!nextProps.auth.isAuthenticated) {
    //   this.createGuestUser();
    // } else {

      const { name, email } = this.state;

      if (name === '') {
        this.setState({
          nameInvalid: true
        })
      } else {
        this.setState({
          nameInvalid: false
        })
      }
      if (email === '') {
        this.setState({
          emailInvalid: true
        })
      } else {
        this.setState({
          emailInvalid: false
        })
      }

      if (this.state.actionType === 'add') {
        const employeeData = {
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          role: this.state.role,
          logo: this.props.auth.user.logo,
          companyName: this.props.auth.user.companyName,
          companyAddress: this.props.auth.user.companyName,
          parentId: this.props.auth.user.id,
        }

        if (name !== '' && email !== '') {
          axios
            .post('/api/users/addEmployee', { ...employeeData, userName: this.props.auth.user.name })
            .then((res) => {
              this.setState({
                toastOpen: true,
                notificationText: "You have successfully added new employee.",
                notifyType: "success"
              });
              if (this.props.auth.user.role === constant['ROLE']['GUEST']) {
                GAevent('Guest', 'Employee Page', `Guest user: ${this.props.auth.user.name} invited a new employee`);
              } else {
                GAevent('User', 'Employees Page', `User: ${this.props.auth.user.name}  invited a new employe`);
              }
              this.setState({
                newEmployeeOpen: false,
                actionType: 'add',
                employee: {},
                name: "",
                nameInvalid: false,
                email: "",
                emailInvalid: false,
                phone: "",
                role: "Employee",
              });
              this.getEmployees();
              const demoStatus = localStorage.getItem("demoStatus");
              if (demoStatus === 'yes') {
                this.props.history.push('/learn');
              }
            }).catch((err) => {
              this.setState({
                toastOpen: true,
                notificationText: err.response.data.email,
                notifyType: "error"
              });
              GAexception(`Creating a new employee failed: ${err}`);
            });
        }
      } else {
        const employeeData = {
          name: this.state.name,
          phone: this.state.phone,
          email: this.state.email,
          role: this.state.role,
          logo: this.state.employee.logo,
          companyName: this.state.employee.companyName,
          companyAddress: this.state.employee.companyName,
          parentId: this.state.employee.id,
        }

        if (name !== '' && email !== '') {
          let employeeObj = employeeData;
          employeeObj.parentId = this.state.employee.parentId;
          employeeObj.id = this.state.employee._id;
          axios
            .put(`/api/users/${this.state.employee._id}`, employeeObj)
            .then((res) => {
              this.setState({
                toastOpen: true,
                notificationText: "You have successfully updated employee.",
                notifyType: "success"
              });
              this.setState({
                newEmployeeOpen: false,
                actionType: 'add',
                employee: {},
                name: "",
                nameInvalid: false,
                email: "",
                emailInvalid: false,
                phone: "",
              });
              this.getEmployees();
            }).catch((err) => {
              console.log(err);
              GAexception(`Updating an employee failed: ${err}`);
            });
        }
      }
      
    // }
  }

  onEdit = (data) => {
    this.setState({
      newEmployeeOpen: true,
      actionType: 'edit',
      employee: data,
      name: data.name,
      email: data.email,
      phone: data.phone,
      role: data.role
    })
  }

  onDelete = (data) => {
    this.setState({
      employee: data,
      confirmDialog: true
    })
  }

  confirmHandleClose = () => {
    this.setState({
      confirmDialog: false,
      employee: {}
    })
  }

  agreeDelete = () => {
    this.removeEmployee(this.state.employee);
    this.confirmHandleClose();
  }

  onEmail = (data) => {
    this.sendEmail(data);
  }

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  handleClose = () => {
    this.setState({
      toastOpen: false
    });
  };

  goToSubscribe = () => {
    this.props.history.push("/account");
  }

  noSubscribe = () => {
    let subSkippedCount = localStorage.getItem("SubSkippedCount");
    if (subSkippedCount === null) subSkippedCount = 1;
    else subSkippedCount = parseInt(subSkippedCount) + 1;
    localStorage.setItem("SubSkippedCount", subSkippedCount);
    this.setState({
      subscribeQuestion: false
    });
  }

  onClickSetCommission = () => {
    if (this.state.employeeCount === 0) {
      this.setState({
        toastOpen: true,
        notificationText: 'You don not have any employees, create one now.',
        notifyType: "info"
      });
    } else {
      this.props.history.push("/commissions");
    }
  }

  // createGuestUser = () => {
  //   const guestName = this.props.props.location.pathname === '/demo' ? 'Home' : 'App'
  //   const guest = {
  //     name: guestName + parseInt(Math.random(100000) * 100),
  //     email: randomEmail(),
  //     phone: '1111111111',
  //     password: '12345678',
  //     companyName: null,
  //     companyAddress: '',
  //     role: constant['ROLE']['GUEST'],
  //     parentId: ''
  //   };
  //   this.props.registerGuest(guest);
  // }

  render() {
    const {
      employees,
      view,
      newEmployeeOpen,
      confirmDialog,
      toastOpen,
      notificationText,
      notifyType,
      subscriptionText
    } = this.state;

    return (
      <>
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={notifyType} sx={{ width: '100%', fontSize: 24 }}>
            {notificationText}
          </Alert>
        </Snackbar>
        <div className="employee-page">
          <div className="header display-flex justify-between align-center">
            <div className="layout-toggle">
              <BrowserView>
                <span className={view === "table" ? "active-icon" : ""} onClick={() => this.onView('table')}>
                  <TocIcon fontSize={'large'} />
                </span>
                <span className={view === "card" ? "active-icon" : ""} onClick={() => this.onView('card')}>
                  <WindowIcon />
                </span>
              </BrowserView>
            </div>
              <div className="btn-blue" onClick={this.onNewEmployee}>
                <AddIcon />
                <span>New Employee</span>
              </div>
            {/* } */}
          </div>
          {
            view === 'table' && <div className="table-view">
              <Table employees={employees} history={this.props.history} onEdit={(data) => this.onEdit(data)} onDelete={(data) => this.onDelete(data)} onEmail={(data) => this.onEmail(data)} />
            </div>
          }
          <div className={view === "card" ? "card-view" : "card-view-mobile"}>
            <CardView employees={employees} history={this.props.history} onEdit={(data) => this.onEdit(data)} onDelete={(data) => this.onDelete(data)} onEmail={(data) => this.onEmail(data)} addNew={this.onNewEmployee} />
          </div>
          {
            employees.length > 0 && <div className="header display-flex justify-end align-center" style={{ marginTop: '2rem' }}>
              <div className="btn-blue" onClick={this.onClickSetCommission}>
                <ArrowRightAltIcon />
                <span>Set Commission</span>
              </div>
            </div>
          }
        </div>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={newEmployeeOpen}
        >
          <DialogContent>
            <div className="new-employee-dialog">
              <div className="dialog-title">
                <span>Enter Employee or Contractor</span>
                <IconButton onClick={() => { this.setState({ newEmployeeOpen: false }) }}>
                  <CloseIcon />
                </IconButton>
              </div>
              <div style={{ height: '40px' }}></div>
              <InputForm
                label={'Name'}
                id={'name'}
                errors={this.state.nameInvalid ? true : false}
                onChange={ev => this.onChange(ev)}
                value={this.state.name}
              />
              <div style={{ height: '40px' }}></div>
              <InputForm
                label={'Email'}
                id={'email'}
                errors={this.state.emailInvalid ? true : false}
                onChange={ev => this.onChange(ev)}
                disabled={this.state.actionType === 'add' ? false : this.state.email !== '' ? true : false}
                value={this.state.email}
              />
              <div style={{ height: '40px' }}></div>
              <InputForm
                label={'Phone'}
                id={'phone'}
                // errors={this.state.emailInvalid ? true : false }
                onChange={ev => this.onChange(ev)}
                value={this.state.phone}
              />
              <div style={{ height: '40px' }}></div>
              <SelectForm
                options={roles}
                onChange={(ev) => this.handleSelection(ev)}
                defaultValue={this.state.role}
              />
              <div style={{ height: '40px' }}></div>
              {/* {this.props.auth.user.role === undefined && this.state.walkthrough ?
                <Floater
                  autoOpen={true}
                  showCloseButton={true}
                  styles={{
                    arrow: {
                      color: '#4A4C5D'
                    },
                    close: {
                      color: 'white'
                    },
                    container: {
                      backgroundColor: '#4A4C5D',
                    }
                  }}
                  content={
                    <div>
                      <h3 className="mt-2" style={{ color: 'white' }}>Create Your First Employee</h3>
                      <h5 style={{ color: 'white' }}>
                        Click + Add Employees
                      </h5>
                      <div style={{ textAlign: 'end' }}>
                        <Button style={{ fontWeight: '500', backgroundColor: '#0094CF', fontSize: '1em', color: '#fff' }} onClick={() => { this.setState({ walkthrough: false }) }}>Got it</Button>
                      </div>
                    </div>
                  }
                  target=".btn-blue-add-employee"
                  placement="right"
                >
                  <div className="btn-blue-add-employee" onClick={() => this.onAddEmployee(this.props)}>
                    <AddIcon />
                    {
                      this.state.actionType === "add" ? <span>Add Employee</span> : <span>Save Employee</span>
                    }
                  </div>
                </Floater> : */}
                <div className="btn-blue-add-employee" onClick={this.onAddEmployee}> 
                  <AddIcon />
                  {
                    this.state.actionType === "add" ? <span>Add Employee</span> : <span>Save Employee</span>
                  }
                </div>
              {/* } */}
            </div>
          </DialogContent>
        </Dialog>
        <Modal
          open={confirmDialog}
          onClose={() => this.confirmHandleClose()}
        >
          <Box sx={style}>
            <div className="dialog-title-2">Delete this Employee and all related data forever?</div>
            <hr />
            <div className="display-flex justify-end align-center" style={{ marginTop: '12px' }}>
              <div className="btn-blue" style={{ fontSize: '14px', marginRight: '12px' }} onClick={() => this.confirmHandleClose()}>No</div>
              <div className="btn-blue" style={{ fontSize: '14px' }} onClick={() => this.agreeDelete()}>Yes</div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={this.state.subscribeQuestion}
          onClose={() => this.setState({subscribeQuestion: false})}
        >
          <Box sx={style}>
            <h2 className="mt-4 mb-2 mx-2 font-bold" style={{ color: '#22395B' }}>Welcome to CommissionPayPro!</h2>
            <h4 className="mt-2 mb-4 mx-2" style={{ color: '#7B8FB2' }}>
              {
                subscriptionText
              }
            </h4>
            <div className="display-flex justify-center align-center mb-4">
              <div className="btn-blue mr-2" style={{ padding: '8px 18px' }} onClick={this.goToSubscribe}>
                Subscribe
              </div>
              {/* {(!localStorage.getItem("SubSkippedCount") || parseInt(localStorage.getItem("SubSkippedCount")) < SUBSCRIBE_SKIP_COUNT) && !this.props.auth.user.stripeCustomerId &&
                <div className="cancel-btn ml-2" style={{ padding: '8px 18px' }} onClick={this.noSubscribe}>
                  Skip for Now
                </div>
              } */}
            </div>
          </Box>
        </Modal>
      </>
    );
  }
}

Employees.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  registerGuest: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { logoutUser, updateUser, registerGuest }
)(Employees);