import React, { Component } from "react";
import axios from "axios";
import _ from 'lodash';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import constant, { SUBSCRIBE_TIME_IN_SECONDS, SUBSCRIBE_SKIP_COUNT } from "../../shared/constant";
import { GApageView, GAevent } from "../../shared/gaUtils";
import html2pdf from "html2pdf.js";

import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '600px',
  width: '100%',
  bgcolor: '#fff',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 3,
  textAlign: 'center',
  padding: 2
};

const moment = require('moment');

class CustomerReport extends Component {

  constructor(props) {
    super(props);
    this.state = {
      employeePayouts: [],
      customers: [],
      errors: {},
      employees: [],
      customer: {},
      subscribeQuestion: false,
      subscriptionText: '',
    }
  }

  intervalID = 0;

  componentDidMount() {
    document.body.classList.add("customer-report-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role === constant['ROLE']['OWNER'] || this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) {
      this.getPayrollData();
      this.getEmployees();
      GApageView('Customer Report Page');
      GAevent('User', 'Customer Report', `User: ${this.props.auth.user.name}  visited Customer Report page`);
    } else {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("customer-report-page");
    document.body.classList.remove("sidebar-collapse");
    clearInterval(this.intervalID);
  }

  getEmployees() {
    axios
      .post('/api/users/employees', { userId: this.props.auth.user.id })
      .then((res) => {
        if (res.status === 200) {
          const employees = res.data.map((employee) => {
            return { id: employee._id, name: employee.name }
          })
          this.setState({ employees: employees });
        }
      }).catch((err) => {
        console.log(err);
      });
  }

  getPayrollData = () => {
    const requests = [];
    requests.push(axios.post('/api/commissions/commissions',
      {
        userId: this.props.auth.user.id,
      }));
    axios.all(requests).then(axios.spread((...responses) => {
      // Parse Commission Data
      let employeePayouts = [];
      responses[0].data.forEach(commission => {
        commission.employeePayouts.forEach((employeePayoutDetail) => {
          employeePayoutDetail.payouts.forEach((payoutDetailObj) => {
            let totalPaid = 0;
            payoutDetailObj.transactions.forEach((transaction) => {
              totalPaid += transaction.amountPaid;
            });
            const payoutObj = {
              employeeName: employeePayoutDetail.employee.name,
              total: payoutDetailObj.total,
              owed: payoutDetailObj.total - totalPaid,
              transactions: payoutDetailObj.transactions,
              customerName: payoutDetailObj.customerName,
              title: commission.title,
              date: payoutDetailObj.date,
              grossIncome: payoutDetailObj.grossIncome,
            }
            employeePayouts.push(payoutObj);
          });
        });
      });

      const sortedPayouts = employeePayouts.sort((payoutA, payoutB) => {
        var nameA = payoutA.customerName.toUpperCase();
        var nameB = payoutB.customerName.toUpperCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
      const customers = [...new Set(_.map(sortedPayouts, 'customerName'))];
      let customerList = [];
      customers.forEach((customer) => {
        customerList.push({ label:customer, value: customer })
      });

      this.setState({ employeePayouts: sortedPayouts, customers: customerList });
    })).catch((err) => {
      console.log(err);
    });
  }

  getPdf = () => {
    const element = document.getElementById('customer-report');
    let clonedElement = element.cloneNode(true);
    let hideList = clonedElement.getElementsByClassName('hide-printing');
    for (let i = hideList.length - 1; i >= 0; i--) {
      let childNode = hideList[i];
      childNode.parentNode.removeChild(childNode);
    }
    const opt = {
      margin: [0.1, 0.1, 0.1, 0.1],
      filename: 'customer-report.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 2,
        letterRendering: true,
        scrollY: 0
      },
      jsPDF: { orientation: 'landscape', unit: 'in', format: 'a4' },
      pagesplit: true,
      putOnlyUsedFonts: true,
      // pagebreak: { mode: 'avoid-all' }
    };
    html2pdf().set(opt).from(clonedElement).save().then((res) => {
      clonedElement.remove();
    }).catch((err) => {
      console.log("RESULT ->", err);
      clonedElement.remove();
    });
  }

  handleSelectChange(customer) {
    this.setState({ customer: customer });
  }

  payrollHistoryElement(payoutDetail, index = 0) {
    const elementPayoutDetail =
      (
        <>
          <div className="record">
            <div className="left">
              <div className="field">
                <div className="font-bold">Customer:</div>
                <div>{payoutDetail.customerName}</div>
              </div>
              <div className="field">
                <div className="font-bold">Employee:</div>
                <div>{payoutDetail.employeeName}</div>
              </div>
              <div className="field">
                <div className="font-bold">Date:</div>
                <div>{moment(payoutDetail.date).format('MM/DD/YYYY')}</div>
              </div>
              <div className="field">
                <div className="font-bold">Title:</div>
                <div>{payoutDetail.title}</div>
              </div>
              <div className="field">
                <div className="font-bold">{payoutDetail.type === 'Expense' ? 'Expense' : 'Gross'}:</div>
                <div>{payoutDetail.grossIncome.toFixed(2)}</div>
              </div>
              <div className="field">
                <div className="font-bold">Owed:</div>
                <div>{parseFloat(payoutDetail.owed).toFixed(2)}</div>
              </div>
              <div className="field">
                <div className="font-bold">Total:</div>
                <div>{(payoutDetail.total - payoutDetail.owed).toFixed(2)}</div>
              </div>
            </div>
            <div className="right">
              <div className="headers">
                <div>Paid On</div>
                <div>Paid By</div>
                <div>Amount Paid</div>
              </div>
              <div className="records">
                {
                  payoutDetail.transactions.map((transaction, index) => {
                    return <>
                            <div className="value">
                              <div>{moment(transaction.datePaid).format('MM/DD/YYYY')}</div>
                              <div>{transaction.paidBy}</div>
                              <div>{transaction.amountPaid.toFixed(2)}</div>
                            </div>
                            <div className="value-mobile">
                              <div className="field">
                                <div>Paid On:</div>
                                <div>{moment(transaction.datePaid).format('MM/DD/YYYY')}</div>
                              </div>
                              <div className="field">
                                <div>Paid By:</div>
                                <div>{transaction.paidBy}</div>
                              </div>
                              <div className="field">
                                <div>Amount Paid:</div>
                                <div>{transaction.amountPaid.toFixed(2)}</div>
                              </div>
                            </div>
                          </>
                  })
                }
              </div>
            </div>
          </div>
        </>
      )
    return elementPayoutDetail;
  }

  renderPayoutsData() {
    console.log("first")
    let payrollHistory = [];
    if (this.state.customer.value) {
      const payoutDetails = _.filter(this.state.employeePayouts, (payout) => { return payout.customerName === this.state.customer.value });
      if (!payoutDetails || payoutDetails.length === 0) {
        return (
          <h4>No Data Found</h4>
        )
      } else {
        let tempEmployeePayouts = payoutDetails.sort(function (a, b) {
          return new Date (b.date) - new Date(a.date)
        })
        tempEmployeePayouts.forEach((payoutDetail) => {
          payrollHistory.push(this.payrollHistoryElement(payoutDetail, 0));
        });
      }

    } else {
      let tempEmployeePayouts = this.state.employeePayouts.sort(function (a, b) {
        return new Date (b.date) - new Date(a.date)
      })
      tempEmployeePayouts.forEach((payoutDetail, index) => {
        payrollHistory.push(this.payrollHistoryElement(payoutDetail, index));
      });
    }

    return payrollHistory;
  }


  goToSubscribe = () => {
    this.props.history.push("/account");
  }

  noSubscribe = () => {
    let subSkippedCount = localStorage.getItem("SubSkippedCount");
    if (subSkippedCount === null) subSkippedCount = 1;
    else subSkippedCount = parseInt(subSkippedCount) + 1;
    localStorage.setItem("SubSkippedCount", subSkippedCount);
    this.setState({
      subscribeQuestion: false
    });
  }

  render() {
    const { subscriptionText } = this.state;
    return (
      <>
        <div className="customer-report-page" id="customer-report">
          <div className="top-content">
            <div className="check-date">
              <div className="title">Transactions by Customer</div>
              <div className="date-range">
                <div className={`label ${!this.state.customer ? 'hide-printing' : ''}`}>Select A Customer</div>
                <div className={`calendar ${!this.state.customer ? 'hide-printing' : ''}`}>
                  <Select
                    className="react-select react-select-info"
                    classNamePrefix="react-select"
                    placeholder="Customer"
                    value={this.state.customer}
                    onChange={value => this.handleSelectChange(value)}
                    options={this.state.customers}
                  ></Select>
                </div>
              </div>
              <div className="actions hide-printing">
                <div className="btn-blue" onClick={this.getPayrollData}>
                  Get Report
                </div>
                <div className="btn-blue" onClick={this.getPdf}>
                  Print
                </div>
              </div>
            </div>
            <div className="payroll">
              <div className="title">All Transactions</div>
              <div className="payroll-table">
                {this.state.employeePayouts.length > 0 ?
                  this.renderPayoutsData()
                  :
                  <h4>No Payroll Data Found</h4>
                }
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.subscribeQuestion}
        >
          <Box sx={style}>
            <h2 className="mt-4 mb-2 mx-2 font-bold" style={{ color: '#22395B' }}>Welcome to CommissionPayPro!</h2>
            <h4 className="mt-2 mb-4 mx-2" style={{ color: '#7B8FB2' }}>
              {
                subscriptionText
              }
            </h4>
            <div className="display-flex justify-center align-center mb-4">
              <div className="btn-blue mr-2" style={{ padding: '8px 18px' }} onClick={this.goToSubscribe}>
                Subscribe
              </div>
              {/* {(!localStorage.getItem("SubSkippedCount") || parseInt(localStorage.getItem("SubSkippedCount")) < SUBSCRIBE_SKIP_COUNT) && !this.props.auth.user.stripeCustomerId &&
                <div className="cancel-btn ml-2" style={{ padding: '8px 18px' }} onClick={this.noSubscribe}>
                  Skip for Now
                </div>
              } */}
            </div>
          </Box>
        </Modal>
      </>
    )
  }
}

CustomerReport.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps
)(CustomerReport)