/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function Footer() {
  return (
    <>
      <footer className="footer">
        <Container>
          <nav>
            <ul>
              <li>
                <a
                  href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit?usp=sharing"
                  target="_blank"
                >
                  TERMS OF SERVICE
                </a>
              </li>
              <li>
                <a
                  href="/contact-us"
                  target="_self"
                >
                  CONTACT US
                </a>
              </li>
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © 2020, Published by{" "}
            <a
              href=""
              target="_blank"
            >
              My Market Partners
            </a>
            .
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
