import React, { Component } from "react";
import axios from "axios";
import _ from 'lodash';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import ReactDatetime from "react-datetime";
import Select from "react-select";
import constant, { SUBSCRIBE_TIME_IN_SECONDS, SUBSCRIBE_SKIP_COUNT } from "../../shared/constant";
import { GApageView, GAevent } from "../../shared/gaUtils";
import html2pdf from "html2pdf.js";
import calendar_icon from '../../assets/images/icons/calendar-icon.png';

import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '600px',
  width: '100%',
  bgcolor: '#fff',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 3,
  textAlign: 'center',
  padding: 2
};

const moment = require('moment');

class PayrollHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      employeePayouts: [],
      totalsPaidByEmployees: [],
      errors: {},
      employees: [],
      employee: { id: null, name: 'Employee' },
      dateFrom: moment().subtract(30, 'd').format('MM/DD/YYYY'),
      dateTo: moment().format('MM/DD/YYYY'),
      subscribeQuestion: false,
      subscriptionText: '',
    }
  }

  intervalID = 0;

  componentDidMount() {
    document.body.classList.add("payroll-history-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role === constant['ROLE']['OWNER'] || this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) {
      this.getPayrollData();
      this.getEmployees();
      GApageView('Payroll History Page');
      GAevent('User', 'Payroll History Report', `User: ${this.props.auth.user.name}  visited Payroll History page`);
    } else {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("payroll-history-page");
    document.body.classList.remove("sidebar-collapse");
    clearInterval(this.intervalID);
  }

  getEmployees() {
    axios
      .post('/api/users/employees', { userId: this.props.auth.user.id })
      .then((res) => {
        if (res.status === 200) {
          const employees = res.data.map((employee) => {
            return { id: employee._id, name: employee.name }
          })
          this.setState({ employees: employees });
        }
      }).catch((err) => {
        console.log(err);
      });
  }

  getPayrollData = () => {
    const requests = [];
    requests.push(axios.post('/api/commissions/commissions',
      {
        userId: this.props.auth.user.id,
        dateFrom: moment(this.state.dateFrom).utc(),
        dateTo: moment(this.state.dateTo).endOf('day').utc()
      }));
    requests.push(axios.post('/api/expenses/expenses',
      {
        userId: this.props.auth.user.id,
        dateFrom: moment(this.state.dateFrom).utc(),
        dateTo: moment(this.state.dateTo).endOf('day').utc()
      }));
    axios.all(requests).then(axios.spread((...responses) => {
      // Parse Commission Data
      let employeePayouts = [];
      responses[0].data.forEach(commission => {
        commission.employeePayouts.forEach((employeePayoutDetail) => {
          employeePayoutDetail.payouts.forEach((payoutDetailObj) => {
            if (payoutDetailObj.transactions.length === 0) {
              return;
            }
            let totalPaid = 0;
            payoutDetailObj.transactions.forEach((transaction) => {
              totalPaid += transaction.amountPaid;
            });
            const payoutObj = {
              employeeId: employeePayoutDetail.employee.id,
              employeeName: employeePayoutDetail.employee.name,
              total: payoutDetailObj.total,
              owed: payoutDetailObj.total - totalPaid,
              transactions: payoutDetailObj.transactions,
              customerName: payoutDetailObj.customerName,
              title: commission.title,
              date: payoutDetailObj.date,
              grossIncome: payoutDetailObj.grossIncome,
              type: 'Payout'
            }
            employeePayouts.push(payoutObj);
          });
        });
      });
      // Parse Expense Data
      const expenses = _.filter(responses[1].data, { parentId: this.props.auth.user.id });
      expenses.forEach((expense) => {
        if (expense.transactions.length === 0) {
          return;
        }
        let totalPaid = 0;
        expense.transactions.forEach((transaction) => {
          totalPaid += transaction.amountPaid;
        });
        const payoutObj = {
          employeeId: expense.employeeId,
          employeeName: expense.employeeName,
          total: expense.total,
          owed: expense.total - totalPaid.toFixed(2),
          transactions: expense.transactions,
          customerName: 'Expense',
          date: expense.date,
          grossIncome: expense.total,
          title: 'Expense',
          type: 'Expense'
        }
        employeePayouts.push(payoutObj);
      });

      const payoutsByEmployees = _.groupBy(employeePayouts, 'employeeId');
      let totalsPaidByEmployees = [];
      _.mapKeys(payoutsByEmployees, (payoutDetails, employeeId) => {
        let totalPaid = 0;
        let expenseTotalPaid = 0;
        payoutDetails.forEach((payoutDetail) => {
          if (payoutDetail.type === 'Payout') {
            totalPaid += (payoutDetail.total - payoutDetail.owed);
          } else {
            expenseTotalPaid += (payoutDetail.total - payoutDetail.owed);
          }
        });
        if (totalPaid > 0) {
          totalsPaidByEmployees.push({
            employeeId: payoutDetails[0].employeeId,
            employeeName: payoutDetails[0].employeeName,
            totalPaid: totalPaid,
            reimbursements: expenseTotalPaid,
            grossPaid: totalPaid + expenseTotalPaid
          });
        }
      });

      const sortedEmployeePayouts = employeePayouts.sort((payoutA, payoutB) => {
        return new Date(payoutB.date) - new Date(payoutA.date);
      });

      const sortedTotalsPaidByEmployees = totalsPaidByEmployees.sort((totalA, totalB) => {
        return new Date(totalB.date) - new Date(totalA.date);
      });
      this.setState({ employeePayouts: sortedEmployeePayouts, totalsPaidByEmployees: sortedTotalsPaidByEmployees });
    })).catch((err) => {
      console.log(err);
    });
  }

  getPdf = () => {
    const element = document.getElementById('payroll-history-report');
    let clonedElement = element.cloneNode(true);
    let hideList = clonedElement.getElementsByClassName('hide-printing');
    for (let i = hideList.length - 1; i >= 0; i--) {
      let childNode = hideList[i];
      childNode.parentNode.removeChild(childNode);
    }
    const opt = {
      margin: [0.1, 0.1, 0.1, 0.1],
      filename: 'payroll-history-report.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 2,
        letterRendering: true,
        scrollY: 0
      },
      jsPDF: { orientation: 'landscape', unit: 'in', format: 'a4' },
      pagesplit: true,
      putOnlyUsedFonts: true,
      // pagebreak: { mode: 'avoid-all' }
    };
    html2pdf().set(opt).from(clonedElement).save().then((res) => {
      clonedElement.remove();
    }).catch((err) => {
      console.log("RESULT ->", err);
      clonedElement.remove();
    });
  }

  handleDateFromChange = (date) => {
    this.setState({ dateFrom: moment(date).format('MM/DD/YYYY') });
  }

  handleDateToChange = (date) => {
    this.setState({ dateTo: moment(date).format('MM/DD/YYYY') });
  }

  handleSelectChange(employee) {
    this.setState({ employee: employee });
  }

  payrollHistoryElement(payoutDetail, index = 0) {
    const elementPayoutDetail =
      (
        <>
          <div className="record">
            <div className="left">
              <div className="field">
                <div className="font-bold">Employee:</div>
                <div>{payoutDetail.employeeName}</div>
              </div>
              <div className="field">
                <div className="font-bold">Date:</div>
                <div>{moment(payoutDetail.date).format('MM/DD/YYYY')}</div>
              </div>
              <div className="field">
                <div className="font-bold">Title:</div>
                <div>{payoutDetail.title}</div>
              </div>
              <div className="field">
                <div className="font-bold">Customer:</div>
                <div>{payoutDetail.customerName}</div>
              </div>
              <div className="field">
                <div className="font-bold">{payoutDetail.type === 'Expense' ? 'Expense' : 'Gross'}:</div>
                <div>{payoutDetail.grossIncome.toFixed(2)}</div>
              </div>
              <div className="field">
                <div className="font-bold">Owed:</div>
                <div>{parseFloat(payoutDetail.owed).toFixed(2)}</div>
              </div>
              <div className="field">
                <div className="font-bold">Total:</div>
                <div>{(payoutDetail.total - payoutDetail.owed).toFixed(2)}</div>
              </div>
            </div>
            <div className="right">
              <div className="headers">
                <div>Paid On</div>
                <div>Paid By</div>
                <div>Amount Paid</div>
              </div>
              <div className="records">
                {
                  payoutDetail.transactions.map((transaction, index) => {
                    return <>
                            <div className="value">
                              <div>{moment(transaction.datePaid).format('MM/DD/YYYY')}</div>
                              <div>{transaction.paidBy}</div>
                              <div>{transaction.amountPaid.toFixed(2)}</div>
                            </div>
                            <div className="value-mobile">
                              <div className="field">
                                <div>Paid On:</div>
                                <div>{moment(transaction.datePaid).format('MM/DD/YYYY')}</div>
                              </div>
                              <div className="field">
                                <div>Paid By:</div>
                                <div>{transaction.paidBy}</div>
                              </div>
                              <div className="field">
                                <div>Amount Paid:</div>
                                <div>{transaction.amountPaid.toFixed(2)}</div>
                              </div>
                            </div>
                          </>
                  })
                }
              </div>
            </div>
          </div>
        </>
      )
    return elementPayoutDetail;
  }

  renderPayoutsData() {
    let payrollHistory = [];
    if (this.state.employee.id) {
      const payoutDetails = _.filter(this.state.employeePayouts, { employeeId: this.state.employee.id });
      if (!payoutDetails || payoutDetails.length === 0) {
        return (
          <h4>No Data Found</h4>
        )
      } else {
        payoutDetails.forEach((payoutDetail) => {
          payrollHistory.push(this.payrollHistoryElement(payoutDetail, 0));
        });
      }

    } else {
      this.state.employeePayouts.forEach((payoutDetail, index) => {
        payrollHistory.push(this.payrollHistoryElement(payoutDetail, index));
      });
    }

    return payrollHistory;
  }

  renderTotalsByEmployeeData() {
    if (this.state.employee.id) { // Filter totalPaidByEmployees if employee selected
      const totalPaidByEmployee = _.find(this.state.totalsPaidByEmployees, { employeeId: this.state.employee.id });
      if (totalPaidByEmployee) {
        return (
          <>
          <div className="records">
            <div className="record">
              <div>{totalPaidByEmployee.employeeName}</div>
              <div>{parseFloat(totalPaidByEmployee.totalPaid).toFixed(2)}</div>
              <div>{parseFloat(totalPaidByEmployee.reimbursements).toFixed(2)}</div>
              <div>{parseFloat(totalPaidByEmployee.grossPaid).toFixed(2)}</div>
            </div>
            <div className="record-mobile">
              <div className="mobile-field">
                <div>Employee:</div>
                <div>{totalPaidByEmployee.employeeName}</div>
              </div>
              <div className="mobile-field">
                <div>Total Paid:</div>
                <div>{parseFloat(totalPaidByEmployee.totalPaid).toFixed(2)}</div>
              </div>
              <div className="mobile-field">
                <div>Expenses:</div>
                <div>{parseFloat(totalPaidByEmployee.reimbursements).toFixed(2)}</div>
              </div>
              <div className="mobile-field">
                <div>Gross Pay:</div>
                <div>{parseFloat(totalPaidByEmployee.grossPaid).toFixed(2)}</div>
              </div>
            </div>
          </div>
          </>
        )
      } else {
        return (
          <h4>No Data Found</h4>
        )
      }
    }
    return this.state.totalsPaidByEmployees.map((data) => {
      return (
        <>
          <div className="records">
            <div className="record">
              <div>{data.employeeName}</div>
              <div>{parseFloat(data.totalPaid).toFixed(2)}</div>
              <div>{data.reimbursements.toFixed(2)}</div>
              <div>{parseFloat(data.grossPaid).toFixed(2)}</div>
            </div>

            <div className="record-mobile">
              <div className="mobile-field">
                <div>Employee:</div>
                <div>{data.employeeName}</div>
              </div>
              <div className="mobile-field">
                <div>Total Paid:</div>
                <div>{parseFloat(data.totalPaid).toFixed(2)}</div>
              </div>
              <div className="mobile-field">
                <div>Expenses:</div>
                <div>{data.reimbursements.toFixed(2)}</div>
              </div>
              <div className="mobile-field">
                <div>Gross Pay:</div>
                <div>{parseFloat(data.grossPaid).toFixed(2)}</div>
              </div>
            </div>
          </div>
        </>
      )
    });
  }

  goToSubscribe = () => {
    this.props.history.push("/account");
  }

  noSubscribe = () => {
    let subSkippedCount = localStorage.getItem("SubSkippedCount");
    if (subSkippedCount === null) subSkippedCount = 1;
    else subSkippedCount = parseInt(subSkippedCount) + 1;
    localStorage.setItem("SubSkippedCount", subSkippedCount);
    this.setState({
      subscribeQuestion: false
    });
  }

  render() {
    const { subscriptionText } = this.state;
    return (
      <>
        <div className="payroll-page" id="payroll-history-report">
          <div className="top-content">
            <div className="check-date">
              <div className="title">Pay Period</div>
              <div className="date-range">
                <div className="label">Date from:</div>
                <div className="calendar">
                  <div>
                    <img src={calendar_icon} alt="calendar icon" />
                  </div>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Datetime Picker Here",
                      value: this.state.dateFrom
                    }}
                    closeOnSelect={true}
                    timeFormat={false}
                    onChange={this.handleDateFromChange}
                  ></ReactDatetime>
                </div>
                <div className="label">Date to</div>
                <div className="calendar">
                  <div>
                    <img src={calendar_icon} alt="calendar icon" />
                  </div>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Datetime Picker Here",
                      value: this.state.dateTo,
                    }}
                    closeOnSelect={true}
                    timeFormat={false}
                    onChange={this.handleDateToChange}
                  ></ReactDatetime>
                </div>
                <div className={`label ${!this.state.employee.id ? 'hide-printing' : ''}`}>Select An Employee</div>
                <div className={`calendar ${!this.state.employee.id ? 'hide-printing' : ''}`}>
                  <Select
                    className="react-select react-select-info"
                    classNamePrefix="react-select"
                    placeholder="Employee"
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    onChange={value => this.handleSelectChange(value)}
                    options={this.state.employees}
                  ></Select>
                </div>
              </div>
              <div className="actions hide-printing">
                <div className="btn-blue" onClick={this.getPayrollData}>
                  Get Report
                </div>
                <div className="btn-blue" onClick={this.getPdf}>
                  Print
                </div>
              </div>
            </div>
            <div className="totals-by-employee">
              <div className="title">Totals By Employee</div>
              <div className="employee-table">
                <div className="headers">
                  <div>Employee</div>
                  <div>Total Paid</div>
                  <div>Expenses</div>
                  <div>Gross Pay</div>
                </div>
                {this.state.totalsPaidByEmployees.length > 0 ?
                  this.renderTotalsByEmployeeData()
                  :
                  <h4>No Payroll Data Found</h4>
                }
              </div>
            </div>
          </div>
          <div className="bottom-content">
            <div className="payroll">
              <div className="title">
                <div>Payroll</div>
                {/* <div className="btn-blue">Edit</div> */}
              </div>
              <div className="payroll-table">
                {
                  this.renderPayoutsData()
                }
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.subscribeQuestion}
        >
          <Box sx={style}>
            <h2 className="mt-4 mb-2 mx-2 font-bold" style={{ color: '#22395B' }}>Welcome to CommissionPayPro!</h2>
            <h4 className="mt-2 mb-4 mx-2" style={{ color: '#7B8FB2' }}>
              {
                subscriptionText
              }
            </h4>
            <div className="display-flex justify-center align-center mb-4">
              <div className="btn-blue mr-2" style={{ padding: '8px 18px' }} onClick={this.goToSubscribe}>
                Subscribe
              </div>
              {/* {(!localStorage.getItem("SubSkippedCount") || parseInt(localStorage.getItem("SubSkippedCount")) < SUBSCRIBE_SKIP_COUNT) && !this.props.auth.user.stripeCustomerId &&
                <div className="cancel-btn ml-2" style={{ padding: '8px 18px' }} onClick={this.noSubscribe}>
                  Skip for Now
                </div>
              } */}
            </div>
          </Box>
        </Modal>
      </>
    )
  }
}

PayrollHistory.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps
)(PayrollHistory)