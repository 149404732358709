
module.exports = {
  OOPS_ERROR: "Oops!! Something went wrong",
  REQUEST_OK: "The request is OK",
  AUTH_FAIL: "Authentication failed",

  'VALIDATION_MSG': {

  },
  
  'POPUP_MSG': {
    'VERIFY_EMAIL': '',
  },

  "STRIPE_MEMBERSHIP": {
    'STARTER': "price_1KCQNFCQzib0fykmu5JJ4jeH",
    'PLUS': "price_1KDx3KCQzib0fykmFXbLTDtA",
    'PROFESSIONAL': "price_1KCQO9CQzib0fykmSiF3iVyS",
    'CORPORATE': "price_1KE8qtCQzib0fykmgkw13v5l",
    'ULTIMATE': "price_1On7EPCQzib0fykmFhGeq8p8",
  },

  'ROLE': {
    'SUPER_ADMIN': 'SuperAdmin',
    'OWNER': 'Owner',
    'EMPLOYEE': 'Employee',
    'CONTRACTOR': 'Contractor',
    'MANAGER': 'Manager',
    'GUEST': 'Guest'
  },

  'API_URLS': {
    'SIGN_UP': {
      'name': '/signup',
      'type': 'post',
      'tokenEnabled': false
    },
    'SIGN_IN': {
      'name': '/login',
      'type': 'post',
      'tokenEnabled': false
    },
    'SIGN_OUT': {
      'name': '/logout',
      'type': 'post'
    }
  },

  'HTTP_STATUS_CODES': {
    'SUCCESS': 200,
    'IM_USED': 226,
    'FAIL': 400,
    'UNAUTHORIZED': 401,
    'NOT_FOUND': 404,
    'NOT_ACCEPTABLE': 406,
    'CONFLICT': 409,
    'INVALID_BAR_ID': 417,
    'UNPROCESSABLE': 422,
    'LOCKED': 423,
    'FAILED_DEPENDENCY': 424,
    'PRECONDITION_REQUIRED': 428
  },

  'STATUS': {
    'ACTIVE': 1,
    'EMAILED': 0,
    'DELETED': -1
  },

  'MEMBERSHIP': {
    'TRIAL' : -1, 
    'STARTER' : 0,
    'PLUS' : 1,
    'PROFESSIONAL': 2,
    'CORPORATE': 3,
    'ULTIMATE': 4
  },

  'MEMBERSHIP_PLANS': {
    'STARTER': 'Starter',
    'PLUS': 'Plus',
    'PRO': 'Professional',
    'CORP': 'Corporate',
    'ULTI': 'Ultimate'
  },

  'PAYOUT_STATUS': {
    'OPEN' : 1,
    'CLOSED' : 0,
  },
  
  'IMG_PATH': '/images/',
  'MAX_UPLOAD_FILE_SIZE': 10000000,
  'MAX_UPLOAD_FILE_COUNT': 10,
  'DATE_FORMAT': 'MM/DD/YYYY',
  'TRIAL_PERIOD': 20,
  'SUBSCRIBE_SKIP_COUNT': 20,
  'SESSION_EXPIRE_TIME_IN_SECONDS': 1000 * 10 * 60,
  'SUBSCRIBE_TIME_IN_SECONDS': 1000 * 5 * 60
};
