import React, { Component } from "react";
import axios from 'axios';

// material
import { Link } from "react-router-dom";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { Grid } from "@mui/material";
import Slide from '@mui/material/Slide';

// core components
import { GApageView } from "../../shared/gaUtils";
import lineFloat from "../../assets/images/login/line.png";
import curveFloat from "../../assets/images/login/curve.png";
import moonFloat from "../../assets/images/login/moon.png";
import banner from "../../assets/images/login/banner.png";
import logo from "../../assets/images/logo.png";
import InputForm from '../component/InputForm';

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      passwordFocus: false,
      password: '',
      email: '',
      toastOpen: false,
      notificationText: '',
      success: false,
    };
  }

  async componentDidMount() {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    const queryString = require('query-string');
    const parsed = queryString.parse(this.props.location.search);
    await axios.get(`api/users/reset/${parsed.token}`)
      .then((res) => {
        this.setState({
          toastOpen: true,
          notificationText: res.data.message,
          success: true,
          email: res.data.email,
        });
      }).catch((err) => {
        console.log(err)
        this.setState({
          toastOpen: true,
          notificationText: typeof err.response !== "undefined" ? err.response.data.message : err.message,
          success: false
        });
      });
    GApageView('Reset Password');
  }

  componentWillUnmount() {
    document.body.classList.remove("login-page");
    document.body.classList.remove("sidebar-collapse");
  }

  onChange = e => {
    this.setState({ [e.id]: e.value });
  };

  onSubmit = e => {
    e.preventDefault();
    axios
      .post('api/users/updatePasswordViaEmail', {
        email: this.state.email,
        password: this.state.password
      }).then((res) => {
        this.setState({
          toastOpen: true,
          notificationText: res.data.message,
          success: true
        });
        setTimeout(() => {
          this.props.history.push('/login');
        }, 3000);
      }).catch((err) => {
        this.setState({
          toastOpen: true,
          notificationText: typeof err.response !== "undefined" ? err.response.data.message : err.message,
          success: false
        });
      });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({
      toastOpen: false,
      notificationText: ''
    });
  };

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  render() {
    const { toastOpen, notificationText, success } = this.state;
    return (
      <div className="auth-page">
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={success ? 'success' : 'error'} sx={{ width: '100%', fontSize: 24 }}>
            {notificationText}
          </Alert>
        </Snackbar>
        <div className="line-float">
          <img className="img-responsive" src={lineFloat} alt={'line-float'} />
        </div>
        <div className="curve-float">
          <img className="img-responsive" src={curveFloat} alt={'curve-float'} />
        </div>
        <div className="moon-float">
          <img className="img-responsive" src={moonFloat} alt={'moon-float'} />
        </div>
        <div className="content">
          <Grid item container spacing={0} className="content-container">
            <Grid item xl={8} lg={7} md={6} sm={12} xs={12} className="left">
              <div className="title-container display-flex justify-start align-center">
                <div className="page-title-line"></div>
                <div className="page-title">Easy Commission Calculations</div>
              </div>
              <div className="sub-title">
                All your commission calculations in one place
              </div>
              <div className="w-100 display-flex justify-end mobile-login">
                <div className="auth-form mobile-form">
                  <div className="top display-flex justify-start align-center">
                    <div>
                      <img className="img-responsive" alt="logo" src={logo} />
                    </div>
                    <div>
                      Reset Password
                    </div>
                  </div>
                  <div className="middle">
                    <div className="middle-title">
                      Please enter a new password for your account
                    </div>
                    <div style={{ height: '20px' }}></div>
                    <InputForm
                      label={'Password'}
                      id={'password'}
                      isAddon={false}
                      placeholderText={''}
                      onChange={ev => this.onChange(ev)}
                      value={this.state.password}
                    />
                  </div>
                  <div style={{ height: '40px' }}></div>
                  <div className="tos forgot-tos">
                    <a rel="noopener noreferrer" href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit?usp=sharing" target="_blank">
                      You agree to our TOS and Privacy Policy
                    </a>
                  </div>
                  <div style={{ height: '40px' }}></div>
                  <div className="bottom display-flex justify-between align-center">
                    <div className={"bubbly-button"} style={{ width: '100%' }} onClick={this.onSubmit}>
                      <span>Submit</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-image">
                <img className="img-responsive" alt={'page-img'} src={banner} />
              </div>
              <div className="display-flex justify-start align-center terms-policy">
                <a href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit" target="_blank" rel="noopener noreferrer">TERMS OF SERVICE</a>
                <Link to="/contact-us">CONTACT US</Link>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={6} sm={12} xs={12} className="right">
              <div className="w-100 display-flex justify-end">
                <div className="auth-form">
                  <div className="top display-flex justify-start align-center">
                    <div>
                      <img className="img-responsive" alt="logo" src={logo} />
                    </div>
                    <div>
                      Reset Password
                    </div>
                  </div>
                  <div className="middle">
                    <div className="middle-title">
                      Please enter a new password for your account
                    </div>
                    <div style={{ height: '40px' }}></div>
                    <InputForm
                      label={'Password'}
                      id={'password'}
                      isAddon={false}
                      placeholderText={''}
                      onChange={ev => this.onChange(ev)}
                      value={this.state.password}

                    />
                  </div>
                  <div style={{ height: '40px' }}></div>
                  <div className="tos forgot-tos">
                    <a rel="noopener noreferrer" href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit?usp=sharing" target="_blank">
                      You agree to our TOS and Privacy Policy
                    </a>
                    {/* <span>You agree to our TOS and Privacy Policy</span>                                */}
                  </div>
                  <div style={{ height: '40px' }}></div>
                  <div className="bottom display-flex justify-between align-center">
                    <div className={"bubbly-button"} style={{ width: '100%' }} onClick={this.onSubmit}>
                      <span>Submit</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="licence">
                <span>© 2020, Published by <span className="marked-text">My Market Partners</span></span>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

export default ResetPassword;