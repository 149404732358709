import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import _ from 'lodash';
import { Grid } from "@mui/material";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import SelectForm from '../component/SelectForm';
import lineFloat from "../../assets/images/login/line.png";
import curveFloat from "../../assets/images/login/curve.png";
import moonFloat from "../../assets/images/login/moon.png";
import banner from "../../assets/images/login/banner.png";
import logo from "../../assets/images/logo.png";
import InputForm from '../component/InputForm';

import { GApageView, GAexception } from "../../shared/gaUtils";
import axios from "axios";

const categories = [
    { value: 'Problem', label: 'Problem' },
    { value: 'Improvement', label: 'Improvement' },
    { value: 'Other', label: 'Other' }
  ]

class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
        email: this.props.auth.user ? this.props.auth.user.email : '',
        name: this.props.auth.user ? this.props.auth.user.name : '',
        phone: this.props.auth.user ? this.props.auth.user.phone : '',
        message: "",
        errors: {},
        category: {value: 'Problem', label: 'Problem'},
        toastOpen: false
      };
  }

  componentDidMount() {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    GApageView("Contact-US");
  }
  
  componentWillUnmount() {
    document.body.classList.remove("login-page");
    document.body.classList.remove("sidebar-collapse");
  }

  onChange = e => {
    this.setState({ [e.id]: e.value });
  };

  handleSelection = (selectedCategory) => {
    this.setState({ category: selectedCategory});
  };

  onSubmit = e => {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.loginUser(userData); // since we handle the redirect within our component, 
                                    // we don't need to pass in this.props.history as a parameter
  };

  validateForm = () => {
    if (_.isEmpty(this.state.name))
    {
      this.setState({
        notificationText: "Please put your name and fill in information required.",
        toastOpen: true
      });
      setTimeout(() => {
        this.setState({
          notificationText: "",
          toastOpen: false
        });
      }, 3000);
      return false;
    }
    if (_.isEmpty(this.state.email))
    {
      this.setState({
        notificationText: "Please put your email and fill in information required.",
        toastOpen: true
      });
      setTimeout(() => {
        this.setState({
          notificationText: "",
          toastOpen: false
        });
      }, 3000);
      return false;
    }
    if (_.isEmpty(this.state.message))
    {
      this.setState({
        notificationText: "Please write down your message.",
        toastOpen: true
      });
      setTimeout(() => {
        this.setState({
          notificationText: "",
          toastOpen: false
        });
      }, 3000);
      return false;
    }
    if (_.isEmpty(this.state.phone))
    {
      this.setState({
        notificationText: "Please put your phone number.",
        toastOpen: true
      });
      setTimeout(() => {
        this.setState({
          notificationText: "",
          toastOpen: false
        });
      }, 3000);
      return false;
    }
    return true;
  }

  submitMessage = () => {
    if (!this.validateForm())
      return;
    axios.post('/api/users/contact-us', {
        name: this.state.name,
        email: this.state.email,
        phone: this.state.phone,
        message: this.state.message,
        category: this.state.category.value
      }).then((res) => {
        this.props.history.push("/");
      }).catch((err) => {
        console.log(err);
        GAexception(`Sending Message Failed: ${err}`);
      });
  }


  render() {
    const { toastOpen, notificationText } = this.state;

    return (
        <div className="auth-page">
          <Snackbar
              open={toastOpen}
              autoHideDuration={3000}
              onClose={this.handleClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
              <Alert severity="error" sx={{ width: '100%' }}>
                  {notificationText}
              </Alert>
          </Snackbar>
          <div className="line-float">
              <img className="img-responsive" src={lineFloat} alt={'line-float'} />
          </div>
          <div className="curve-float">
              <img className="img-responsive" src={curveFloat} alt={'curve-float'} />
          </div>
          <div className="moon-float">
              <img className="img-responsive" src={moonFloat} alt={'moon-float'} />
          </div>
          <div className="content">
              <Grid item container spacing={0} className="content-container">
                  <Grid item xl={8} lg={7} md={6} sm={12} xs={12} className="left">
                      <div className="title-container display-flex justify-start align-center">
                          <div className="page-title-line"></div>
                          <div className="page-title">Easy Commission Calculations</div>
                      </div>
                      <div className="sub-title">
                          Get Payroll Reports by Employee or by Date
                      </div>
                      <div className="w-100 display-flex justify-end mobile-login">
                        <div className="auth-form mobile-form">
                            <div className="top display-flex justify-start align-center">
                                <div>
                                    <img className="img-responsive" alt="logo" src={logo} />
                                </div>
                                <div>
                                  Send Us A Message
                                </div>
                            </div>
                            <div className="middle">
                                {/* <div style={{height: '40px'}}></div> */}
                                <InputForm
                                    label={'Your Name'}
                                    id={'name'}
                                    isAddon={false}
                                    placeholderText={''}
                                    onChange={ev => this.onChange(ev)}
                                    value={this.state.name}
                                />
                                <div style={{height: '40px'}}></div>
                                <InputForm
                                    label={'Your Email'}
                                    id={'email'}
                                    isAddon={false}
                                    placeholderText={''}
                                    onChange={ev => this.onChange(ev)}
                                    value={this.state.email}
                                />
                                <div style={{height: '40px'}}></div>
                                <InputForm
                                    label={'Number Here'}
                                    id={'number'}
                                    isAddon={false}
                                    placeholderText={''}
                                    onChange={ev => this.onChange(ev)}
                                    value={this.state.phone}
                                />
                                <div style={{height: '40px'}}></div>
                                <InputForm
                                    label={'Problem'}
                                    id={'problem'}
                                    isAddon={false}
                                    placeholderText={''}
                                    onChange={ev => this.onChange(ev)}
                                    value={this.state.problem}
                                />
                                <div style={{height: '40px'}}></div>
                                <InputForm
                                    label={'Message'}
                                    id={'message'}
                                    isAddon={false}
                                    placeholderText={''}
                                    onChange={ev => this.onChange(ev)}
                                    value={this.state.message}
                                />
                            </div>
                            <div style={{height: '30px'}}></div>
                            <div className="bottom display-flex justify-between align-center">
                                <div className={"bubbly-button"} style={{width: "181px"}} onClick={this.onSubmit}>
                                    <span>Submit</span>
                                </div>
                                <Link to="/login">Back</Link>
                            </div>
                      </div>
                      </div>
                      <div className="page-image">
                          <img className="img-responsive" alt={'page-img'} src={banner} />
                      </div>
                      <div className="display-flex justify-start align-center terms-policy">
                          <a href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit"  target="_blank" rel="noopener noreferrer">TERMS OF SERVICE</a>
                          <Link to="/contact-us">CONTACT US</Link>
                      </div>
                  </Grid>
                  <Grid item xl={4} lg={5} md={6} sm={12} xs={12} className="right">
                      <div className="w-100 display-flex justify-end">
                          <div className="auth-form register-form">
                              <div className="top display-flex justify-start align-center">
                                  <div>
                                      <img className="img-responsive" alt="logo" src={logo} />
                                  </div>
                                  <div>
                                      Send Us A Message
                                  </div>
                              </div>
                              <div className="middle">
                                  {/* <div style={{height: '40px'}}></div> */}
                                  <InputForm
                                      label={'Your Name'}
                                      id={'name'}
                                      isAddon={false}
                                      placeholderText={''}
                                      onChange={ev => this.onChange(ev)}
                                      value={this.state.name}
                                  />
                                  <div style={{height: '40px'}}></div>
                                  <InputForm
                                      label={'Your Email'}
                                      id={'email'}
                                      isAddon={false}
                                      placeholderText={''}
                                      onChange={ev => this.onChange(ev)}
                                      value={this.state.email}
                                  />
                                  <div style={{height: '40px'}}></div>
                                  <InputForm
                                      label={'Number Here'}
                                      id={'phone'}
                                      isAddon={false}
                                      placeholderText={''}
                                      onChange={ev => this.onChange(ev)}
                                      value={this.state.phone}
                                  />
                                  <div style={{height: '40px'}}></div>
                                  <SelectForm options={categories} onChange={(ev) => this.handleSelection(ev)} defaultVale={this.state.category} />
                                  <div style={{height: '40px'}}></div>
                                  <InputForm
                                      label={'Message'}
                                      id={'message'}
                                      isAddon={false}
                                      placeholderText={''}
                                      onChange={ev => this.onChange(ev)}
                                      value={this.state.message}
                                  />
                              </div>
                              <div style={{height: '30px'}}></div>
                              <div className="bottom display-flex justify-between align-center">
                                  <div className={"bubbly-button"} onClick={this.submitMessage}>
                                      <span>Submit</span>
                                  </div>
                                  <Link to="/login">Back</Link>
                              </div>
                          </div>
                      </div>
                    <div className="licence">
                      <span>© 2020, Published by <span className="marked-text">My Market Partners</span></span>
                    </div>
                  </Grid>
              </Grid>
          </div>
          {/* <div className="licence">
              <span>© 2020, Published by <span className="marked-text">My Market Partners</span></span>
          </div> */}
      </div>
    );
  }
}

ContactUs.propTypes = {
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
  };
  const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
  });
  export default connect(
    mapStateToProps
  )(ContactUs);