import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactPlayer from 'react-player'
import 'react-toastify/dist/ReactToastify.css';
// import Floater from 'react-floater';  
import { logoutUser, updateUser, registerGuest } from "../../actions/authActions";

const videoUrl = "https://commissionpaypro.s3.amazonaws.com/Commission+Pay+Pro+Explainer.mp4";

class Explainer extends Component {

  constructor(props) {
    super(props);
  }

  toLearn = () => {
    this.props.history.push('/learn');
  }

  render() {
    return (
        <>
            <div className="justify-center text-center" style={{maxWidth: '1300px', margin: 'auto'}}>
                <ReactPlayer
                    url= {videoUrl}
                    controls
                    playing
                    width="100%"
                    height="100%"
                />
                <div style={{float: 'right'}}>
                    <div className="btn-blue" onClick={this.toLearn} style={{width: 'fit-content', marginTop: '10px'}}>
                        <span>Start Now</span>
                    </div>
                </div>
            </div>
        </>
    );
  }
}

Explainer.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  registerGuest: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { logoutUser, updateUser, registerGuest }
)(Explainer);