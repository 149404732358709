
import React, { Component } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactDatetime from "react-datetime";
import 'react-toastify/dist/ReactToastify.css';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

import constant, { SUBSCRIBE_TIME_IN_SECONDS, SUBSCRIBE_SKIP_COUNT } from "../../shared/constant";
import { GApageView, GAevent } from "../../shared/gaUtils";
import html2pdf from "html2pdf.js";
import _ from 'lodash';
import calendar_icon from '../../assets/images/icons/calendar-icon.png';
import AddIcon from '@mui/icons-material/Add';  

import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '600px',
  width: '100%',
  bgcolor: '#fff',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 3,
  textAlign: 'center',
  padding: 2
};

const moment = require('moment');

class GrossSales extends Component {

  constructor(props) {
    super(props);
    this.state = {
      commissions: [],
      employeePayouts: [],
      totalsPaidByEmployees: [],
      errors: {},
      dateFrom: moment().subtract(7, 'd').format('MM/DD/YYYY'),
      dateTo: moment().format('MM/DD/YYYY'),
      subscribeQuestion: false,
      toastOpen: false,
      notificationText: "",
      notifyType: "error",
      subscriptionText: '',
      employeeCount: 0
    }
  }

  intervalID = 0;

  componentDidMount() {
    document.body.classList.add("gross-sales-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role === constant['ROLE']['OWNER'] || this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) {
      this.getCommissions();
      this.getEmployeeCount();
      GApageView('Gross Sales Page');
      GAevent('User', 'Gross Sales Page', `User: ${this.props.auth.user.name}  visited Gross Sales page`);
    } else {
      this.props.history.push('/');
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("gross-sales-page");
    document.body.classList.remove("sidebar-collapse");
    clearInterval(this.intervalID);
  }

  getEmployeeCount() {
    axios
      .post('/api/users/employeeCount',
        {
          userId: this.props.auth.user.id
        })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ employeeCount: res.data });
        }
      });
  }

  getPdf = () => {
    const element = document.getElementById('gross-sales-report');
    let clonedElement = element.cloneNode(true);
    let hideList = clonedElement.getElementsByClassName('hide-printing');
    for (let i = hideList.length - 1; i >= 0; i--) {
      let childNode = hideList[i];
      childNode.parentNode.removeChild(childNode);
    }
    let datePickerList = clonedElement.getElementsByClassName('rdtPicker');
    for (let i = datePickerList.length - 1; i >= 0; i--) {
      let childNode = datePickerList[i];
      childNode.parentNode.removeChild(childNode);
    }
    const opt = {
      margin: [0.1, 0.1, 0.1, 0.1],
      filename: 'gross-sales-report.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: {
        scale: 2,
        letterRendering: true,
        scrollY: 0
      },
      jsPDF: { orientation: 'landscape', unit: 'in', format: 'a4' },
      pagesplit: true,
      putOnlyUsedFonts: true,
      // pagebreak: { mode: 'avoid-all' }
    };

    html2pdf().set(opt).from(clonedElement).save().then((res) => {
      clonedElement.remove();
    }).catch((err) => {
      console.log("RESULT ->", err);
      clonedElement.remove();
    });
  }

  getCommissions = () => {
    axios
      .post('/api/commissions/commissions',
        {
          userId: this.props.auth.user.id,
          dateFrom: moment(this.state.dateFrom).utc(),
          dateTo: moment(this.state.dateTo).endOf('day').utc(),
          mode: 'GrossSales'
        })
      .then((res) => {
        this.setState({ commissions: res.data });
        let employeePayouts = [];
        res.data.forEach(commission => {
          let employees = [];
          commission.employeePayouts.forEach((employeePayoutDetail) => {
            employees.push(employeePayoutDetail.employee.name);
            employeePayoutDetail.payouts.forEach((payoutDetailObj) => {
              // if (payoutDetailObj.status === constant['PAYOUT_STATUS']['OPEN']) {
                // Define Payout Object
                const payoutObj = {
                  commissionId: commission._id,
                  employeeId: employeePayoutDetail.employee.id,
                  employeeName: employeePayoutDetail.employee.name,
                  payoutId: payoutDetailObj._id,
                  employees: employees,
                  customerName: payoutDetailObj.customerName,
                  grossIncome: payoutDetailObj.grossIncome,
                  date: payoutDetailObj.date,
                  employeePayoutId: employeePayoutDetail._id
                }
                employeePayouts.push(payoutObj);
              // }
            });
          });
        });
        const payoutsByEmployees = _.groupBy(employeePayouts, 'employeeId');
        let totalsPaidByEmployees = [];
        _.mapKeys(payoutsByEmployees, (payoutDetails, employeeId) => {
          let totalGross = 0;
          payoutDetails.forEach((payoutDetail) => {
            totalGross += payoutDetail.grossIncome;
          });
          totalsPaidByEmployees.push({
            employeeName: payoutDetails[0].employeeName,
            totalGross: totalGross.toFixed(2)
          });
        });

        const sortedEmployeePayouts = employeePayouts.sort((totalA, totalB) => {
          return new Date(totalB.date) - new Date(totalA.date);
        });

        const sortedTotalsPaidByEmployees = totalsPaidByEmployees.sort((totalA, totalB) => {
          var nameA = totalA.employeeName.toUpperCase();
          var nameB = totalB.employeeName.toUpperCase();
          return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
        });

        this.setState({ employeePayouts: sortedEmployeePayouts, totalsPaidByEmployees: sortedTotalsPaidByEmployees });
      }).catch((err) => {
        console.log(err);
      });
  }

  handleDateFromChange = (date) => {
    this.setState({ dateFrom: moment(date).format('MM/DD/YYYY') });
  }

  handleDateToChange = (date) => {
    this.setState({ dateTo: moment(date).format('MM/DD/YYYY') });
  }

  getGrossSalesData = () => {
    this.getCommissions();
  }

  removetransaction = (payout) => {
    let commission = _.find(this.state.commissions, { _id: payout.commissionId });
    let employeePayout = _.find(commission.employeePayouts, { _id: payout.employeePayoutId });
    const tIndex = _.findIndex(employeePayout.payouts, {  _id: payout.payoutId });
    employeePayout.payouts.splice(tIndex, 1);
    axios.put(`/api/commissions/commissions/${commission._id}`, { commission: commission })
    .then((res) => {
      this.setState({
        toastOpen: true,
        notificationText: "Successfully removed the commission.",
        notifyType: "success"
      });
      this.getCommissions();
    }).catch((err) => {
      console.log(err);
      this.setState({
        toastOpen: true,
        notificationText: "Failed removing the commission.",
        notifyType: "error"
      });
    });
  }

  handleClose = () => {
    this.setState({
      toastOpen: false
    });
  };

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  renderGrossSalesData() {
    const moment = require('moment');
    let grossSales = [];
    this.state.employeePayouts.forEach((payout) => {
      const elementPayoutDetail =
        (
          <>
            <div className="record">
              <div>
                {payout.employees.join()}
              </div>
              <div>
                {moment(payout.date).format('MM/DD/YYYY')}
              </div>
              <div>
                {payout.customerName}
              </div>
              <div>
                {payout.grossIncome.toFixed(2)}
              </div>
              <div className="hide-printing">
                <div className="remove-icon" onClick={() => this.removetransaction(payout)}>
                  <AddIcon fontSize="medium" />
                </div>
              </div>
            </div>
            <div className="record-mobile">
              <div>
                <span>Employee:</span>
                <span>{payout.employees.join()}</span>
              </div>
              <div>
                <span>Date:</span>
                <span>{moment(payout.date).format('MM/DD/YYYY')}</span>
              </div>
              <div>
                <span>Customer:</span>
                <span>{payout.customerName}</span>
              </div>
              <div>
                <span>Amount:</span>
                <span>{payout.grossIncome.toFixed(2)}</span>
              </div>
              <div className="hide-printing">
                <span>Action:</span>
                <span>
                  <div className="remove-icon" onClick={() => this.removetransaction(payout)}>
                    <AddIcon fontSize="medium" />
                  </div>
                </span>
              </div>
            </div>
          </>
        )
      grossSales.push(elementPayoutDetail);
    });
    // return grossSales;
    return <>
      <div className="headers">
        <div>Employee</div>
        <div>Date</div>
        <div>Customer</div>
        <div>Amount</div>
        <div className="hide-printing">Action</div>
      </div>
      <div className="records">
        {
          grossSales
        }
      </div>
    </>
  }

  renderTotalsByEmployeeData() {
    return (
      <>
        <div className="headers">
          <div>Employee</div>
          <div>Total Gross Income</div>
        </div>
        <div className="records">
          {
            this.state.totalsPaidByEmployees.map((data) => {
              return (
                <>
                  <div className="record">
                    <div>{data.employeeName}</div>
                    <div>{data.totalGross}</div>
                  </div>
                  <div className="record-mobile">
                    <div>
                      <span>Employee:</span>
                      <span>{data.employeeName}</span>
                    </div>
                    <div>
                      <span>Total Paid:</span>
                      <span>{data.totalGross}</span>
                    </div>
                  </div>
                </>
              )
            })
          }            
        </div>
      </>
    )
  }

  goToSubscribe = () => {
    this.props.history.push("/account");
  }

  noSubscribe = () => {
    let subSkippedCount = localStorage.getItem("SubSkippedCount");
    if (subSkippedCount === null) subSkippedCount = 1;
    else subSkippedCount = parseInt(subSkippedCount) + 1;
    localStorage.setItem("SubSkippedCount", subSkippedCount);
    this.setState({
      subscribeQuestion: false
    });
  }

  render() {
    const { subscriptionText, toastOpen, notificationText, notifyType  } = this.state;
    return (
      <>
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={notifyType} sx={{ width: '100%', fontSize: 24 }}>
            {notificationText}
          </Alert>
        </Snackbar>
        <div className="gross-page" id="gross-sales-report">
          <div className="top-content">
            <div className="check-date">
              <div className="title">Pay Period</div>
              <div className="date-range">
                <div className="label">Date from:</div>
                <div className="calendar">
                  <div>
                    <img src={calendar_icon} alt="calendar icon" />
                  </div>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Datetime Picker Here",
                      value: this.state.dateFrom
                    }}
                    closeOnSelect={true}
                    timeFormat={false}
                    onChange={this.handleDateFromChange}
                  ></ReactDatetime>
                </div>
                <div className="label">Date to</div>
                <div className="calendar">
                  <div>
                    <img src={calendar_icon} alt="calendar icon" />
                  </div>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Datetime Picker Here",
                      value: this.state.dateTo,
                    }}
                    closeOnSelect={true}
                    timeFormat={false}
                    onChange={this.handleDateToChange}
                  ></ReactDatetime>
                </div>
                {/* <div className="label">Select An Employee</div>
                <div className="calendar">
                  <Select
                    className="react-select react-select-info"
                    classNamePrefix="react-select"
                    placeholder="Employee"
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => option.name}
                    onChange={value => this.handleSelectChange(value)}
                    options={this.state.employees}
                  ></Select>
                </div> */}
              </div>
              <div className="actions hide-printing">
                <div className="btn-blue" onClick={this.getGrossSalesData}>
                  Get Report
                </div>
                <div className="btn-blue" onClick={this.getPdf}>
                  Print
                </div>
              </div>
            </div>
            <div className="totals-by-employee">
              <div className="title">Totals By Employee</div>
              <div className="employee-table">
                {this.state.totalsPaidByEmployees.length > 0 ?
                  this.renderTotalsByEmployeeData()
                  :
                  <h4>No Data Found</h4>
                }
              </div>
            </div>
          </div>
          <div className="bottom-content">
            <div className="payroll">
              <div className="title">
                <div>Gross Sales</div>
              </div>
              <div className="payroll-table">
                {this.state.employeePayouts.length > 0 ?
                  this.renderGrossSalesData()
                  :
                  <h4>No Gross Sales Found</h4>
                }
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={this.state.subscribeQuestion}
        >
          <Box sx={style}>
            <h2 className="mt-4 mb-2 mx-2 font-bold" style={{ color: '#22395B' }}>Welcome to CommissionPayPro!</h2>
            <h4 className="mt-2 mb-4 mx-2" style={{ color: '#7B8FB2' }}>
              {
                subscriptionText
              }
            </h4>
            <div className="display-flex justify-center align-center mb-4">
              <div className="btn-blue mr-2" style={{ padding: '8px 18px' }} onClick={this.goToSubscribe}>
                Subscribe
              </div>
            </div>
          </Box>
        </Modal>
      </>
    )
  }
}

GrossSales.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps
)(GrossSales)