
import React, { Component } from "react";
import axios from "axios";
import _ from 'lodash';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import ReactDatetime from "react-datetime";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import { GApageView } from "../../shared/gaUtils";
import constant from "../../shared/constant";
import calendar_icon from '../../assets/images/icons/calendar-icon.png';

const moment = require('moment');

class EmployeePay extends Component {

  constructor(props) {
    super(props);
    this.state = {
      commissions: [],
      employeePayouts: [],
      totalsPaidByEmployees: [],
      totalExpensesByEmployees: [],
      employeeExpenses: [],
      errors: {},
      employee: { id: props.auth.user.id, name: props.auth.user.name },
      dateFrom: moment().subtract(30, 'd').format('MM/DD/YYYY'),
      dateTo: moment().format('MM/DD/YYYY'),
      toastOpen: false,
      notificationText: "",
      notifyType: "error"
    }
  }

  componentDidMount() {
    document.body.classList.add("payroll-history-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    this.getCommissions();
    this.getExpenses();
    GApageView('Employee Pay');
  }

  componentWillUnmount() {
    document.body.classList.remove("payroll-history-page");
    document.body.classList.remove("sidebar-collapse");
  }

  getCommissions = () => {
    axios
      .post('/api/commissions/commissions',
        {
          userId: this.props.auth.user.parentId,
          dateFrom: moment(this.state.dateFrom).utc(),
          dateTo: moment(this.state.dateTo).endOf('day').utc()
        })
      .then((res) => {
        this.setState({ commissions: res.data });
        let employeePayouts = [];
        res.data.forEach(commission => {
          commission.employeePayouts.forEach((employeePayoutDetail) => {
            if (employeePayoutDetail.employee.id === this.props.auth.user.id) {
              employeePayoutDetail.payouts.forEach((payoutDetailObj) => {
                if ((payoutDetailObj.status === constant['PAYOUT_STATUS']['OPEN']) ||
                  (payoutDetailObj.transactions.length === 0)) {
                  return;
                }
                // Calculate Owed amount
                let totalPaid = 0;
                payoutDetailObj.transactions.forEach((transaction) => {
                  totalPaid += transaction.amountPaid;
                });
                // Define Payout Object
                const payoutObj = {
                  id: employeePayoutDetail._id,
                  payoutId: payoutDetailObj._id,
                  employeeId: employeePayoutDetail.employee.id,
                  employeeName: employeePayoutDetail.employee.name,
                  total: payoutDetailObj.total,
                  owed: (payoutDetailObj.total - totalPaid).toFixed(2),
                  transactions: payoutDetailObj.transactions,
                  title: commission.title,
                  customerName: payoutDetailObj.customerName,
                  grossIncome: payoutDetailObj.grossIncome,
                  date: commission.date,
                }
                employeePayouts.push(payoutObj);
              });
            }
          });
        });
        const payoutsByEmployees = _.groupBy(employeePayouts, 'employeeId');
        let totalsPaidByEmployees = [];
        _.mapKeys(payoutsByEmployees, (payoutDetails, employeeId) => {
          let totalPaid = 0;
          payoutDetails.forEach((payoutDetail) => {
            totalPaid += (payoutDetail.total - payoutDetail.owed);
          });
          if (totalPaid > 0) {
            totalsPaidByEmployees.push({
              employeeId: payoutDetails[0].employeeId,
              employeeName: payoutDetails[0].employeeName,
              totalPaid: _.floor(totalPaid, 2),
              reimbursements: 0,
              grossPaid: totalPaid + 0
            });
          }
        });
        this.setState({ employeePayouts: employeePayouts, totalsPaidByEmployees: totalsPaidByEmployees });
      }).catch((err) => {
        console.log(err);
      });
  }

  getExpenses() {
    axios
      .post('/api/expenses/expenses',
        {
          userId: this.props.auth.user.parentId,
          dateFrom: moment(this.state.dateFrom).utc(),
          dateTo: moment(this.state.dateTo).endOf('day').utc()
        })
      .then((res) => {
        const expenses = _.filter(res.data, { employeeId: this.props.auth.user.id });
        let employeeExpenses = [];
        expenses.forEach((expense) => {
          if ((expense.status === constant['PAYOUT_STATUS']['OPEN']) ||
            (expense.transactions.length === 0)) {
            return;
          }
          let totalPaid = 0;
          expense.transactions.forEach((transaction) => {
            totalPaid += transaction.amountPaid;
          });
          employeeExpenses.push({ ...expense, owed: expense.total - _.floor(totalPaid, 2) });
        });

        const expensesByEmployees = _.groupBy(employeeExpenses, 'employeeId');
        let totalsPaidByEmployees = [];
        _.mapKeys(expensesByEmployees, (expenses, employeeId) => {
          let totalPaid = 0;
          expenses.forEach((expense) => {
            totalPaid += (expense.total - expense.owed);
          });
          if (totalPaid > 0) {
            totalsPaidByEmployees.push({
              employeeId: expenses[0].employeeId,
              employeeName: expenses[0].employeeName,
              totalPaid: 0,
              reimbursements: _.floor(totalPaid, 2),
              grossPaid: 0
            });
          }
        });
        this.setState({ employeeExpenses: employeeExpenses, totalExpensesByEmployees: totalsPaidByEmployees });
      }).catch((err) => {
        // console.log(err);
        this.setState({
          toastOpen: true,
          notificationText: err.message || 'Error occured while retrieving expenses',
          notifyType: "error"
        });
      });
  }

  handleDateFromChange = (date) => {
    this.setState({ dateFrom: moment(date).format('MM/DD/YYYY') });
  }

  handleDateToChange = (date) => {
    this.setState({ dateTo: moment(date).format('MM/DD/YYYY') });
  }

  payrollHistoryElement(expense = false, payoutDetail, index = 0) {
    const elementPayoutDetail =
      (
        <>
          <div className="record">
            <div className="left">
              <div className="field">
                <div className="font-bold">Employee:</div>
                <div>{payoutDetail.employeeName}</div>
              </div>
              <div className="field">
                <div className="font-bold">Date:</div>
                <div>{moment(payoutDetail.date).format('MM/DD/YYYY')}</div>
              </div>
              <div className="field">
                <div className="font-bold">Title:</div>
                <div>{expense ? 'Expense' : payoutDetail.title}</div>
              </div>
              <div className="field">
                <div className="font-bold">Customer:</div>
                <div>{expense ? 'Expense' : payoutDetail.customerName}</div>
              </div>
              <div className="field">
                <div className="font-bold">{payoutDetail.type === 'Expense' ? 'Expense' : 'Gross'}:</div>
                <div>{expense ? payoutDetail.total.toFixed(2) : payoutDetail.grossIncome.toFixed(2)}</div>
              </div>
              <div className="field">
                <div className="font-bold">Owed:</div>
                <div>{parseFloat(payoutDetail.owed).toFixed(2)}</div>
              </div>
              <div className="field">
                <div className="font-bold">Total:</div>
                <div>{(payoutDetail.total - payoutDetail.owed).toFixed(2)}</div>
              </div>
            </div>
            <div className="right">
              <div className="headers">
                <div>Paid On</div>
                <div>Paid By</div>
                <div>Amount Paid</div>
              </div>
              <div className="records">
                {
                  payoutDetail.transactions.map((transaction) => {
                    return <>
                            <div className="value">
                              <div>{moment(transaction.datePaid).format('MM/DD/YYYY')}</div>
                              <div>{transaction.paidBy}</div>
                              <div>{transaction.amountPaid.toFixed(2)}</div>
                            </div>
                            <div className="value-mobile">
                              <div className="field">
                                <div>Paid On:</div>
                                <div>{moment(transaction.datePaid).format('MM/DD/YYYY')}</div>
                              </div>
                              <div className="field">
                                <div>Paid By:</div>
                                <div>{transaction.paidBy}</div>
                              </div>
                              <div className="field">
                                <div>Amount Paid:</div>
                                <div>{transaction.amountPaid.toFixed(2)}</div>
                              </div>
                            </div>
                          </>
                  })
                }
              </div>
            </div>
          </div>
        </>
      )
    return elementPayoutDetail;
  }

  renderPayoutsData() {
    let payrollHistory = [];
    if (this.state.employee.id) {
      const payoutDetail = _.find(this.state.employeePayouts, { employeeId: this.state.employee.id });
      payrollHistory.push(this.payrollHistoryElement(false, payoutDetail, 0));

    } else {
      this.state.employeePayouts.forEach((payoutDetail, index) => {
        payrollHistory.push(this.payrollHistoryElement(false, payoutDetail, index));
      });
    }

    return payrollHistory;
  }

  renderExepensePayoutsData() {
    let payrollHistory = [];
    if (this.state.employee.id) {
      const payoutDetail = _.find(this.state.employeeExpenses, { employeeId: this.state.employee.id });
      payrollHistory.push(this.payrollHistoryElement(true, payoutDetail, 0));

    } else {
      this.state.employeeExpenses.forEach((payoutDetail, index) => {
        payrollHistory.push(this.payrollHistoryElement(true, payoutDetail, index));
      });
    }

    return payrollHistory;
  }

  renderTotalsByEmployeeData() {
    if (this.state.employee.id) { // Filter totalPaidByEmployees if employee selected
      const totalPaidByEmployee = _.find(this.state.totalsPaidByEmployees, { employeeId: this.state.employee.id });
      if (totalPaidByEmployee) {
        return (
        <>
          <div className="headers">
            <div>Employee</div>
            <div>Total Paid</div>
            <div>Reimbursements</div>
            <div>Gross Pay</div>
          </div>
          <div className="records">
            <div className="record">
              <div>{totalPaidByEmployee.employeeName}</div>
              <div>{totalPaidByEmployee.totalPaid}</div>
              <div>{totalPaidByEmployee.reimbursements}</div>
              <div>{totalPaidByEmployee.grossPaid}</div>
            </div>
            <div className="record-mobile">
              <div className="mobile-field">
                <div>Employee:</div>
                <div>{totalPaidByEmployee.employeeName}</div>
              </div>
              <div className="mobile-field">
                <div>Total Paid:</div>
                <div>{totalPaidByEmployee.totalPaid}</div>
              </div>
              <div className="mobile-field">
                <div>Reimbursements:</div>
                <div>{totalPaidByEmployee.reimbursements}</div>
              </div>
              <div className="mobile-field">
                <div>Gross Pay:</div>
                <div>{totalPaidByEmployee.grossPaid}</div>
              </div>
            </div>
          </div>
        </>
        )
      } else {
        return (
          <h4>No Data Found</h4>
        )
      }
    }
    return this.state.totalsPaidByEmployees.map((data) => {
      return (
        <>
          <div className="headers">
            <div>Employee</div>
            <div>Total Paid</div>
            <div>Reimbursements</div>
            <div>Gross Pay</div>
          </div>
          <div className="records">
            <div className="record">
              <div>{data.employeeName}</div>
              <div>{data.totalPaid}</div>
              <div>{data.reimbursements}</div>
              <div>{data.grossPaid}</div>
            </div>
            <div className="record-mobile">
              <div className="mobile-field">
                <div>Employee:</div>
                <div>{data.employeeName}</div>
              </div>
              <div className="mobile-field">
                <div>Total Paid:</div>
                <div>{data.totalPaid}</div>
              </div>
              <div className="mobile-field">
                <div>Reimbursements:</div>
                <div>{data.reimbursements}</div>
              </div>
              <div className="mobile-field">
                <div>Gross Pay:</div>
                <div>{data.grossPaid}</div>
              </div>
            </div>
          </div>
        </>
      )
    });
  }

  renderExpensesTotalsByEmployeeData() {
    if (this.state.employee.id) { // Filter totalPaidByEmployees if employee selected
      const totalExpensesPaidByEmployee = _.find(this.state.totalExpensesByEmployees, { employeeId: this.state.employee.id });
      if (totalExpensesPaidByEmployee) {
        return (
          <>
            <div className="headers">
              <div>Employee</div>
              <div>Total Paid</div>
              <div>Reimbursements</div>
              <div>Gross Pay</div>
            </div>
            <div className="records">
              <div className="record">
                <div>{totalExpensesPaidByEmployee.employeeName}</div>
                <div>{totalExpensesPaidByEmployee.totalPaid}</div>
                <div>{totalExpensesPaidByEmployee.reimbursements}</div>
                <div>{totalExpensesPaidByEmployee.grossPaid}</div>
              </div>
              <div className="record-mobile">
                <div className="mobile-field">
                  <div>Employee:</div>
                  <div>{totalExpensesPaidByEmployee.employeeName}</div>
                </div>
                <div className="mobile-field">
                  <div>Total Paid:</div>
                  <div>{totalExpensesPaidByEmployee.totalPaid}</div>
                </div>
                <div className="mobile-field">
                  <div>Reimbursements:</div>
                  <div>{totalExpensesPaidByEmployee.reimbursements}</div>
                </div>
                <div className="mobile-field">
                  <div>Gross Pay:</div>
                  <div>{totalExpensesPaidByEmployee.grossPaid}</div>
                </div>
              </div>
            </div>
          </>
        )
      } else {
        return (
          <h4>No Data Found</h4>
        )
      }
    }
    return this.state.totalExpensesByEmployees.map((data) => {
      return (
        <>
            <div className="headers">
              <div>Employee</div>
              <div>Total Paid</div>
              <div>Reimbursements</div>
              <div>Gross Pay</div>
            </div>
            <div className="records">
              <div className="record">
                <div>{data.employeeName}</div>
                <div>{data.totalPaid}</div>
                <div>{data.reimbursements}</div>
                <div>{data.grossPaid}</div>
              </div>
              <div className="record-mobile">
                <div className="mobile-field">
                  <div>Employee:</div>
                  <div>{data.employeeName}</div>
                </div>
                <div className="mobile-field">
                  <div>Total Paid:</div>
                  <div>{data.totalPaid}</div>
                </div>
                <div className="mobile-field">
                  <div>Reimbursements:</div>
                  <div>{data.reimbursements}</div>
                </div>
                <div className="mobile-field">
                  <div>Gross Pay:</div>
                  <div>{data.grossPaid}</div>
                </div>
              </div>
            </div>
          </>
      )
    });
  }

  getPayrollData = () => {
    this.getCommissions();
    this.getExpenses();
  }

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  handleClose = () => {
    this.setState({
      toastOpen: false
    })
  };

  render() {
    const { 
      toastOpen, 
      notificationText, 
      notifyType 
    } = this.state;

    return (
      <>
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={notifyType} sx={{ width: '100%', fontSize: 24 }}>
              {notificationText}
          </Alert>
        </Snackbar>
        <div className="payroll-page" id="payroll-history-report">
          <div className="top-content">
            <div className="check-date">
              <div className="title">Pay Period</div>
              <div className="date-range">
                <div className="label">Date from:</div>
                <div className="calendar">
                  <div>
                    <img src={calendar_icon} alt="calendar icon" />
                  </div>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Datetime Picker Here",
                      value: this.state.dateFrom
                    }}
                    closeOnSelect={true}
                    timeFormat={false}
                    onChange={this.handleDateFromChange}
                  ></ReactDatetime>
                </div>
                <div className="label">Date to</div>
                <div className="calendar">
                  <div>
                    <img src={calendar_icon} alt="calendar icon" />
                  </div>
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "Datetime Picker Here",
                      value: this.state.dateTo,
                    }}
                    closeOnSelect={true}
                    timeFormat={false}
                    onChange={this.handleDateToChange}
                  ></ReactDatetime>
                </div>
              </div>
              <div className="actions">
                <div className="btn-blue" style={{width: '100%'}} onClick={this.getPayrollData}>
                  Get Report
                </div>
                {/* <div className="btn-blue" onClick={this.getPdf}>
                  Print
                </div> */}
              </div>
            </div>
            <div className="totals-by-employee">
              <div className="title">Payroll Summary</div>
              <div className="employee-table">
                {this.state.totalsPaidByEmployees.length > 0 ?
                  this.renderTotalsByEmployeeData()
                  :
                  <h4>No Payroll Data Found</h4>
                }
                {this.state.employeeExpenses.length > 0 ?
                  this.renderExpensesTotalsByEmployeeData()
                  :
                  <h4>No Transactions For Expenses Found</h4>
                }
              </div>
            </div>
          </div>
          <div className="bottom-content">
            <div className="payroll">
              <div className="title">
                <div>Payroll History</div>
                {/* <div className="btn-blue">Edit</div> */}
              </div>
              <div className="payroll-table">
                {this.state.employeePayouts.length > 0 ?
                  this.renderPayoutsData()
                  :
                  <h4>No Transactions Found</h4>
                }
                {this.state.employeeExpenses.length > 0 ?
                  this.renderExepensePayoutsData()
                  :
                  <h4>No Transactions For Expenses Found</h4>
                }
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

EmployeePay.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps
)(EmployeePay)