
import React, { Component } from "react";
import axios from "axios";
import _ from 'lodash';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import DownIcon from '@mui/icons-material/KeyboardArrowDown';
import UpIcon from '@mui/icons-material/KeyboardArrowUp';
import VisibilityIcon from '@mui/icons-material/Visibility'

import constant, { SUBSCRIBE_TIME_IN_SECONDS, SUBSCRIBE_SKIP_COUNT } from "../../shared/constant";
import { GAexception, GApageView, GAevent } from "../../shared/gaUtils";
import Floater from 'react-floater';
import { logoutUser } from "../../actions/authActions";

import Modal from '@mui/material/Modal';
import { Box } from '@mui/system';
import trashIcon from '../../assets/images/Trash.png'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '600px',
  width: '100%',
  bgcolor: '#fff',
  border: '2px solid #fff',
  boxShadow: 24,
  borderRadius: 3,
  textAlign: 'center',
  padding: 2
};

const modalTitle = {
  color: '#22395B',
  fontSize: '24px',
  fontWeight: '600',
  marginBottom: '20px'
}
// style={{display: 'flex', flexDirection: 'column', gap: '10px', width: 'fit-content', 
//               margin: 'auto', 
//               alignItems: 'flex-start'}}

const modalContent = {
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: 'fit-Content',
  margin: 'auto',
  alignItems: 'flex-start'
}

const moment = require('moment');

class Pay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commissions: [],
      expenses: [],
      employeePayouts: [],
      payData: [],
      errors: {},
      walkthrough: false,
      toastOpen: false,
      notificationText: "",
      notifyType: "error",
      takeLive: false,
      subscribeQuestion: false,
      subscriptionText: '',
      employeeCount: 0, 
      openPdfviwer: false,
      expenseDocs: []
    }
  }

  intervalID = 0;
  index = 0;

  componentDidMount() {
    document.body.classList.add("pay-now-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    if (this.props.auth.user.role === constant['ROLE']['EMPLOYEE'] || this.props.auth.user.role === constant['ROLE']['CONTRACTOR'] || this.props.auth.user.role === constant['ROLE']['MANAGER']) {
      this.props.history.push('/');
    } else {
      this.getLineItemsAndExpenses();
      this.getEmployeeCount();
      GApageView('Pay Page');
      if (this.props.auth.user.role === constant['ROLE']['GUEST']) {
        GAevent('Guest', 'Pay Page', `Guest User: ${this.props.auth.user.name}  visited Pay page`);
      } else {
        GAevent('User', 'Pay Page', `User: ${this.props.auth.user.name}  visited Pay page`);
      }
    }
    if (this.props.auth.user.role === constant['ROLE']['GUEST']) {
      const walkThrough = localStorage.getItem("WalkThrough");
      if (walkThrough === null || walkThrough !== "Completed") {
        localStorage.setItem("WalkThrough", "Completed");
        this.setState({ walkthrough: true, takeLive: true });
      } else {
        this.setState({ takeLive: true });
      }
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("pay-now-page");
    document.body.classList.remove("sidebar-collapse");
    clearInterval(this.intervalID);
  }

  getEmployeeCount() {
    axios
      .post('/api/users/employeeCount',
        {
          userId: this.props.auth.user.id
        })
      .then((res) => {
        if (res.status === 200) {
          this.setState({ employeeCount: res.data });
        }
      });
  }

  getLineItemsAndExpenses() {
    const requests = [];
    requests.push(axios.post('/api/commissions/commissions', { userId: this.props.auth.user.id }));
    requests.push(axios.get('/api/expenses'));
    axios.all(requests).then(axios.spread((...responses) => {
      let employeePayouts = [];
      let payData = [];
        // Parse Commission Data
      if(responses[0].data.length > 0){
        this.setState({ commissions: responses[0].data });
        responses[0].data.forEach(commission => {
          commission.employeePayouts.forEach((employeePayoutDetail) => {
            employeePayoutDetail.payouts.forEach((payoutDetailObj) => {
              if (payoutDetailObj.status === constant['PAYOUT_STATUS']['OPEN']) {
                // Calculate Owed amount
                let totalPaid = 0;
                payoutDetailObj.transactions.forEach((transaction) => {
                  totalPaid += transaction.amountPaid;
                });
                const payoutObj = {
                  id: employeePayoutDetail._id,
                  payoutId: payoutDetailObj._id,
                  employeeId: employeePayoutDetail.employee.id,
                  employeeName: employeePayoutDetail.employee.name,
                  total: payoutDetailObj.total,
                  owed: (payoutDetailObj.total - totalPaid).toFixed(2),
                  transactions: payoutDetailObj.transactions,
                  title: commission.title,
                  customerName: payoutDetailObj.customerName,
                  grossIncome: payoutDetailObj.grossIncome,
                  expense: employeePayoutDetail.expense,
                  date: payoutDetailObj.date,
                  type: 'Payout'
                }
                employeePayouts.push(payoutObj);
                payData.push({ type: 'Payout', id: payoutObj.id, payoutId: payoutObj.payoutId, amountPaid: '', paidBy: this.props.auth.user.name, reason: '' });
              }
            });
          });
        });
      }
        // Parse Expense Data
      if(responses[1].data.length > 0){
        const expenses = _.filter(responses[1].data, { parentId: this.props.auth.user.id });
        this.setState({ expenses: expenses });
        expenses.forEach((expense) => {
          if (expense.status === constant['PAYOUT_STATUS']['OPEN']) {
            let totalPaid = 0;
            expense.transactions.forEach((transaction) => {
              totalPaid += transaction.amountPaid;
            });
            const payoutObj = {
              id: expense._id,
              employeeId: expense.employeeId,
              employeeName: expense.employeeName,
              total: expense.total,
              owed: (expense.total - totalPaid).toFixed(2),
              transactions: expense.transactions,
              title: 'Expense',
              customerName: expense.customerName === undefined ? 'Expense' : expense.customerName,
              grossIncome: expense.total,
              date: expense.date,
              docs: expense.docs,
              type: 'Expense'
            }
            employeePayouts.push(payoutObj);
            payData.push({ type: 'Expense', id: payoutObj.id, payoutId: payoutObj.id, amountPaid: '', paidBy: this.props.auth.user.name, reason: '' });
          }
        });
      }
      const sortedEmployeePayouts = employeePayouts.sort((payoutA, payoutB) => {
        if (payoutA.customerName === payoutB.customerName)
          return new Date(payoutB.date) - new Date(payoutA.date);
        var nameA = payoutA.customerName.toUpperCase();
        var nameB = payoutB.customerName.toUpperCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
      const filteredPayouts = _.groupBy(sortedEmployeePayouts, 'employeeId');
      this.setState({ employeePayouts: filteredPayouts, payData: payData });
      const data = [];
      _.mapKeys(filteredPayouts, (payoutDetails, employeeId) => {
        payoutDetails.forEach(item => {
          data.push({collapsed: false})
        })
      })
      this.setState({ data });
    })).catch((err) => {
      console.log(err);
      this.setState({
        toastOpen: true,
        notificationText: err.message || 'Error occured while retrieving payment data',
        notifyType: "error"
      });
    });
  }

  checkPaymentStatus(payoutObj) {
    let sum = 0;
    payoutObj.transactions.forEach((transaction) => {
      sum += Number(transaction.amountPaid);
    });
    if (Number(payoutObj.total) === sum)
      return true;
    return false;
  }

  checkPaymentOverOwed(transaction, payoutObj) {
    let sum = 0;
    payoutObj.transactions.forEach((transaction) => {
      sum += Number(transaction.amountPaid);
    });
    const owed = payoutObj.total - _.floor(sum, 2);
    return Number(transaction.amountPaid) > owed;
  }

  setTransactionDetails(e, employeePayoutDetail) {
    if (e.target.id === 'amountPaid' && (Number(e.target.value) > employeePayoutDetail.owed && employeePayoutDetail.owed > 0)) {
      this.setState({
        toastOpen: true,
        notificationText: "You can't pay more than owed amount!",
        notifyType: "error"
      });
    }
    let payData = this.state.payData;
    const transaction = employeePayoutDetail.type === 'Payout' ? _.find(payData, { payoutId: employeePayoutDetail.payoutId }) : _.find(payData, { payoutId: employeePayoutDetail.id });
    transaction[e.target.id] = e.target.value;
    const index = employeePayoutDetail.type === 'Payout' ? _.findIndex(payData, { payoutId: employeePayoutDetail.payoutId }) : _.findIndex(payData, { payoutId: employeePayoutDetail.id });
    payData.splice(index, 1, transaction);
    this.setState({ payData: payData });
  }

  updatePayouts = () => {
    if (this.props.auth.user.role === constant['ROLE']['GUEST']) {
      this.setState({
        toastOpen: true,
        notificationText: "You must take this Live to Click Pay Now",
        notifyType: "error"
      });
      return;
    }
    if (this.state.employeePayouts && !_.isEmpty(this.state.employeePayouts)) {
      const payData = _.filter(this.state.payData, (transaction) => { return transaction.amountPaid !== '' });
      if (payData.length === 0) {
        this.setState({
          toastOpen: true,
          notificationText: "Please put any amounts to pay!",
          notifyType: "error"
        });
        return;
      } else {
        const filteredPayData = _.filter(payData, (transaction) => { return Number(transaction.amountPaid) === 0 && transaction.reason === '' });
        if (filteredPayData.length > 0) {
          this.setState({
            toastOpen: true,
            notificationText: "Please add the reason why you should pay Zero",
            notifyType: "error"
          });
          return;
        }
      }
      // Replace commissions/expenses with newly added transaction
      const updatedCommissions = [];
      const updatedExpenses = [];
      payData.forEach((transaction) => {
        if (transaction.type === 'Payout') {
          this.state.commissions.forEach((commission) => {
            const commissionObj = commission;
            const employeePayout = _.find(commission.employeePayouts, { _id: transaction.id });
            if (employeePayout) {
              const payoutObj = _.find(employeePayout.payouts, { _id: transaction.payoutId });
              if (payoutObj) {
                if (Math.sign(payoutObj.total) !== -1 && this.checkPaymentOverOwed(transaction, payoutObj)) {
                  this.setState({
                    toastOpen: true,
                    notificationText: "You can't pay more than owed amount!",
                    notifyType: "error"
                  });
                  return;
                }
                payoutObj.transactions.push(transaction);
                if (this.checkPaymentStatus(payoutObj)) {
                  payoutObj.status = constant['PAYOUT_STATUS']['CLOSED'];
                }
                const index = _.findIndex(employeePayout.payouts, { _id: payoutObj._id });
                employeePayout.payouts.splice(index, 1, payoutObj);
                const eIndex = _.findIndex(commission.employeePayouts, { _id: employeePayout._id });
                commissionObj.employeePayouts.splice(eIndex, 1, employeePayout);
                updatedCommissions.push(commissionObj);
              }
            }
          });
        } else {
          this.state.expenses.forEach((expense) => {
            const expenseObj = expense;
            if (expense._id === transaction.id) {
              expenseObj.transactions.push(transaction);
              if (this.checkPaymentStatus(expenseObj)) {
                expenseObj.status = constant['PAYOUT_STATUS']['CLOSED'];
              }
              updatedExpenses.push(expenseObj);
            }
          });
        }
      });
      const commissionPayRequests = [];
      if (updatedCommissions.length > 0) {
        updatedCommissions.forEach((commission) => {
          commissionPayRequests.push(
            axios
              .put(`/api/commissions/commissions/${commission._id}`, { commission: commission })
          );
        });
      }

      const expensePayRequests = [];
      if (updatedExpenses.length > 0) {
        updatedExpenses.forEach((expense) => {
          expensePayRequests.push(
            axios
              .put(`/api/expenses/${expense._id}`, { expense: expense })
          );
        });
      }

      // Chaining all update api requests for both commissions & expenses
      axios.all(_.concat(commissionPayRequests, expensePayRequests)).then(axios.spread((...responses) => {
        this.setState({
          toastOpen: true,
          notificationText: "Successfully made payments!",
          notifyType: "success"
        });
        this.props.history.push("/report/pay-report?currentDay=true");
      })).catch((errors) => {
        this.setState({
          toastOpen: true,
          notificationText: "Error occured while making payments!",
          notifyType: "error"
        });
        console.log(errors);
        GAexception(`Making Payments to Employees failed: ${errors}`);
      });
    } else {
      this.setState({
        toastOpen: true,
        notificationText: "No one to pay now!",
        notifyType: "info"
      });
    }
  }

  closePayoutTransaction(payoutDetail) {
    if (payoutDetail.type === 'Payout') {
      this.state.commissions.forEach((commission) => {
        const commissionObj = commission;
        const employeePayout = _.find(commission.employeePayouts, { _id: payoutDetail.id });
        if (employeePayout) {
          const payoutObj = _.find(employeePayout.payouts, { _id: payoutDetail.payoutId });
          if (payoutObj) {
            payoutObj.status = constant['PAYOUT_STATUS']['CLOSED'];
            const index = _.findIndex(employeePayout.payouts, { _id: payoutObj._id });
            employeePayout.payouts.splice(index, 1, payoutObj);
            const eIndex = _.findIndex(commission.employeePayouts, { _id: employeePayout._id });
            commissionObj.employeePayouts.splice(eIndex, 1, employeePayout);
            axios
              .put(`/api/commissions/commissions/${commissionObj._id}`, { commission: commissionObj })
              .then((res) => {
                this.setState({
                  toastOpen: true,
                  notificationText: "Sucessfully closed the transaction",
                  notifyType: "info"
                });
                this.getLineItemsAndExpenses();
              }).catch((err) => {
                this.setState({
                  toastOpen: true,
                  notificationText: err.message || "Failed to close the transaction",
                  notifyType: "error"
                });
              });
          }
        }
      });
    } else {
      const expenseObj = _.find(this.state.expenses, { _id: payoutDetail.id });
      if (expenseObj) {
        expenseObj.status = constant['PAYOUT_STATUS']['CLOSED'];
        axios
          .put(`/api/expenses/${expenseObj._id}`, { expense: expenseObj })
          .then((res) => {
            this.setState({
              toastOpen: true,
              notificationText: "Sucessfully closed the transaction",
              notifyType: "info"
            });
            this.getLineItemsAndExpenses();
          }).catch((err) => {
            this.setState({
              toastOpen: true,
              notificationText: err.message || "Failed to close the transaction",
              notifyType: "error"
            });
          });
      }
    }
  }

  renderDocFloater = (employeePayoutDetail) => {
    if(employeePayoutDetail.docs && employeePayoutDetail.docs.length > 0) {
      return (
        <div>
          {employeePayoutDetail.docs.map((doc) =>
            <div className="file-status-bar" key={doc._id}>
              <div>
                <div className="file-type-logo"></div>
                <div className="file-type">{doc.type}</div>
                <a className="file-name" href={doc.path} target="_blank" rel="noopener noreferrer">{doc.name}</a>
              </div>
            </div>
          )}
        </div>
      )
    } else {
      return <div></div>
    }
  }

  pdfViewer = (docs) => {
    if (docs) {
      this.setState({ expenseDocs: [...docs] })
    } else {
      this.setState({ expenseDocs: [] })
    }
    this.setState({ openPdfviwer: true })
  }

  renderTableData = () => {
    this.index = 0;
    const _renderItems = [];
    let displayData = [];
    Object.keys(this.state.employeePayouts).forEach((key, _index) => {
      this.state.employeePayouts[key].forEach((item, index) => { 
        displayData.push(item);
      })
    })
    displayData.forEach((item, index) => {
      _renderItems.push(<>
        <tr>
          <td style={{minWidth: '150px'}}>{item.employeeName}</td>
          <td style={{minWidth: '100px'}}>{moment(item.date).format('MM/DD/YYYY')}</td>
          <td style={{minWidth: '100px'}}>{item.title}</td>
          <td style={{minWidth: '100px'}}>{item.customerName}</td>
          <td style={{minWidth: '100px'}}>{item.type === 'Payout' ? Number(item.grossIncome).toFixed(2) : 0}</td>
          <td style={{minWidth: '100px'}}>{item.type === 'Payout' ? 0 : Number(item.grossIncome).toFixed(2)}</td>
          <td style={{minWidth: '100px'}}>{Number(item.owed).toFixed(2)}</td>
          <td style={{minWidth: '100px'}}>
            <VisibilityIcon style={{fontSize: '20px', marginRight: '5px'}} onClick={() => this.pdfViewer(item.docs)} />
            <input type="number" placeholder="Pay Here..." id="amountPaid" onChange={(e) => this.setTransactionDetails(e, item)} autoComplete="off" pattern="[0-9]*" style={{width: "130px"}}/>
          </td>
          <td style={{minWidth: '100px'}}><input type="text" placeholder="Optional Reason Here" id="reason" onChange={(e) => this.setTransactionDetails(e, item)} autoComplete="off"/></td>
          <td style={{minWidth: '100px'}}><button  className="grey-btn" onClick={this.updatePayouts}>Pay Now</button></td>
          <td>
            <button className="toggle-btn" onClick={() => this.closePayoutTransaction(item)}><img src={trashIcon} alt= ""/></button>
          </td>
          <td>
            <button className="toggle-btn" onClick={(e)=>this.toggleCollapse(e, index)}>{this.state.data[index].collapsed ? <UpIcon /> : <DownIcon />}</button>
          </td>
        </tr>
        <tr className={`${!this.state.data[index].collapsed ? 'd-none' : ''} sub-thead`}>
          <td>Paid On</td>
          <td>Paid By</td>
          <td>Amount Paid</td>
          <td>Reason</td>
        </tr>
        {
          item.transactions.map((transaction, tIndex) => {
            return <tr className={`${!this.state.data[index].collapsed ? 'd-none' : ''} ${tIndex < item.transactions.length - 1 ? 'sub-tbody' : ''}`}>
            <td>{moment(transaction.datePaid).format('MM/DD/YYYY')}</td>
            <td>{transaction.paidBy}</td>
            <td>{Number(transaction.amountPaid).toFixed(2)}</td>
            <td>{transaction.reason}</td>
          </tr>
          })
        }
        </>
      );
    })
    return _renderItems
  }

  renderPayoutsData() {
    const moment = require('moment');
    let cards = [];
    if(Object.getOwnPropertyNames(this.state.employeePayouts).length > 0) {
      _.mapKeys(this.state.employeePayouts, (payoutDetails, employeeId) => {
        const elementPayoutDetailsByEmployee =
          (
            <div className="pay-element">
              <div className="header">
                <div className="title">{payoutDetails[0].employeeName}</div>
              </div>
              {
                payoutDetails.map((employeePayoutDetail, index) => {
                  const elementPayoutDetail = (
                    <div className="element-content">
                      <div className="left">
                        <div className="date">
                          <div className="text">Date</div>
                          <div className="value">{moment(employeePayoutDetail.date).format('MM/DD/YYYY')}</div>
                        </div>
                        <div className="sub-title">
                          <div className="text">Title</div>
                          <div className="value">
                            {employeePayoutDetail.title}
                            {
                              this.renderDocFloater(employeePayoutDetail)
                            }
                          </div>
                        </div>
                        <div className="customer">
                          <div className="text">Customer</div>
                          <div className="value">
                            {employeePayoutDetail.customerName}
                          </div>
                        </div>
                        <div className="gross">
                          <div className="text">Gross</div>
                          <div className="value">
                            {employeePayoutDetail.grossIncome.toFixed(2)}
                          </div>
                        </div>
                        <div className="owed">
                          <div className="text">Owed</div>
                          <div className="value">{employeePayoutDetail.owed}</div>
                        </div>
                        <div className="pay">
                          <div className="text">Pay</div>
                          <div className="value">
                            <input
                              placeholder="Pay Here..."
                              // className="amountPaid"
                              id="amountPaid"
                              onChange={(e) => this.setTransactionDetails(e, employeePayoutDetail)}
                              autoComplete="off"
                              type="number"
                              pattern="[0-9]*"
                            />
                          </div>
                        </div>
                        <div className="reason">
                          <div className="text">Reason</div>
                          <div className="value">
                            <input
                              type={'text'}
                              placeholder="Enter Reason (optional)"
                              id="reason"
                              onChange={(e) => this.setTransactionDetails(e, employeePayoutDetail)}
                              autoComplete="off"
                            />
                          </div>
                        </div>
                        <div className="pay-btn">
                          <div className="grey-btn" onClick={this.updatePayouts}>Pay Now</div>
                          <div className="grey-btn" onClick={() => this.closePayoutTransaction(employeePayoutDetail)}>Delete</div>
                        </div>
                      </div>
                      <div className="right">
                        <div className="headers">
                          <div className="field">Paid On</div>
                          <div className="field">Paid By</div>
                          <div className="field">Amount Paid</div>
                          <div className="field">Reason</div>
                        </div>
                        <div className="records">
                          {
                            employeePayoutDetail.transactions.map((transaction, index) => {
                              return (
                                <>
                                  <div className="record">
                                    <div className="field-record">{moment(transaction.datePaid).format('MM/DD/YYYY')}</div>
                                    <div className="field-record">{transaction.paidBy}</div>
                                    <div className="field-record">{Number(transaction.amountPaid).toFixed(2)}</div>
                                    <div className="field-record">{transaction.reason}</div>
                                  </div>
                                  <div className="record-mobile">
                                    <div className="field-record">
                                      <div>Paid On:</div>
                                      <div>{moment(transaction.datePaid).format('MM/DD/YYYY')}</div>
                                    </div>
                                    <div className="field-record">
                                      <div>Paid By:</div>
                                      <div>{transaction.paidBy}</div>
                                    </div>
                                    <div className="field-record">
                                      <div>Amount Paid:</div>
                                      <div>{Number(transaction.amountPaid).toFixed(2)}</div>
                                    </div>
                                    <div className="field-record">
                                      <div>Reason:</div>
                                      <div>{transaction.reason}</div>
                                    </div>
                                  </div>
                                </>
                              )
                            })
                          }
                        </div>
                      </div>
                    </div>
                  );
                  return elementPayoutDetail;
                })
              }
            </div>
          )
        cards.push(elementPayoutDetailsByEmployee);
      });
    }
    
    return cards;
  }

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  handleClose = () => {
    this.setState({
      toastOpen: false
    });
  };

  goToRegister = () => {
    GAevent('Guest', 'Pay Page', `Guest user: ${this.props.auth.user.name}  clicked Rigster button to create a new account`);
    const guestId = this.props.auth.user.id;
    this.props.logoutUser();
    this.props.history.push(`/register?guest=${guestId}`);
  }

  noTakeLive = () => {
    this.setState({
      takeLive: false
    })
  }

  goToSubscribe = () => {
    this.props.history.push("/account");
  }

  noSubscribe = () => {
    let subSkippedCount = localStorage.getItem("SubSkippedCount");
    if (subSkippedCount === null) subSkippedCount = 1;
    else subSkippedCount = parseInt(subSkippedCount) + 1;
    localStorage.setItem("SubSkippedCount", subSkippedCount);
    this.setState({
      subscribeQuestion: false
    });
  }

  toggleCollapse = (e, index) => {
    let data = this.state.data
    data[index].collapsed = !data[index].collapsed
    if(data[index].collapsed) {
      e.target.parentNode.parentNode.parentNode.style.border = 'none';
    } else {
      e.target.parentNode.parentNode.parentNode.style.borderBottom = '0.5px solid rgba(14, 40, 63, 0.38)';
    }
    this.setState({data})
  }

  removeTotal = async (item) => {
    let commissions = this.state.commissions.filter((item) => item.userId === this.props.auth.user.id)
    let tempCommission = []
    for (let commission of commissions) {
      for (let employeePayout of commission.employeePayouts) {
        if (employeePayout.employee.name === item.employeeName) {
          tempCommission.push(commission)
        }
      }
    }

    for (let item of tempCommission) {
      for (let employeePayout of item.employeePayouts) {
        for (let payout of employeePayout.payouts) {
          payout.status = constant['PAYOUT_STATUS']['CLOSED']
        }
      }
    }
    for (let item of tempCommission) {
      await axios.put(`/api/commissions/commissions/${item._id}`, {commission: item})
    }

    let expenses = [];
    for (let expense of this.state.expenses) {
      expense.status === constant['PAYOUT_STATUS']['OPEN'] && expenses.push(expense)
    }
    let finalExpenses = expenses.filter((expense) => expense.employeeId === item.employeeId)
    for (let expense of finalExpenses) {
      expense.status = constant['PAYOUT_STATUS']['CLOSED']
      await axios.put(`/api/expenses/${expense._id}`, {expense: expense})
      .then((res) => {
        this.setState({
          toastOpen: true,
          notificationText: "Successfully closed the transaction",
          notifyType: "info"
        })
      })
    }
    this.getLineItemsAndExpenses()
  }

  totalShow = () => {
    let displayData = [];
    let _renderTotalItems = []
    Object.keys(this.state.employeePayouts).forEach((key, _index) => {
      this.state.employeePayouts[key].forEach((item, index) => { 
        displayData.push(item);
      })
    })

    const totalDisplay = displayData.reduce((acc, { employeeName, owed,  grossIncome, ...rest}) => {
      const existing = acc.find(item => item.employeeName === employeeName);
      if (existing) {
        existing.grossIncome += grossIncome;
        existing.owed = Number(existing.owed);
        existing.owed += Number(owed);
      } else {
        acc.push({ employeeName, grossIncome, owed, ...rest });
      }
      return acc;
    }, []);

    totalDisplay.forEach((item) => {
      _renderTotalItems.push(<>
        <tr style={{border: "none"}}>
          <td>{item.employeeName}</td>
          <td>{moment(item.date).format('MM/DD/YYYY')}</td>
          <td></td>
          <td></td>
          <td>{Number(item.grossIncome).toFixed(2)}</td>
          <td>{Number(item.owed).toFixed(2)}</td>
          <td style={{textAlign: "center"}} colSpan={3}><button  className="grey-btn" onClick={() => this.payFullTotal(item)} style={{borderRadius: "12px", padding: "0px 90px 0px 30px"}} >Pay Full Total Owed</button></td>
          <td>
            <button onClick={() => this.removeTotal(item)} className="toggle-btn"><img src={trashIcon} alt= ""/></button>
          </td>
        </tr>
      </>)
    })
    return _renderTotalItems
  }

  payFullTotal = (item) => {
    let expenses = this.state.expenses.filter((expense) => expense.employeeId === item.employeeId)
    let totalExpenses = expenses.filter((expense) => expense.status !== constant['PAYOUT_STATUS']['CLOSED'])
    for (let expense of totalExpenses) {
      let totalPaid = 0;
      for (let transaction of expense.transactions) {
        totalPaid += transaction.amountPaid
      }
      let totalTransaction = {
        amountPaid: expense.total - totalPaid,
        paidBy: this.props.auth.user.name,
        reason: "Paid Totally",
      }
      expense.transactions.push(totalTransaction)
      expense.status = constant['PAYOUT_STATUS']['CLOSED']
      axios.put(`/api/expenses/${expense._id}`, {expense: expense})
    }

    let tempCommission = []
    for (let commission of this.state.commissions) {
      for (let employeePayout of commission.employeePayouts) {
        if (employeePayout.employee.name === item.employeeName) {
          tempCommission.push(commission)
        }
      }
    }

    // let commissions = this.state.commissions
    tempCommission.forEach((commission) => {
      commission.employeePayouts.forEach((employeePayout) => {
        employeePayout.payouts.forEach((payOut) => {
          if (payOut.status === constant['PAYOUT_STATUS']['OPEN']) {
            let totalPaid = 0;
            for (let transaction of payOut.transactions) {
              totalPaid += transaction.amountPaid;
            }
            let totalTransaction = {
              amountPaid: payOut.total - totalPaid,
              paidBy: this.props.auth.user.name,
              reason: "Paid Totally",
            }
            payOut.transactions.push(totalTransaction)
            payOut.status = constant['PAYOUT_STATUS']['CLOSED']
          }
        })
      })
    })

    for (let commission of tempCommission) {
      axios.put(`/api/commissions/commissions/${commission._id}`, {commission: commission})
      .then((res) => {
        this.setState({
          toastOpen: true,
          notificationText: "Successfully made payments!",
          notifyType: "info"
        })
      })
    }
    this.props.history.push("/report/pay-report?currentDay=true");

    this.getLineItemsAndExpenses()
  }

  render() {
    const {
      toastOpen,
      notificationText,
      notifyType,
      subscriptionText
    } = this.state;

    return (
      <>
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity={notifyType} sx={{ width: '100%', fontSize: 24 }}>
            {notificationText}
          </Alert>
        </Snackbar>
        <div className="paynow-page">
          <div className="page-title">
            Decide how much to pay your employees. If you pay a partial payment amount, you will be reminded next pay period an amount you still owe that employee.
          </div>
          <div className="pay-content">
            {(this.state.employeePayouts && !_.isEmpty(this.state.employeePayouts)) ?
              <div>
                <table className="payments-table">
                  <thead>
                    <tr>
                      <td style={{minWidth: '150px'}}>Name</td>
                      <td style={{minWidth: '100px'}}>Date</td>
                      <td style={{minWidth: '100px'}}>Commission</td>
                      <td style={{minWidth: '100px'}}>Customer</td>
                      <td style={{minWidth: '100px'}}>Gross</td>
                      <td style={{minWidth: '100px'}}>Expense</td>
                      <td style={{minWidth: '100px'}}>Owed</td>
                      <td style={{minWidth: '100px'}}>Pay</td>
                      <td style={{minWidth: '100px'}}>Reason</td>
                      <td style={{minWidth: '100px'}}>Pay Now</td>
                      <td style={{minWidth: '100px'}}>Delete</td>
                      <td style={{minWidth: '100px'}}></td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.data && this.state.data.length > 0 && this.renderTableData()
                    }
                    <tr style={{borderTop: "4px solid black", padding: "20px"}}>
                      <td style={{fontSize: "20px"}}>Totals</td>
                    </tr>
                    {
                      this.totalShow()
                    }
                  </tbody>
                </table>
              </div>
              :
              <div className="no-pay">No One to Pay Now</div>
            }
          </div>
        </div>

        <Modal
          open={this.state.takeLive}
        >
          <Box sx={style}>
            <h2 className="mt-4 mb-2 mx-2 font-bold" style={{ color: '#22395B' }}>Welcome to CommissionPayPro!</h2>
            <h4 className="mt-2 mb-4 mx-2" style={{ color: '#7B8FB2' }}>Save your work and come back - Just <span style={{ color: '#508BF4' }}>99¢</span> for the first 7 days, then <span style={{ color: '#508BF4' }}>$39.95/mo</span> for up to 5 users. Cancel Anytime</h4>
            <div className="display-flex justify-center align-center mb-4">
              <div className="btn-blue mr-2" style={{ padding: '8px 18px' }} onClick={this.goToRegister}>
                Take Live
              </div>
              <div className="cancel-btn ml-2" style={{ padding: '8px 18px' }} onClick={this.noTakeLive}>
                Skip for Now
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={this.state.subscribeQuestion}
        >
          <Box sx={style}>
            <h2 className="mt-4 mb-2 mx-2 font-bold" style={{ color: '#22395B' }}>Welcome to CommissionPayPro!</h2>
            <h4 className="mt-2 mb-4 mx-2" style={{ color: '#7B8FB2' }}>
              {
                subscriptionText
              }
            </h4>
            
            <div className="display-flex justify-center align-center mb-4">
              <div className="btn-blue mr-2" style={{ padding: '8px 18px' }} onClick={this.goToSubscribe}>
                Subscribe
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
          open={this.state.openPdfviwer}
          onClose={() => this.setState({ openPdfviwer: false })}
        >
          <Box sx={style}>
            <h2 className={modalTitle}>Expense Report</h2>
            <div style={modalContent}>
            {
              this.state.expenseDocs && this.state.expenseDocs.length > 0 
              ? this.state.expenseDocs.map((docs) => <div style={{fontSize: '18px'}}>
                <a href={docs.path} target="_blank" rel="noopener noreferrer">{docs.name}</a>
              </div>)
              : <div style={{fontSize: '18px'}}>There is no expense Docs</div>
            }
            </div>
          </Box>
        </Modal>
      </>
    )
  }
}

Pay.propTypes = {
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { logoutUser }
)(Pay)