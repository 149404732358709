import React, { Component } from 'react';
import { connect } from "react-redux";
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PlusIcon from '@mui/icons-material/AddCircleOutline';
import { fabClasses } from '@mui/material';

class CardView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAction: 0
        }

        this.actionRef = React.createRef();
    }

    componentDidMount() {
        document.addEventListener("mousedown", this.checkIfClickedOutside)
    }

    checkIfClickedOutside = e => {
        if (this.state.isAction !== 0 && this.actionRef.current && !this.actionRef.current.contains(e.target)) {
            this.setState({
                isAction: 0,
            });
        }
    }

    onAction = (id) => {
        this.setState({
            isAction: id
        });
    }

    onEdit = (data) => {
        this.props.onEdit(data);
    }

    onEmail = (data) => {
        this.props.onEmail(data);
    }

    onDelete = (data) => {
        this.props.onDelete(data);
    }

    addNew = () => {
        this.props.addNew();
    }

    _renderCardRecord = (employee, ind) => {
        const { name,  role, email, phone, status, verified} = employee;
        // const shortName = name.split(" ")[0][0].toUpperCase() + name.split(" ")[1][0].toUpperCase();

        return  <div className="card-record">
                    <div className={status === 0 ? "card-image" : "card-image card-image-light"}>
                        
                    </div>
                    <div className="card-content">
                        <div className="title">
                            {name}
                        </div>
                        <div className="card-column display-flex justify-start align-center">
                            <span>
                                <PersonIcon fontSize='medium' />
                            </span>
                            <span>{role}</span>
                        </div>
                        <div className="card-column display-flex justify-start align-center">
                            <span>
                                <EmailIcon fontSize='medium' />
                            </span>
                            <span>{email}</span>
                        </div>
                        <div className="card-column display-flex justify-start align-center">
                            <span>
                                <PhoneIcon fontSize='medium' />
                            </span>
                            {
                                phone === '' ? <span>
                                    <MoreHoriz fontSize='medium' />
                                </span> : <span>+{phone}</span>
                            }
                        </div>
                        <div className="card-column display-flex justify-start align-center">
                            <span>
                                <VisibilityIcon fontSize='medium' />
                            </span>
                            {
                                status === 0 && verified === false && <span>Working</span> 
                            }
                            {
                                status === 1 && verified === false && <span>Emailed</span> 
                            }
                            {
                                verified === true && <span>Active</span>
                            }
                        </div>
                        <div className="card-action display-flex justify-around align-center">
                            <span onClick={() => this.onEdit(employee)}>
                                <EditIcon fontSize='small' />
                            </span>
                            {
                                status === 0 && <span onClick={() => this.onEmail(employee)}>
                                                    <EmailIcon fontSize='small' />
                                                </span>
                            }
                            <span onClick={() => this.onDelete(employee)}>
                                <DeleteIcon fontSize='small' />
                            </span>
                        </div>
                    </div>
                </div> 
    }

    _renderEmptyCardRecord = () => {
        return <div className="card-record empty-record" onClick={this.addNew}>
                    <div className={"card-image"}>
                        
                    </div>
                    <div className="card-content">
                        <PlusIcon fontSize={'large'} />
                    </div>
                </div> 
    }

    render() {
        const { employees } = this.props;

        return <div className="card-view-container">
            <div className="card-view-body">
                {
                    employees.length !== 0 && employees.map((employee, index) => {
                        return this._renderCardRecord(employee, index)
                    })
                }
                {
                    employees.length === 0 && this._renderEmptyCardRecord()
                }
            </div>
        </div>;
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
  
export default connect(
    mapStateToProps,
    {  }
)(CardView);

// export default Table;