import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Divider from '@mui/material/Divider';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import logo from '../assets/images/logo.png';
import admin from '../assets/images/icons/admin.png';
import admin_active from '../assets/images/icons/admin-active.png';
import dashboard_icon from '../assets/images/icons/dashboard.png';
import dashboard_icon_active from '../assets/images/icons/dashboard-active.png';
import account_icon from '../assets/images/icons/account.png';
import account_icon_active from '../assets/images/icons/account-active.png';
import employees_icon from '../assets/images/icons/employee.png';
import employees_icon_active from '../assets/images/icons/employee-active.png';
import commission_icon from '../assets/images/icons/commission.png';
import commission_icon_active from '../assets/images/icons/commission-active.png';
import fund_icon from '../assets/images/icons/funds.png';
import fund_icon_active from '../assets/images/icons/funds-active.png';
import pay_icon from '../assets/images/icons/pay.png';
import pay_icon_active from '../assets/images/icons/pay-active.png';
import learn_icon from '../assets/images/icons/learn.png';
import pay_report_icon from '../assets/images/icons/report.png';
import avatar_default from '../assets/images/avatar-default.png';
import payroll_icon from '../assets/images/payroll-report-icon.png';
import gross_sales from '../assets/images/gross-sales-icon.png';
import history_icon from '../assets/images/payroll-history-icon.png';
import expense_icon from '../assets/images/expense-report-icon.png';
import admin_icon from '../assets/images/admin-icon.png';
import logout from "../assets/images/icons/logout.png"
import Switch from '@mui/material/Switch';

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logoutUser, toggleSidebar, switchMode } from "../actions/authActions";
import constant from "../shared/constant";

class Layout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sidebarWidth: 310,
            minSidebarWidth: 90,
            screenWidth: window.innerWidth,
            screenPadding: 80,
            mobileView: false,
            mobileViewSidebar: false,
            isSearch: false,
            subMenu: false, 
        }

        this.subMenuRef = React.createRef();
    }

    updateDimensions = () => {
        if (window.innerWidth <= 768) {
            this.setState({ mobileView: true, screenPadding: 20 });
        } else {
            this.setState({ mobileView: false });
            if (window.innerWidth < 1730 && window.innerWidth >= 1635) {
                this.setState({ screenPadding: 30 });
            } else if (window.innerWidth >= 1730) {
                this.setState({ screenPadding: 80 });
                this.props.toggleSidebar(true);
            } else {
                this.setState({ screenPadding: 30 });
                this.props.toggleSidebar(false);
            }
        }
    };

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();

        document.addEventListener("mousedown", this.checkIfClickedOutside)

        // if (this.props.auth.user.role === constant['ROLE']['EMPLOYEE'])  
        //     this.props.props.history.push('/employeeHome');
    }

    checkIfClickedOutside = e => {
        if (this.state.subMenu && this.subMenuRef.current && !this.subMenuRef.current.contains(e.target)) {
            this.setState({
                subMenu: false,
            });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    toggleSidebar = () => {
        const { isSidebar } = this.props.auth;
        this.props.toggleSidebar(!isSidebar);
    }

    toggleSubMenu = () => {
        this.setState({
            subMenu: !this.state.subMenu
        });
    }

    onNavItemClick = () => {
        this.setState({
            subMenu: false
        });
    }

    _renderSidebar = () => {
        const { sidebarWidth, minSidebarWidth, subMenu } = this.state;
        const { isSidebar } = this.props.auth;
        const currentPath = this.props.props.location.pathname;

        return (
            <div className="sidebar" style={isSidebar ? { width: sidebarWidth + 'px' } : { width: minSidebarWidth + 'px' }}>
                <div className="sidebar-content">
                    <div className="drawer-icon display-flex justify-center align-center" onClick={this.toggleSidebar}>
                        {
                            isSidebar ? <span><MenuOpenIcon sx={{ color: '#508BF4' }} /></span> : <span><MenuIcon sx={{ color: '#508BF4' }} /></span>
                        }
                    </div>
                    <Link to="/" className="sidebar-header display-flex justify-start align-center">
                        <div className="logo">
                            <img src={this.props.auth.user.logo ? this.props.auth.user.logo : logo} alt="logo" />
                        </div>
                        <div className="logo-text primary-text font-medium">
                            {this.props.auth.user.companyName ? this.props.auth.user.companyName : 'Commission Pay Pro'}
                        </div>
                    </Link>
                    {
                        (this.props.auth.user.role === constant['ROLE']['EMPLOYEE'] || this.props.auth.user.role === constant['ROLE']['CONTRACTOR'] || this.props.auth.user.role === constant['ROLE']['MANAGER']) && <div className="nav-list display-flex flex-column justify-center">
                            <Link to="/employee-pay" className={currentPath === "/employee-pay" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"} onClick={this.onNavItemClick}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/employee-pay" ? pay_icon : pay_icon} alt="dashbaord-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">My Pay</div>
                                {
                                    isSidebar ? null : <div className="nav-tip display-flex justify-center align-center">
                                        <span>My Pay</span>
                                    </div>
                                }
                                {
                                    isSidebar ? null : <div className="nav-tip-arrow"></div>
                                }
                            </Link>
                            <Link to="/expense" className={currentPath === "/expense" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"} onClick={this.onNavItemClick}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/expense" ? expense_icon : expense_icon} alt="account-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Expense</div>
                                {
                                    isSidebar ? null : <div className="nav-tip display-flex justify-center align-center">
                                        <span>Expense</span>
                                    </div>
                                }
                                {
                                    isSidebar ? null : <div className="nav-tip-arrow"></div>
                                }
                            </Link>
                            <div className="nav-item nav-item display-flex justify-start align-center" onClick={() => this.logout()}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={logout} alt="log out" />
                                </div>
                                <div className="nav-icon__text font-medium">Log Out</div>
                                {
                                    isSidebar ? null : <div className="nav-tip display-flex justify-center align-center">
                                        <span>Log Out</span>
                                    </div>
                                }
                                {
                                    isSidebar ? null : <div className="nav-tip-arrow"></div>
                                }
                            </div>
                        </div>
                    }
                    {
                        (this.props.auth.user.role === constant['ROLE']['OWNER'] || this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) && <div className="nav-list display-flex flex-column justify-center">
                            {
                                this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN'] &&
                                <Link to="/admin/users" className={currentPath === "/admin/users" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"} onClick={this.onNavItemClick}>
                                    <div className="nav-icon display-flex justify-center align-center">
                                        <img src={currentPath === "/admin/users" ? admin_active : admin} alt="admin-icon" />
                                    </div>
                                    <div className="nav-icon__text font-medium">Admin</div>
                                    {
                                        isSidebar ? null : <div className="nav-tip display-flex justify-center align-center">
                                            <span>Admin</span>
                                        </div>
                                    }
                                    {
                                        isSidebar ? null : <div className="nav-tip-arrow"></div>
                                    }
                                </Link>
                            }
                            <Link to="/" className={currentPath === "/" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"} onClick={this.onNavItemClick}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/" ? dashboard_icon_active : dashboard_icon} alt="dashbaord-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Dashboard</div>
                                {
                                    isSidebar ? null : <div className="nav-tip display-flex justify-center align-center">
                                        <span>Dashboard</span>
                                    </div>
                                }
                                {
                                    isSidebar ? null : <div className="nav-tip-arrow"></div>
                                }
                            </Link>
                            <Link to="/account" className={currentPath === "/account" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"} onClick={this.onNavItemClick}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/account" ? account_icon_active : account_icon} alt="account-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Account</div>
                                {
                                    isSidebar ? null : <div className="nav-tip display-flex justify-center align-center">
                                        <span>Account</span>
                                    </div>
                                }
                                {
                                    isSidebar ? null : <div className="nav-tip-arrow"></div>
                                }
                            </Link>
                            <Link to="/employees" className={currentPath === "/employees" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"} onClick={this.onNavItemClick}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/employees" ? employees_icon_active : employees_icon} alt="employees-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Employees</div>
                                {
                                    isSidebar ? null : <div className="nav-tip display-flex justify-center align-center">
                                        <span>Employees</span>
                                    </div>
                                }
                                {
                                    isSidebar ? null : <div className="nav-tip-arrow"></div>
                                }
                            </Link>
                            <Link to="/commissions" className={currentPath === "/commissions" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"} onClick={this.onNavItemClick}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/commissions" ? commission_icon_active : commission_icon} alt="commission-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Set Commission</div>
                                {
                                    isSidebar ? null : <div className="nav-tip display-flex justify-center align-center">
                                        <span>Set Commission</span>
                                    </div>
                                }
                                {
                                    isSidebar ? null : <div className="nav-tip-arrow"></div>
                                }
                            </Link>
                            <Link to="/receive-funds" className={currentPath === "/receive-funds" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"} onClick={this.onNavItemClick}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/receive-funds" ? fund_icon_active : fund_icon} alt="receive-funds-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Gross Sales or Employee Expenses</div>
                                {
                                    isSidebar ? null : <div className="nav-tip display-flex justify-center align-center">
                                        <span>Gross Sales / Expenses</span>
                                    </div>
                                }
                                {
                                    isSidebar ? null : <div className="nav-tip-arrow"></div>
                                }
                            </Link>
                            <Link to="/pay" className={currentPath === "/pay" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"} onClick={this.onNavItemClick}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/pay" ? pay_icon_active : pay_icon} alt="pay-now-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Pay Now</div>
                                {
                                    isSidebar ? null : <div className="nav-tip display-flex justify-center align-center">
                                        <span>Pay Now</span>
                                    </div>
                                }
                                {
                                    isSidebar ? null : <div className="nav-tip-arrow"></div>
                                }
                            </Link>
                            <div className={"nav-item display-flex justify-start align-center"} onClick={this.toggleSubMenu}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={pay_report_icon} alt="pay-report-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Report</div>
                                <div className={subMenu ? "report-addon addon-down" : "report-addon"}>
                                    <ArrowRightIcon />
                                </div>
                                {
                                    isSidebar ? null : <div className="nav-tip display-flex justify-center align-center">
                                        <span>Report</span>
                                    </div>
                                }
                                {
                                    isSidebar ? null : <div className="advanced-nav-tip-arrow-2"><ArrowLeftIcon /></div>
                                }
                                {
                                    isSidebar ? null : subMenu ? <div className="advanced-nav-tip" ref={this.subMenuRef}>
                                        <Link to="/report/pay-report" className={currentPath.includes("/pay-report") ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                            <div className="nav-icon display-flex justify-center align-center">
                                                <img src={payroll_icon} alt="Pay Report" />
                                            </div>
                                            <div className="nav-icon__text font-medium">Payroll Report</div>
                                        </Link>
                                        <Link to="/report/gross-sales" className={currentPath.includes("/gross-sales") ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                            <div className="nav-icon display-flex justify-center align-center">
                                                <img src={gross_sales} alt="Gross Sales" />
                                            </div>
                                            <div className="nav-icon__text font-medium">Gross Sales</div>
                                        </Link>
                                        <Link to="/report/payroll-history" className={currentPath.includes("/payroll-history") ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                            <div className="nav-icon display-flex justify-center align-center">
                                                <img src={history_icon} alt="Payroll History" />
                                            </div>
                                            <div className="nav-icon__text font-medium">Payroll History</div>
                                        </Link>
                                        <Link to="/report/expense-report" className={currentPath.includes("/expense-report") ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                            <div className="nav-icon display-flex justify-center align-center">
                                                <img src={expense_icon} alt="Expense Report" />
                                            </div>
                                            <div className="nav-icon__text font-medium">Expense Report</div>
                                        </Link>
                                        <Link to="/report/customer-report" className={currentPath.includes("/customer-report") ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                            <div className="nav-icon display-flex justify-center align-center">
                                                <img src={account_icon} alt="Customer Report" />
                                            </div>
                                            <div className="nav-icon__text font-medium">Customer Report</div>
                                        </Link>
                                    </div> : null
                                }
                                {
                                    isSidebar ? null : subMenu ? <div className="advanced-nav-tip-arrow"><ArrowLeftIcon /></div> : null
                                }
                            </div>
                            <div className={isSidebar && subMenu ? "sub-menu" : "sub-menu sub-menu-hide"}>
                                <Link to="/report/pay-report" className={currentPath.includes("/pay-report") ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                    <div className="nav-icon display-flex justify-center align-center">
                                        <img src={payroll_icon} alt="pay-now-icon" />
                                    </div>
                                    <div className="nav-icon__text font-medium">Payroll Report</div>
                                </Link>
                                <Link to="/report/gross-sales" className={currentPath.includes("/gross-sales") ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                    <div className="nav-icon display-flex justify-center align-center">
                                        <img src={gross_sales} alt="pay-now-icon" />
                                    </div>
                                    <div className="nav-icon__text font-medium">Gross Sales</div>
                                </Link>
                                <Link to="/report/payroll-history" className={currentPath.includes("/payroll-history") ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                    <div className="nav-icon display-flex justify-center align-center">
                                        <img src={history_icon} alt="pay-now-icon" />
                                    </div>
                                    <div className="nav-icon__text font-medium">Payroll History</div>
                                </Link>
                                <Link to="/report/expense-report" className={currentPath.includes("/expense-report") ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                    <div className="nav-icon display-flex justify-center align-center">
                                        <img src={expense_icon} alt="pay-now-icon" />
                                    </div>
                                    <div className="nav-icon__text font-medium">Expense Report</div>
                                </Link>
                                <Link to="/report/customer-report" className={currentPath.includes("/customer-report") ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                    <div className="nav-icon display-flex justify-center align-center">
                                        <img src={account_icon} alt="Customer Report" />
                                    </div>
                                    <div className="nav-icon__text font-medium">Customer Report</div>
                                </Link>
                            </div>
                            {/* <Link to="/learn" className={currentPath === "/learn" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"} onClick={this.onNavItemClick}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/learn" ? learn_icon_active : learn_icon} alt="pay-now-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Learn</div>
                                {
                                    isSidebar ? null : <div className="nav-tip display-flex justify-center align-center">
                                        <span>Learn</span>
                                    </div>
                                }
                                {
                                    isSidebar ? null : <div className="nav-tip-arrow"></div>
                                }
                            </Link> */}
                            <div className="nav-item nav-item display-flex justify-start align-center" onClick={() => this.logout()}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={logout} alt="log out" />
                                </div>
                                <div className="nav-icon__text font-medium">Log Out</div>
                                {
                                    isSidebar ? null : <div className="nav-tip display-flex justify-center align-center">
                                        <span>Log Out</span>
                                    </div>
                                }
                                {
                                    isSidebar ? null : <div className="nav-tip-arrow"></div>
                                }
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    hideMobileView = () => {
        this.setState({
            mobileViewSidebar: false
        });
    }

    hideMobileSidebar = () => {
        this.setState({
            mobileViewSidebar: false
        })
    }

    _renderMobileSidebar = () => {
        const { mobileViewSidebar, subMenu } = this.state;
        const currentPath = this.props.props.location.pathname;
        const label = { inputProps: { 'aria-label': 'Switch demo' } };

        return <div className={mobileViewSidebar ? "mobile-view display-flex justify-between overlay" : "mobile-view display-flex justify-between"} style={mobileViewSidebar ? { width: '100%', backgroundColor: "rgba($color: #000000, $alpha: 0.5)" } : { width: '0%' }}>
            <div className="mobile-sidebar" style={{ width: "310px" }}>
                <div className="sidebar-content">
                    <div className="drawer-icon display-flex justify-center align-center" onClick={this.hideMobileSidebar}>
                        <span>
                            <MenuIcon sx={{ color: '#508BF4' }} />
                        </span>
                    </div>
                    <div className="sidebar-header display-flex justify-start align-center">
                        <div className="logo">
                            <img src={this.props.auth.user.logo ? this.props.auth.user.logo : logo} alt="logo" />
                        </div>
                        <div className="logo-text primary-text font-medium">
                            {this.props.auth.user.companyName ? this.props.auth.user.companyName : 'Commission Pay Pro'}
                        </div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {
                            this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN'] && <div>
                                <Switch {...label} checked={this.props.auth.modeType} onChange={this.handleChange} />
                            </div>
                        }
                        {
                            this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN'] && <div style={{marginRight: '12px'}}>{ this.props.auth.modeType ? "Test Mode" : "Live Mode" }</div>
                        }
                    </div>
                    
                    <Divider sx={{ marginLeft: '16px', marginRight: '16px' }} />
                    {
                        (this.props.auth.user.role === constant['ROLE']['OWNER'] || this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN']) && <div className="nav-list display-flex flex-column justify-center">
                            <Link onClick={this.hideMobileSidebar} to="/admin/users" className={currentPath === "/admin-users" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/admin/users" ? admin_active : admin} alt="admin-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Admin</div>
                            </Link>
                            <Link onClick={this.hideMobileSidebar} to="/" className={currentPath === "/" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/" ? dashboard_icon_active : dashboard_icon} alt="dashbaord-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Dashboard</div>
                            </Link>
                            <Link onClick={this.hideMobileSidebar} to="/account" className={currentPath === "/account" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/account" ? account_icon_active : account_icon} alt="account-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Account</div>
                            </Link>
                            <Link onClick={this.hideMobileSidebar} to="/employees" className={currentPath === "/employees" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/employees" ? employees_icon_active : employees_icon} alt="employees-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Employees</div>
                            </Link>
                            <Link onClick={this.hideMobileSidebar} to="/commissions" className={currentPath === "/commissions" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/commissions" ? commission_icon_active : commission_icon} alt="commission-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Set Commission</div>
                            </Link>
                            <Link onClick={this.hideMobileSidebar} to="/receive-funds" className={currentPath === "/receive-funds" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/receive-funds" ? fund_icon_active : fund_icon} alt="account-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Gross Sales or Employee Expenses</div>
                            </Link>
                            <Link onClick={this.hideMobileSidebar} to="/pay" className={currentPath === "/pay" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/pay" ? pay_icon_active : pay_icon} alt="account-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Pay now</div>
                            </Link>
                            <div className={"nav-item display-flex justify-start align-center"} onClick={this.toggleSubMenu}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={pay_report_icon} alt="pay-report-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Report</div>
                                <div className={subMenu ? "report-addon addon-down" : "report-addon"}>
                                    <ArrowRightIcon />
                                </div>
                            </div>
                            <div className={subMenu ? "sub-menu" : "sub-menu sub-menu-hide"}>
                                <Link onClick={this.hideMobileSidebar} to="/report/pay-report" className={currentPath.includes('/pay-report') ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                    <div className="nav-icon display-flex justify-center align-center">
                                        <img src={payroll_icon} alt="pay-now-icon" />
                                    </div>
                                    <div className="nav-icon__text font-medium">Payroll Report</div>
                                </Link>
                                <Link onClick={this.hideMobileSidebar} to="/report/gross-sales" className={currentPath.includes('/gross-sales') ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                    <div className="nav-icon display-flex justify-center align-center">
                                        <img src={gross_sales} alt="pay-now-icon" />
                                    </div>
                                    <div className="nav-icon__text font-medium">Gross Sales</div>
                                </Link>
                                <Link onClick={this.hideMobileSidebar} to="/report/payroll-history" className={currentPath.includes('/payroll-history') ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                    <div className="nav-icon display-flex justify-center align-center">
                                        <img src={history_icon} alt="pay-now-icon" />
                                    </div>
                                    <div className="nav-icon__text font-medium">Payroll History</div>
                                </Link>
                                <Link onClick={this.hideMobileSidebar} to="/report/expense-report" className={currentPath.includes('/expense-report') ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                    <div className="nav-icon display-flex justify-center align-center">
                                        <img src={expense_icon} alt="pay-now-icon" />
                                    </div>
                                    <div className="nav-icon__text font-medium">Expense Report</div>
                                </Link>
                                <Link onClick={this.hideMobileSidebar} to="/report/customer-report" className={currentPath.includes('/customer-report') ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                    <div className="nav-icon display-flex justify-center align-center">
                                        <img src={account_icon} alt="pay-now-icon" />
                                    </div>
                                    <div className="nav-icon__text font-medium">Expense Report</div>
                                </Link>
                            </div>
                            <div className='nav-item display-flex justify-start align-center' onClick={() => this.logout()}>
                                <div className='nav-icon display-flex justify-center align-center'>
                                    <img src={logout} alt="log out" />
                                </div>
                                <div className="nav-icon__text font-medium">Log Out</div>
                            </div>
                        </div>
                    }
                    {
                        (this.props.auth.user.role === constant['ROLE']['EMPLOYEE'] || this.props.auth.user.role === constant['ROLE']['CONTRACTOR'] || this.props.auth.user.role === constant['ROLE']['MANAGER']) && <div className="nav-list display-flex flex-column justify-center">
                            <Link to="/employee-pay" className={currentPath === "/employee-pay" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/employee-pay" ? pay_icon : pay_icon} alt="dashbaord-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">My Pay</div>
                            </Link>
                            <Link to="/expense" className={currentPath === "/expense" ? "nav-item nav-item__active display-flex justify-start align-center" : "nav-item display-flex justify-start align-center"}>
                                <div className="nav-icon display-flex justify-center align-center">
                                    <img src={currentPath === "/expense" ? expense_icon : expense_icon} alt="account-icon" />
                                </div>
                                <div className="nav-icon__text font-medium">Expense</div>
                            </Link>
                            <div className='nav-item display-flex justify-start align-center' onClick={() => this.logout()}>
                                <div className='nav-icon display-flex justify-center align-center'>
                                    <img src={logout} alt="log out" />
                                </div>
                                <div className="nav-icon__text font-medium">Log Out</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    }

    showMobileSidebar = () => {
        this.setState({
            mobileViewSidebar: true
        });
    }

    toggleSearch = () => {
        this.setState({
            isSearch: !this.state.isSearch
        });
    }

    logout = () => {
        this.props.logoutUser();
        this.props.props.history.push("/login");
    }

    login = () => {
        this.props.logoutUser();
        this.props.props.history.push("/login");
    }

    register = () => {
        this.props.logoutUser();
        this.props.props.history.push("/register");
    }

    handleChange = (event) => {
        this.props.switchMode(event.target.checked);
        localStorage.setItem("modeType", event.target.checked)
    }

    _renderContentHeader = () => {
        const { mobileView, isSearch } = this.state;
        const { isAuthenticated, user } = this.props.auth;
        // console.log(this.props.auth)
        const currentPath = this.props.props.location.pathname;
        const label = { inputProps: { 'aria-label': 'Switch demo' } };

        return (
            !mobileView ? <div className="content-header display-flex justify-between align-center">
                <div className="search display-flex align-center">
                </div>
                {
                    currentPath === '/admin/users' && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={admin_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">Admin / User Management</div>
                    </div>
                }
                {
                    currentPath === '/' && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={dashboard_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">Dashboard</div>
                    </div>
                }
                {
                    currentPath === '/learn' && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={learn_icon} alt="learn" />
                        </div>
                        <div className="title-text font-bold">Learn</div>
                    </div>
                }
                {
                    currentPath === '/account' && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={account_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">Account</div>
                    </div>
                }
                {
                    currentPath === '/employees' && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={employees_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">Employees</div>
                    </div>
                }
                {
                    currentPath === '/commissions' && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={commission_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">Set Commission</div>
                    </div>
                }
                {
                    currentPath === '/receive-funds' && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={fund_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">Gross Sales or Employee Expenses</div>
                    </div>
                }
                {
                    currentPath === '/pay' && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={pay_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">Pay Now</div>
                    </div>
                }
                {
                    currentPath.includes('/pay-report') && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={pay_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">Payroll Report</div>
                    </div>
                }
                {
                    currentPath.includes('/gross-sales') && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={pay_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">Gross Sales</div>
                    </div>
                }
                {
                    currentPath.includes('/payroll-history') && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={pay_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">Payroll History</div>
                    </div>
                }
                {
                    currentPath.includes('/expense-report') && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={pay_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">Expense Report</div>
                    </div>
                }
                {
                    currentPath.includes('/customer-report') && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={account_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">Customer Report</div>
                    </div>
                }
                {
                    currentPath.includes('/expense') && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={expense_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">Enter Expenses</div>
                    </div>
                }
                {
                    currentPath.includes('/employee-pay') && <div className="page-title display-flex justify-center align-center">
                        <div className="page-icon">
                            <img src={pay_icon} alt="dashboard" />
                        </div>
                        <div className="title-text font-bold">My Pay</div>
                    </div>
                }
                <div className="user-profile display-flex justify-end align-center">
                    {
                        this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN'] && <div>
                            <Switch {...label} checked={this.props.auth.modeType} onChange={this.handleChange} />
                        </div>
                    }
                    {
                        this.props.auth.user.role === constant['ROLE']['SUPER_ADMIN'] && <div style={{marginRight: '12px'}}>{ this.props.auth.modeType ? "Test Mode" : "Live Mode" }</div>
                    }
                    <div className="profile-avatar">
                        <img src={this.props.auth.user.logo ? this.props.auth.user.logo : avatar_default} alt="avatar" />
                    </div>
                    {
                        isAuthenticated && user.role !== constant['ROLE']['GUEST'] ? null : <div style={{ marginRight: '10px' }} onClick={this.register}>
                            Register
                        </div>
                    }
                    {
                        isAuthenticated && user.role !== constant['ROLE']['GUEST'] ? <div className="" onClick={this.logout}>
                            Logout
                        </div> :
                            <div className="" onClick={this.login}>
                                Login
                            </div>
                    }
                    {
                        isAuthenticated && user.role !== constant['ROLE']['GUEST'] ? <div className="logout-icon display-flex align-center">
                            <LogoutIcon />
                        </div> : <div className="logout-icon display-flex align-center">
                            <LoginIcon />
                        </div>
                    }
                </div>
            </div> : <div className="content-header display-flex justify-center align-center">
                <div className="search display-flex align-center" style={!isSearch ? { position: 'absolute', left: "20px", width: "100px" } : { position: 'absolute', left: "20px", width: "90%" }}>
                    <span className="drawer-icon display-flex justify-center align-center" onClick={this.showMobileSidebar}>
                        <MenuIcon sx={{ color: '#508BF4' }} />
                    </span>
                    <input className="search-placeholder" placeholder="Search for anything" style={!isSearch ? { width: "0%" } : { width: 'calc(100vw - 300px)' }} />
                </div>
                {
                    currentPath === '/admin/users' && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">Admin / User Management</div>
                    </div>
                }
                {
                    currentPath === '/' && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">Dashboard</div>
                    </div>
                }
                {
                    currentPath === '/account' && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">Account</div>
                    </div>
                }
                {
                    currentPath === '/employees' && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">Employees</div>
                    </div>
                }
                {
                    currentPath === '/commissions' && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">Set Commission</div>
                    </div>
                }
                {
                    currentPath === '/receive-funds' && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">Gross Sales or Employee Expenses</div>
                    </div>
                }
                {
                    currentPath === '/pay' && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">Pay Now</div>
                    </div>
                }
                {
                    currentPath.includes('/pay-report') && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">Payroll Report</div>
                    </div>
                }
                {
                    currentPath.includes('/gross-sales') && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">Gross Sales</div>
                    </div>
                }
                {
                    currentPath.includes('/payroll-history') && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">Payroll History</div>
                    </div>
                }
                {
                    currentPath.includes('/expense-report') && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">Expense Report</div>
                    </div>
                }
                {
                    currentPath.includes('/customer-report') && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">Customer Report</div>
                    </div>
                }
                {
                    currentPath.includes('/expense') && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">Enter Expenses</div>
                    </div>
                }
                {
                    currentPath.includes('/employee-pay') && <div className="page-title display-flex justify-center align-center">
                        <div className="title-text font-bold">My Pay</div>
                    </div>
                }
            </div>
        );
    }

    render() {
        const { sidebarWidth, minSidebarWidth, screenPadding, mobileView } = this.state;
        const { isSidebar } = this.props.auth;
        return (
            <div className="layout-wrapper display-flex justify-between">
                {
                    !mobileView && this._renderSidebar()
                }
                {
                    mobileView && this._renderMobileSidebar()
                }
                <div className="content" style={mobileView ? isSidebar ? { paddingLeft: screenPadding + 'px', paddingRight: screenPadding } : { paddingLeft: screenPadding + 'px', paddingRight: screenPadding } : isSidebar ? { paddingLeft: sidebarWidth + screenPadding + 'px', paddingRight: screenPadding } : { paddingLeft: minSidebarWidth + screenPadding + 'px', paddingRight: screenPadding }}>
                    {
                        this._renderContentHeader()
                    }
                    <div className="pages">
                        {
                            this.props.children
                        }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

Layout.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    switchMode: PropTypes.func.isRequired,
}

export default connect(
    mapStateToProps,
    { logoutUser, toggleSidebar, switchMode }
)(Layout);