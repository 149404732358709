import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import _ from 'lodash';

import { Grid } from "@mui/material";
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

import lineFloat from "../../assets/images/login/line.png";
import curveFloat from "../../assets/images/login/curve.png";
import moonFloat from "../../assets/images/login/moon.png";
import banner from "../../assets/images/login/banner.png";
import logo from "../../assets/images/logo.png";
import InputForm from '../component/InputForm';


import { GApageView } from "../../shared/gaUtils";
import axios from "axios";
import validator from "validator";

class AccessDemo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toastOpen: false
    };
  }

  componentDidMount() {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    GApageView("Access-Demo");
  }

  componentWillUnmount() {
    document.body.classList.remove("login-page");
    document.body.classList.remove("sidebar-collapse");
  }

  onChange = e => {
    this.setState({ [e.id]: e.value });
  };

  validateForm = () => {
    if (_.isEmpty(this.state.name)) {
      this.setState({
        toastOpen: true,
        notificationText: "Please put your name"
      });
      setTimeout(() => {
        this.setState({
          toastOpen: false,
          notificationText: ""
        });
      }, 3000);
      return false;
    }
    if (_.isEmpty(this.state.email)) {
      this.setState({
        toastOpen: true,
        notificationText: "Please put your email"
      });
      setTimeout(() => {
        this.setState({
          toastOpen: false,
          notificationText: ""
        });
      }, 3000);
      return false;
    }
    if (!validator.isEmail(this.state.email)) {
      this.setState({
        toastOpen: true,
        notificationText: "Please put a correct email."
      });
      setTimeout(() => {
        this.setState({
          toastOpen: false,
          notificationText: ""
        });
      }, 3000);
      return false;
    }
    if (_.isEmpty(this.state.salesPerson)) {
      this.setState({
        toastOpen: true,
        notificationText: "Please write down # of sales persons."
      });
      setTimeout(() => {
        this.setState({
          toastOpen: false,
          notificationText: ""
        });
      }, 3000);
      return false;
    }
    return true;
  }

  submitSurvey = () => {
    if (!this.validateForm())
      return;
    const requests = [];
    requests.push(axios.post('/api/users/sendSurveyQuestions',
      {
        name: this.state.name,
        email: this.state.email,
        salesPerson: this.state.salesPerson,
        phone: this.state.phone,
      }));
    requests.push(axios.post('/api/users/sendSurveyReply',
      {
        name: this.state.name,
        email: this.state.email,
        salesPerson: this.state.salesPerson,
        phone: this.state.phone,
      }));
    axios.all(requests).then(axios.spread((...responses) => {
      this.setState({
        toastOpen: true,
        notificationText: "Please Check your Email Now."
      });
      setTimeout(() => {
        this.setState({
          toastOpen: false,
          notificationText: ""
        });
      }, 5000);
    })).catch((err) => {
      console.log(err);
      this.setState({
        toastOpen: true,
        notificationText: "Oops! Error happened while sending a message."
      });
      setTimeout(() => {
        this.setState({
          toastOpen: false,
          notificationText: ""
        });
      }, 3000);
    });
  }

  transitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  render() {
    const { toastOpen, notificationText } = this.state;
    return (
      <div className="auth-page">
        <Snackbar
          open={toastOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          TransitionComponent={this.transitionLeft}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity="error" sx={{ width: '100%', fontSize: 24 }}>
            {notificationText}
          </Alert>
        </Snackbar>
        <div className="line-float">
          <img className="img-responsive" src={lineFloat} alt={'line-float'} />
        </div>
        <div className="curve-float">
          <img className="img-responsive" src={curveFloat} alt={'curve-float'} />
        </div>
        <div className="moon-float">
          <img className="img-responsive" src={moonFloat} alt={'moon-float'} />
        </div>
        <div className="content">
          <Grid item container spacing={0} className="content-container">
            <Grid item xl={8} lg={7} md={6} sm={12} xs={12} className="left">
              <div className="title-container display-flex justify-start align-center">
                <div className="page-title-line"></div>
                <div className="page-title">Easy Commission Calculations</div>
              </div>
              <div className="sub-title">
                Get Payroll Reports by Employee or by Date
              </div>
              <div className="w-100 display-flex justify-end mobile-login">
                <div className="auth-form mobile-form">
                  <div className="top display-flex justify-start align-center">
                    <div>
                      <img className="img-responsive" alt="logo" src={logo} />
                    </div>
                    <div>
                      Try Commission Pay Pro Free Now
                    </div>
                  </div>
                  <div className="middle">
                    {/* <div style={{height: '40px'}}></div> */}
                    <InputForm
                      label={'Your Name'}
                      id={'name'}
                      isAddon={false}
                      placeholderText={''}
                      onChange={ev => this.onChange(ev)}
                      value={this.state.name}
                    />
                    <div style={{ height: '40px' }}></div>
                    <InputForm
                      label={'Your Email'}
                      id={'email'}
                      isAddon={false}
                      placeholderText={''}
                      onChange={ev => this.onChange(ev)}
                      value={this.state.email}
                    />
                    <div style={{ height: '40px' }}></div>
                    <InputForm
                      label={'# of sales persons to track...'}
                      id={'salesPerson'}
                      isAddon={false}
                      placeholderText={''}
                      onChange={ev => this.onChange(ev)}
                      value={this.state.salesPerson}
                    />
                    <div style={{ height: '40px' }}></div>
                    <InputForm
                      label={'Phone, if you want sales to call'}
                      id={'phone'}
                      isAddon={false}
                      placeholderText={''}
                      onChange={ev => this.onChange(ev)}
                      value={this.state.phone}
                    />
                  </div>
                  <div style={{ height: '30px' }}></div>
                  <div className="bottom display-flex justify-between align-center">
                    <div className={"bubbly-button"} style={{ width: "100%" }} onClick={this.submitSurvey}>
                      Show me Commission Pay Pro now
                    </div>
                  </div>
                </div>
              </div>
              <div className="page-image">
                <img className="img-responsive" alt={'page-img'} src={banner} />
              </div>
              <div className="display-flex justify-start align-center terms-policy">
                <a href="https://docs.google.com/document/d/1HSAwK1r1D5Byja2rEmwo5wKY3d1eUdjpR1wylv9NeeU/edit" target="_blank" rel="noopener noreferrer">TERMS OF SERVICE</a>
                <Link to="/contact-us">CONTACT US</Link>
              </div>
            </Grid>
            <Grid item xl={4} lg={5} md={6} sm={12} xs={12} className="right">
              <div className="w-100 display-flex justify-end">
                <div className="auth-form register-form">
                  <div className="top display-flex justify-start align-center">
                    <div>
                      <img className="img-responsive" alt="logo" src={logo} />
                    </div>
                    <div>
                      Try Commission Pay Pro Free Now
                    </div>
                  </div>
                  <div className="middle">
                    <InputForm
                      label={'Your Name'}
                      id={'name'}
                      isAddon={false}
                      placeholderText={''}
                      onChange={ev => this.onChange(ev)}
                      value={this.state.name}
                    />
                    <div style={{ height: '40px' }}></div>
                    <InputForm
                      label={'Your Email'}
                      id={'email'}
                      isAddon={false}
                      placeholderText={''}
                      onChange={ev => this.onChange(ev)}
                      value={this.state.email}
                    />
                    <div style={{ height: '40px' }}></div>
                    <InputForm
                      label={'# of sales persons to track...'}
                      id={'salesPerson'}
                      isAddon={false}
                      placeholderText={''}
                      onChange={ev => this.onChange(ev)}
                      value={this.state.salesPerson}
                    />
                    <div style={{ height: '40px' }}></div>
                    <InputForm
                      label={'Phone, if you want sales to call'}
                      id={'phone'}
                      isAddon={false}
                      placeholderText={''}
                      onChange={ev => this.onChange(ev)}
                      value={this.state.phone}
                    />
                  </div>
                  <div style={{ height: '30px' }}></div>
                  <div className="bottom display-flex justify-between align-center">
                    <div className={"bubbly-button"} style={{ width: "100%" }} onClick={this.submitSurvey}>
                      Show me Commission Pay Pro now
                    </div>
                  </div>
                </div>
              </div>
              <div className="licence">
                <span>© 2020, Published by <span className="marked-text">My Market Partners</span></span>
              </div>
            </Grid>
          </Grid>
        </div>
        {/* <div className="licence">
                <span>© 2020, Published by <span className="marked-text">My Market Partners</span></span>
            </div> */}
      </div>
    );
  }
}

AccessDemo.propTypes = {
  auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
});
export default connect(
  mapStateToProps
)(AccessDemo);