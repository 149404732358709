import ReactGA from 'react-ga4';

export const initGA = () => {       
  ReactGA.initialize('G-CZE2CJPJEJ'); // put your tracking id here
} 

export const GApageView = (page) => {   
  // ReactGA.pageview(page);  
  ReactGA.send({ hitType: "pageview", page: page }); 
}

export const GAevent = (categoryName, eventName, labelName = '') => {
  ReactGA.event({       
      category: categoryName,  // Required
      action: eventName,       // Required
      label: labelName,       
      value: 10,       
      nonInteraction: false     
  });   
}

export const GAexception = (detail) => {
  // ReactGA.exception({ description: detail });
  console.log('GA Exception--------->' , detail)
};