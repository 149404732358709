import React, { Component } from "react";

import envelop from '../../assets/images/login/envelop.png';
import eye from '../../assets/images/login/visible.png';

class InputForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFocus: this.props.value !== '' ? true : false,
            inputValue: this.props.value,
            passwordShown: true,
            disabled: this.props.value ? this.props.value : false,
        }

        this.inputRef = React.createRef();
    }

    focusEvent = () => {
        this.setState({
            isFocus: true
        });
        this.inputRef.current.focus();
    }

    unFocusEvent = () => {
        this.setState({
            isFocus: this.state.inputValue !== '' ? true : false
        });
    }

    onChange = (ev) => {
        this.setState({
            inputValue: ev.target.value
        })
        this.props.onChange({ id: ev.target.id, value: ev.target.value });
    }

    togglePassword = () => {
        this.setState({
            passwordShown: !this.state.passwordShown
        })
    };

    render() {
        const { isFocus, passwordShown } = this.state;
        const { isAddon, label, placeholderText, errors, id, value, disabled } = this.props;

        return <div className={errors ? "input-form has-error" : "input-form"}>
            <input id={id} type={id === 'email' ? id : id === 'password' && passwordShown ? 'password' : 'text'} placeholder={placeholderText} onFocus={this.focusEvent} onBlur={this.unFocusEvent} ref={this.inputRef} onChange={this.onChange} value={value} disabled={disabled}/>
            <div className="label" style={isFocus ? { top: "-12px" } : { top: "12px" }} onClick={this.focusEvent}>{label}</div>
            {
                isAddon &&
                    <div className="input-addon">
                        {
                            label === 'Email' && <img className="img-responsive" alt={'input-addon'} src={envelop} />
                        }
                        {
                            (label === 'Password' || label === 'Create Password') && <img className="img-responsive" alt={'input-addon'} src={eye} onClick={this.togglePassword} />
                        }
                    </div>
            }
        </div>;
    }
}

export default InputForm;