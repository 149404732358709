import React from "react";

const CustomBtn = (props) => {
    return <div className="custom-btn" style={{color: props.color}}>
        {
            props.children
        }
    </div>;
}

export default CustomBtn;